import React, { useState } from "react";
import "./css/device.css";
import styles from "../../DataDashboard.module.scss";
import BackIcon from "../../../../assets/backIcon.svg";
import { dataNew } from "../../../../utils/Data";
import { DataDashBoardHeader } from "../../DataDashboard";
import { useNavigate } from "react-router-dom";
const DeviceAndLocation = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("-1")}>
              Back
            </span>
          </div>
        </div>
        <div className="main_expert_component">
          <h1
            className="main_expert_div_heading"
            style={{ lineHeight: "55px" }}
          >
            Device & Location Data
          </h1>
          <p className="main_device_div_desc">
            information on the device used and geographic location. This
            information is maintained to keep your account secure.
          </p>
          <div className="expert_picker_inner_div">
            <div
              className="browsing_data_inner_div_child"
              style={{
                display: "flex",
                gap: "30px",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {dataNew.map((e) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="img_main"
                    style={{
                      background: `${
                        e.title === "Macbook Pro Gen 12"
                          ? "background: linear-gradient(180deg, #60A9FF 0%, #003A7F 100%)"
                          : ""
                      }`,
                    }}
                  >
                    <img className="" src={e.img} alt="" />
                  </div>
                  <p className="img_title">{e.title}</p>
                  <p className="img_desc">{e.description}</p>
                </div>
              ))}
            </div>
            <div className="browsing_data_inner_div_child">
              <div className="browsing_child_heading">
                <div className="browsingdata_name">
                  <p className="heading_title">logs</p>
                </div>
                <div
                  className="browsing_data_show_more"
                  style={{ margin: "0" }}
                >
                  <p style={{ margin: "0" }} className="show_all">
                    Show All
                  </p>
                </div>
              </div>
              <div className="data_table_expert_picker">
                <table>
                  <tr>
                    <th style={{ padding: "0" }} colSpan="5">
                      <hr style={{ width: "78vw" }} className="table-line" />
                    </th>
                  </tr>
                  <tr>
                    <th>Ip Address</th>
                    <th>Device type</th>
                    <th>Location Data</th>
                    <th>Browser type</th>
                    <th>Date/Time</th>
                  </tr>
                  <tr>
                    <th style={{ padding: "0" }} colSpan="5">
                      <hr style={{ width: "80vw" }} className="table-line" />
                    </th>
                  </tr>
                  <tr>
                    <td>Jill</td>
                    <td>Smith</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceAndLocation;
