import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./RecentProcurement.module.css";
import BackIcon from "../../assets/backIcon.svg";
import clockIcon from "../../assets/MyItem/clockIcon.svg";
import cancleOrderImg from "../../assets/RecentProcurement/CancleOrder.svg";
import track from "../../assets/RecentProcurement/track.svg";
import plusSign from "../../assets/RecentProcurement/PlusSign.svg";
import ChangeShippingAddress from "../../assets/RecentProcurement/ChangeShippingAddress.svg";
import {
  cancle_order_API,
  image_base_url,
  order_review_api,
  product_track_api,
} from "../../utils/APIS/Apis";
import moment from "moment";
import facebookIcon from "../../assets/RecentProcurement/facebook.svg";
import twitterIcon from "../../assets/RecentProcurement/twitterX.svg";
import emojiIcon from "../../assets/RecentProcurement/emojiIconwhite.svg";
import leftIcon from "../../assets/RecentProcurement/leftShap.svg";
import rightIcon from "../../assets/RecentProcurement/rightShap.svg";
import { FeedBack } from "../../components/Feedback/FeedBack";
import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Helmet } from "react-helmet";
import { Base64 } from "js-base64";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { FaRegCopy } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

export const RecentProcurement = () => {
  const [rating, setRating] = useState(0);
  const [writeComment, setWriteComment] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [frdShare, setfrdShare] = useState(false);
  const location = useLocation((state) => state);
  const navigate = useNavigate();
  const productDetails = location?.state;
  const [showTrack, setShowTrack] = useState(false);
  const [trackData, setTrackData] = useState([]);
  const [addData, setAddData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!productDetails) {
      navigate("/");
    }
  }, []);

  const track_product = async () => {
    setShowTrack(!showTrack);
    try {
      const response = await product_track_api(productDetails?.consumer_po_id);
      if (response?.success) {
        setTrackData(response?.shipment_track_activities);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedFile, setSelectedFile] = useState([]);
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFile([...selectedFile, ...files]);

    setAddData({ ...addData, filename: event.target.files[0].name });
  };

  const add_review = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("consumer_uuid", productDetails?.consumer_id);
      body.append("product_uuid", productDetails?.product_id);
      body.append("order_id", productDetails?.order_id);
      body.append("rating", rating);

      if (addData?.title !== undefined) {
        body.append("review_title", addData?.title);
      }
      if (addData?.description !== undefined) {
        body.append("review_description", addData?.description);
      }
      if (addData?.screen_name !== undefined) {
        body.append("screen_name", addData?.screen_name);
      }

      body.append("photos", selectedFile[0]);

      const response = await order_review_api(body);
      setLoading(false);
      if (response.success) {
        toast.success(response?.message);
        setShowShare(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleWriteReviewClick = () => {
    document.getElementById("fileInput").click();
  };

  const cancle_order = async () => {
    try {
      const response = await cancle_order_API(productDetails?.consumer_po_id);
      if (response?.success) {
        toast.success(response.message);
        navigate("/myitems");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.RP_main}>
      <div className="PD-back" style={{ fontSize: "15px" }}>
        <img src={BackIcon} alt="" onClick={() => navigate(-1)} />
        <div>
          <span onClick={() => navigate("/")}>Home</span> /
          <span onClick={() => navigate("/myitems")}> My Items/</span>
          <span>
            {" "}
            Recent Procurements /{" "}
            {productDetails?.Product_Details[0]?.product_name}
          </span>
        </div>
      </div>
      <div className={styles?.RP_1st_right}>
        <img src={clockIcon} alt="" />
        <div className={styles?.recent}>Recent ProcuRements</div>
      </div>
      <div className={styles.item}>
        <div className={styles.item_left}>
          <img
            src={`${image_base_url}${productDetails?.Image_Details[0]?.product_image_url}`}
            alt=""
            className={""}
          />
        </div>
        <div className={styles.item_right}>
          <div className={styles.title}>
            {productDetails?.Product_Details[0]?.product_name}
          </div>
          {writeComment ? (
            <>
              <div className={styles.rvw_title}>
                How would you rate this item?
              </div>
              <div style={{ paddingLeft: "0" }} className={styles.feedback}>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <FeedBack
                    className={styles.feed_item}
                    rating={rating}
                    setRating={setRating}
                    disabled={true}
                  />
                </div>
              </div>
              {showShare ? (
                <>
                  <div className={styles.share_title}>
                    Would you consider sharing a link about your purchase?
                  </div>
                  <div className={styles.share_card}>
                    <div className={styles.share_card_title}>
                      Share this link with friends and get an additional 1% off
                      your next purchase for each person who buys through your
                      link!
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <div className={styles.share_link}>
                        https://demandeyweb.pmpframe.com/productdetails/
                        {Base64.encode(productDetails?.product_id)}/
                        {productDetails?.category[0]?.category_name}
                      </div>
                      <FaRegCopy
                        size={"50px"}
                        color="#fff"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(`https://demandeyweb.pmpframe.com/productdetails/
                      ${Base64.encode(productDetails?.product_id)}/
                      ${productDetails?.category[0]?.category_name}`);
                          toast.success("Copied");
                        }}
                      />
                    </div>
                    <div className={styles.share_social_media}>
                      <FacebookShareButton
                        title={"demandey"}
                        separator=":: "
                        url={`https://demandeyweb.pmpframe.com/productdetails/${Base64.encode(
                          productDetails?.product_id
                        )}/${productDetails?.category[0]?.category_name}`}
                      >
                        <img src={facebookIcon} alt="" />
                      </FacebookShareButton>
                      <TwitterShareButton
                        title={"demandey"}
                        separator=":: "
                        url={`https://demandeyweb.pmpframe.com/productdetails/${Base64.encode(
                          productDetails?.product_id
                        )}/${productDetails?.category[0]?.category_name}`}
                      >
                        <img src={twitterIcon} alt="" />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        title="demandey"
                        image={`${image_base_url}${productDetails?.Image_Details[0]?.product_image_url}`}
                        separator=":: "
                        url={`https://demandeyweb.pmpframe.com/productdetails/${Base64.encode(
                          productDetails?.product_id
                        )}/${productDetails?.category[0]?.category_name}`}
                      >
                        {/* <img src={instaIcon} alt="" /> */}
                        <FaWhatsapp size={25} color="#fff" />
                      </WhatsappShareButton>
                      <RedditShareButton
                        title={"demandey"}
                        separator=":: "
                        url={`https://demandeyweb.pmpframe.com/productdetails/${Base64.encode(
                          productDetails?.product_id
                        )}/${productDetails?.category[0]?.category_name}`}
                      >
                        <img src={emojiIcon} alt="" />
                      </RedditShareButton>
                      <Helmet />
                    </div>
                  </div>
                  {frdShare ? (
                    <>
                      <div className={styles.thanks_you}>Thank you!</div>
                    </>
                  ) : (
                    <>
                      <div className={styles.share_title}>
                        Would you recommend your purchase?
                      </div>
                      <div className={styles.share_frd}>
                        <div className={styles.sign}>
                          <img src={leftIcon} alt="" />
                          <span
                            style={{ color: "#DC3F3F" }}
                            onClick={() => navigate("/myitems")}
                          >
                            No
                          </span>
                        </div>
                        <div
                          className={styles.sign}
                          onClick={() => setfrdShare(true)}
                        >
                          <span style={{ color: "#35C71E" }}>Yes</span>
                          <img src={rightIcon} alt="" />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <form>
                  <div className={styles.rvw_input}>
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="What did you like or dislike?"
                      value={addData?.description}
                      onChange={(e) => {
                        setAddData({ ...addData, description: e.target.value });
                      }}
                    />
                  </div>
                  <div className={styles.rvw_input}>
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="Choose a screen name"
                      value={addData?.screen_name}
                      onChange={(e) => {
                        setAddData({ ...addData, screen_name: e.target.value });
                      }}
                    />
                  </div>
                  <div className={styles.rvw_input}>
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="Add a title"
                      value={addData?.title}
                      onChange={(e) => {
                        setAddData({ ...addData, title: e.target.value });
                      }}
                    />
                  </div>
                  <div className={styles.rvw_input}>
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="Add photos (optional)"
                      value={addData?.filename}
                      readOnly={true}
                    />
                  </div>
                  <div onClick={handleWriteReviewClick}>
                    <img src={plusSign} alt="" className={styles.file_btn} />
                  </div>

                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />

                  <div className={styles.rvw} onClick={add_review}>
                    Write A Review
                  </div>
                </form>
              )}
            </>
          ) : (
            <>
              <div className={styles.price}>
                Purchase Price:
                <span style={{ color: "#000" }}>
                  {productDetails?.Currency_Details[0]?.currency_symbol}
                  {Number(
                    (
                      productDetails?.Product_Price_Details[0]?.product_price *
                      productDetails?.quantity
                    ).toFixed(2)
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
              <div className={styles.delivery}>
                Delivered On:
                {moment(productDetails?.actual_delivery_date).format(
                  "MMM Do, YYYY"
                )}
              </div>
              <div className={styles.cancle} onClick={() => cancle_order()}>
                <img
                  src={cancleOrderImg}
                  alt=""
                  className={styles.cancleIcon}
                />
                <div className={styles.cancleOrder}>Cancel Order</div>
              </div>
              <div className={styles.cancle}>
                <img
                  src={ChangeShippingAddress}
                  alt=""
                  className={styles.cancleIcon}
                />
                <div className={styles.cancleOrder}>
                  Change Shipping Address
                </div>
              </div>
              <div className={styles.cancle} onClick={track_product}>
                <img src={track} alt="" className={styles.cancleIcon} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{ border: "none" }}
                    className={styles.cancleOrder}
                  >
                    Track
                  </div>
                  {showTrack &&
                    trackData.map((v, i) => (
                      <div
                        className={styles.cancle}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: "none",
                            padding: ".2rem 0",
                            height: "auto",
                          }}
                          className={styles.cancleOrder}
                        >
                          Status : {v?.status}
                        </div>
                        <div
                          style={{
                            borderBottom: "none",
                            padding: ".2rem 0",
                            height: "auto",
                          }}
                          className={styles.cancleOrder}
                        >
                          Date : {moment(v?.date).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className={styles.feedback}>
                <div className={styles.feed_content}>
                  How would you rate this item?
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <FeedBack
                    // style={{ width: "50px", height: "50px" }}
                    className={styles.item1}
                    rating={rating}
                    setRating={setRating}
                  />
                </div>
                {rating !== 0 && (
                  <>
                    <div className={styles.tq}>
                      Thank you! Would you consider writing a review?
                    </div>
                    <div
                      className={styles.rvw}
                      onClick={() => setWriteComment(true)}
                    >
                      Write A Review
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <div style={{}}></div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </div>
  );
};
