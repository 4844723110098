import React, { useEffect, useState } from "react";
import styles from "./Queue.module.scss";
import { Modal, Popover, Steps, Tooltip } from "antd";
import deleteIcon from "../../assets/Queue/DeleteIcon.svg";
import iIconPink from "../../assets/Queue/iIconPink.svg";
import bankIcon from "../../assets/Queue/Bank.svg";
import DebitCardIcon from "../../assets/Queue/DebitCard.svg";
import applePay from "../../assets/Queue/applePay.svg";
import paypalIcon from "../../assets/Queue/PayPal.svg";
import gPay from "../../assets/Queue/gPayIcon.svg";
import ArrRight from "../../assets/Queue/ArrRight.svg";
import ArrDown from "../../assets/Queue/ArrDown.svg";
import crossBlueWhite from "../../assets/Queue/x-circle.svg";
import closeIconImg from "../../assets/Queue/CloseIcon.svg";
import editIcon from "../../assets/Queue/edit.svg";
import { ManuAgreement } from "./SmallComponent/ManuAgreement";
import { CheckBox, RadioCheckBox } from "../../components/inputs/CheckBox";
import { WarrentyPop } from "./SmallComponent/Warrenty";
import cancleOrderImg from "../../assets/RecentProcurement/CancleOrder.svg";
import track from "../../assets/RecentProcurement/track.svg";
import ChangeShippingAddress from "../../assets/RecentProcurement/ChangeShippingAddress.svg";
import plusWhite from "../../assets/Queue/plus.svg";
import cardIcon from "../../assets/Queue/card_icon.svg";
import backImage from "../../assets/backIcon.svg";
import walmartImg from "../../assets/CategoryItem/walmart.svg";
import BestBuyImg from "../../assets/CategoryItem/Best_Buy_Logo1.svg";
import AmazonImg from "../../assets/CategoryItem/Amazon_logo.svg";
import { FaMinus, FaPlus } from "react-icons/fa6";

import {
  cancle_order_API,
  card_add_api,
  card_list_api,
  confirm_payment_api,
  create_payment_api,
  get_queue_detail,
  image_base_url,
  my_item_list_api,
  postal_address_add_api,
  postal_address_delete_api,
  postal_address_list_api,
  postal_address_update_api,
  product_track_api,
  queue_delete_api,
  queue_quantity_update_api,
  state_list_api,
  update_billing_address_api,
  update_shipping_address_api,
} from "../../utils/APIS/Apis";
import { toast } from "react-toastify";
import { DropdownAddress } from "../../components/Dropdown/Dropdown";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import sty from "../07RecentProcurement/RecentProcurement.module.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { QueueSlider } from "./SmallComponent/QueueSlider";
import { Base64 } from "js-base64";
import store from "../../redux/store";
import { get_Queue, remove_from_Queue } from "../../redux/productSlice";
import { numberWithCommas } from "../../utils/utils";
import QueueDrawer from "../../components/drawer/QueueDrawer";
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const Queue = ({ setTotalQueue, totalQueue }) => {
  const [Item, setItem] = useState({});
  const [ItemType, setItemType] = useState("");
  const [showManufactureAgree, setShowManufactureAgree] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState("addressdata"); //queuelist paymentdata
  const [ShowLoader, setShowLoader] = useState(false);
  const [shipStatus, setShipStates] = useState(false);
  const [shipData, setShipData] = useState({});
  const [shipData2, setShipData2] = useState({});
  const [CardStatus, setCardStates] = useState(false);
  const [CardData, setCardData] = useState({});
  const [OrderId, setOrderId] = useState();
  const [productData2, setProductData2] = useState(null);
  const [showPriceBreak, setShowPriceBreak] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const location = useLocation((state) => state.state);
  const item = location?.state?.item;
  const count = location?.state?.count;
  const [defaulCardStatus, setDefaulCardStatus] = useState(false);
  const [billStatus, setBillStatus] = useState(false);
  const [openQueueSlider, setOpenQueueSlider] = useState(false);
  const [instant_By, setInstant_By] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!item) {
      navigate("/");
    }
  }, [item]);

  useEffect(() => {
    setCurrentComponent("addressdata");
  }, []);

  useEffect(() => {
    if (location?.state?.id) {
      queueListData();
      setQuantity(item.quantity);
    }
  }, [location?.state?.id]);

  const queueListData = async () => {
    try {
      setShowLoader(true);
      const response = await get_queue_detail(
        location?.state?.q_id,
        localStorage.getItem("zipcode")
      );

      setShowLoader(false);
      if (response.success) {
        setInstant_By(response?.data[0]?.instant_By);
        setShowPriceBreak(true);
        setItemType("NOW");
        setProductData2(response?.data[0]);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const handleQueueList = async (data, v, navigate1 = false) => {
    if (navigate1) {
      setItemType(data);
      setItem(v);
      setCurrentComponent("addressdata");
    } else {
      setItemType(data);
      setItem(v);
    }
  };

  const handleAddressData = (Data, Status) => {
    setShipData(Data);
    setShipStates(Status);
  };
  const handleAddressDataBill = (Data, Status) => {
    setShipData2(Data);
    setBillStatus(Status);
  };
  const handleCardData = (CardData, CardStatus, defaultCard) => {
    setCardData(CardData);
    setCardStates(CardStatus);

    if (CardStatus) {
      setDefaulCardStatus(true);
    } else {
      setDefaulCardStatus(false);
    }
  };
  const handlePaymentData = () => {
    if (CardData.name == undefined) {
      toast.error("Please add payment methed", { toastId: 1 });
      return;
    }
    if (!defaulCardStatus) {
      toast.error("Please add payment methed", { toastId: 1 });
      return;
    }
    setCurrentComponent("queuelist");
  };
  const handlePurchaseData = async () => {
    const data = BankValidation(CardData);
    if (data.length == 0) {
      try {
        setShowLoader(true);
        const body = {
          queue_ids: [Item?.queue_uuid || item.queue_uuid],
          instant_By: ItemType == "NOW" ? true : false,
          terms: 1,
          shipping_address_id: shipData?.postal_address_id,
        };
        const response = await create_payment_api(body);
        setShowLoader(false);
        if (response.success) {
          try {
            setShowLoader(true);
            const obj = {
              card_number: CardData?.card_number.split(" ").join(""),
              expirationDate: CardData?.card_expiration,
              name: CardData?.name,
              cvv: CardData?.cvv.toString(),
              order_id: response.order_id,
              queue_ids: [Item?.queue_uuid || item?.queue_uuid],
              shipping_address_id: shipData?.postal_address_id,
              billing_address_id: shipData2?.postal_address_id,
            };
            const res = await confirm_payment_api(obj);
            setShowLoader(false);
            if (res.success) {
              setOrderId(res?.consumer_po_ids[0]);
              toast(res.message);
              setCurrentComponent("purchasefinalpage");
              setShowPriceBreak(false);
              setTotalQueue(totalQueue - 1);
              store.dispatch(get_Queue(localStorage.getItem("consumer_id")));
            } else if (!res.success) {
              toast.success(res.message);
              setCurrentComponent("queuelist");
            }
          } catch (error) {
            console.log(error);
          }
        } else if (!response.success) {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error(data.map((item) => `${item}\n`));
    }
  };

  const handleAddressSubmit = async () => {
    if (shipStatus) {
      setCurrentComponent("paymentdata");
    } else {
      toast.error("Please select Shipping Address First");
    }
  };
  const handleNavigatetoAddress = () => {
    setCurrentComponent("purchasecomponent");
  };

  const handleClick = () => {
    if (!checkValue) {
      toast.error("Please Accept Agreement");
      return;
    } else {
      setCurrentComponent("purchasecomponent");
    }
  };

  return (
    <div className={styles.Queue}>
      <div className={styles.main}>
        {currentComponent == "purchasefinalpage" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "calc(100% - 20%)",
              padding: "0 10%",
            }}
          >
            <div className={styles.title_2}>
              "You purchased {productData2.Product_Details[0]?.product_name}!
              Congratulations {shipData?.first_name}, Thank you for being our
              partner."
            </div>
            <div className={styles.title_3}>
              Thank you for your order. We’ll send a confirmation when your
              order ships. Your estimated delivery date is indicated below. If
              you would like to view the status of your order or make any
              changes to it, you may do so below or visit Your Orders.
            </div>
            {count - 1 == 0 ? (
              <div className={styles.title_4}></div>
            ) : (
              <div
                className={styles.title_4}
                onClick={() => setOpenQueueSlider(true)}
              >
                <div>
                  {count - 1} More {count > 1 ? "Items" : "Item"} In Queue
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={styles.title}>Purchase from manufacturer</div>
          </>
        )}
      </div>
      <div className={"SAP-back"} style={{ padding: ".5rem 2rem" }}>
        <img src={backImage} alt="" className="" onClick={() => navigate(-1)} />
        <div>
          <span onClick={() => navigate("/")}>Home </span>/
          <span
            onClick={() =>
              navigate("/category", {
                state: {
                  productpicker: false,
                },
              })
            }
          >
            {" "}
            Category
          </span>{" "}
          /{" "}
          <span
            onClick={() => {
              if (productData2?.category[0]?.category_name != "Laptops") {
                navigate(
                  `/categoryitem/${Base64.encode(
                    productData2?.category[0]?.category_uuid
                  )}/${productData2?.category[0]?.category_name}`
                );
              } else {
                navigate(
                  `/laptopitem/${Base64.encode(
                    productData2?.category[0]?.category_uuid
                  )}/${productData2?.category[0]?.category_name}`
                );
              }
            }}
          >
            {productData2?.category[0]?.category_name}{" "}
          </span>
          /{" "}
          <span
            onClick={() =>
              navigate(
                `/productdetails/${Base64.encode(productData2?.product_uuid)}/${
                  productData2?.category[0]?.category_name
                }`
              )
            }
          >
            {" "}
            {productData2?.Product_Details[0]?.product_name}
            {productData2?.product_name}{" "}
          </span>
        </div>
      </div>
      <div
        className={styles.second_main}
        style={{
          height: currentComponent == "purchasefinalpage" ? "fit-content" : "",
        }}
      >
        {(currentComponent == "queuelist" ||
          currentComponent == "addressdata" ||
          currentComponent == "paymentdata") && (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  padding: "1.5rem 2rem",
                  height: "fit-content",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  width:
                    currentComponent == "purchasefinalpage"
                      ? "fit-content"
                      : "calc(100% - 4rem)",
                }}
                className={styles.cards}
              >
                <div className={styles.cards_heading}>
                  <div className={styles.cards_heading_2}>
                    <div className={styles.cards_heading_number}>1</div>
                    <div className={styles.cards_heading_title}>
                      shipping address
                    </div>
                  </div>

                  <img
                    src={
                      currentComponent !== "addressdata" ? ArrRight : ArrDown
                    }
                    onClick={() => setCurrentComponent("addressdata")}
                    alt="down"
                  />
                </div>
                {currentComponent === "addressdata" && (
                  <AddressData
                    handleAddressData={handleAddressData}
                    setShowLoader={setShowLoader}
                    queueListData={queueListData}
                  />
                )}
              </div>
              <div
                style={{
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  padding: "1.5rem 2rem",
                  height: "fit-content",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  width:
                    currentComponent == "purchasefinalpage"
                      ? "fit-content"
                      : "calc(100% - 4rem)",
                }}
                className={styles.cards}
              >
                <div className={styles.cards_heading}>
                  <div className={styles.cards_heading_2}>
                    <div className={styles.cards_heading_number}>2</div>
                    <div className={styles.cards_heading_title}>
                      Payment Method
                    </div>
                  </div>

                  <img
                    src={
                      currentComponent !== "paymentdata" ? ArrRight : ArrDown
                    }
                    alt="down"
                    onClick={() => setCurrentComponent("paymentdata")}
                  />
                </div>

                {currentComponent === "paymentdata" && (
                  <>
                    <AddressDataBIll
                      handleAddressData={handleAddressDataBill}
                      setShowLoader={setShowLoader}
                    />

                    <PaymentData
                      setShowLoader={setShowLoader}
                      OrderId={OrderId}
                      handleCardData={handleCardData}
                    />
                  </>
                )}
              </div>
              <div
                style={{
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  padding: "1.5rem 2rem",
                  height: "fit-content",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  width:
                    currentComponent == "purchasefinalpage"
                      ? "fit-content"
                      : "calc(100% - 4rem)",
                }}
                className={styles.cards}
              >
                <div className={styles.cards_heading}>
                  <div className={styles.cards_heading_2}>
                    <div className={styles.cards_heading_number}>3</div>
                    <div className={styles.cards_heading_title}>
                      view data to manufacturer
                    </div>
                  </div>

                  <img
                    src={currentComponent !== "queuelist" ? ArrRight : ArrDown}
                    onClick={() => setCurrentComponent("queuelist")}
                    alt="down"
                  />
                </div>
                {currentComponent === "queuelist" && (
                  <ManuAgreement
                    setShowManufactureAgree={setShowManufactureAgree}
                    handleNavigatetoAddress={handleNavigatetoAddress}
                    product={item}
                    setItemType={setItemType}
                    checkValue={checkValue}
                    setCheckValue={setCheckValue}
                    ItemType={ItemType}
                    brandShow={true}
                    addressData={shipData}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {(currentComponent == "purchasecomponent" ||
          currentComponent == "purchasefinalpage") && (
          <div
            style={{
              boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "3rem 2rem",
              height: "fit-content",
              backgroundColor: "white",
              borderRadius: "10px",
              width:
                currentComponent == "purchasefinalpage"
                  ? "fit-content"
                  : "calc(100% - 4rem)",
            }}
            className={styles.cards}
          >
            {currentComponent === "purchasecomponent" && (
              <PurchaseComponent
                setDefaulCardStatus
                ItemType={ItemType}
                setShowLoader={setShowLoader}
                product={item}
                Quan={quantity}
                shipData={shipData}
                cardData={CardData}
                handlePurchaseData={handlePurchaseData}
                setCurrentComponent={setCurrentComponent}
              />
            )}
            {currentComponent === "purchasefinalpage" && (
              <PurchaseFinalPage
                orderId={OrderId}
                ItemType={ItemType}
                setShowLoader={setShowLoader}
                product={item}
                Quan={quantity}
                shipData={shipData}
                cardData={CardData}
                handlePurchaseData={handlePurchaseData}
                setCurrentComponent={setCurrentComponent}
              />
            )}
          </div>
        )}

        {showPriceBreak && currentComponent != "purchasefinalpage" && (
          <div className={styles.price}>
            <div className={styles.price_main}>
              <QueuelistData
                setTotalQueue={setTotalQueue}
                handleQueueList={handleQueueList}
                setShowLoader={setShowLoader}
                ShowLoader={ShowLoader}
                product={item}
                queueId={item?.queue_uuid}
                setQuantity={setQuantity}
                ItemType={ItemType}
              />
              <div className={styles.price_1}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    gap: 10,
                    marginBottom: 10,
                    marginTop: -10,
                  }}
                >
                  <div className="or-get-it-form">Other prices</div>
                  <div className="CI-compare">
                    <div>
                      <img src={walmartImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {productData2?.Currency_Details[0]?.currency_symbol}
                        {Number(
                          productData2?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <img src={BestBuyImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {productData2?.Currency_Details[0]?.currency_symbol}
                        {Number(
                          productData2?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <img src={AmazonImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {productData2?.Currency_Details[0]?.currency_symbol}
                        {Number(
                          productData2?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignSelf: "center",
                    marginBlock: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        border: instant_By
                          ? "1px solid #5258D9"
                          : "1px solid #DEDEDE",
                        borderRadius: 10,
                        padding: "1rem",
                        cursor: "pointer",
                        backgroundColor: instant_By ? "#F6F7FF" : "#fff",
                      }}
                      onClick={() => setInstant_By(true)}
                    >
                      <div
                        style={{
                          color: "#1D1D1F",
                          fontSize: 15,
                          fontWeight: "600",
                        }}
                      >
                        Buy it Now
                      </div>
                      <div
                        style={{
                          color: "#000",
                          fontSize: 27,
                          fontWeight: "500",
                          textAlign: "center",
                          marginBlock: "0.5rem",
                        }}
                      >
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          productData2?.Product_Price_Details[0]?.product_price
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      <div
                        style={{
                          border: "1px solid #5258D9",
                          borderRadius: 5,
                          padding: "0.5rem",
                          backgroundColor: "#B8F8E9",
                          fontSize: 12,
                          color: "#252BBE",
                          fontWeight: "400",
                          textAlign: "center",
                          lineHeight: "18px",
                        }}
                      >
                        Your data saves
                        <br />
                        you{" "}
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          {productData2?.Currency_Details[0]?.currency_symbol}
                          {Number(
                            productData2?.Product_Price_Details[0]?.instant_save
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                    <Tooltip
                      color="#5258D9"
                      title={`‘Buy it Now’ empowers you to save significantly by purchasing directly from the manufacturer's inventory rather than the costly and inefficient retail and ecommerce supply chain.`}
                    >
                      <div className="PBID-1-i">
                        <span>?</span>
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      height: "120px",
                      width: 1,
                      backgroundColor: "#252BBE",
                      alignSelf: "center",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        border: !instant_By
                          ? "1px solid #5258D9"
                          : "1px solid #DEDEDE",
                        borderRadius: 10,
                        padding: "1rem",
                        cursor: "pointer",
                        backgroundColor: !instant_By ? "#F6F7FF" : "#fff",
                      }}
                      onClick={() => setInstant_By(false)}
                    >
                      <div
                        style={{
                          color: "#1D1D1F",
                          fontSize: 15,
                          fontWeight: "600",
                        }}
                      >
                        Wait and Save
                      </div>
                      <div
                        style={{
                          color: "#000",
                          fontSize: 27,
                          fontWeight: "500",
                          textAlign: "center",
                          marginBlock: "0.5rem",
                        }}
                      >
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          productData2?.Product_Price_Details[0]?.wait_price
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      <div
                        style={{
                          border: "1px solid #5258D9",
                          borderRadius: 5,
                          padding: "0.5rem",
                          backgroundColor: "#B8F8E9",
                          fontSize: 12,
                          color: "#252BBE",
                          fontWeight: "400",
                          textAlign: "center",
                          lineHeight: "18px",
                        }}
                      >
                        Your data saves
                        <br />
                        you{" "}
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          {productData2?.Currency_Details[0]?.currency_symbol}
                          {Number(
                            productData2?.Product_Price_Details[0]?.wait_save
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                    <Tooltip
                      color="#5258D9"
                      title={`‘Wait and Save’ empowers you to order a product before it’s manufactured. While this increases the wait time, you benefit from incredible savings!`}
                    >
                      <div className="PBID-1-i">
                        <span>?</span>
                      </div>
                    </Tooltip>
                  </div>
                </div>

                <div className={styles.price_breack}>price breakdown</div>
                {!instant_By && (
                  <div className={styles.price_desc}>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}>
                        Manufacturer (WAIT AND SAVE)
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]?.wait_price *
                            quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}>
                        Delivery (
                        {moment(
                          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                        ).format("MM/DD/YYYY")}
                        ){/* ).format("MMMM Do YYYY")} */}
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForWaitAndSaveBy?.shippingCost *
                            quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}>Demandey's 7%</div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForWaitAndSaveBy
                              ?.demandeyCharges * quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}> State Tax 6.25%</div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForWaitAndSaveBy?.stateTax *
                            quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}>
                        <Tooltip
                          title={
                            "Payment fees vary depending on your payment provider"
                          }
                        >
                          <img
                            src={iIconPink}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>
                      <div className={styles.td_2_1}>
                        Credit Card Processing Fee
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForWaitAndSaveBy?.processingFee *
                            quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_underLine}>
                        <Popover
                          content={<WarrentyPop close={hide} />}
                          trigger="click"
                          open={open}
                          placement="left"
                          onOpenChange={handleOpenChange}
                        >
                          Add Extended Warranty
                        </Popover>
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        0.00{" "}
                      </div>
                    </div>
                  </div>
                )}
                {instant_By && (
                  <div className={styles.price_desc}>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}>
                        Manufacturer (BUY IT NOW)
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.product_price * quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}>
                        Delivery (
                        {moment(
                          new Date().getTime() + 1 * 24 * 60 * 60 * 1000
                          // ).format("MMMM Do YYYY")}
                        ).format("MM/DD/YYYY")}
                        )
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForInstantBy?.shippingCost *
                            quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}>Demandey's 7%</div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForInstantBy?.demandeyCharges *
                            quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_2_1}> State Tax 6.25%</div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForInstantBy?.stateTax * quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <Tooltip
                        title={
                          "Payment fees vary depending on your payment provider"
                        }
                      >
                        <img
                          src={iIconPink}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>

                      <div className={styles.td_2_1}>
                      Credit Card Processing Fee
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          (
                            productData2?.Product_Price_Details[0]
                              ?.priceBreakDownForInstantBy?.processingFee *
                            quantity
                          ).toFixed(2)
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </div>
                    </div>
                    <div className={styles.price_desc_row}>
                      <div className={styles.td_1_1}></div>
                      <div className={styles.td_underLine}>
                        <Popover
                          content={<WarrentyPop close={hide} />}
                          trigger="click"
                          open={open}
                          placement="left"
                          onOpenChange={handleOpenChange}
                        >
                          Add Extended Warranty
                        </Popover>
                      </div>
                      <div className={styles.td_3_1}>
                        {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                        0.00{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.price_2}>
                <div className={styles.price_amt}>
                  {productData2?.Currency_Details[0]?.currency_symbol}{" "}
                  {!instant_By &&
                    numberWithCommas(
                      Number(
                        productData2?.Product_Price_Details[0]
                          ?.priceBreakDownForWaitAndSaveBy?.actualTotal *
                          quantity
                      ).toFixed(2)
                    )}
                  {instant_By &&
                    Number(
                      (
                        productData2?.Product_Price_Details[0]
                          ?.priceBreakDownForInstantBy?.actualTotal * quantity
                      ).toFixed(2)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </div>
              </div>

              {currentComponent === "queuelist" && (
                <div
                  className={styles.price_btn}
                  style={{
                    backgroundColor: checkValue ? "" : "#B3B5E8",

                    border: checkValue ? "" : "2px solid #B3B5E8",
                    color: checkValue ? "" : "#fff",
                  }}
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <span>I accept the Agreement</span>
                </div>
              )}
              {currentComponent === "addressdata" && (
                <div
                  className={styles.price_btn}
                  onClick={() => {
                    handleAddressSubmit();
                  }}
                >
                  <span>Use this Address</span>
                </div>
              )}
              {currentComponent === "paymentdata" && (
                <div
                  className={styles.price_btn}
                  onClick={() => {
                    if (defaulCardStatus) {
                      handlePaymentData();
                    } else {
                      toast.error("Please Select Card First");
                    }
                  }}
                >
                  <span>Use this Payment Method</span>
                </div>
              )}
              {currentComponent === "purchasecomponent" && (
                <div
                  className={styles.price_btn}
                  onClick={() => {
                    if (defaulCardStatus) {
                      handlePurchaseData();
                    } else {
                      toast.error("Please Select Card First ");
                    }
                  }}
                >
                  <span>Purchase</span>
                </div>
              )}

              {currentComponent == "queuelist" && (
                <div
                  style={{ textAlign: "center" }}
                  className={styles.price_content}
                >
                  To procure your product at this price you must first consent
                  to the Manufacturer’s Agreement.
                </div>
              )}
              {currentComponent == "addressdata" && (
                <div
                  style={{ textAlign: "center" }}
                  className={styles.price_content}
                >
                  Choose a shipping address to continue your queue. You'll still
                  have a chance to review & edit your order before it's final.
                </div>
              )}
              {currentComponent == "paymentdata" && (
                <div
                  style={{ textAlign: "center" }}
                  className={styles.price_content}
                >
                  Choose a payment method to continue your queue. You'll still
                  have a chance to review and edit your order before it's final.
                </div>
              )}
            </div>
          </div>
        )}
        {showManufactureAgree && (
          <Modal
            closeIcon={<img src={closeIconImg} alt="" />}
            title=""
            centered
            open={showManufactureAgree}
            footer={false}
            width={"80%"}
            style={{ padding: "0", margin: "0" }}
            onCancel={() => setShowManufactureAgree(false)}
          >
            <ManuAgreement
              setShowManufactureAgree={setShowManufactureAgree}
              handleNavigatetoAddress={handleNavigatetoAddress}
              product={item}
              setItemType={setItemType}
              checkValue={checkValue}
              setCheckValue={setCheckValue}
              ItemType={ItemType}
            />
          </Modal>
        )}
      </div>
      {currentComponent == "purchasefinalpage" && (
        <div className={styles.PC_btn_div}>
          <div
            className={styles.PC_btn_1}
            onClick={() =>
              navigate("/category", {
                state: {
                  productpicker: false,
                },
              })
            }
          >
            Continue Shopping
          </div>
          {count - 1 > 0 && (
            <div
              className={styles.PC_btn_2}
              onClick={() => setOpenQueueSlider(true)}
            >
              Purchase Next Item
            </div>
          )}
        </div>
      )}

      <QueueDrawer open={openQueueSlider} setOpen={setOpenQueueSlider} />

      {ShowLoader && <Loader loading={ShowLoader} />}
    </div>
  );
};

export const QueuelistData = ({
  setTotalQueue,
  setQuantity,
  queueId,
  handleQueueList,
  setShowLoader,
  ShowLoader,
  product,
  ItemType,
}) => {
  const [QueueList, setQueueList] = useState([]);
  const [showItem, setShowItem] = useState(false);

  useEffect(() => {
    fetchQueueList(queueId);
  }, [queueId]);

  const fetchQueueList = async (id) => {
    try {
      setShowLoader(true);
      const response = await my_item_list_api(
        localStorage.getItem("consumer_id")
      );
      setShowLoader(false);
      if (response.success) {
        if (id) {
          const data = response.data.filter((v, i) => v?.queue_uuid == id);
          setQueueList(data.length > 0 ? data[0] : null);
          setQuantity(data.length > 0 && data[0]?.quantity);
          setShowItem(true);
        }
      } else if (!response.success) {
        toast("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();

  const handleUpdateQueue = async (queue_id, qty) => {
    try {
      if (qty === 0) {
        const response = await queue_delete_api(queue_id);
        if (response.success) {
          fetchQueueList(queue_id);
          setTotalQueue(0);
          navigate("/category", {
            state: {
              productpicker: false,
            },
          });
        }
      } else {
        const response = await queue_quantity_update_api(queue_id, qty);
        if (response.success) {
          fetchQueueList(queue_id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {showItem && (
        <>
          <div style={{ margin: "0rem 0 2rem 0" }} className={styles.card_2}>
            <img
              style={{ width: "148px", height: "97px" }}
              src={`${image_base_url}${QueueList?.Image_Details[0]?.product_image_url}`}
              alt=""
              className={styles.img}
            />
            <div style={{ paddingTop: 0 }} className={styles.card_content}>
              <div className={styles.card_content_2} style={{ color: "black" }}>
                {QueueList?.Product_Details[0]?.product_name}
              </div>
              <div className={styles.card_content_2}>
                {QueueList?.Product_Details[0]?.product_description}
              </div>
              <div
                style={{
                  color: "#5258D9",
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                  textDecoration: "underline",
                  textTransform: "capitalize",
                  wordWrap: "break-word",
                }}
              >
                Buy It now:{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  Next Day Delivery
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <div
                  style={{ marginTop: "0" }}
                  className={styles.card_content_3}
                >
                  <div
                    style={{
                      width: "35px",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      cursor:
                        QueueList?.quantity == 1 ? "not-allowed" : "pointer",
                    }}
                    onClick={() =>
                      QueueList?.quantity == 1 ||
                      handleUpdateQueue(
                        QueueList?.queue_uuid,
                        QueueList?.quantity - 1
                      )
                    }
                  >
                    <FaMinus
                      color={QueueList?.quantity == 1 ? "gray" : "#252BBE"}
                      size={15}
                    />
                  </div>
                  <div>{QueueList?.quantity}</div>
                  <div
                    style={{
                      width: "35px",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleUpdateQueue(
                        QueueList?.queue_uuid,
                        QueueList?.quantity + 1
                      )
                    }
                  >
                    <FaPlus color="#252BBE" size={15} />
                  </div>
                </div>
                <div
                  className={styles.card_1}
                  style={{ justifyContent: "flex-start" }}
                >
                  <img
                    src={deleteIcon}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      store
                        .dispatch(remove_from_Queue(QueueList?.queue_uuid))
                        .unwrap()
                        .then(() => navigate("/category"))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const AddressData = ({
  handleAddressData,
  setShowLoader,
  queueListData,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [addData, setAddData] = useState({
    mobile: "",
    email: "",
    first_name: "",
    last_name: "",
  });
  const [ShipAdd, setShipAdd] = useState(true);
  const [billStatus, setBillStatus] = useState(true);
  const [addressList, setAddressList] = useState([]);
  const [showList, setshowList] = useState(true);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [shipStatus, setShipStates] = useState(false);
  const [shipData, setShipData] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [openMostView, setOpenMostView] = useState(false);

  useEffect(() => {
    fetchPostelAddress();
    fetchStateList();
  }, []);

  useEffect(() => {
    if (!ShipAdd) {
      setBillStatus(false);
    }
  }, [ShipAdd]);

  useEffect(() => {
    const fetchData = () => {
      handleAddressData(shipData, shipStatus);
    };
    fetchData();
  }, [shipStatus, shipData]);

  const fetchStateList = async () => {
    try {
      setShowLoader(true);
      const res = await state_list_api();
      setShowLoader(false);
      if (res.success) {
        setStateList(res.data);
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPostelAddress = async () => {
    try {
      const response = await postal_address_list_api();
      if (response.success) {
        if (response?.data.length !== 0) {
          const data = response?.data.reverse();
          setAddressList(data);
          if (data.length !== 0) {
            const data2 = response?.data.filter((v) => v.useShippingAdd == 1);
            if (data2.length !== 0) {
              setShipData(data2[0]);
              setAddData(data2[0]);
              handleAddressData(data2[0], true);
              setShipStates(true);
            }
          }
        } else {
          setShowAddAddress(true);
          setshowList(false);
          setShowAdd(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const update_address = async () => {
    const data = valiDate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const response = await postal_address_update_api(
          addData,
          addData?.postal_address_id,
          ShipAdd
        );
        setShowLoader(false);
        if (response.success) {
          setAddData({});
          toast.success(response.message);
          setShowAddAddress(false);
          setEditAddress(false);
          fetchPostelAddress();
          setshowList(true);
          setShowAdd(false);
        } else if (!response.success) {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const delete_address = async (id, current_shipping_address) => {
    if (current_shipping_address == 1) {
      toast.error("please change shipping Address first");
    } else {
      try {
        setShowLoader(true);
        const response = await postal_address_delete_api(id);
        setShowLoader(false);
        if (response.success) {
          fetchPostelAddress();
          toast(response.message);
        } else if (!response.success) {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const add_address = async () => {
    const data = valiDate(addData);
    if (data.length == 0) {
      try {
        setShowLoader(true);
        const obj = {
          consumer_id: localStorage.getItem("consumer_id"),
          first_name: addData?.first_name,
          last_name: addData?.last_name,
          zipCode: addData?.zipCode,
          address: addData?.address,
          city: addData?.city,
          state_id: addData?.state_id,
          logitude: "31.42.223",
          latitude: "-94923.2323.23",
          mobile: addData?.mobile,
          email: addData?.email,
        };
        const response = await postal_address_add_api(obj, ShipAdd);
        setShowLoader(false);
        if (response.success) {
          setAddData({});
          toast.success(response.message);
          setshowList(true);
          setShowAddAddress(false);
          fetchPostelAddress();
          setShowAdd(false);
        } else if (!response.message) {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };
  const shipping_address_update = async (id, v) => {
    try {
      setShowLoader(true);
      const response = await update_shipping_address_api(id);
      setShowLoader(false);
      if (response.success) {
        fetchPostelAddress();
        toast.success(response.message, {
          hideProgressBar: true,
        });
        setShipStates(true);
        setShipData(v);
      } else if (!response.success) {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {showAdd && (
        <div className={styles.add_1}>
          {addressList.map((v, i) => (
            <div
              style={{
                padding: "1rem",
                // width: "40%",
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 4px 0px",
              }}
              className={styles.pay_bill}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  width: "100%",
                }}
                key={i}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                    color: "#252bbe",
                    fontSize: "15px",
                  }}
                >
                  <span>{`${v.first_name} ${v.last_name}`}</span>
                  <span>{`${v.address} ${v.city} ${v.zipCode}`}</span>
                  <span>Mobile Number :- {v.mobile}</span>
                  <span>Email :- {v.email}</span>
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      color: "#252bbe",
                      marginTop: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAddData(v);
                        setShowAddAddress(true);
                        setshowList(false);
                        setEditAddress(true);
                      }}
                      src={editIcon}
                      alt="edit"
                    />
                    <img
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        delete_address(v.postal_address_id, v.useShippingAdd)
                      }
                      src={deleteIcon}
                      alt="delete"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            color: "#252bbe",
            cursor: "pointer",
          }}
          className={styles.add_1}
        >
          Name & Contact Details
        </div>

        {showAdd && addressList.length !== 0 && (
          <img
            src={crossBlueWhite}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              setAddData(shipData);
              setShowAdd(!showAdd);
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          boxShadow: "none",
        }}
        className={styles.pay_card}
      >
        {showAdd ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div className={styles.input_2}>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>First Name</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  disabled={!showAdd}
                  required={true}
                  type={"text"}
                  name="First Name"
                  label="First Name"
                  value={addData?.first_name}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      first_name: e.target.value
                        .trimStart()
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .replace(/^[0-9]/, "")
                        .replace(/\s{2,}/g, " "),
                    });
                  }}
                />
              </FormControl>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Last Name</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  disabled={!showAdd}
                  required={true}
                  type={"text"}
                  name="Last Name"
                  label="Last Name"
                  value={addData?.last_name}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      last_name: e.target.value
                        .trimStart()
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .replace(/^[0-9]/, "")
                        .replace(/\s{2,}/g, " "),
                    });
                  }}
                />
              </FormControl>
            </div>
            <div className={styles.input_2}>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Phone Number</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  required={true}
                  type={"text"}
                  name="Phone Number"
                  label="Phone Number"
                  disabled={!showAdd}
                  value={addData.mobile}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    const truncatedValue = numericValue.slice(0, 10);
                    setAddData({
                      ...addData,
                      mobile: truncatedValue.trim(),
                    });
                  }}
                />
              </FormControl>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  required={true}
                  type={"text"}
                  name="Email"
                  label="Email"
                  disabled={!showAdd}
                  value={addData.email}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      email: e.target.value.trim(),
                    });
                  }}
                />
              </FormControl>
            </div>
          </div>
        ) : (
          <div
            style={{
              padding: "1rem",
              width: "fit-content",
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
              borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 4px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                  color: "#252bbe",
                  fontSize: "15px",
                }}
              >
                <span>{`${addData?.first_name} ${addData?.last_name}`}</span>
                <span>{`${addData?.address} ${addData?.city} ${addData?.zipCode}`}</span>
                <span>Mobile Number :- {addData?.mobile}</span>
                <span>Email :- {addData?.email}</span>
                <div
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    color: "#252bbe",
                    marginTop: "1rem",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    style={{
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAddData(addData);
                      setShowAddAddress(true);
                      setShowAdd(true);
                      setshowList(false);
                      setEditAddress(true);
                    }}
                    src={editIcon}
                    alt="edit"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ borderTop: "1px solid #B7B9EC", width: "100%" }}></div>

        {!showAdd && (
          <div className={styles.queue_add_ship_div}>
            <div className={styles.add_select_title}>Select your Address:</div>
            <div className={styles.add_add_btn}>
              <div className={styles.plus_icon_white}>
                <img src={plusWhite} alt="" />
              </div>
              <div
                className={styles.ship_btn_content}
                onClick={() => {
                  setShowAdd(!showAdd);
                  setAddData({
                    ...addData,
                    first_name: "",
                    last_name: "",
                    mobile: "",
                    email: "",
                    state_id: "",
                    address: "",
                    city: "",
                    zipCode: "",
                  });
                }}
              >
                Add an Address
              </div>
            </div>
          </div>
        )}

        {showAdd && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Address</InputLabel>
                <OutlinedInput
                  width={"90%"}
                  required={true}
                  type={"text"}
                  name="Address"
                  label="Address"
                  value={addData?.address}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      address: e.target.value
                        .trimStart()
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .replace(/\s{2,}/g, " "),
                    });
                  }}
                />
              </FormControl>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>City/Town</InputLabel>
                <OutlinedInput
                  width={"90%"}
                  required={true}
                  type={"text"}
                  name="City/Town"
                  label="City/Town"
                  value={addData?.city}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      city: e.target.value
                        .trimStart()
                        .replace(/[^a-zA-Z ]/g, "")
                        .replace(/\s{2,}/g, " "),
                    });
                  }}
                />
              </FormControl>
              <div className={styles.input_2}>
                <FormControl style={{ flex: 1 }} variant="outlined">
                  <InputLabel>Zip Code</InputLabel>
                  <OutlinedInput
                    width={"50%"}
                    required={true}
                    type={"text"}
                    name="Zip Code"
                    label="Zip Code"
                    value={addData?.zipCode}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "");
                      const truncatedValue = numericValue.slice(0, 5);
                      setAddData({
                        ...addData,
                        zipCode: truncatedValue.trim(),
                      });
                    }}
                  />
                </FormControl>
                <TextField
                  style={{ flex: 1 }}
                  className="InputWidth"
                  required
                  value={addData?.state_id}
                  select
                  onChange={(e) => {
                    setAddData({ ...addData, state_id: e.target.value });
                  }}
                  name="State"
                  label="State"
                  defaultValue="select"
                >
                  <MenuItem
                    required={true}
                    key={"Select"}
                    value={"Select"}
                    defaultChecked={true}
                  >
                    Select
                  </MenuItem>
                  {stateList.map((option, i) => (
                    <MenuItem
                      required={true}
                      value={option.abbreviation}
                      key={i}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            {editAddress ? (
              <div className={styles.card_add_btn} onClick={update_address}>
                Edit Address
              </div>
            ) : (
              <div className={styles.card_add_btn} onClick={add_address}>
                Add Address
              </div>
            )}
          </div>
        )}

        {!showAdd && (
          <>
            <DropdownAddress
              onChange={(e) => {
                setShipData(e.target.value);
              }}
              width={"90%"}
              title={
                <>
                  <div className={styles.add_drop_content}>
                    {shipData?.first_name} {shipData?.last_name},{" "}
                    {shipData?.address},{shipData?.city}-{shipData?.zipCode}{" "}
                    {shipData?.mobile}
                  </div>
                </>
              }
              setOpen={setOpenMostView}
              open={openMostView}
              body={
                <>
                  {addressList.map((v, i) => (
                    <div
                      onClick={() => {
                        localStorage.setItem("zipcode", v.zipCode);
                        setShipData(v);
                        setAddData(v);
                        shipping_address_update(v.postal_address_id, v);
                        queueListData();
                      }}
                      value={v}
                      className={styles.add_drop_1}
                      key={i}
                    >
                      <div className={styles.add_drop_title}>Home:</div>
                      <div className={styles.add_drop_content}>
                        {v?.first_name} {v?.last_name}, {v?.address},{v?.city}-
                        {v?.zipCode} USA, {v?.mobile}
                      </div>
                    </div>
                  ))}
                </>
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export const AddressDataBIll = ({ handleAddressData, setShowLoader }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [addData, setAddData] = useState({
    mobile: "",
    email: "",
    first_name: "",
    last_name: "",
  });
  const [ShipAdd, setShipAdd] = useState(true);
  const [billStatus, setBillStatus] = useState(true);
  const [addressList, setAddressList] = useState([]);
  const [showList, setshowList] = useState(true);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [shipStatus, setShipStates] = useState(false);
  const [shipData, setShipData] = useState(false);
  const [openMostView, setOpenMostView] = useState(false);
  const [shipData2, setShipData2] = useState({});

  useEffect(() => {
    fetchPostelAddress();
    fetchStateList();
  }, []);

  useEffect(() => {
    if (!ShipAdd) {
      setBillStatus(false);
    }
  }, [ShipAdd]);

  useEffect(() => {
    const fetchData = () => {
      handleAddressData(shipData, shipStatus);
    };
    fetchData();
  }, [shipStatus, shipData]);

  const fetchStateList = async () => {
    try {
      setShowLoader(true);
      const res = await state_list_api();
      setShowLoader(false);
      if (res.success) {
        setStateList(res.data);
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPostelAddress = async () => {
    try {
      const response = await postal_address_list_api();
      if (response.success) {
        if (response?.data.length !== 0) {
          const data = response?.data.reverse();
          setAddressList(data);
          const data2 =
            response?.data?.length == 1
              ? response?.data
              : response?.data.filter((v) => v.useBillingAdd == 1);
          if (data2.length !== 0) {
            setAddData(data2[0]);
            setShipData2(data2[0]);
            setShipStates(true);
          }
        } else {
          setShowAddAddress(true);
          setshowList(false);
          setShowAdd(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const update_address = async () => {
    const data = valiDate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const response = await postal_address_update_api(
          addData,
          addData?.postal_address_id,
          ShipAdd
        );
        setShowLoader(false);
        if (response.success) {
          setAddData({});
          toast.success(response.message);
          setShowAddAddress(false);
          setEditAddress(false);
          fetchPostelAddress();
          setshowList(true);
          setShowAdd(false);
        } else if (!response.success) {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const delete_address = async (id, current_shipping_address) => {
    if (current_shipping_address == 1) {
      toast.error("please change shipping Address first");
    } else {
      try {
        setShowLoader(true);
        const response = await postal_address_delete_api(id);
        setShowLoader(false);
        if (response.success) {
          fetchPostelAddress();
          toast(response.message);
        } else if (!response.success) {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const add_address = async () => {
    const data = valiDate(addData);
    if (data.length == 0) {
      try {
        setShowLoader(true);
        const obj = {
          consumer_id: localStorage.getItem("consumer_id"),
          first_name: addData?.first_name,
          last_name: addData?.last_name,
          zipCode: addData?.zipCode,
          address: addData?.address,
          city: addData?.city,
          state_id: addData?.state_id,
          logitude: "31.42.223",
          latitude: "-94923.2323.23",
          mobile: addData?.mobile,
          email: addData?.email,
        };
        const response = await postal_address_add_api(obj, ShipAdd);
        setShowLoader(false);
        if (response.success) {
          setAddData({});
          toast.success(response.message);
          setshowList(true);
          setShowAddAddress(false);
          fetchPostelAddress();
          setShowAdd(false);
        } else if (!response.message) {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  const billing_address_update = async (id, v) => {
    try {
      setShowLoader(true);
      const response = await update_billing_address_api(id);
      setShowLoader(false);
      if (response.success) {
        fetchPostelAddress();
        toast.success(response.message, {
          hideProgressBar: true,
        });
        setShipStates(true);
        setShipData(v);
      } else if (!response.success) {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {showAdd && (
        <div
          style={{
            marginLeft: "3rem",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            flexWrap: "wrap",
            marginTop: "1rem",
          }}
        >
          {addressList.map((v, i) => (
            <div
              style={{
                padding: "1rem",

                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 4px 0px",
              }}
              className={styles.pay_bill}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  width: "100%",
                }}
                key={i}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                    color: "#252bbe",
                    fontSize: "15px",
                  }}
                >
                  <span>{`${v.first_name} ${v.last_name}`}</span>
                  <span>{`${v.address} ${v.city} ${v.zipCode}`}</span>
                  <span>Mobile Number :- {v.mobile}</span>
                  <span>Email :- {v.email}</span>
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      color: "#252bbe",
                      marginTop: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAddData(v);
                        setShowAddAddress(true);
                        setshowList(false);
                        setEditAddress(true);
                      }}
                      src={editIcon}
                      alt="edit"
                    />
                    <img
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        delete_address(v.postal_address_id, v.useShippingAdd)
                      }
                      src={deleteIcon}
                      alt="delete"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginTop: "1rem",
            marginLeft: "3rem",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            color: "#252bbe",
            cursor: "pointer",
          }}
        >
          Billing Details
        </div>

        {showAdd && addressList.length !== 0 && (
          <img
            src={crossBlueWhite}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              setAddData(shipData);
              setShowAdd(!showAdd);
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          boxShadow: "none",
        }}
        className={styles.pay_card}
      >
        {showAdd ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div className={styles.input_2}>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>First Name</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  disabled={!showAdd}
                  required={true}
                  type={"text"}
                  name="First Name"
                  label="First Name"
                  value={addData?.first_name}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      first_name: e.target.value,
                    });
                  }}
                />
              </FormControl>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Last Name</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  disabled={!showAdd}
                  required={true}
                  type={"text"}
                  name="Last Name"
                  label="Last Name"
                  value={addData?.last_name}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      last_name: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </div>
            <div className={styles.input_2}>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Phone Number</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  required={true}
                  type={"text"}
                  name="Phone Number"
                  label="Phone Number"
                  disabled={!showAdd}
                  value={addData.mobile}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    const truncatedValue = numericValue.slice(0, 10);
                    setAddData({
                      ...addData,
                      mobile: truncatedValue,
                    });
                  }}
                />
              </FormControl>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  width={"50%"}
                  required={true}
                  type={"text"}
                  name="Email"
                  label="Email"
                  disabled={!showAdd}
                  value={addData.email}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      email: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </div>
          </div>
        ) : (
          addData?.first_name && (
            <div
              style={{
                padding: "1rem",
                width: "fit-content",
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 4px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                    color: "#252bbe",
                    fontSize: "15px",
                  }}
                >
                  <span>{`${addData?.first_name} ${addData?.last_name}`}</span>
                  <span>{`${addData?.address} ${addData?.city} ${addData?.zipCode}`}</span>
                  <span>Mobile Number :- {addData?.mobile}</span>
                  <span>Email :- {addData?.email}</span>
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      color: "#252bbe",
                      marginTop: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAddData(addData);
                        setShowAddAddress(true);
                        setShowAdd(true);
                        setshowList(false);
                        setEditAddress(true);
                      }}
                      src={editIcon}
                      alt="edit"
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        <div style={{ borderTop: "1px solid #B7B9EC", width: "100%" }}></div>

        {!showAdd && (
          <div className={styles.queue_add_ship_div}>
            <div className={styles.add_select_title}>
              Select Billing Address:
            </div>
            <div className={styles.add_add_btn}>
              <div className={styles.plus_icon_white}>
                <img src={plusWhite} alt="" />
              </div>
              <div
                className={styles.ship_btn_content}
                onClick={() => {
                  setShowAdd(!showAdd);
                  setAddData({
                    ...addData,
                    first_name: "",
                    last_name: "",
                    mobile: "",
                    email: "",
                    state_id: "",
                    address: "",
                    city: "",
                    zipCode: "",
                  });
                }}
              >
                Add an Address
              </div>
            </div>
          </div>
        )}

        {showAdd && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>Address</InputLabel>
                <OutlinedInput
                  width={"90%"}
                  required={true}
                  type={"text"}
                  name="Address"
                  label="Address"
                  value={addData?.address}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      address: e.target.value,
                    });
                  }}
                />
              </FormControl>
              <FormControl style={{ flex: 1 }} variant="outlined">
                <InputLabel>City/Town</InputLabel>
                <OutlinedInput
                  width={"90%"}
                  required={true}
                  type={"text"}
                  name="City/Town"
                  label="City/Town"
                  value={addData?.city}
                  onChange={(e) => {
                    setAddData({
                      ...addData,
                      city: e.target.value,
                    });
                  }}
                />
              </FormControl>
              <div className={styles.input_2}>
                <FormControl style={{ flex: 1 }} variant="outlined">
                  <InputLabel>Zip Code</InputLabel>
                  <OutlinedInput
                    width={"50%"}
                    required={true}
                    type={"text"}
                    name="Zip Code"
                    label="Zip Code"
                    value={addData?.zipCode}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "");
                      const truncatedValue = numericValue.slice(0, 5);
                      setAddData({
                        ...addData,
                        zipCode: truncatedValue,
                      });
                    }}
                  />
                </FormControl>
                <TextField
                  style={{ flex: 1 }}
                  className="InputWidth"
                  required
                  value={addData?.state_id}
                  select
                  onChange={(e) => {
                    setAddData({ ...addData, state_id: e.target.value });
                  }}
                  name="State"
                  label="State"
                  defaultValue="select"
                >
                  <MenuItem
                    required={true}
                    key={"Select"}
                    value={"Select"}
                    defaultChecked={true}
                  >
                    Select
                  </MenuItem>
                  {stateList.map((option, i) => (
                    <MenuItem
                      required={true}
                      value={option.abbreviation}
                      key={i}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            {editAddress ? (
              <div className={styles.card_add_btn} onClick={update_address}>
                Edit Address
              </div>
            ) : (
              <div className={styles.card_add_btn} onClick={add_address}>
                Add Address
              </div>
            )}
          </div>
        )}

        {!showAdd && (
          <>
            <DropdownAddress
              onChange={(e) => {
                setShipData(e.target.value);
              }}
              width={"90%"}
              title={
                <>
                  <div className={styles.add_drop_content}>
                    {Object.entries(shipData2)?.length != 0 ? (
                      <>
                        {shipData2?.first_name} {shipData2?.last_name},{" "}
                        {shipData2?.address},{shipData2?.city}-
                        {shipData2?.zipCode} {shipData2?.mobile}
                      </>
                    ) : (
                      "Select Billing Address"
                    )}
                  </div>
                </>
              }
              setOpen={setOpenMostView}
              open={openMostView}
              body={
                <>
                  {addressList.map((v, i) => (
                    <div
                      onClick={() => {
                        setShipData(v);
                        setAddData(v);
                        setShipStates(true);
                        billing_address_update(v?.postal_address_id, v);
                      }}
                      value={v}
                      className={styles.add_drop_1}
                      key={i}
                    >
                      <div className={styles.add_drop_title}>Home:</div>
                      <div className={styles.add_drop_content}>
                        {v?.first_name} {v?.last_name}, {v?.address},{v?.city}-
                        {v?.zipCode} USA, {v?.mobile}
                      </div>
                    </div>
                  ))}
                </>
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export const PaymentData = ({ handleCardData }) => {
  const [cardData, setCardData] = useState({});
  const [addCard, setAddCard] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [defaultCard, setDefaultCard] = useState(0);

  useEffect(() => {
    handleCardData(
      cardData,
      Object.entries(cardData).length > 0 ? true : false,
      defaultCard,
      addCard
    );
  }, [cardData]);
  useEffect(() => {
    fetchCardList();
  }, []);

  const fetchCardList = async () => {
    try {
      const response = await card_list_api(localStorage.getItem("consumer_id"));
      if (response.success) {
        setCardList(response.data);
        setDefaultCard(0);
        setCardData({
          name: response.data[0]?.name,
          card_number: response.data[0]?.card_number,
          card_expiration: response.data[0]?.expirationDate,
          cvv: "100",
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddCard = async () => {
    try {
      const obj = {
        card_number: "5111111111111118",
        expirationDate: "01/39",
        consumer_uuid: localStorage.getItem("consumer_id"),
        name: "MasterCard Test",
      };
      const response = await card_add_api(obj);
      if (response.success) {
        toast.success(response.message);
        fetchCardList();
        setAddCard(false);
        setCardData({});
      } else if (!response.success) {
        setAddCard(false);
        setCardData({});
        toast.error(response.message);
      }
    } catch (error) {}
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        boxShadow: "none",
      }}
      className={styles.pay_card}
    >
      {cardList?.length > 0 && (
        <>
          <div className={styles.card_table}>
            <div className={styles.payment_title}>
              Your Credit or Debit Card
            </div>
            <div className={styles.card_tr}>
              <div className={styles.card_td_1}>Card</div>
              <div className={styles.card_td_2}></div>
              <div className={styles.card_td_3}>Name on card</div>
              <div className={styles.card_td_4}>Expiry</div>
            </div>
            {cardList?.map((v, i) => (
              <>
                <div
                  style={{
                    border: defaultCard == i && "1px solid #252bbe",
                    borderRadius: defaultCard == i && "10px",
                    backgroundColor: defaultCard == i && "#EDEFFF",
                  }}
                  className={styles.card_tr}
                >
                  <div className={styles.card_td_1}>
                    <input
                      type="radio"
                      value={defaultCard == i ? true : false}
                      checked={defaultCard == i ? true : false}
                      onChange={() => {
                        if (!addCard) {
                          setDefaultCard(i);
                          setCardData({
                            ...cardData,
                            name: "glen maxwel",
                            card_number: v?.card_number,
                            card_expiration: v?.expirationDate,
                          });
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{ padding: ".5rem", gap: ".25rem" }}
                    className={styles.card_td_2}
                  >
                    <img src={cardIcon} alt="" />
                    <div className={styles.card_td_2_1}>
                      <div>
                        Card ending in **** {v?.card_number.slice(12, 16)}
                      </div>
                      {defaultCard == i && (
                        <div
                          style={{
                            margin: ".5rem 0 0 .5rem",
                            padding: ".5rem ",
                            width: "90px",
                            border: "1px solid #525bbe",
                          }}
                        >
                          CVV :
                          <input
                            type="password"
                            placeholder="CVV"
                            className={styles.card_cvv}
                            style={{ border: "none" }}
                            disabled={true}
                            value={cardData?.cvv}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.card_td_3}>{v?.name}</div>
                  <div className={styles.card_td_4}>
                    {v?.expirationDate.slice(0, 3)}20
                    {v?.expirationDate.slice(3, 5)}
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      )}

      {!addCard && (
        <div className={styles.bank_details_title}>
          Add another preferred payment providers
        </div>
      )}
      {addCard && (
        <>
          <div className={styles.add_card_options}>
            <div>Credit Card</div>
            <img
              src={crossBlueWhite}
              alt=""
              onClick={() => setAddCard(false)}
            />
          </div>

          <FormControl style={{ flex: 1 }} variant="outlined">
            <InputLabel>Name</InputLabel>
            <OutlinedInput
              width={"90%"}
              disabled={true}
              type={"text"}
              name="Name"
              label="Name"
              value={"MasterCard Test"}
            />
          </FormControl>
          <FormControl style={{ flex: 1 }} variant="outlined">
            <InputLabel>Card Number</InputLabel>
            <OutlinedInput
              width={"90%"}
              disabled={true}
              type={"text"}
              name="Card Number"
              label="Card Number"
              onChange={(e) => {
                if (e.inputType === "deleteContentBackward") {
                  const truncatedValue = cardData.card_number.slice(0, -1);
                  setCardData({ ...cardData, card_number: truncatedValue });
                } else {
                  const inputValue = e.target.value.replace(/\D/g, "");
                  const formattedValue = inputValue.slice(0, 16);
                  const truncatedValue = formattedValue
                    .replace(/(\d{4})/g, "$1 ")
                    .trim();
                  setCardData({ ...cardData, card_number: truncatedValue });
                }
              }}
              value={"5111 1111 1111 1118"}
            />
          </FormControl>

          <FormControl style={{ flex: 1 }} variant="outlined">
            <InputLabel>Exp. mm/yy</InputLabel>
            <OutlinedInput
              width={"90%"}
              // required={true}
              disabled={true}
              type={"text"}
              name="Exp.  mm/yy"
              label="Exp.  mm/yy"
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, "");
                if (inputValue.length <= 4) {
                  const formattedValue = inputValue
                    .replace(/^(\d{2})(\d{0,2})$/, "$1/$2")
                    .replace(/\/$/, "");
                  setCardData({ ...cardData, card_expiration: formattedValue });
                }
              }}
              value={"01/39"}
              // value={cardData?.card_expiration}
            />
          </FormControl>
          <div className={styles.input_2}></div>
          <div className={styles.pay_fee}>
            Fee: 0.75% + $0.10 per transaction
          </div>
          <div onClick={handleAddCard} className={styles.card_add_btn}>
            Add Payment Method
          </div>
        </>
      )}
      <div className={styles.banks_details}>
        <div className={styles.pay_banks}>
          <div className={styles.pay_bank}>
            <div>
              <img src={bankIcon} alt="" />
              <div>ACH Payment </div>
            </div>
            <div>
              <div style={{ fontSize: "13px" }}>
                Fee: $0.26 and $0.50/transaction
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pay_banks}>
          <div
            className={styles.pay_bank}
            onClick={() => {
              setAddCard(!addCard);

              toast.error(
                "We do not need you to add a card here, we have populated the card details with a test number from Mastercard."
              );
            }}
          >
            <div>
              <img src={DebitCardIcon} alt="" />
              <div>Credit Card </div>
            </div>
            <div>
              <div style={{ fontSize: "13px" }}>
                Fee: 0.75% to 0.10%/transaction
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pay_banks}>
          <div
            className={styles.pay_bank}
            onClick={() => {
              setAddCard(!addCard);

              toast.error(
                "We do not need you to add a card here, we have populated the card details with a test number from Mastercard."
              );
            }}
          >
            <div>
              <img src={DebitCardIcon} alt="" />
              <div>Debit Payment </div>
            </div>
            <div>
              <div style={{ fontSize: "13px" }}>
                Fee: 0.34% to 0.74%/transaction
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pay_banks}>
          <div className={styles.pay_bank}>
            <div>
              <img src={applePay} alt="" />
            </div>
            <div>
              <div style={{ fontSize: "13px" }}>
                Fee: 2.9% + $0.30 per transaction
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pay_banks}>
          <div className={styles.pay_bank}>
            <div>
              <img src={paypalIcon} alt="" />
            </div>
            <div>
              <div style={{ fontSize: "13px" }}>
                Fee: 2.9% + $0.30 per transaction
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pay_banks}>
          <div className={styles.pay_bank}>
            <div>
              <img src={gPay} alt="" />
            </div>
            <div>
              <div style={{ fontSize: "13px" }}>
                Fee: 2.9% + $0.30 per transaction
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PurchaseComponent = ({
  setDefaulCardStatus,
  product = {},
  Quan,
  shipData,
  cardData = {},
  ItemType,
  setCurrentComponent,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.card}>
      <div className={styles.card_1}>
        <img
          src={deleteIcon}
          alt=""
          onClick={() =>
            store
              .dispatch(remove_from_Queue(product.queue_uuid))
              .unwrap()
              .then(() => navigate("/category"))
          }
        />
      </div>
      <div
        className={styles.card_2}
        style={{ borderBottom: "1px solid #C5C5C5", paddingBottom: "2rem" }}
      >
        <img
          src={`${image_base_url}${product?.Image_Details[0]?.product_image_url}`}
          alt=""
          className={styles.img}
        />
        <div className={styles.card_content}>
          <div className={styles.card_content_1}>OLED55G3PUA</div>
          <div className={styles.card_content_2}>
            {product?.Product_Details[0]?.product_name}
          </div>
          <div className={styles.PC_2nd}>
            <div className={styles?.PC_price}>
              ${" "}
              {ItemType == "WAIT"
                ? Number(
                    product?.Product_Price_Details[0]?.wait_price * Quan
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : Number(
                    product?.Product_Price_Details[0]?.product_price * Quan
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: ".5rem",
                marginLeft: "2rem",
              }}
            >
              <div className={styles.PC_2nd_2}>
                <div> Qty. {Quan}</div>
              </div>
              <div className={styles.PC_2nd_3}>
                <div className={styles.PC_2nd_3_1}>BUY IT NOW </div>
                <div className={styles.PC_2nd_3_2}>Next Day Delivery</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.PC_3rd} style={{ flexDirection: "column" }}>
        <div className={styles.PC_3rd}>
          <div className={styles.PC_3rd_1}>
            <div>
              <img
                onClick={() => {
                  setCurrentComponent("paymentdata");
                }}
                src={editIcon}
                style={{ cursor: "pointer" }}
                alt=""
              />
            </div>
            <div className={styles.PC_3rd_1_1}>
              <div className={styles.PC_3rd_1_1_title}>
                Your Payment Details
              </div>
              <div>
                Card : {cardData?.card_number?.slice(0, 2)}** **** ****{" "}
                {cardData?.card_number?.slice(12, 16)}
              </div>
              <div>Expiry: {cardData?.card_expiration}</div>
              <div>CVV:***</div>
            </div>
          </div>
          <div className={styles.PC_3rd_1}>
            <div>
              <img
                onClick={() => {
                  setCurrentComponent("addressdata");
                }}
                src={editIcon}
                style={{ cursor: "pointer" }}
                alt=""
              />
            </div>
            <div style={{ gap: ".25rem" }} className={styles.PC_3rd_1_1}>
              <div className={styles.PC_3rd_1_1_title}>
                Your Shipping Address
              </div>
              <div>{shipData?.address}</div>
              <div>{shipData?.city}</div>
              <div>{shipData?.zipCode} </div>
              <div>USA </div>
            </div>
          </div>
        </div>
        <div className={styles.PC_4th}>
          <RadioCheckBox disabled={true} checkValue={true} />
          <div className={styles.PC_4th_1}>
            <div className={styles.PC_4th_1_title}>
              Manufacturer’s Agreement
            </div>
            <div>
              To procure your product at this price you must first consent to
              the Manufacturer’s Agreement.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PurchaseFinalPage = ({
  product,
  Quan,
  shipData,
  cardData,
  ItemType,
  setCurrentComponent,
  orderId,
}) => {
  const [showTrack, setShowTrack] = useState(false);
  const [trackData, setTrackData] = useState({});
  const productDetails = { consumer_po_id: orderId };
  const navigate = useNavigate();
  const track_product = async () => {
    setShowTrack(!showTrack);
    try {
      const response = await product_track_api(orderId);
      if (response?.success) {
        setTrackData({
          ...trackData,
          estimated_delivery_date: response.estimated_delivery_date,
          order_status: response.shipment_track_activities[0]?.status,
        });
      } else {
      }
    } catch (error) {}
  };
  const cancle_order = async () => {
    try {
      const response = await cancle_order_API(productDetails?.consumer_po_id);
      if (response?.success) {
        toast.success(response.message);
        navigate("/category");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div
        className={sty.item}
        style={{ boxShadow: "none", padding: "1rem", marginBottom: "0" }}
      >
        <div
          className={sty.item_left}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <img
            src={`${image_base_url}${product?.Image_Details[0]?.product_image_url}`}
            alt=""
            className={""}
            style={{
              height: "200px",
              width: "250px",
              objectFit: "contain",
              objectPosition: "top",
            }}
          />
        </div>
        <div className={sty.item_right}>
          <div className={sty.title}>
            {product.Product_Details[0]?.product_name}
          </div>
          <div className={sty.price}>
            Purchase Price:{" "}
            <span style={{ color: "#000" }}>
              {product.Currency_Details[0]?.currency_symbol}{" "}
              {Number(
                product.Product_Price_Details[0]?.product_price
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className={sty.delivery}>Delivered On:</div>
          <div className={sty.cancle} onClick={() => cancle_order()}>
            <img src={cancleOrderImg} alt="" className={sty.cancleIcon} />
            <div className={sty.cancleOrder}>Cancel Order</div>
          </div>
          <div className={sty.cancle}>
            <img
              src={ChangeShippingAddress}
              alt=""
              className={sty.cancleIcon}
            />
            <div className={sty.cancleOrder}>Change Shipping Address</div>
          </div>
          <div className={sty.cancle} onClick={track_product}>
            <img src={track} alt="" className={sty.cancleIcon} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ border: "none" }} className={sty.cancleOrder}>
                Track
              </div>
              {showTrack && (
                <div
                  className={sty.cancle}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className={sty.cancleOrder}>
                    Estimated Delivery Date :{" "}
                    {moment(trackData?.estimated_delivery_date).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                  <div className={sty.cancleOrder}>
                    Delivery Status : {trackData?.order_status}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const valiDate = (addData) => {
  const data = [];
  if (
    !addData?.first_name ||
    (addData?.first_name && addData?.first_name.length === 0)
  ) {
    data.push("Please Enter First Name");
  }
  if (
    !addData?.last_name ||
    (addData?.last_name && addData?.last_name.length === 0)
  ) {
    data.push("Please Enter Last Name");
  }
  if (!addData?.mobile || (addData?.mobile && addData?.mobile.length === 0)) {
    data.push("Please Select Mobile Number");
  }
  if (!addData?.email || (addData?.email && addData?.email.length === 0)) {
    data.push("Please Enter Email");
  }
  if (
    !addData?.address ||
    (addData?.address && addData?.address.length === 0)
  ) {
    data.push("Please Enter Address");
  }
  if (!addData?.city || (addData?.city && addData?.city.length === 0)) {
    data.push("Please Enter city");
  }
  if (
    !addData?.zipCode ||
    (addData?.zipCode && addData?.zipCode.length === 0)
  ) {
    data.push("Please Enter Zip Code");
  }
  if (
    !addData?.state_id ||
    (addData?.state_id && addData?.state_id.length === 0)
  ) {
    data.push("Please Select Stete");
  }
  return data;
};

export const BankValidation = (bankData) => {
  const data = [];
  if (!bankData?.name || (bankData?.name && bankData?.name.length === 0)) {
    data.push("Please Enter Name");
  }
  if (
    !bankData?.card_number ||
    (bankData?.card_number && bankData?.card_number.length === 0)
  ) {
    data.push("Please Enter Card Number");
  }
  if (
    bankData?.card_number?.length < 16 ||
    bankData?.card_number?.length > 19
  ) {
    data.push("Please Enter a Valid Card Number");
  }
  if (
    !bankData?.card_expiration ||
    (bankData?.card_expiration && bankData?.card_expiration.length === 0)
  ) {
    data.push("Please Select Card Expiry");
  }
  if (!bankData?.cvv || (bankData?.cvv && bankData?.cvv.length === 0)) {
    data.push("Please Enter CVV");
  }
  return data;
};
