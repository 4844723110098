import React, { useEffect, useState } from "react";
import CategoryItem from "../../components/CategoryItem/CategoryItem";
import { ProductNotAvailable, images } from "../../utils/utils";
import "./categoryModule.css";
import VideoComp from "../../components/VideoComp";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import ExpertCard from "../../components/ExpertCard";
import { Box, Fade, Modal } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { full_products, servicesData } from "../../utils/Data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80%",
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};
const ExpertProductPicker = () => {
  const { product_list, p_loading } = useSelector((state) => state.product);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [viewAllCategory, setViewAllCategory] = useState(false);
  const [allCategories, setAllCategories] = useState(false);

  const onSeeProduct = (item) => {
    if (item?.entity_status == 1) {
      navigate("/seeallproduct", { state: item });
    } else {
      setOpenModal(true);
    }
  };
  return (
    <div className="sbc_main">
      <ExpertCard setOpen={setOpen} />
      <div className="sbc_heading epp_heading">
        Use Product Picker by Category
      </div>
      {p_loading && product_list?.length == 0 ? (
        <Loader />
      ) : (
        <>
          <div className="sbc_category">
            {product_list?.map((item, index) => (
              <div className="sbc_title" onClick={() => onSeeProduct(item)}>
                {item.category_name}
              </div>
            ))}
          </div>
          <div className="sbc_category_main_div">
            {product_list?.slice(0, 3)?.map((item, index) => (
              <>
                <div className="sbc_category_div">
                  <div className="sbc_heading1">{item.category_name}</div>
                  <div
                    className="sbc_show_all"
                    onClick={() => onSeeProduct(item)}
                  >
                    Show All
                  </div>
                </div>
                <div className="sbc_sub_category_div">
                  {item?.Sub_Category_Data?.map((v) => v)
                    ?.sort((a, b) => a.priority - b.priority)
                    ?.map((item, index) => (
                      <CategoryItem
                        onClick={() => {
                          if (item?.entity_status == 1) {
                            navigate("/expertpicker", {
                              state: {
                                id: "01",
                                name: item?.category_name,
                              },
                            });
                          } else {
                            setOpenModal(true);
                          }
                        }}
                        title={item?.category_name}
                        img={item?.category_image_url3}
                        style_div={{
                          border:
                            item?.entity_status == 1 && "3.5px solid #B8F8E9",
                          opacity: item?.entity_status == 1 ? "1" : ".5",
                        }}
                      />
                    ))}
                </div>
              </>
            ))}
          </div>
        </>
      )}
      <div
        style={{
          color: "#252bbe",
          fontWeight: "bolder",
          cursor: "pointer",
          fontSize: "18px",
          padding: "1.5rem ",
          width: "calc(100% - 10%)",
        }}
        onClick={() => setAllCategories(true)}
      >
        {!viewAllCategory ? "View Other Categories" : "Sort Category"}
      </div>
      <VideoComp
        url="uploads/demandey_video/demandey_product_picker.mp4"
        open={open}
        setOpen={setOpen}
      />
      <ProductNotAvailable openModal={openModal} setOpenModal={setOpenModal} />
      {allCategories && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={allCategories}
          onClose={() => setAllCategories(false)}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={allCategories}>
            <Box sx={style}>
              <RxCross2
                onClick={() => {
                  setAllCategories(false);
                }}
                size={25}
                style={{
                  color: "#000",
                  cursor: "pointer",
                  position: "absolute",
                  right: 5,
                  top: 5,
                }}
              />
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "scroll",
                }}
              >
                <div
                  style={{
                    fontFamily: "Roboto",
                    color: "#252ABE",
                    fontWeight: "bold",
                    fontSize: 20,
                    display: "flex ",

                    marginBottom: 10,
                  }}
                >
                  Full List of Demandey Verticals
                </div>
                <div style={{ display: "flex", gap: 30 }}>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        color: "#252ABE",
                        fontWeight: "500",
                        fontSize: 16,
                      }}
                    >
                      Products
                    </div>
                    <div
                      style={{
                        height: 1,
                        backgroundColor: "#DDE1FE",
                        marginBlock: 5,
                      }}
                    ></div>
                    {full_products.map((v, i) => (
                      <div
                        style={{ color: "#000", fontSize: 14, padding: "2px" }}
                        key={i}
                      >
                        {v}
                      </div>
                    ))}
                  </div>
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        color: "#252ABE",
                        fontWeight: "500",
                        fontSize: 16,
                      }}
                    >
                      Services
                    </div>
                    <div
                      style={{
                        height: 1,
                        backgroundColor: "#DDE1FE",
                        marginBlock: 5,
                      }}
                    >
                      {servicesData.map((v, i) => (
                        <div
                          style={{
                            color: "#000",
                            fontSize: 14,
                            padding: "2px",
                          }}
                          key={i}
                        >
                          {v}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
};

export default ExpertProductPicker;
