import React, { useEffect, useState } from "react";
import sty from "./DataDashboard.module.scss";
import computerImg from "../../assets/DataDashboard/computerImg.svg";
import { DataDemandey } from "./SmallComponent/DataDemandy";
import { DataWithManu } from "./SmallComponent/DataWithManu";
import { useLocation, useNavigate } from "react-router-dom";

export const DataDashboard = () => {
  const [dataDash, setDataDash] = useState(1);
  const location = useLocation();

  useEffect(() => {
    setDataDash(location?.state?.id == 2 ? 2 : 1);
  }, [location?.state?.id == 2]);

  return (
    <div className={sty.Data_dash}>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div className={sty.Data_3}>
        {dataDash == 1 && <DataDemandey />}
        {dataDash == 2 && <DataWithManu setDataDash={setDataDash} />}
      </div>
    </div>
  );
};

export const DataDashBoardHeader = ({ setDataDash, dataDash }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={sty.Data_1}>
        <img src={computerImg} alt="" className={sty.Data_title_img} />
        <div className={sty.Data_title}>Data Dashboard</div>
      </div>
      <div className={sty.Data_2}>
        <div
          onClick={() => {
            // setDataDash(1);
            navigate("/datadashboard", { state: { id: 1 } });
          }}
          style={{
            borderBottom: dataDash == 1 ? "5px solid #4148C8" : "",
            paddingBottom: "1rem",
          }}
        >
          Data With Demandey
        </div>
        <div
          onClick={() => {
            // setDataDash(2);
            navigate("/datadashboard", { state: { id: 2 } });
          }}
          style={{
            borderBottom: dataDash == 2 ? "5px solid #4148C8" : "",
            paddingBottom: "1rem",
          }}
        >
          Data To manufacturers
        </div>
      </div>
    </>
  );
};
