import React from "react";
import arrowRight from "../../assets/arrowRight.svg";

const Pagination = ({ totalCount, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / 10);
  const generatePageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <p
          key={i}
          className={`page-number ${currentPage === i ? "active_page" : ""}`}
          onClick={() => {
            onPageChange(i);
            scrollToTop();
          }}
          style={{}}
        >
          {i}
        </p>
      );
    }
    return pageNumbers;
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="pagination">
      <div className="page1">
        {currentPage > 1 && (
          <img
            style={{ cursor: "pointer", transform: "rotate(180deg)" }}
            src={arrowRight}
            alt="Arrow Right"
            onClick={() => {
              onPageChange(currentPage - 1);
              scrollToTop();
            }}
          />
        )}
        {generatePageNumbers()}
        {currentPage < totalPages && (
          <img
            style={{ cursor: "pointer" }}
            src={arrowRight}
            alt="Arrow Right"
            onClick={() => {
              onPageChange(currentPage + 1);
              scrollToTop();
            }}
          />
        )}
      </div>
      <div className="backtotop" onClick={() => scrollToTop()}>
        Back to the Top
      </div>
    </div>
  );
};

export default Pagination;
