import React, { useEffect, useRef, useState } from "react";
import styles from "../DataDashboard.module.scss";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../../assets/NewDataDashboard/backIcon.svg";
import computerImg from "../../../assets/NewDataDashboard/computerImg.svg";
import tvImage from "../../../assets/NewDataDashboard/TV.svg";
import lgLogo from "../../../assets/NewDataDashboard/LG.svg";
import ExpertProductPicker from "../../..//assets/NewDataDashboard/Data new/ExpertData.png";
import contractImg from "../../../assets/NewDataDashboard/Data new/Contract.svg";
import PaymentDetails from "../../../assets/NewDataDashboard/Data new/Payment.svg";
import rightArrow from "../../../assets/ExpertSearch/Shape.svg";
import manuImg2 from "../../../assets/Queue/vector2.svg";
import { DataDashBoardHeader } from "../DataDashboard";
import graphImg from "../../../assets/NewDataDashboard/GraphPathToPath (1).png";
import SortImage from "../../../assets/NewDataDashboard/SortImage.svg";
import EditIcon from "../../../assets/NewDataDashboard/EditIcon.svg";
import card_icon from "../../../assets/Queue/card_icon.svg";
import iIconPink from "../../../assets/Queue/iIconPink.svg";
import Add from "../../../assets/NewDataDashboard/Add.svg";
import dmdGiftCard from "../../../assets/NewDataDashboard/dmdGiftCard.svg";
import vectorImg from "../../../assets/NewDataDashboard/Vector.svg";
import plusSign from "../../../assets/NewDataDashboard/PlusSign.svg";
import { Input } from "../../../components/inputs/Input";
import arrowDown from "../../../assets/NewDataDashboard/arrowDown.svg";

import css from "../../06MyItems/MyItems.module.css";
import { image_base_url } from "../../../utils/APIS/Apis";
import reviewIcon from "../../../assets/Review/Group691.svg";
import tvImg from "../../../assets/NewDataDashboard/tvImg.svg";

import heartFill from "../../../assets/NewDataDashboard/heartFill.png";
import heartEpty from "../../../assets/CategoryItem/likeImg.svg";
import { Communities } from "../../../utils/Data";

const arrForPathtoPath = [
  {
    title1: "Step 9: Confirmation",
    title2:
      "demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K/confirmation",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 8: Queue-Payment",
    title2:
      "demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K/paymentdetails",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 7: Queue-Address",
    title2:
      "demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K/address",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 6: Queue-MA",
    title2:
      "demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K/manufacteragreement",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 5: Product-Detail",
    title2:
      "demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K-Smart-OLED-TV",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 4: Expert-Picker",
    title2: "demandey.com/categories/televisions/Expert-Product-Picker",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 3: Televisions",
    title2: "demandey.com/categories/televisions/",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 2: Categories",
    title2: "demandey.com/categories/",
    title3: "(10 seconds) 13 November 2023",
  },
  {
    title1: "Step 1: Home",
    title2: "www.demandey.com",
    title3: "(10 seconds) 13 November 2023",
  },
];
const arrForServay = [
  {
    Question:
      "how satisfied are you with the overall design and aesthetics of your recently purchased television?",
    Answers: "I really like the sleek design.",
  },
  {
    Question:
      "How frequently do you use the smart features and apps on your TV (e.g., streaming services, internet browsing)?",
    Answers: "Frequently",
  },
  {
    Question:
      "Which specific features influenced your decision to choose our brand over others?",
    Answers: "The picture and sound quality sealed the deal for me.",
  },
  {
    Question:
      "How likely are you to recommend our brand to a friend or family member, based on your current TV ownership experience?",
    Answers: "Extremely likely, love my TV!",
  },
  {
    Question:
      "What types of content do you most frequently watch on your TV? (e.g., movies, sports, TV shows, gaming)",
    Answers: "Movies, Sports, TV Shows, Gaming",
  },
  {
    Question:
      "How would you rate the ease of setup and installation process for your television?",
    Answers: "Fairly easy, but had a few hiccups.",
  },
  {
    Question:
      "Have you experienced any technical issues or malfunctions with your TV since purchase?",
    Answers: "Yes - The remote stopped working after a month.",
  },
  {
    Question:
      "How important is energy efficiency to you when considering a television purchase?",
    Answers: "Moderately important, but not a deal-breaker.",
  },
];

const arrLoginDetails = [
  {
    keyData: "Name",
    ValueData: "Naveen Patidar",
    otherData: "",
  },
  {
    keyData: "Email",
    ValueData: "naveen@veramasa.com",
    otherData: "",
  },
  {
    keyData: "Phone Number",
    ValueData: "+1 000-000-0000",
    otherData:
      "Sign in, recover passwords, and receive security notifications with this number.",
  },
  {
    keyData: "Passkey",
    ValueData:
      "Sign in the same way you unlock your device, by using face, fingerprint, or PIN.",
    otherData: "",
  },
  {
    keyData: "Password",
    ValueData: "***********",
    otherData: "",
  },
  {
    keyData: "2-Step Verification",
    ValueData:
      "Add a layer of security. Require a code and password to sign in.",
    otherData: "",
  },
  {
    keyData: "Compromised Account?",
    ValueData:
      "Take steps like changing your password and signing out everywhere.",
    otherData: "",
  },
];

const BehavioralData = [
  { que: "Purchasing history and habits" },
  {
    que: "Browsing activity and patterns (e.g., pages visited, time spent on site)",
  },
  {
    que: "Engagement with the website (e.g., clicks, form submissions, use of interactive tools) ",
  },
  {
    que: " Response to marketing efforts (e.g., opened emails, clicked on ads)",
  },
  { que: "Device usage (e.g., mobile vs. desktop, browser types)" },
  { que: "Frequency and recency of website visits" },
  { que: "Content preferences (types of articles or products viewed)" },
  { que: "Queue abandonment rate and checkout behavior" },
];

const recentProcurementList = [1, 2, 3, 4, 5, 6, 7, 8];

export const ProductDataDashboard = () => {
  const navigate = useNavigate();
  const name = "naveen";
  return (
    <div className={styles.PDD}>
      <div
        className="PD-back"
        style={{ width: "calc(100% - 10%)", padding: "1rem 5%" }}
      >
        <img
          src={BackIcon}
          alt=""
          onClick={() => navigate("/datadashboard", { state: { id: 2 } })}
        />
        <div>
          <span onClick={() => navigate("/")}>Home</span> /
          <span onClick={() => navigate("/datadashboard")}>DataDashboard</span>/
          <span
            onClick={() => navigate("/datadashboard", { state: { id: 2 } })}
          >
            DatatoManufacturers
          </span>
          / LG GX 55” Gallery Design 4K Smart OLED TV
        </div>
      </div>
      <div
        className={styles.Data_1}
        style={{
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          boxShadow: "none",
        }}
      >
        <img src={computerImg} alt="" className={styles.Data_title_img} />
        <div className={styles.Data_title}>Data Dashboard</div>
      </div>
      <div>
        <div
          className={styles.PDD_main}
          style={{ width: "calc(100% - 10%)", padding: "1rem 5%" }}
        >
          <div className={styles.PDD_main_1st}>
            <div className={styles.PDD_main_1st_left}>
              <img
                className={styles.PDD_main_1st_left_img1}
                src={tvImage}
                alt="TV Image"
              />
              <img
                className={styles.PDD_main_1st_left_img2}
                src={lgLogo}
                alt="LG Logo"
              />
            </div>
            <div className={styles.PDD_main_1st_right}>
              <div className={styles.PDD_right_1}>
                ORDER # 114-5662925-2962646
              </div>
              <div className={styles.PDD_right_2}>
                LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® Total
                Cost:
              </div>
              <div className={styles.PDD_right_3}>$1,001.87 </div>
              <div className={styles.PDD_right_4}>Qty: 1</div>
              <div className={styles.PDD_right_5}>
                <div className={styles.PDD_right_5_1}>BUY IT NOW</div>
                <div className={styles.PDD_right_5_2}>Next Day Delivery</div>
              </div>
            </div>
          </div>
          <div className={styles.PDD_main_2nd}>
            <div className={styles.PDD_the_data_div}>
              <div className={styles.agree_img_content_2}>
                The Data you WILL share with LG
              </div>
              <img src={manuImg2} alt="" />
            </div>
            <div className={styles.PDD_main_2nd_main}>
              <div className={styles.PDD_main_2nd_main_title}>
                Personal Information
              </div>
              <div className={styles.PDD_main_2nd_main_content_main}>
                <div className={styles.PDD_main_2nd_main_content_main_1}>
                  <div className={styles.PDD_main_2nd_main_content_main_1_1}>
                    Name:
                  </div>
                  <div className={styles.PDD_main_2nd_main_content_main_1_2}>
                    Alexander B. Rowan
                  </div>
                </div>
                <div className={styles.PDD_main_2nd_main_content_main_1}>
                  <div className={styles.PDD_main_2nd_main_content_main_1_1}>
                    Address:
                  </div>
                  <div className={styles.PDD_main_2nd_main_content_main_1_2}>
                    1456 Cedar Lane, Lakeside, FL 32065
                  </div>
                </div>
                <div className={styles.PDD_main_2nd_main_content_main_1}>
                  <div className={styles.PDD_main_2nd_main_content_main_1_1}>
                    Email:
                  </div>
                  <div className={styles.PDD_main_2nd_main_content_main_1_2}>
                    alexrowan1456@example.com
                  </div>
                </div>
                <div className={styles.PDD_main_2nd_main_content_main_1}>
                  <div className={styles.PDD_main_2nd_main_content_main_1_1}>
                    Phone Number:
                  </div>
                  <div className={styles.PDD_main_2nd_main_content_main_1_2}>
                    (555) 482-3948
                  </div>
                </div>
                <div className={styles.PDD_main_2nd_main_content_main_1}>
                  <div className={styles.PDD_main_2nd_main_content_main_1_1}>
                    Product Details:
                  </div>
                  <div className={styles.PDD_main_2nd_main_content_main_1_2}>
                    LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ®
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.data_de_cards}
          style={{ padding: "1rem 5%", width: "calc(100% -10%)" }}
        >
          <div
            className={styles.data_de_card}
            style={{ width: "308px", gap: "1.25rem" }}
          >
            <div className={styles.img_div}>
              <img src={ExpertProductPicker} alt="" />
            </div>
            <div className={styles.data_de_card_right}>
              <div className={styles.data_de_card_right_title}>
                Expert product picker answers
              </div>
              <div className={styles.data_de_card_right_content}>
                responses for selecting the best-suited products of your
                previous choices.
              </div>
              <div
                onClick={() => navigate("/dataexpertanswers")}
                className={styles.PDD_btn_view_div}
              >
                <span>View</span>
                <img src={rightArrow} alt="" />
              </div>
            </div>
          </div>
          <div
            className={styles.data_de_card}
            style={{ width: "308px", gap: "1.25rem" }}
          >
            <div className={styles.img_div}>
              <img src={PaymentDetails} alt="" />
            </div>
            <div className={styles.data_de_card_right}>
              <div className={styles.data_de_card_right_title}>
                Path to Purchase
              </div>
              <div className={styles.data_de_card_right_content}>
                the journey a consumer takes from awareness to buying.
              </div>
              <div
                className={styles.PDD_btn_view_div}
                onClick={() => navigate("/datapathtopurchase")}
              >
                <span>View</span>
                <img src={rightArrow} alt="" />
              </div>
            </div>
          </div>
          <div
            className={styles.data_de_card}
            style={{ width: "308px", gap: "1.25rem" }}
          >
            <div className={styles.img_div}>
              <img src={PaymentDetails} alt="" />
            </div>
            <div className={styles.data_de_card_right}>
              <div className={styles.data_de_card_right_title}>
                Answers to Survey at Purchase
              </div>
              <div className={styles.data_de_card_right_content}>
                valuable insights on customer preferences and satisfaction
                levels.
              </div>
              <div
                className={styles.PDD_btn_view_div}
                onClick={() => navigate("/answerstosurveyatpurchase")}
              >
                <span>View</span>
                <img src={rightArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }} className={styles.PDD_bottom}>
          <div className={styles.PDD_main_3rd_main}>
            <div className={styles.PDD_maint_3rd_box}>
              <div>
                <img
                  src={contractImg}
                  alt=""
                  style={{ height: "35px", width: "50px", objectFit: "fill" }}
                />
              </div>
              <div>Your Contract with LG</div>
            </div>
            <div className={styles.PDD_maint_3rd_line}></div>
          </div>
          <div>
            <div className={styles.PDD_main_3rd_content_title}>
              Product Purchase and Data Sale Agreement
            </div>
            <div className={styles.PDD_main_3rd_content_title2}>
              This Product Purchase and Data Sale Agreement ("Agreement") is
              entered into on [Date], by and between [Consumer's Full Name],
              residing at [Consumer's Address] ("Consumer"), and [Manufacturer's
              Full Name], with its principal place of business at
              [Manufacturer's Address] ("Manufacturer").
            </div>
            <div>
              <div className={styles.PDD_main_3rd_content_title2}>
                1. Purchase of Product:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                1.1. The Consumer agrees to purchase [Product Name], further
                described as [Product Details], from the Manufacturer for the
                total price of [Amount], inclusive of all applicable taxes and
                fees.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                1.2. The Manufacturer agrees to deliver the Product to the
                Consumer at the above-stated address within [Number of Days]
                days from the date of this Agreement.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                2. Sale of Consumer Data:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                2.1. In consideration of a discount of [Discount Amount or
                Percentage] on the purchase price of the Product, the Consumer
                agrees to sell certain personal data to the Manufacturer as
                outlined in Section 3.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                2.2. The Consumer acknowledges and agrees that the Manufacturer
                may use, store, process, and transfer this data in accordance
                with the terms of this Agreement and the Manufacturer’s Privacy
                Policy.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                3. Description of Consumer Data:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                3.1. The Consumer agrees to provide the following personal data
                to the Manufacturer: [List of Data, e.g., Name, Email, Phone
                Number, Address, Purchasing Habits, Product Preferences, etc.].
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                3.2. The Consumer declares that the data provided is accurate
                and up-to-date.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                4. Use of Consumer Data:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                4.1. The Manufacturer may use the Consumer's data for marketing
                purposes, product development, consumer behavior analysis, and
                other lawful purposes as described in the Manufacturer's Privacy
                Policy.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                4.1. The Manufacturer may use the Consumer's data for marketing
                purposes, product development, consumer behavior analysis, and
                other lawful purposes as described in the Manufacturer's Privacy
                Policy.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                4.2. The Manufacturer agrees not to sell or disclose the
                Consumer's data to third parties without the Consumer's express
                consent, except as required by law.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                5. Confidentiality and Security:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                5.1. The Manufacturer shall take all reasonable steps to ensure
                the confidentiality and security of the Consumer’s data and to
                prevent unauthorized access, use, alteration, or disclosure.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                6. Term and Termination:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                6.1. This Agreement shall remain in effect until the Consumer's
                data is deleted from the Manufacturer’s systems in accordance
                with the Manufacturer’s data retention policy.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                6.2. Either party may terminate this Agreement with [Number of
                Days] days’ written notice to the other party.
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                7. Governing Law:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                7.1. This Agreement shall be governed by and construed in
                accordance with the laws of [State/Country].
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                8. Dispute Resolution:
              </div>
              <div className={styles.PDD_main_3rd_content_title2}>
                8.1. Any disputes arising under this Agreement shall be resolved
                through final and binding arbitration in accordance with the
                rules of [Arbitration Association].
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DataExpertAnswers = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();

  return (
    <>
      {/* <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} /> */}
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
            Back
          </span>
          {/* <div style={{ fontSize: "12px" }}>
            <span style={{ fontSize: "12px" }} onClick={() => navigate("/")}>
              Home
            </span>
            /<span style={{ fontSize: "12px" }}>DataDashboard</span>/
            <span style={{ fontSize: "12px" }}>DatatoManufacturers</span>/
            <span style={{ fontSize: "12px" }}>DatatoManufacturers</span>/ LG GX
            55” Gallery Design 4K Smart OLED TV
          </div> */}
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Expert-Product Picker Answers</div>
          <div className={styles.DEA_que_div}>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>1. What do you watch?</div>
              <div className={styles.DEA_Ans1}>
                Sports-Action Sports (Football, Basketball), Movies
              </div>
            </div>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>
                2. Where do you get your content?
              </div>
              <div className={styles.DEA_Ans1}>
                Cable/Satellite, Streaming (Netflix, etc), Gaming Console
              </div>
            </div>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>
                3. Where will this TV be used?
              </div>
              <div className={styles.DEA_Ans1}>Living Room</div>
            </div>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>
                4. Do you have an external speaker system?
              </div>
              <div className={styles.DEA_Ans1}>Yes-Wired</div>
            </div>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>
                5. What is your typical viewing distance?
              </div>
              <div className={styles.DEA_Ans1}>6’-8’</div>
            </div>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>
                6.With this viewing distance we recommend a 55-60 inch TV. Will
                this work for you?
              </div>
              <div className={styles.DEA_Ans1}>
                Size: 55-60’ | Viewing Distance: 6’-8’
              </div>
            </div>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>
                7. What viewing angle might you require?
              </div>
              <div className={styles.DEA_Ans1}> Angle: Straight On</div>
            </div>
            <div className={styles.DEA_que_1}>
              <div className={styles.DEA_Que1}>8. What is your budget?</div>
              <div className={styles.DEA_Ans1}> $700</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DataPathToPurchase = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();

  return (
    <>
      {/* <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} /> */}
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Path to purchase</div>
          <div
            className={styles.DEA_que_div}
            style={{
              gap: "1rem",
              width: "calc(100% - 6%)",
              padding: "1rem 3%",
            }}
          >
            <div className={styles.PTP_1st}>
              <div className={styles.PTP_1st_1}>
                <div className={styles.PTP_1st_1_title}>
                  the journey you Took to buy:
                </div>
                <div className={styles.PTP_1st_1_desc}>
                  LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® (No
                  Warranty)
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <div className={styles.PTP_1st_1_sum}>Summary</div>
                  <div className={styles.PTP_1st_1_sum_content}>
                    The journey begins at the home page, where our user explores
                    the vast digital shelves of Demandey's website, effortlessly
                    browsing through a plethora of products. With intuitive
                    navigation and user-friendly design, they quickly identify
                    the desired item. The user engaged with the product details
                    and reviews but chooses to use Demandey's expert product
                    picker recommendations guiding them to this choice. The user
                    proceeded to check the reviews and added it to the queue.
                    Transitioning to the queue process, the user encounters the
                    three step system, providing multiple payment options for
                    convenience. The user checks the order through real-time
                    order tracking and updates, keeping our shopper informed and
                    excited about the impending delivery. With the click of a
                    button, the purchase is complete, marking the end of a
                    delightful journey that seamlessly connects the comfort of
                    home with the excitement of a successful shopping expedition
                    at Demandey.
                  </div>
                </div>
              </div>
              <div className={styles.PTP_1st_2}>
                <img className={styles.DEA_graph} src={graphImg} alt="" />
              </div>
            </div>

            <div className={styles.PTP_2nd}>
              <div className={styles.PTP_2nd_1}>
                <span>Sort</span>
                <img src={SortImage} alt="" />
              </div>
              <div className={styles.PTP_2nd_2}>
                <table className={styles.PTP_2nd_2_table}>
                  {arrForPathtoPath.map((v, i) => (
                    <tr key={i}>
                      <td className={styles.PTP_2nd_2_td_1}>{v?.title1}</td>
                      <td className={styles.PTP_2nd_2_td_2}>{v?.title2}</td>
                      <td className={styles.PTP_2nd_2_td_3}>{v?.title3}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AnswerstoSurveyatpurchase = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();

  return (
    <>
      {/* <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} /> */}
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Answers to Survey at purchase</div>
          <div className={styles.DEA_que_div}>
            <div className={styles.ASP_title}>
              LG GX 55" Gallery Design 4K Smart OLED TV
            </div>
            {arrForServay.map((v, i) => (
              <div key={i} className={styles.DEA_que_1}>
                <div className={styles.DEA_Que1} style={{ fontSize: "18px" }}>
                  <span>{i + 1}.</span> <span>{v?.Question}</span>
                </div>
                <div className={styles.DEA_Ans1}>{v?.Answers}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const LoginDetails = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();

  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Login Details</div>
          <div className={styles.DEA_que_div}>
            {arrLoginDetails.map((v, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className={styles.DEA_que_1}>
                  <div
                    className={styles.DEA_Que1}
                    style={{ fontSize: "20px", color: "#000" }}
                  >
                    {v?.keyData}
                  </div>
                  <div
                    className={styles.DEA_Ans1}
                    style={{ paddingLeft: "0", fontSize: "17px" }}
                  >
                    {v?.ValueData}
                  </div>
                  <div
                    className={styles.DEA_Ans1}
                    style={{ paddingLeft: "0", fontSize: "15px" }}
                  >
                    {v?.otherData}
                  </div>
                </div>
                <div className={styles.LD_edit}>
                  <img src={EditIcon} alt="" />
                  <span>Edit</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const PaymentInformation = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();

  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Payment Information</div>
          <div className={styles.DEA_que_div}>
            <div className={styles.ASP_title}>Cards & Accounts</div>
            <div className={styles.PI_cards}>
              <div className={styles.PI_card}>
                <div>
                  <img className={styles.PI_card_icon} src={card_icon} alt="" />
                </div>
                <div className={styles.PI_card_content}>
                  <div className={styles.PI_card_bank_name}>
                    Bank of America Visa Credit Card
                  </div>
                  <div className={styles.PI_card_no}>
                    Debit Card ending in **** 0000
                  </div>
                  <div className={styles.PI_card_expiry_actions}>
                    <div className={styles.PI_card_ex}>08/2025</div>
                    <div className={styles.PI_card_actions}>
                      <span>Edit</span> {" | "}
                      <span>Remove</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.PI_card}>
                <div>
                  <img className={styles.PI_card_icon} src={card_icon} alt="" />
                </div>
                <div className={styles.PI_card_content}>
                  <div className={styles.PI_card_bank_name}>
                    Bank of America Visa Credit Card
                  </div>
                  <div className={styles.PI_card_no}>
                    Debit Card ending in **** 0000
                  </div>
                  <div className={styles.PI_card_expiry_actions}>
                    <div className={styles.PI_card_ex}>08/2025</div>
                    <div className={styles.PI_card_actions}>
                      <span>Edit</span> {" | "}
                      <span>Remove</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.PI_card}
                style={{
                  boxShadow: "none",
                  border: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    className={styles.PI_card_icon}
                    src={Add}
                    alt=""
                  />
                </div>
                <div className={styles.PI_add_more_card}>
                  Add a payment Method
                </div>
              </div>
            </div>
            <div className={styles.ASP_title}>Gift Cards & Balances</div>
            <div
              className={styles.PI_cards}
              style={{ flexDirection: "column" }}
            >
              <div
                className={styles.PI_card}
                style={{
                  boxShadow: "none",
                  border: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={iIconPink} style={{ cursor: "pointer" }} alt="" />
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    className={styles.PI_card_icon}
                    src={dmdGiftCard}
                    alt=""
                  />
                </div>
                <div className={styles.PI_add_more_card}>
                  Demandey Gift Card
                </div>
              </div>
              <div
                className={styles.PI_card}
                style={{
                  boxShadow: "none",
                  border: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={iIconPink} style={{ cursor: "pointer" }} alt="" />
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    className={styles.PI_card_icon}
                    src={Add}
                    alt=""
                  />
                </div>
                <div className={styles.PI_add_more_card}>
                  Add a Reward or Balance
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const ShippingAndFulfillment = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>
            Shipping & Delivery Preferences
          </div>
          <div className={styles.DEA_que_div}>
            <div
              className={styles.ASP_title}
              style={{ fontSize: "20px", fontWeight: "700" }}
            >
              Your Shipping Addresses
            </div>

            <div
              style={{
                margin: "2rem 0rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div className={styles.SDP_content_1}>
                <div className={styles.SDP_plus_sign_div}>+</div>
                <div className={styles.SDP_conttent}>Add Address</div>
              </div>
              <div
                className={styles.SDP_content_1}
                style={{
                  width: "350px",
                  padding: "1rem 2rem",
                  height: "calc(231px - 2rem)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div className={styles.SDP_default_home}>Default Home</div>
                <div className={styles.SDP_content_item}>
                  <span> Glen Maxwell</span>
                  <span>126 Court Road Drive</span>
                  <span>Nashville, Tennesse-37011</span>
                  <span>USA</span>
                  <span>Phone number: ‪+1 000-000-0000</span>
                </div>
                <div className={styles.PI_card_actions}>
                  <span>Edit</span> {" | "}
                  <span>Remove</span> {" | "}
                  <span>Set as Default</span>
                </div>
              </div>
              <div
                className={styles.SDP_content_1}
                style={{
                  width: "350px",
                  padding: "1rem 2rem",
                  height: "calc(231px - 2rem)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div className={styles.SDP_default_home}>Mom's Home</div>
                <div className={styles.SDP_content_item}>
                  <span> Martha Maxwell</span>
                  <span> 235 Maple Avenue</span>
                  <span> Nashville, Tennesse-37011</span>
                  <span> USA</span>
                  <span> Phone number: ‪+1 000-000-0000</span>
                </div>
                <div className={styles.PI_card_actions}>
                  <span>Edit</span> {" | "}
                  <span>Remove</span> {" | "}
                  <span>Set as Default</span>
                </div>
              </div>
            </div>
            <div
              className={styles.ASP_title}
              style={{ fontSize: "20px", fontWeight: "700" }}
            >
              Delivery Preferences
            </div>

            <div
              style={{
                margin: "2rem 0rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                className={styles.SDP_content_1}
                style={{
                  width: "350px",
                  padding: "1rem 2rem",
                  height: "calc(231px - 2rem)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div className={styles.SDP_default_home}>Default Home</div>
                <div className={styles.SDP_content_item}>
                  <span> Glen Maxwell</span>
                  <span>126 Court Road Drive</span>
                  <span>Nashville, Tennesse-37011</span>
                  <span>USA</span>
                  <span>Phone number: ‪+1 000-000-0000</span>
                </div>
                <div className={styles.PI_card_actions}>
                  <span>Edit</span> {" | "}
                  <span>Remove</span> {" | "}
                  <span>Set as Default</span>
                </div>
              </div>
              <div
                className={styles.SDP_content_1}
                style={{
                  width: "350px",
                  padding: "1rem 2rem",
                  height: "calc(231px - 2rem)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div className={styles.SDP_default_home}>Mom's Home</div>
                <div className={styles.SDP_content_item}>
                  <span> Martha Maxwell</span>
                  <span> 235 Maple Avenue</span>
                  <span> Nashville, Tennesse-37011</span>
                  <span> USA</span>
                  <span> Phone number: ‪+1 000-000-0000</span>
                </div>
                <div className={styles.PI_card_actions}>
                  <span>Edit</span> {" | "}
                  <span>Remove</span> {" | "}
                  <span>Set as Default</span>
                </div>
              </div>
            </div>

            <div className={styles.SPD_content_test}>
              Write you delivery preferences for this address. Example: Where
              you want the package to be delivered, at the reception, a lockbox,
              doorstep etc.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AccountProfileOne = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />

      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Account Profile</div>
          <div className={styles.DEA_que_div}>
            <div
              className={styles.ASP_title}
              style={{ fontSize: "20px", fontWeight: "700" }}
            >
              Manage your profiles
            </div>
            <div
              className={styles.ASP_title}
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "45px",
              }}
            >
              demandey uses these profiles to provide a personalized experience.
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1.5rem",
              }}
            >
              <div
                className={styles.DEA_que_1}
                style={{ borderBottom: "none" }}
              >
                <div
                  className={styles.DEA_Que1}
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    color: "#2E2F46",
                  }}
                >
                  Active Profile
                </div>
                <div
                  className={styles.DEA_Ans1}
                  style={{ paddingLeft: "0", fontSize: "14px", color: "#000" }}
                >
                  Selecting a profile will switch profiles, allowing you to shop
                  with and manage that profile.
                </div>
              </div>
              <div className={styles.LD_edit} style={{ width: "150px" }}>
                <img src={plusSign} alt="" />
                <span>Add a Profile</span>
              </div>
            </div>

            <div className={styles.AP_container}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "none",
                  width: "calc(100% - 5rem)",
                  padding: ".5rem 2.5rem",
                }}
              >
                <div className={styles.DEA_que_1}>
                  <div
                    className={styles.DEA_Que1}
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#353535",
                    }}
                  >
                    Glenn Maxwell
                  </div>
                </div>
                <div
                  className={styles.LD_edit}
                  onClick={() => navigate("/edit-profile")}
                >
                  <img src={EditIcon} alt="" />
                  <span>Edit</span>
                </div>
              </div>
              <div
                style={{
                  border: "none",
                  width: "calc(100% - 5rem)",
                  height: "2px",
                  margin: "0rem 2.5rem",
                  background: "#CECFDF",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "none",
                  width: "calc(100% - 5rem)",
                  padding: ".5rem 2.5rem",
                }}
              >
                <div className={styles.DEA_que_1}>
                  <div
                    className={styles.DEA_Que1}
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#353535",
                    }}
                  >
                    Kids
                  </div>
                </div>
                <div className={styles.LD_edit}>
                  <img src={EditIcon} alt="" />
                  <span>Edit</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SavedItems = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  const [openMostView, setOpenMostView] = useState(false);

  const openMostViewRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (
      openMostViewRef.current &&
      !openMostViewRef.current.contains(event.target)
    ) {
      setTimeout(() => {
        setOpenMostView(false);
      }, 100);
    } else {
    }
  };

  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />

      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Saved Items</div>
          <div className={styles.DEA_que_div}>
            <div className={styles.SI_input_div}>
              <div className={styles.SI_content_1}>
                <Input
                  placeholder={"Search all Saved Procurements"}
                  style={{ width: "332.594px" }}
                />
                <div
                  className={styles.LD_edit}
                  style={{
                    width: "150px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>Search Saved Items</span>
                </div>
              </div>
              <div style={{ width: "auto" }}>
                <div className="bt-right-sort-left">
                  <span style={{ width: "73px" }}>Sort By:</span>
                  <div>
                    <div
                      onClick={() => setOpenMostView(!openMostView)}
                      ref={openMostViewRef}
                      style={{
                        cursor: "pointer",
                        height: "29px",
                        color: "#252bbe",
                      }}
                    >
                      <p>Most Viewed</p>
                      <img src={arrowDown} alt="" />
                    </div>
                    {openMostView && (
                      <div
                        style={{
                          flexDirection: "column",
                          height: "auto",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          borderRadius: "20px",
                          position: "absolute",
                          border: "1.5px solid #252BBE",
                          background: "#F0F2FF",
                          marginTop: ".12rem",
                          gap: "14.72px",
                          color: "#252ABE",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "normal",
                          padding: "1rem 1.5rem",
                        }}
                        className="scroll-in-animation PD-dropdown"
                      >
                        <p>Newest</p>
                        <p>Ratings (High to Low)</p>
                        <p>Price (High to Low)</p>
                        <p>Price (Low to High)</p>
                        <p>Rated Best for Mixed Usage</p>
                        <p>Rated Best for Movies</p>
                        <p>Rated Best for Sports</p>
                        <p>Rated Best for Streaming</p>
                        <p>Rated Best for Gaming</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={css?.cards}
              style={{ padding: "2rem 0", gap: "2rem" }}
            >
              {recentProcurementList?.map((v, i) => (
                <div
                  className={css.card}
                  style={{ width: "45%" }}
                  key={i}
                  onClick={() => navigate("/recentprocurement", { state: v })}
                >
                  <div className={css.card_img}>
                    <img src={tvImg} alt="" />
                  </div>
                  <div className={css.card_content}>
                    <div className={css?.title}>
                      Chanel Classic Black Curved Flap 9" Bag
                    </div>
                    <img className={css.img} src={reviewIcon} alt="" />
                    <div className={styles.PI_card_actions}>
                      <span>Edit</span> {" | "}
                      <span>Remove</span>
                    </div>
                    <div className={css.price}>
                      wait and save: <span>$ 2152.00</span>
                    </div>
                    <div className={css.price}>
                      BUY IT NOW PRICE: <span>$ 2415.00</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PurchaseHistory = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  const [data, setdata] = useState("Orders");

  const arr = [1, 2, 3];

  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />

      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Purchase History</div>
          <div className={styles.DEA_que_div}>
            <div className={styles.SI_input_div}>
              <div className={styles.PH_links}>
                <span
                  style={{
                    borderBottom: data == "Orders" ? "5px solid #252bbe" : "",
                    padding: "1rem 0",
                    fontWeight: data == "Orders" ? "900" : "400",
                    height: data == "Orders" ? "calc(12.98px - 5px)" : "",
                  }}
                  onClick={() => setdata("Orders")}
                >
                  Orders
                </span>
                <span
                  style={{
                    borderBottom:
                      data == "Buy Again" ? "5px solid #252bbe" : "",
                    padding: "1rem 0",
                    fontWeight: data == "Buy Again" ? "900" : "400",
                    height: data == "Buy Again" ? "calc(12.98px - 5px)" : "",
                  }}
                  onClick={() => setdata("Buy Again")}
                >
                  Buy Again
                </span>
                <span
                  style={{
                    borderBottom:
                      data == "Not Yet Shipped" ? "5px solid #252bbe" : "",
                    padding: "1rem 0",
                    fontWeight: data == "Not Yet Shipped" ? "900" : "400",
                    height:
                      data == "Not Yet Shipped" ? "calc(12.98px - 5px)" : "",
                  }}
                  onClick={() => setdata("Not Yet Shipped")}
                >
                  Not Yet Shipped
                </span>
                <span
                  style={{
                    borderBottom:
                      data == "Cancelled" ? "5px solid #252bbe" : "",
                    padding: "1rem 0",
                    fontWeight: data == "Cancelled" ? "900" : "400",
                    height: data == "Cancelled" ? "calc(12.98px - 5px)" : "",
                  }}
                  onClick={() => setdata("Cancelled")}
                >
                  Cancelled
                </span>
              </div>
              <div className={styles.SI_content_1}>
                <Input
                  placeholder={"Search all purchases"}
                  style={{ width: "332.594px" }}
                />
                <div
                  className={styles.LD_edit}
                  style={{
                    width: "150px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>Search Orders</span>
                </div>
              </div>
            </div>
            <div className={styles.PH_cards}>
              {arr.map((v, i) => (
                <div className={styles.PH_card}>
                  <div className={styles.PH_card_1}>
                    <div className={styles.PH_card_1_1}>
                      <div className={styles.PH_content}>
                        <div className={styles.PH_content_text_1}>
                          ORDER PLACED
                        </div>
                        <div className={styles.PH_content_text_2}>
                          December 5, 2021
                        </div>
                      </div>
                      <div className={styles.PH_content}>
                        <div className={styles.PH_content_text_1}>TOTAL</div>
                        <div className={styles.PH_content_text_2}>$1001.78</div>
                      </div>
                      <div className={styles.PH_content}>
                        <div className={styles.PH_content_text_1}>SHIP TO</div>
                        <div className={styles.PH_content_text_2}>
                          Naveen Patidar
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.PH_card_1_1}>
                        <div className={styles.PH_content}>
                          <div className={styles.PH_content_text_2}>
                            ORDER # 113-4706874-6471411
                          </div>
                          <div className={styles.PH_content_text_1}>
                            View order details | View invoice
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.PH_card_2}>
                    <div className={styles.PH_card_2_1}>
                      <div
                        className={css.card}
                        style={{ width: "100%", boxShadow: "none" }}
                        key={i}
                        onClick={() =>
                          navigate("/recentprocurement", { state: v })
                        }
                      >
                        <div
                          className={css.card_img}
                          style={{
                            height: "150px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <img src={tvImg} alt="" />
                          <div className={styles.archive_order}>
                            Archive Order
                          </div>
                        </div>
                        <div className={css.card_content}>
                          <div
                            className={css?.title}
                            style={{ color: "#252bbe" }}
                          >
                            LG GX 55” Gallery Design 4K Smart OLED TV
                          </div>
                          <div
                            className={styles.PH_card_btn}
                            style={{ width: "107px", height: "23px" }}
                          >
                            <span>Buy Again</span>
                          </div>
                          <div
                            className={css.price}
                            style={{
                              textDecoration: "underline",
                              textTransform: "capitalize",
                              fontSize: "13px",
                            }}
                          >
                            Manufacturer’s Agreement
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.PH_card_2_2}>
                      <div className={styles.PH_card_btn}> Track a Package</div>
                      <div className={styles.PH_card_btn}>
                        {" "}
                        Write a Community Product Review
                      </div>
                      <div className={styles.PH_card_btn}>
                        {" "}
                        Return or Replace
                      </div>
                      <div className={styles.PH_card_btn}>
                        {" "}
                        Share a Gift Receipt
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SocialEngagement = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();

  const arr = [1, 2, 3];

  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Social Engagement</div>
          <div className={styles.DEA_que_div}>
            <div
              className={css?.MI_1st}
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div className={styles.SE_text}>Communities</div>
              <div className={css?.ShowAll} style={{ cursor: "pointer" }}>
                Show All
              </div>
            </div>

            <div
              className={css?.cards + " " + styles.no_scroll}
              style={{
                overflowX: "scroll",
                flexWrap: "nowrap",
                margin: "1rem 0",
                gap: ".2rem",
              }}
            >
              {Communities?.map((v, i) => (
                <div
                  className={css.card}
                  key={i}
                  style={{
                    width: "100px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "1.25rem",
                    boxShadow: "none",
                  }}
                >
                  <div className={css.card_img}>
                    <img src={v?.img} alt="" />
                  </div>
                  <div className={styles.card_title_new}>{v?.title}</div>
                </div>
              ))}
            </div>
            <div
              className={css?.MI_1st}
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div className={styles.SE_text}>Likes</div>
              <div className={css?.ShowAll} style={{ cursor: "pointer" }}>
                Show All
              </div>
            </div>
            <div
              className={css?.cards + " " + styles.no_scroll}
              style={{
                overflowX: "scroll",
                flexWrap: "nowrap",
                margin: "1rem 0",
              }}
            >
              {recentProcurementList?.map((v, i) => (
                <div
                  className={css.card}
                  key={i}
                  style={{ minWidth: "385px" }}
                  onClick={() => navigate("/recentprocurement", { state: v })}
                >
                  <div className={css.card_img}>
                    <img src={tvImg} alt="" />
                  </div>
                  <div className={css.card_content} style={{ gap: ".5rem" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: "22px",
                          height: "19px",
                          objectFit: "fill",
                        }}
                        src={heartFill}
                        alt=""
                      />
                    </div>
                    <div className={css?.title}>
                      New Balance Men's Fresh Foam X Hierro V7
                    </div>
                    <div className={css.price}>
                      <div>
                        {" "}
                        wait and save: <span>$55</span>
                      </div>
                      <div>
                        {" "}
                        BUY IT NOW PRICE: <span>$100</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={css?.MI_1st}
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div className={styles.SE_text}>Shares</div>
              <div className={css?.ShowAll} style={{ cursor: "pointer" }}>
                Show All
              </div>
            </div>
            <div
              className={css?.cards + " " + styles.no_scroll}
              style={{
                overflowX: "scroll",
                flexWrap: "nowrap",
                margin: "1rem 0",
              }}
            >
              {recentProcurementList?.map((v, i) => (
                <div
                  className={css.card}
                  key={i}
                  style={{
                    minWidth: "385px",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  onClick={() => navigate("/recentprocurement", { state: v })}
                >
                  <div className={css.card_img}>
                    <img src={tvImg} alt="" />
                  </div>
                  <div className={css.card_content} style={{ gap: ".5rem" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: "22px",
                          height: "19px",
                          objectFit: "fill",
                        }}
                        src={heartEpty}
                        alt=""
                      />
                    </div>
                    <div className={styles.PH_content_text_1}>
                      Shared via Gmail to{" "}
                    </div>
                    <div className={css?.title}>
                      New Balance Men's Fresh Foam X Hierro V7
                    </div>
                    <div className={css.price}>
                      <div>
                        {" "}
                        wait and save: <span>$55</span>
                      </div>
                      <div>
                        {" "}
                        BUY IT NOW PRICE: <span>$100</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={css?.MI_1st}
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div className={styles.SE_text}>Social media Referrals</div>
              <div className={css?.ShowAll} style={{ cursor: "pointer" }}>
                Show All
              </div>
            </div>
            <div
              className={css?.cards + " " + styles.no_scroll}
              style={{
                overflowX: "scroll",
                flexWrap: "nowrap",
                margin: "1rem 0",
              }}
            >
              {recentProcurementList?.map((v, i) => (
                <div
                  className={css.card}
                  key={i}
                  style={{
                    minWidth: "385px",
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  onClick={() => navigate("/recentprocurement", { state: v })}
                >
                  <div className={css.card_img}>
                    <img src={tvImg} alt="" />
                  </div>
                  <div className={css.card_content} style={{ gap: ".5rem" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: "22px",
                          height: "19px",
                          objectFit: "fill",
                        }}
                        src={heartEpty}
                        alt=""
                      />
                    </div>
                    <div className={styles.PH_content_text_1}>
                      Refferal Link
                    </div>
                    <div className={css?.title}>
                      New Balance Men's Fresh Foam X Hierro V7
                    </div>
                    <div className={css.price}>
                      <div>
                        {" "}
                        wait and save: <span>$55</span>
                      </div>
                      <div>
                        {" "}
                        BUY IT NOW PRICE: <span>$100</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const BehavioralDataAnalysis = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  const [showQus, setShowQus] = useState();

  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("/")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
          <div className={styles.DEA_title}>Behavioral Data & Analysis</div>
          <div
            className={styles.DEA_que_div}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {BehavioralData.map((v, i) => (
              <div
                key={i}
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.250)",
                  width: "calc(100% - 10%)",
                }}
              >
                <div
                  className="qts-1"
                  style={{ borderTop: "none", padding: "2rem 0rem" }}
                  onClick={() => {
                    if (showQus != i) {
                      setShowQus(i);
                    } else {
                      setShowQus();
                    }
                  }}
                >
                  <div className="qts-content">{v?.que}</div>
                  <img
                    src={vectorImg}
                    alt=""
                    className="qts-icon"
                    style={{
                      transform:
                        showQus !== i ? "rotate(-90deg)" : "rotate(0deg)",
                      transition:
                        showQus == i
                          ? "transform 0.5s ease"
                          : "transform 0.5s ease",
                    }}
                  />
                </div>
                {showQus == i && (
                  <div
                    style={{
                      color: "#252bbe",
                      padding: "0rem 1rem 1rem 2rem ",
                    }}
                  >
                    {v?.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
