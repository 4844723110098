import React, { useEffect, useState } from "react";
import "./ProductDetails.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../assets/backIcon.svg";
import {
  image_base_url,
  product_by_uuid_api,
  saved_product_API,
} from "../../utils/APIS/Apis";
import likeImg from "../../assets/CategoryItem/like1.svg";
import like from "../../assets/CategoryItem/like.svg";
import ReviewImg from "../../assets/Review/Group691.svg";
import PDFImg from "../../assets/SingleProductDetails/PDFIcon.svg";
import walmartImg from "../../assets/CategoryItem/walmart.svg";
import BestBuyImg from "../../assets/CategoryItem/Best_Buy_Logo1.svg";
import AmazonImg from "../../assets/CategoryItem/Amazon_logo.svg";
import { Overview } from "./SmallComponent/Overview";
import { Experience } from "./SmallComponent/Experience";
import { AllSpecs } from "./SmallComponent/AllSpecs";
import { Review, Review2 } from "./SmallComponent/Review";
import Loader from "../../components/Loader/Loader";
import { PaymentButtonItemDesc } from "../../components/btn/Button";
import closeIconImg from "../../assets/Queue/CloseIcon.svg";
import { Modal } from "antd";
import { ForgatePass, Login, SignUp } from "../../components/auth/LoginSign";
import { ManuAgreement } from "../08Queue/SmallComponent/ManuAgreement";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";
import QueueDrawer from "../../components/drawer/QueueDrawer";
import store from "../../redux/store";
import {
  add_to_Queue,
  delete_MyItem,
  get_MyItem,
} from "../../redux/productSlice";
import { useSelector } from "react-redux";

export const ProductDetails = ({ setTokenData, scrollToElement }) => {
  const [openQueueSlider, setOpenQueueSlider] = useState(false);
  const [showFeature, setShowFeature] = useState("Overview");
  const [product, setProduct] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation((state) => state);
  const { id, nameData } = useParams();
  const [product_uuid, setproduct_uuid] = useState(
    location.state?.id || Base64.decode(id) || ""
  );
  const name = location.state?.name || nameData;
  const [showList, setShowList] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");
  const [openNew, setOpenNew] = useState(false);
  const [imgSet, setImgSet] = useState(0);
  const [ShowManufactureAgree, setShowManufactureAgree] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(true);
  const [showReviewData, setShowReviewData] = useState(false);
  const { myItemData, myItemloading } = useSelector((state) => state.product);

  useEffect(() => {
    setTimeout(() => {
      setOpenPopUp(false);
    }, 3000);
  }, []);

  useEffect(() => {
    setproduct_uuid(location.state?.id || Base64.decode(id) || "");
  }, [id]);

  useEffect(() => {
    fetchProduct();
  }, [product_uuid]);

  const fetchProduct = async () => {
    try {
      setShowLoader(true);
      const response = await product_by_uuid_api(
        product_uuid,
        localStorage.getItem("zipcode")
      );
      setShowLoader(false);

      if (response.success) {
        setProduct(response?.data[0]);
        setShowReviewData(true);
        setShowList(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const arr = [1, 2, 3, 4, 5];

  const filterProductType = localStorage.getItem("QueueData");
  const data = JSON.parse(filterProductType);

  const add_my_item = async (id) => {
    try {
      const obj = {
        consumer_uuid: localStorage.getItem("consumer_id"),
        product_uuid: id,
        quantity: 1,
      };
      const response = await saved_product_API(obj);
      if (response.success) {
        store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
        toast.success(response.message, { toastId: 1 });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addtoqueue = async (id, instant_By) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        consumer_uuid: localStorage.getItem("consumer_id"),
        product_uuid: id,
        quantity: "1",
        instant_By: instant_By == 1 ? true : false,
      };
      store
        .dispatch(add_to_Queue(obj))
        .unwrap()
        .then((res) => {
          setShowLoader(false);
          if (res.success) {
            setOpenQueueSlider(true);
          }
        });
    } catch {}
  };
  return (
    <div style={{ minHeight: "500px" }}>
      {showList && (
        <div className="PD-main">
          <div className="PD-back">
            <img src={BackIcon} alt="" onClick={() => navigate(-1)} />
            <div>
              <span onClick={() => navigate("/")}>Home</span> /
              <span
                onClick={() =>
                  navigate("/category", {
                    state: {
                      productpicker: false,
                    },
                  })
                }
              >
                {" "}
                Category{" "}
              </span>
              /
              <span
                onClick={() => {
                  if (name != "Laptops") {
                    navigate(
                      `/categoryitem/${Base64.encode(
                        product?.product_category_uuid
                      )}/${name}`
                    );
                  } else {
                    navigate(
                      `/laptopitem/${Base64.encode(
                        product?.product_category_uuid
                      )}/${name}`
                    );
                  }
                }}
              >
                {" " + name + " "}
              </span>
              /
              <span
                onClick={() =>
                  navigate("/category", {
                    state: {
                      productpicker: false,
                    },
                  })
                }
              >
                {" " + product?.product_name}
              </span>
            </div>
          </div>
          <div
            style={{
              borderBottom: "5px solid #252abe",
              marginTop: "2rem",
              width: "120px",
            }}
          ></div>
          <div className="np">
            <div className="PD-details">
              {!showLoader && product?.Product_Image_Details[0]?.imagePaths ? (
                <div className="PD-img">
                  <div className="PD-sort-img">
                    {product?.Product_Image_Details[0]?.imagePaths
                      ?.slice(0, 4)
                      .map((v, i) => (
                        <img
                          key={i}
                          src={`${image_base_url}${v}`}
                          alt=""
                          className="PD-big-img"
                          onClick={() => setImgSet(i)}
                        />
                      ))}
                  </div>

                  <img
                    src={`${image_base_url}${product?.Product_Image_Details[0]?.imagePaths[imgSet]} `}
                    alt=""
                    className="PD-big-img"
                  />
                </div>
              ) : (
                <div className="PD-img">
                  <div
                    className="PD-sort-img"
                    style={{
                      height: "500px",
                      overflowY: "scroll",
                    }}
                  >
                    {arr.map((v, i) => (
                      <img
                        key={i}
                        src={`${image_base_url}${product?.Product_Image_Details[0]?.product_image_url}`}
                        alt=""
                        className="PD-big-img"
                        onClick={() => setImgSet(i)}
                      />
                    ))}
                  </div>
                  <img
                    src={`${image_base_url}${product?.Product_Image_Details[0]?.product_image_url} `}
                    alt=""
                    className="PD-big-img"
                  />
                </div>
              )}
              <div className="PD-content">
                <div className="PD-1st">
                  <div className="PD-id">{product?.product_name}</div>
                  <img
                    style={{ height: "25px", width: "25px", cursor: "pointer" }}
                    src={
                      myItemData.find(
                        (val) =>
                          val.product_uuid ==
                          product?.Features_Details[0]?.product_uuid
                      )
                        ? like
                        : likeImg
                    }
                    alt=""
                    onClick={() => {
                      myItemData.find(
                        (val) =>
                          val.product_uuid ==
                          product?.Features_Details[0]?.product_uuid
                      )
                        ? store.dispatch(
                            delete_MyItem(
                              myItemData.filter(
                                (val) =>
                                  val.product_uuid ==
                                  product?.Features_Details[0]?.product_uuid
                              )[0]?.queue_uuid
                            )
                          )
                        : add_my_item(
                            product?.Features_Details[0]?.product_uuid
                          );
                    }}
                  />
                </div>
                <div className="PD-2nd">{product?.product_description}</div>
                <div className="PD-3rd">
                  <div className="PD-review">
                    <img src={ReviewImg} alt="" />
                  </div>
                  <div className="PD-rvw-quantity">(324) COMMUNITY Reviews</div>
                </div>
                {!localStorage.getItem("token") || (
                  <div className="PD-4th">
                    {!checkValue && (
                      <div
                        style={{ cursor: "pointer" }}
                        className="Manu-agree"
                        onClick={() => setShowManufactureAgree(true)}
                      >
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            objectFit: "cover",
                          }}
                          src={PDFImg}
                          alt=""
                        />
                        <div>Manufacturer's agreement</div>
                      </div>
                    )}
                  </div>
                )}
                <div className="PD-5th" style={{ flexWrap: "wrap" }}>
                  {product?.available_Sizes[0]?.tv_size?.length > 0 &&
                  product?.available_Sizes?.length > 0 ? (
                    <>
                      {product?.available_Sizes.slice(0).map((v, i) => (
                        <div
                          onClick={() => setproduct_uuid(v?.product_uuid)}
                          style={{
                            cursor: "pointer",
                            border:
                              name == "Laptops"
                                ? product?.Features_Details[0]?.features[0][
                                    "Size"
                                  ] == v?.tv_size[0]
                                  ? "1px solid #252bbe"
                                  : ""
                                : product?.Features_Details[0]?.features[10][
                                    "Size"
                                  ] == v?.tv_size[0]
                                ? "1px solid #252bbe"
                                : "",
                          }}
                          key={i}
                        >
                          {v?.tv_size[0]}"
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {name == "Laptops" ? (
                  <div className="PD-6th">
                    <div className="PD-6th-1">
                      <div className="PD-6th-1-title">Usage Ratings</div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.Battery > 1
                            ? Number(
                                product?.Products_Weights[0]?.Battery
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.Battery * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Battery
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.Gaming > 1
                            ? Number(
                                product?.Products_Weights[0]?.Gaming
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.Gaming * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Gaming
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.Touchpad > 1
                            ? Number(
                                product?.Products_Weights[0]?.Touchpad
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.Touchpad * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Touchpad
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.Business > 1
                            ? Number(
                                product?.Products_Weights[0]?.Business
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.Business * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Business
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.Keyboard > 1
                            ? Number(
                                product?.Products_Weights[0]?.Keyboard
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.Keyboard * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Keyboard
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="PD-6th-1">
                      <div className="PD-6th-1-title">Specs</div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {product?.Features_Details[0]?.features[0]["Size"]}”
                          inch Screen
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {
                            product?.Features_Details[0]?.features[19][
                              "Screen Resolution"
                            ]
                          }{" "}
                          Screen Resolution
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {product?.Features_Details[0]?.features[1]["RAM"]}
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {product?.Features_Details[0]?.features[2]["Storage"]}
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {
                            product?.Features_Details[0]?.features[5][
                              "Operating System"
                            ]
                          }{" "}
                          Operating System
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="PD-6th">
                    <div className="PD-6th-1">
                      <div className="PD-6th-1-title">Usage Ratings</div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.MixedUsage > 1
                            ? Number(
                                product?.Products_Weights[0]?.MixedUsage
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.MixedUsage * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Mixed Usage
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.VideoGames > 1
                            ? Number(
                                product?.Products_Weights[0]?.VideoGames
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.VideoGames * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Video Games
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.HDRMovies > 1
                            ? Number(
                                product?.Products_Weights[0]?.HDRMovies
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.HDRMovies * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Movies
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.Sports > 1
                            ? Number(
                                product?.Products_Weights[0]?.Sports
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]?.Sports * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Sports
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-rate">
                          {product?.Products_Weights[0]?.VarableRefreshRate > 1
                            ? Number(
                                product?.Products_Weights[0]?.VarableRefreshRate
                              ).toFixed(1)
                            : Number(
                                product?.Products_Weights[0]
                                  ?.VarableRefreshRate * 10
                              ).toFixed(1)}
                        </div>
                        <div className="item-data">
                          Streaming
                          <div
                            style={{
                              marginLeft: ".5rem",
                              width: "9.5px",
                              height: "9.5px",
                            }}
                          >
                            ?
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="PD-6th-1">
                      <div className="PD-6th-1-title">Specs</div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {product?.Features_Details[0]?.features[10]["Size"]}”
                          inch Screen
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {" "}
                          {
                            product?.Features_Details[0]?.features[19][
                              "Display Type"
                            ]
                          }
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">120 Hz</div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {
                            product?.Features_Details[0]?.features[15][
                              "Control Method"
                            ]
                          }
                        </div>
                      </div>
                      <div className="PD-6th-1-item">
                        <div className="item-data">
                          {
                            product?.Features_Details[0]?.features[14][
                              "Connectivity Type"
                            ]
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="PD-7th">
                  <div>Key Features</div>
                  <ul>
                    {product?.Features_Details[0]?.keyFeature?.map((v, i) => (
                      <li key={i}>{v}</li>
                    ))}
                  </ul>
                </div>
                <div className="btn_payment">
                  <PaymentButtonItemDesc
                    title={`‘Buy it Now’ empowers you to save significantly by purchasing directly from the manufacturer's inventory rather than the costly and inefficient retail and ecommerce supply chain.`}
                    onClick={() => addtoqueue(product?.product_uuid, 1)}
                    // onClick={() => add_to_queue(product?.product_uuid, "GET")}
                    savedPrice={Number(
                      product?.Product_Price_Details[0]?.instant_save
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    price={Number(
                      product?.Product_Price_Details[0]?.product_price
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    type={"GET"}
                    symbol={product?.Currency_Details[0]?.currency_symbol}
                  />
                  <div
                    style={{ border: ".5px solid #5258D9", height: "130px" }}
                  ></div>
                  <PaymentButtonItemDesc
                    onClick={() => addtoqueue(product?.product_uuid, 0)}
                    // onClick={() => add_to_queue(product?.product_uuid, "WAIT")}
                    title={`‘Wait and Save’ empowers you to order a product before it’s manufactured. While this increases the wait time, you benefit from incredible savings!`}
                    savedPrice={Number(
                      product?.Product_Price_Details[0]?.wait_save
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    price={Number(
                      product?.Product_Price_Details[0]?.wait_price
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    type={"WAIT"}
                    symbol={product?.Currency_Details[0]?.currency_symbol}
                  />
                </div>
                <div className="PD-compare">
                  <div>
                    <img src={walmartImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {product.Currency_Details[0]?.currency_symbol}{" "}
                      {Number(
                        product.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div>
                    <img src={BestBuyImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {product.Currency_Details[0]?.currency_symbol}{" "}
                      {Number(
                        product.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div>
                    <img src={AmazonImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {product.Currency_Details[0]?.currency_symbol}{" "}
                      {Number(
                        product.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
                <div className="PD-or-get-it-form">Other prices</div>
              </div>
            </div>
          </div>
          <div className="PD-feature">
            <div className="PD-feat-title">Features & Specs</div>
            <div className="PD-feat-links">
              <div
                style={{
                  borderBottom:
                    showFeature == "Overview" ? "2px solid #252bbe" : "",
                }}
                onClick={() => setShowFeature("Overview")}
              >
                Overview
              </div>
              <div
                style={{
                  borderBottom:
                    showFeature == "Experience" ? "2px solid #252bbe" : "",
                }}
                onClick={() => setShowFeature("Experience")}
              >
                Features
              </div>

              <div
                style={{
                  borderBottom:
                    showFeature == "AllSpecs" ? "2px solid #252bbe" : "",
                }}
                onClick={() => setShowFeature("AllSpecs")}
              >
                All Specs
              </div>
            </div>
            <div
              className="PD-feat-content"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flex: 1,
              }}
            >
              {showFeature == "Overview" && <Overview product={product} />}
              {showFeature == "Experience" && <Experience product={product} />}
              {showFeature == "AllSpecs" && <AllSpecs product={product} />}
            </div>
          </div>
          <div className="PD-review">
            <Review bool={showReviewData} product={product} />
          </div>
        </div>
      )}
      {openNew && (
        <Modal
          centered
          open={openNew}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpenNew(false)}
        >
          {showSignIn == "signin" && (
            <Login
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
              scrollToElement={scrollToElement}
              setTokenData={setTokenData}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp setShowSignIn={setShowSignIn} setOpenNew={setOpenNew} />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
            />
          )}
        </Modal>
      )}

      <QueueDrawer open={openQueueSlider} setOpen={setOpenQueueSlider} />

      {ShowManufactureAgree && (
        <Modal
          closeIcon={<img src={closeIconImg} alt="" />}
          title=""
          centered
          open={ShowManufactureAgree}
          footer={false}
          width={"80%"}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setShowManufactureAgree(false)}
        >
          {ShowManufactureAgree && (
            <ManuAgreement
              setCheckValue={setCheckValue}
              checkValue={checkValue}
              setShowManufactureAgree={setShowManufactureAgree}
              handleNavigatetoAddress={() => {}}
              product={product}
            />
          )}
        </Modal>
      )}

      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};
