import React from "react";
import "./Review.css";
import profileImg from "../../../assets/Review/screen1.svg";
import profileImg2 from "../../../assets/Review/Profile2.svg";
import profileImg3 from "../../../assets/Review/Profile3.svg";
import RatingImg from "../../../assets/Review/Group691.svg";
import VectorImg from "../../../assets/Review/Vector.svg";
import Vector2Img from "../../../assets/Review/Vector2.svg";

export const Review = () => {
  return (
    <div className="Review">
      <div className="RV-1st">
        The reviews are in. And the results are resounding.
      </div>
      <div className="RV-2nd">
        Hear what our delighted partners have to say!
      </div>
      <div className="RV-3rd">
        <div className="RV-3rd-1st">
          <img src={profileImg} alt="" className="RV-profile" />
          <div className="RV-rating">
            <img src={RatingImg} alt="" className="RV-Rating-img" />
          </div>
          <div className="RV-comment">
            <img src={VectorImg} alt="" className="RV-coma-img1" />
            I love this app! It has the best deals by far, & I get to decide
            which brands I want to share my data with.
            <img src={Vector2Img} alt="" className="RV-coma-img2" />
          </div>
          <div className="RV-Name">-Dave Mathew</div>
        </div>
        <div className="RV-3rd-1st">
          <img src={profileImg2} alt="" className="RV-profile" />
          <div className="RV-rating">
            <img src={RatingImg} alt="" className="RV-Rating-img" />
          </div>
          <div className="RV-comment">
            <img src={VectorImg} alt="" className="RV-coma-img1" />
            I just bought a TV for half the price of the same one at Best Buy
            and Amazon. I couldn’t be happier.
            <img src={Vector2Img} alt="" className="RV-coma-img2" />
          </div>
          <div className="RV-Name">-Victoria Chang</div>
        </div>
        <div className="RV-3rd-1st">
          <img src={profileImg3} alt="" className="RV-profile" />
          <div className="RV-rating">
            <img src={RatingImg} alt="" className="RV-Rating-img" />
          </div>
          <div className="RV-comment">
            <img src={VectorImg} alt="" className="RV-coma-img1" />
            This is the future of buying! And I am 100% down for it. Literally
            saved so much on Demandey it’s UNREAL.
            <img src={Vector2Img} alt="" className="RV-coma-img2" />
          </div>
          <div className="RV-Name">-Dominic Rodriguez</div>
        </div>
      </div>
    </div>
  );
};
