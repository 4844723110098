import React, { useEffect, useRef, useState } from "react";
import "./CategoryItem.css";
import BackIcon from "../../assets/backIcon.svg";
import arrowRight from "../../assets/arrowRight.svg";
import arrowDown from "../../assets/arrowDown.svg";
import iIconBLue from "../../assets/ExpertSearch/iIconBlue.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import starIcon from "../../assets/Review/Group691.svg";
import walmartImg from "../../assets/CategoryItem/walmart.svg";
import BestBuyImg from "../../assets/CategoryItem/Best_Buy_Logo1.svg";
import AmazonImg from "../../assets/CategoryItem/Amazon_logo.svg";
import likeImg from "../../assets/CategoryItem/like1.svg";
import like from "../../assets/CategoryItem/like.svg";
import fltLogo from "../../assets/CategoryItem/fltLogo.svg";
import Loader from "../../components/Loader/Loader";
import {
  consumer_id,
  filter_data_api,
  image_base_url,
  product_category_list_api,
  queue_add_api,
  queue_delete_api,
  saved_product_API,
  saved_product_list_API,
} from "../../utils/APIS/Apis";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { toast } from "react-toastify";
import { PaymentButton, PaymentButtonItem } from "../../components/btn/Button";
import { Modal } from "antd";
import { ForgatePass, Login, SignUp } from "../../components/auth/LoginSign";
import { QueueSlider } from "../08Queue/SmallComponent/QueueSlider";
import Pagination from "../../components/Pagination/Pagination";
import { Base64 } from "js-base64";
import { Review2 } from "../05ProductDetails/SmallComponent/Review";
import store from "../../redux/store";
import {
  add_to_Queue,
  delete_MyItem,
  get_MyItem,
} from "../../redux/productSlice";
import { useSelector } from "react-redux";
import QueueDrawer from "../../components/drawer/QueueDrawer";

export const CategoryItem = ({ scrollToElement, setTokenData }) => {
  const { myItemData, myItemloading } = useSelector((state) => state.product);
  const [sortingTitle, setSortingTitle] = useState("Mixed Use Ratings");
  const [openFilterItem, setOpenFilterItem] = useState("");
  const [openMostView, setOpenMostView] = useState(false);
  const [productList, setProductList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [filterItem, setFilterItem] = useState({});
  const [feature_names, setFeatureNames] = useState([]);
  const [showSignIn, setShowSignIn] = useState("signin");
  const [openNew, setOpenNew] = useState(false);
  const [openQueueSlider, setOpenQueueSlider] = useState(false);
  const [page, setPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const navigate = useNavigate();
  const [SizeChange, setSizeChange] = useState([]);
  const [filterItemData, setFilterItemData] = useState([]);
  const [sortDataNew, setSortDataNew] = useState([]);
  const { sub_category_id, name } = useParams();
  const [showRating, setShowRating] = useState(false);
  const [ratingProduct, setRatingProduct] = useState({});
  const [sizes, setSizes] = useState([]);
  const [sizes1, setSizes1] = useState([]);
  const [product_Name, setProduct_Name] = useState("");

  const handleChangeSize = (id, size, ind, j) => {
    const filterData = SizeChange.filter((v, s) => v?.index != ind);
    const newObj = {
      index: ind,
      id: id,
      size: size,
      index2: j,
    };
    setSizeChange([...filterData, newObj]);
  };

  useEffect(() => {
    localStorage.getItem("token") &&
      store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
  }, []);
  useEffect(() => {
    fetchFilterData();
  }, [Base64.decode(sub_category_id)]);

  useEffect(() => {
    fetchProductList();
  }, [
    Base64.decode(sub_category_id),
    filterItem,
    sortingTitle,
    page,
    listPerPage,
  ]);

  const fetchFilterData = async () => {
    try {
      const response = await filter_data_api(Base64.decode(sub_category_id));
      if (response.success) {
        setFilterItemData(response?.data?.webFilter);
        setSortDataNew(response?.data?.sortBy);
      } else {
        setFilterItemData([]);
        setSortDataNew([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProductList = async () => {
    // setSizes1([]);
    try {
      setShowLoader(true);

      const obj = {
        sub_category_uuid: Base64.decode(sub_category_id),
        sort_by: sortingTitle == "Mixed Use Ratings" ? "" : sortingTitle,
        feature_names: Object.keys(filterItem),
        feature_values: Object.values(filterItem).map((values) =>
          values.map((value) => value)
        ),
        zipCode: localStorage.getItem("zipcode"),
      };
      const response = await product_category_list_api(
        obj,
        feature_names.length > 0 ? 1 : page,
        listPerPage
      );
      setShowLoader(false);

      if (response.success) {
        setTotalCount(response.results);
        setProductList(response.data);
        const list = response.data;
        var data = [];
        const bul =
          feature_names.filter(
            (item) => item == "TV Size" || item == "TV Price"
          ).length > 0
            ? true
            : false;
        for (var i = 0; i < list?.length; i++) {
          for (var j = 0; j < list[i]?.available_Sizes?.length; j++) {
            if (
              list[i]?.Features_Details[0]?.features[10]["TV Size"] ==
              list[i]?.available_Sizes[j]?.tv_size[0]
            ) {
              const obj = {
                index: i,
                id: list[i]?.available_Sizes[j]?.product_uuid,
                size: list[i]?.available_Sizes[j]?.tv_size[0],
                index2: bul ? j : 0,
              };
              data.push(obj);
            }
          }
        }
        setSizeChange(data);
        for (let index = 0; index < response.data.length; index++) {
          sizes.push(0);
        }
        const arr = [];
        for (let index = 0; index < response.data.length; index++) {
          const sz = response.data[index]?.Features_Details[0]?.features[10];
          arr.push(sz.Size);
          setSizes1(arr);
        }
      } else if (!response.success) {
        toast.error("List is not Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addtoqueue = async (id, instant_By) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        consumer_uuid: localStorage.getItem("consumer_id"),
        product_uuid: id,
        quantity: "1",
        instant_By: instant_By == 1 ? true : false,
      };
      store
        .dispatch(add_to_Queue(obj))
        .unwrap()
        .then((res) => {
          setShowLoader(false);
          if (res.success) {
            setOpenQueueSlider(true);
          }
        });
    } catch {}
  };

  const filterProductType = localStorage.getItem("QueueData");
  const data = JSON.parse(filterProductType);
  const openMostViewRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      openMostViewRef.current &&
      !openMostViewRef.current.contains(event.target)
    ) {
      setTimeout(() => {
        setOpenMostView(false);
      }, 100);
    } else {
    }
  };

  const openFilterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handlCLickOutFilter);
    return () => {
      document.removeEventListener("click", handlCLickOutFilter);
    };
  }, []);
  const handlCLickOutFilter = (event) => {
    if (
      openFilterRef.current &&
      !openFilterRef.current.contains(event.target)
    ) {
      setOpenFilterItem("");
    } else {
    }
  };
  const [openFilterItems, setOpenFilterItems] = useState([]);

  const handleToggleFilterItem = (category) => {
    setOpenFilterItems((prevOpenFilterItems) => {
      if (prevOpenFilterItems.includes(category)) {
        return prevOpenFilterItems.filter((item) => item !== category);
      } else {
        return [...prevOpenFilterItems, category];
      }
    });
  };

  const add_my_item = async (id) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
    } else {
      try {
        const obj = {
          consumer_uuid: localStorage.getItem("consumer_id"),
          product_uuid: id,
          quantity: 1,
        };
        const response = await saved_product_API(obj);
        if (response.success) {
          store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
          toast.success(response.message, { toastId: 1 });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onPageChange = (i) => {
    setPage(i);
  };
  return (
    <div className="CI">
      <div className="CI-top">
        <div className="CI-left" onClick={() => navigate(-1)}>
          <img src={BackIcon} alt="" />
          <div>Back</div>
          <div className="SAP-back np1">
            <div>
              <span onClick={() => navigate("/")}>Home </span>/
              <span
                onClick={() =>
                  navigate("/category", {
                    state: {
                      productpicker: false,
                    },
                  })
                }
              >
                Category
              </span>{" "}
              /{name}
            </div>
          </div>
        </div>
        <div className="CI-right">
          <div className="SAP-back np2">
            <div>
              <span onClick={() => navigate("/")}>Home </span>/
              <span
                onClick={() =>
                  navigate("/category", {
                    state: {
                      productpicker: false,
                    },
                  })
                }
              >
                Category
              </span>{" "}
              /{name}
            </div>
          </div>
          <div
            style={{ borderBottom: "5px solid #252ABE", width: "100px" }}
          ></div>
          <div className="CI-title-div">
            <div className="CI-title">{name}</div>
            <div
              className="CI-expert"
              onClick={() =>
                navigate("/expertpicker", {
                  state: {
                    id: "01",
                    name: name,
                  },
                })
              }
            >
              Expert Product Picker <img src={iIconBLue} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="CI-bottom">
        <div className="CI-bottom-left" ref={openFilterRef}>
          <img src={fltLogo} alt="" className="fltLogo" />
          <div className="CI-filter-title">
            <div className="CI-flt-title">Filter Results</div>
            <div
              className="flt-clrall"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setFilterItem({});
                setOpenFilterItem("");
                setOpenFilterItems([]);
                setFeatureNames([]);
              }}
            >
              Clear All
            </div>
          </div>

          {filterItemData?.map((v, i) => (
            <div className="CI-flt" key={i}>
              <div
                className="CI-flt-main"
                onClick={() => handleToggleFilterItem(v.filter_key)}
              >
                <span>{v.filter_name}</span>
                <img
                  src={
                    openFilterItems.includes(v.filter_key)
                      ? arrowDown
                      : arrowRight
                  }
                  alt=""
                />
              </div>
              {openFilterItems.includes(v.filter_key) && (
                <div className="flt-items">
                  {v.options?.map((p, j) => (
                    <div className="flt-item" key={p._id}>
                      <div className="flt-item-main">
                        <input
                          type="checkbox"
                          checked={filterItem[v.filter_key]?.includes(p.value)}
                          onChange={() => {
                            const selectedCategory =
                              filterItem[v.filter_key] || [];
                            const selectedIndex = selectedCategory.indexOf(
                              p.value
                            );

                            if (selectedIndex !== -1) {
                              // Remove the item if it exists
                              selectedCategory.splice(selectedIndex, 1);
                            } else {
                              // Add the item if it doesn't exist
                              selectedCategory.push(p.value);
                            }

                            // If selectedCategory is empty, remove the key from filterItem
                            const updatedFilterItem = { ...filterItem };
                            if (selectedCategory.length === 0) {
                              delete updatedFilterItem[v.filter_key];
                            } else {
                              updatedFilterItem[v.filter_key] =
                                selectedCategory;
                            }

                            setFilterItem(updatedFilterItem);
                            setPage(1);
                          }}
                        />

                        <div className="flt-item-head">{p.name}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="CI-bottom-right">
          <div className="bt-right-sort">
            <div className={"DWM_2_sort"}>
              <div className={"DWM_2_sort_1"}>Sort By:</div>
              <Dropdown
                width={"250px"}
                title={
                  sortDataNew?.filter((v, i) => v.value == sortingTitle)[0]
                    ?.name || sortingTitle
                }
                setOpen={setOpenMostView}
                open={openMostView}
                body={
                  <>
                    {sortDataNew.map((v, i) => (
                      <p onClick={() => setSortingTitle(v.value)}>{v?.name}</p>
                    ))}
                  </>
                }
              />
            </div>

            {productList?.length > 0 &&
              (totalCount > 9 ? (
                <div className="bt-right-sort-right">
                  Showing {listPerPage * page - listPerPage + 1}-
                  {totalCount > listPerPage * page
                    ? listPerPage * page
                    : totalCount}{" "}
                  of {totalCount}
                </div>
              ) : (
                <div className="bt-right-sort-right">
                  Showing 1-
                  {totalCount} of {totalCount}
                </div>
              ))}
          </div>
          <div className="bt-right-cards">
            {productList?.length > 0 ? (
              productList.map((v, i) => (
                <div className="CI-bt-card" key={i}>
                  <div className="card-1">
                    <img
                      className="item-img"
                      onClick={() => {
                        navigate(
                          `/productdetails/${Base64.encode(
                            v?.Product_Details[0]?.product_uuid
                          )}/${v?.category[0]?.category_name}`
                        );
                      }}
                      src={`${image_base_url}${v.Product_Image_Details[0]?.product_image_url}`}
                      alt=""
                    />
                    <img
                      style={{
                        cursor: "pointer",
                        height: 20,
                        width: 20,
                        position: "absolute",
                        right: 0,
                      }}
                      src={
                        myItemData.find(
                          (val) =>
                            val.product_uuid ==
                            v.Features_Details[0]?.product_uuid
                        )
                          ? like
                          : likeImg
                      }
                      alt=""
                      onClick={() => {
                        myItemData.find(
                          (val) =>
                            val.product_uuid ==
                            v.Features_Details[0]?.product_uuid
                        )
                          ? store.dispatch(
                              delete_MyItem(
                                myItemData.filter(
                                  (val) =>
                                    val.product_uuid ==
                                    v.Features_Details[0]?.product_uuid
                                )[0].queue_uuid
                              )
                            )
                          : add_my_item(v?.Product_Details[0]?.product_uuid);
                      }}
                    />
                  </div>

                  <div>
                    <div
                      className="bt-card-title"
                      onClick={() => {
                        navigate(
                          `/productdetails/${Base64.encode(
                            v?.Product_Details[0]?.product_uuid
                          )}/${v?.category[0]?.category_name}`
                        );
                      }}
                    >
                      {
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.product_name
                      }
                    </div>
                    <div
                      className="bt-card-title"
                      style={{
                        marginTop: ".5rem",
                        color: "#252bbe",
                        fontSize: "15px",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        cursor: "pointer",
                        height: 35,
                      }}
                      title={v?.available_Sizes[sizes[i]]?.product_description}
                      onClick={() => {
                        navigate(
                          `/productdetails/${Base64.encode(
                            v?.Product_Details[0]?.product_uuid
                          )}/${v?.category[0]?.category_name}`
                        );
                      }}
                    >
                      {
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.product_description
                      }
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      width: "100%",
                      overflowX: "scroll",
                      alignSelf: "center",
                      justifyContent:
                        v?.available_Sizes.slice(0)?.length > 5
                          ? "start"
                          : "center",
                    }}
                  >
                    {v?.available_Sizes.slice(0).map((s, k) => (
                      <div
                        onClick={() => {
                          const newArr = sizes1.map((val, ind) =>
                            ind == i ? s?.tv_size[0] : val
                          );
                          setSizes1(newArr);
                        }}
                        className="CI-5th1"
                        style={{
                          border:
                            sizes1[i] == s?.tv_size[0]
                              ? "1px solid #252bbe"
                              : "",
                          color: sizes1[i] == s?.tv_size[0] ? "#252bbe" : "",
                        }}
                        key={k}
                      >
                        {s?.tv_size[0]}"
                      </div>
                    ))}
                  </div>

                  <div>
                    <img
                      style={{ width: "110px", objectFit: "cover" }}
                      src={starIcon}
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      color: "#252BBE",
                      fontFamily: "Roboto Condensed",
                      fontSize: "12px",
                      fontWeight: "300",
                      textTransform: "uppercase",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setProduct_Name(
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.product_description
                      );
                      setRatingProduct(v);
                      setShowRating(true);
                    }}
                  >
                    (324) UNBIASED Reviews
                  </div>

                  <div className="property-card">
                    <div className="PPCard-2">
                      <div style={{ width: "100%" }} className="usage-Rating-1">
                        <div className="usage-rat">
                          {Number(
                            v?.Products_Weights[0]?.MixedUsage * 10
                          ).toFixed(1)}
                        </div>
                        <div className="usage">Mixed Usage</div>
                      </div>
                    </div>
                    <div className="PPcard-head">usage rating</div>
                    <div className="usage-Rating">
                      <div className="usage-Rating-1">
                        <div className="usage-rat">
                          {Number(
                            v?.Products_Weights[0]?.HDRMovies * 10
                          ).toFixed(1)}
                        </div>
                        <div className="usage">Movies</div>
                      </div>
                      <div className="usage-Rating-1">
                        <div className="usage-rat">
                          {Number(
                            v?.Products_Weights[0]?.VarableRefreshRate * 10
                          ).toFixed(1)}
                        </div>
                        <div className="usage">Streaming</div>
                      </div>
                      <div className="usage-Rating-1">
                        <div className="usage-rat">
                          {Number(v?.Products_Weights[0]?.Sports * 10).toFixed(
                            1
                          )}
                        </div>
                        <div className="usage">Sports</div>
                      </div>
                      <div className="usage-Rating-1">
                        <div className="usage-rat">
                          {Number(
                            v?.Products_Weights[0]?.VideoGames * 10
                          ).toFixed(1)}
                        </div>
                        <div className="usage">Gaming</div>
                      </div>
                    </div>
                  </div>
                  <div className="or-get-it-form">Other prices</div>
                  <div className="CI-compare">
                    <div>
                      <img src={walmartImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {v?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          v?.available_Sizes[
                            v?.available_Sizes.findIndex(function (item) {
                              return item.tv_size[0] == sizes1[i];
                            })
                          ]?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <img src={BestBuyImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {v?.Currency_Details[0]?.currency_symbol}
                        {Number(
                          v?.available_Sizes[
                            v?.available_Sizes.findIndex(function (item) {
                              return item.tv_size[0] == sizes1[i];
                            })
                          ]?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <img src={AmazonImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {v?.Currency_Details[0]?.currency_symbol}
                        {Number(
                          v?.available_Sizes[
                            v?.available_Sizes.findIndex(function (item) {
                              return item.tv_size[0] == sizes1[i];
                            })
                          ]?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>

                  <PaymentButtonItem
                    onClick={() =>
                      addtoqueue(
                        v.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          }) || 0
                        ]?.product_uuid,
                        1
                      )
                    }
                    savedPrice={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.instant_save
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    price={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.product_price
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    type={"GET"}
                    symbol={v?.Currency_Details[0]?.currency_symbol}
                  />
                  <PaymentButtonItem
                    onClick={() =>
                      addtoqueue(
                        v.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          }) || 0
                        ]?.product_uuid,
                        0
                      )
                    }
                    savedPrice={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.wait_save
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    price={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.wait_price
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    symbol={v?.Currency_Details[0]?.currency_symbol}
                    type={"WAIT"}
                  />
                </div>
              ))
            ) : (
              <h3
                style={{
                  display: "flex",
                  height: "400px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showLoader ? "" : "No Data Found"}
              </h3>
            )}

            {totalCount > 9 && (
              <Pagination
                totalCount={totalCount}
                currentPage={page}
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>

        <QueueDrawer open={openQueueSlider} setOpen={setOpenQueueSlider} />

        {openNew && (
          <Modal
            centered
            open={openNew}
            footer={false}
            style={{ padding: "0", margin: "0" }}
            onCancel={() => setOpenNew(false)}
          >
            {showSignIn == "signin" && (
              <Login
                setShowSignIn={setShowSignIn}
                setOpenNew={setOpenNew}
                scrollToElement={scrollToElement}
                setTokenData={setTokenData}
              />
            )}
            {showSignIn == "signup" && (
              <SignUp setShowSignIn={setShowSignIn} setOpenNew={setOpenNew} />
            )}
            {showSignIn == "forgatepass" && (
              <ForgatePass
                setShowSignIn={setShowSignIn}
                setOpenNew={setOpenNew}
              />
            )}
          </Modal>
        )}
        {showLoader && <Loader loading={showLoader} />}
      </div>
      {showRating && (
        <Modal
          centered
          open={showRating}
          width={"60%"}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setShowRating(false)}
        >
          <Review2
            bool={true}
            product={ratingProduct}
            productName={product_Name}
            // productName={ratingProduct.Product_Details[0]?.product_description}
          />
        </Modal>
      )}
    </div>
  );
};
