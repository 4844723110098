import React, { useEffect, useState } from "react";
import css from "./MyItems.module.css";
import { Input } from "../../components/inputs/Input";
import clockIcon from "../../assets/MyItem/clockIcon.svg";
import deleteIcon from "../../assets/Queue/DeleteIcon.svg";
import reviewIcon from "../../assets/Review/Group691.svg";
import heartIcon from "../../assets/CategoryItem/like.svg";
import {
  image_base_url,
  recent_procurement_list_api,
} from "../../utils/APIS/Apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/backIcon.svg";
import Loader from "../../components/Loader/Loader";
import { Base64 } from "js-base64";
import store from "../../redux/store";
import { delete_MyItem, get_MyItem } from "../../redux/productSlice";
import { useSelector } from "react-redux";

export const MyItems = () => {
  const { myItemData, myItemloading } = useSelector((state) => state.product);
  const [recentProcurementList, setRecentProcurementList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(false);
  const [showAllRecent, setShowAllRecent] = useState(false);
  const [showAllMyItem, setShowAllMyItem] = useState(false);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
    fetchRecentProcurmentList();
  }, []);

  const fetchRecentProcurmentList = async () => {
    try {
      setShowLoader(true);
      const response = await recent_procurement_list_api(
        localStorage.getItem("consumer_id")
      );
      setShowLoader(false);
      if (response.success) {
        setRecentProcurementList(response.data);
      } else if (!response.success) {
        toast("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className={css?.MyItems}>
        <div className="SAP-back">
          <div
            style={{ width: "fit-content", minWidth: "auto" }}
            className="CI-left"
            onClick={() => navigate(-1)}
          >
            <img src={BackIcon} alt="" />
          </div>
          <div>{/* <span onClick={() => navigate("/")}>Home </span>/ */}</div>
        </div>
        <div className={css?.MI_1st}>
          <div className={css?.MI_1st_title}>My Items</div>
          <div className={css?.MI_1st_right}>
            <div>
              <Input
                style={{
                  width: "50vh",
                }}
                placeholder={"Search Here..."}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
            <div
              className={css?.search}
              style={{ cursor: "pointer", paddingInline: 10 }}
              onClick={() => setSearch1(search)}
            >
              Search Orders
            </div>
          </div>
        </div>
        <div
          className={css?.MI_1st}
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <div className={css?.MI_1st_right}>
            <div>
              <img src={clockIcon} alt="" />
            </div>
            <div className={css?.recent}>Recent ProcuRements</div>
          </div>
          {recentProcurementList?.length > 6 && (
            <div
              className={css?.ShowAll}
              style={{ cursor: "pointer" }}
              onClick={() => setShowAllRecent(!showAllRecent)}
            >
              {showAllRecent ? "Show Less" : "Show All"}
            </div>
          )}
        </div>

        <div className={css?.cards}>
          {recentProcurementList.filter((val) => {
            return val.Product_Details[0]?.product_name
              .toLowerCase()
              .includes(search.toLowerCase());
          }).length == 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: 16,
                color: "#000",
                fontWeight: "500",
                padding: "1rem",
                alignSelf: "center",
                width: "100%",
              }}
            >
              No Product Found
            </div>
          ) : (
            recentProcurementList
              .filter((val) => {
                return val.Product_Details[0]?.product_name
                  .toLowerCase()
                  .includes(search.toLowerCase());
              })
              ?.slice(0, showAllRecent ? recentProcurementList?.length : 6)
              .map((v, i) => (
                <div
                  className={css.card}
                  key={i}
                  onClick={() => navigate("/recentprocurement", { state: v })}
                >
                  <div className={css.card_img}>
                    <img
                      src={`${image_base_url}${v.Image_Details[0]?.product_image_url}`}
                      alt=""
                    />
                  </div>
                  <div className={css.card_content}>
                    <div className={css?.title}>
                      {v.Product_Details[0]?.product_name}
                    </div>
                    <img className={css.img} src={reviewIcon} alt="" />
                    <div className={css.price}>
                      your purchase PRICE:{" "}
                      <span>
                        {v?.Currency_Details[0]?.currency_symbol}
                        {
                          Number(
                            (
                              v?.Product_Price_Details[0]?.product_price *
                              v?.quantity
                            ).toFixed(2)
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          // * v?.quantity
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>

        <div style={{ borderBottom: "1px solid #BFC0FF" }}></div>
      </div>
      <div className={css?.MyItems}>
        <div
          className={css?.MI_1st}
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <div className={css?.MI_1st_right}>
            <div>
              <img src={heartIcon} alt="" style={{ height: "24px" }} />
            </div>
            <div className={css?.recent}>My favorites</div>
          </div>
          {myItemData?.length > 6 && (
            <div
              className={css?.ShowAll}
              style={{ cursor: "pointer" }}
              onClick={() => setShowAllMyItem(!showAllMyItem)}
            >
              {showAllMyItem ? "Show Less" : "Show All"}
            </div>
          )}
        </div>

        <div className={css?.cards}>
          {myItemData.filter((val) => {
            return val.Product_Details[0]?.product_name
              .toLowerCase()
              .includes(search.toLowerCase());
          }).length == 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: 16,
                color: "#000",
                fontWeight: "500",
                padding: "1rem",
                alignSelf: "center",
                width: "100%",
              }}
            >
              No Product Found
            </div>
          ) : (
            myItemData
              .filter((val) => {
                return val.Product_Details[0]?.product_name
                  .toLowerCase()
                  .includes(search.toLowerCase());
              })
              .slice(0, showAllMyItem ? myItemData?.length : 6)
              .map((v, i) => (
                <div
                  style={{ flexDirection: "column" }}
                  className={css.card}
                  key={i}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt=""
                      onClick={() =>
                        store.dispatch(delete_MyItem(v?.queue_uuid))
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: ".6rem",
                    }}
                    onClick={() =>
                      navigate(
                        `/productdetails/${Base64.encode(
                          v?.Product_Details[0]?.product_uuid
                        )}/${v?.category[0]?.category_name}`
                      )
                    }
                  >
                    <div className={css.card_img}>
                      <img
                        src={`${image_base_url}${v.Image_Details[0]?.product_image_url}`}
                        alt=""
                      />
                    </div>
                    <div className={css.card_content}>
                      <div className={css?.title}>
                        {v.Product_Details[0]?.product_name}
                      </div>
                      <img className={css.img} src={reviewIcon} alt="" />
                      <div className={css.price}>
                        your purchase PRICE:{" "}
                        <span>
                          {v?.Currency_Details[0]?.currency_symbol}
                          {
                            Number(
                              (
                                v?.Product_Price_Details[0]?.product_price *
                                v?.quantity
                              ).toFixed(2)
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            // * v?.quantity
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
        <div style={{ borderBottom: "1px solid #BFC0FF" }}></div>
      </div>
      {ShowLoader && <Loader loading={ShowLoader} />}
    </div>
  );
};
