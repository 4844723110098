import { Alert } from "@mui/material";
import { Modal } from "antd";

export const images = {
  expertpicker: require("../assets/images/expertpicker.png"),
  playIcon: require("../assets/images/play.png"),
  expPicker1: require("../assets/images/expPicker1.png"),
  queue: require("../assets/images/queue.svg"),
  homeGirl: require("../assets/images/homeGirl.png"),
  videoImg1: require("../assets/images/videoImg1.png"),
  videoImg2: require("../assets/images/videoImg2.png"),
  bgImg: require("../assets/images/bgImg.png"),
  videotext: require("../assets/images/videotext.png"),
  videotext1: require("../assets/images/videotext1.png"),
  videotext2: require("../assets/images/videotext2.png"),
  videotext3: require("../assets/images/videotext3.png"),
  home_card1img: require("../assets/images/home_card1img.png"),
};
export const onSuccess = (mess = "") =>
  mess.length > 0 && <Alert severity="success">{mess}</Alert>;
export function numberWithCommas(x) {
  x = x?.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
}
export const ProductNotAvailable = ({ openModal, setOpenModal }) => (
  <Modal
    centered
    closable={true}
    open={openModal}
    footer={false}
    width={"auto"}
    style={{ padding: 0, margin: "0" }}
    onCancel={() => setOpenModal(false)}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        color: "black",
        fontWeight: "500",
        marginTop: "1.5rem",
        fontSize: "20px",
        textAlign: "center",
        fontFamily: "Roboto",
        padding: "1rem 4rem",
      }}
    >
      This vertical coming soon!
    </div>
  </Modal>
);
