import React, { useEffect, useState } from "react";
import "./ProductCat.css";
import { useNavigate } from "react-router-dom";
import {
  image_base_url,
  product_main_category,
} from "../../../utils/APIS/Apis";
import { BrowseAsGuest } from "../../auth/LoginSign";
import { Modal } from "antd";
export const ProductCat = () => {
  const [productListMain, setProductListMain] = useState([]);
  const navigate = useNavigate();
  const [open1, setOpen1] = useState(false);
  const [rollOverPop, setRollOverPop] = useState(false);

  useEffect(() => {
    fetchProductList();
  }, []);

  const fetchProductList = async () => {
    const data = await product_main_category();
    if (data.success) {
      setProductListMain(data.data);
    }
  };

  const handleGetProduct = (item, index) => {
    if (localStorage.getItem("zipcode")) {
      if (item?.entity_status == 1) {
        navigate("/seeallproduct", { state: item });
      } else {
        setRollOverPop(true);
      }
    } else {
      setOpen1(true);
    }
  };
  return (
    <div className="PC1">
      <div className="PC-1">Search by Category</div>
      <div className="PC-2">
        {productListMain.length > 0 &&
          productListMain
            .sort((a, b) => a.priority - b.priority)
            .map((v, i) => (
              <div
                key={i}
                className="PC-img-div_main"
                onClick={() => handleGetProduct(v, i)}
              >
                <div
                  className="PC-img-div"
                  style={{
                    border:
                      i == 0 ? "5px solid #B8F8E9" : "5px solid transparent",
                  }}
                >
                  <img
                    src={`${image_base_url}${v.category_image_url}`}
                    className="PC-img"
                    alt={v.category_name}
                    style={{ opacity: i == 0 ? "1" : ".4" }}
                  />
                </div>
                <div className="PC-item-desc">{v.category_name}</div>
              </div>
            ))}
      </div>
      <div className="PC-3">
        <button
          className="home_top_header_btn1"
          onClick={() => {
            if (localStorage.getItem("zipcode")) {
              navigate("/category", {
                state: {
                  productpicker: false,
                },
              });
            } else {
              setOpen1(true);
            }
          }}
        >
          See all Categories
        </button>
      </div>
      {open1 && (
        <Modal
          centered
          closable={true}
          open={open1}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen1(false)}
        >
          <BrowseAsGuest setOpenNew={setOpen1} />
        </Modal>
      )}
      {rollOverPop && (
        <Modal
          centered
          closable={true}
          open={rollOverPop}
          footer={false}
          width={"auto"}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setRollOverPop(false)}
        >
          {/* <BrowseAsGuest setOpenNew={setRollOverPop} /> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                color: "black",
                fontWeight: "500",
                marginTop: "1.5rem",
                width: "300px",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              This vertical coming soon!
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
