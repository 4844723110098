import React, { useEffect, useState } from "react";
import "./auth.css";
import ColorLogo from "../../assets/icons/nameblack.svg";
import { Button } from "../btn/Button";
import LogWith from "../../assets/logwith.svg";
import { isValidEmail } from "../../utils/Validations/Validation";
import Loader from "../Loader/Loader";
import { base_url, login_api, sign_up_api } from "../../utils/APIS/Apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import styles from "../../screen/08Queue/Queue.module.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { GoEye, GoEyeClosed } from "react-icons/go";
import store from "../../redux/store";
import {
  get_MyItem,
  get_Queue,
  reset,
  setConsumerID,
} from "../../redux/productSlice";

export const Login = ({
  navi = false,
  setShowSignIn,
  setOpenNew,
  setTokenData,
  dash = false,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [btnLight, setBtnlight] = useState(true);
  const [addData, setAddData] = useState({});
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (addData?.password?.length > 7 && isValidEmail(addData?.email) == true) {
  //     setBtnlight(false);
  //   } else {
  //     setBtnlight(true);
  //   }
  // }, [addData]);

  const handleLogin = async () => {
    if (addData?.password?.length > 7 && isValidEmail(addData?.email) == true) {
      try {
        setLoader(true);
        const body = {
          email: addData?.email,
          password: addData?.password,
          location: addData?.location || "IT Park Indore",
        };
        const response = await login_api(body);
        setLoader(false);
        if (response?.success) {
          store.dispatch(setConsumerID(response?.consumer?.consumer_id));
          setOpenNew(false);
          localStorage.setItem("token", response?.consumer?.token);
          setTokenData("token", response?.consumer?.token);
          localStorage.setItem("consumer_id", response?.consumer?.consumer_id);
          localStorage.setItem("full_name", response?.consumer?.full_name);
          localStorage.setItem("zipcode", response?.consumer?.zip_code);
          store.dispatch(get_MyItem(response?.consumer?.consumer_id));
          store.dispatch(get_Queue(response?.consumer?.consumer_id));

          if (navi) {
            navigate("/category", {
              state: {
                productpicker: false,
              },
            });
          }
          if (dash) {
            navigate("/datadashboard");
          }
        } else {
          toast.error(response.message, {
            toastId: "1",
          });
        }
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    } else {
      console.log("error occured");
    }
  };

  return (
    <div className="signin">
      <div className="Sign-main">
        <div className="log-logo">
          <img src={ColorLogo} alt="" className="log-logo-img" />
        </div>
        <div className="log-tittle">Sign In</div>
        <div
          className="modal2"
          style={{ cursor: "pointer" }}
          onClick={() => setShowSignIn("signup")}
        >
          Don’t have an account? Create a <span>Demandey Account</span>
        </div>
        <div
          className=""
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form style={{ width: "80%" }} className="log-form">
            <TextField
              InputProps={{
                style: { WebkitBoxShadow: "0 0 0 1000px white inset" },
              }}
              id="outlined-basic"
              variant="outlined"
              className="custom-textfield"
              // InputProps={{
              //   // autoComplete: "", // Force browser to treat it as a new password field
              //   style: {
              //     WebkitTextFillColor: "red",
              //     WebkitBackfaceVisibility: "visible",
              //     webkitb,
              //   }, // Change the text color
              // }}
              style={{
                width: "100%",
              }}
              type={"text"}
              size="small"
              name="Email"
              label="Email"
              // inputProps={{ autoComplete: "off" }}
              value={addData?.email}
              onChange={(e) => {
                setAddData({
                  ...addData,
                  email: e.target.value.trim(),
                  emailError: "",
                });
              }}
              error={Boolean(addData?.emailError)}
              helperText={addData?.emailError}
              onBlur={() => {
                if (
                  !addData?.email ||
                  (addData?.email && addData?.email.length === 0)
                ) {
                  setAddData({
                    ...addData,
                    emailError: "Email is required.",
                  });
                } else if (isValidEmail(addData?.email) !== true) {
                  setAddData({
                    ...addData,
                    emailError: "Invalid email address.",
                  });
                }
              }}
            />

            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{
                width: "100%",
              }}
              size="small"
              name="Password"
              label="Password"
              value={addData?.password}
              // inputProps={{
              //   style: { marginRight: 10, backgroundColor: "red" },
              // }}
              onChange={(e) => {
                e.target.value.length <= 16 &&
                  setAddData({
                    ...addData,
                    password: e.target.value.trim(),
                    passwordError: "",
                  });
              }}
              type={showPass ? "text" : "password"}
              InputProps={{
                // style: { backgroundColor: "#f00" },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      backgroundColor: "transparent",
                      width: "30px",
                      display: "flex",
                      height: "100px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {/* <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPass(!showPass)}
                      edge="end"
                      style={{
                        backgroundColor: "yellow",
                      }}
                    > */}
                    {showPass ? (
                      <GoEye
                        className="pass-eye"
                        style={{ cursor: "pointer", marginRight: 8 }}
                        onClick={() => setShowPass(!showPass)}
                      />
                    ) : (
                      <GoEyeClosed
                        className="pass-eye"
                        style={{ cursor: "pointer", marginRight: 8 }}
                        onClick={() => setShowPass(!showPass)}
                      />
                    )}
                    {/* </IconButton> */}
                  </InputAdornment>
                ),
              }}
              error={Boolean(addData?.passwordError)}
              helperText={addData?.passwordError}
              onBlur={() => {
                if (
                  !addData?.password ||
                  (addData?.password && addData?.password.length < 8)
                ) {
                  setAddData({
                    ...addData,
                    passwordError:
                      "Password should be at least 8 and max 16 characters.",
                  });
                }
              }}
            />

            <div
              className="log-FP"
              style={{
                cursor: "pointer",
                alignSelf: "flex-start",
              }}
              onClick={() => setShowSignIn("forgatepass")}
            >
              Forgot Password?
            </div>
            <div className="log-btn">
              <Button
                disable={
                  addData?.password?.length > 7 &&
                  isValidEmail(addData?.email) == true
                    ? false
                    : true
                }
                onClick={() => handleLogin()}
                title={"Sign In"}
              />
            </div>
            <div className="lop-SM">
              <div
                style={{
                  color: "black",
                  backgroundColor: "white",
                  border: "1px solid rgba(0, 0, 0, 0.158)",
                  fontSize: "15px",
                  gap: "0rem",
                }}
                className={`btn btn-success `}
                role="button"
              >
                <div>Sign In with:</div>
                <img src={LogWith} alt="" />
              </div>
            </div>
            <div className="keepin">
              <input type="Checkbox" style={{ width: "20px" }} />
              <div> Keep me signed in</div>
            </div>
          </form>
        </div>
        <div className="log-end">
          By signing in, you agree to the Demandey User Agreement.
        </div>
      </div>
      {loader && <Loader loading={loader} />}
    </div>
  );
};

export const SignUp = ({
  setShowSignIn,
  setOpenNew,
  navi = false,
  setTokenData,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [addData, setAddData] = useState({ terms: true });
  const [loader, setLoader] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    if (
      !addData?.first_name ||
      (addData?.first_name && addData?.first_name.length == 0) ||
      !addData?.last_name ||
      (addData?.last_name && addData?.last_name.length == 0) ||
      !addData?.email ||
      (addData?.email && addData?.email.length == 0) ||
      isValidEmail(addData?.email) !== true ||
      !addData?.password ||
      (addData?.password && addData?.password.length < 8) ||
      !addData?.confirm_password ||
      (addData?.confirm_password && addData?.confirm_password.length < 8) ||
      !addData?.zip_code ||
      (addData?.zip_code && addData?.zip_code.length != 5) ||
      checkValue == false
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSignUp = async () => {
    if (validate()) {
      if (
        addData?.password !== "" &&
        addData?.password == addData?.confirm_password
      ) {
        try {
          setLoader(true);
          const body = {
            email_address: addData?.email,
            password: addData?.password,
            first_name: addData?.first_name,
            last_name: addData?.last_name,
            terms_id: addData?.terms ? "1" : "0",
            zip_code: addData?.zip_code,
          };
          const response = await sign_up_api(body);
          if (response?.success) {
            setOpenNew(false);
            const obj = {
              email: addData?.email,
              password: addData?.password,
              location: addData?.location || "IT Park Indore",
            };
            const res = await login_api(obj);
            setLoader(false);
            if (res?.success) {
              toast.success("User Created and Successsfully Logged In", {
                toastId: "1",
              });
              store.dispatch(reset());

              setOpenNew(false);
              localStorage.setItem("token", res?.consumer?.token);
              setTokenData("token", res?.consumer?.token);
              localStorage.setItem("consumer_id", res?.consumer?.consumer_id);
              localStorage.setItem("full_name", res?.consumer?.full_name);
              localStorage.setItem("zipcode", res?.consumer?.zip_code);
              store.dispatch(get_MyItem(res?.consumer?.consumer_id));
              store.dispatch(get_Queue(res?.consumer?.consumer_id));
              store.dispatch(setConsumerID(res?.consumer?.consumer_id));
              if (navi) {
                navigate("/category", {
                  state: {
                    productpicker: false,
                  },
                });
              }
            }
          } else if (!response.success) {
            toast.success(response?.message, {
              toastId: "1",
            });
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
          console.log(error);
        }
      } else {
      }
    } else {
      let errors = {};

      if (
        !addData?.first_name ||
        (addData?.first_name && addData?.first_name.length === 0)
      ) {
        errors.firstNameError = "First Name is required.";
      }

      if (
        !addData?.last_name ||
        (addData?.last_name && addData?.last_name.length === 0)
      ) {
        errors.lastNameError = "Last Name is required.";
      }

      if (!addData?.email || (addData?.email && addData?.email.length === 0)) {
        errors.emailError = "Email is required.";
      } else if (isValidEmail(addData?.email) !== true) {
        errors.emailError = "Invalid email address.";
      }

      if (
        !addData?.password ||
        (addData?.password && addData?.password.length < 8)
      ) {
        errors.passwordError =
          "Password should be at least 8 and max 16 characters.";
      }

      if (
        !addData?.confirm_password ||
        (addData?.confirm_password && addData?.confirm_password.length < 8)
      ) {
        errors.confirmPasswordError =
          "Confirm Password should be at least 8 and max 16 characters.";
      } else if (addData?.password !== addData?.confirm_password) {
        errors.confirmPasswordError = "Passwords do not match.";
      }

      if (
        !addData?.zip_code ||
        (addData?.zip_code && addData?.zip_code.length !== 5)
      ) {
        errors.zipCodeError = "Zip Code should be 5 characters.";
      }

      if (Object.keys(errors).length > 0) {
        setAddData({ ...addData, ...errors });
        return false;
      } else {
        setAddData({ ...addData, ...errors });
        if (checkValue === false) {
          toast.error("Please Select Agreement First.", {
            toastId: "1",
          });
        }
        return true;
      }
    }
  };
  const handleAccept = () => {
    setCheckValue(true);
    setShowAgreement(false);
  };
  const [isAutofilled, setIsAutofilled] = useState(false);

  useEffect(() => {
    const handleAnimationStart = () => {
      setIsAutofilled(true);
    };

    const handleAnimationEnd = () => {
      setIsAutofilled(false);
    };

    const inputFields = document.querySelectorAll("input");

    inputFields.forEach((input) => {
      input.addEventListener("animationstart", handleAnimationStart);
      input.addEventListener("animationend", handleAnimationEnd);
    });

    return () => {
      inputFields.forEach((input) => {
        input.removeEventListener("animationstart", handleAnimationStart);
        input.removeEventListener("animationend", handleAnimationEnd);
      });
    };
  }, []);
  return (
    <div className="signin">
      <div className="Sign-main">
        <div className="log-logo">
          <img src={ColorLogo} alt="" className="log-logo-img" />
        </div>
        <div className="log-tittle">Create your Demandey account </div>
        <div
          className="modal2"
          style={{ cursor: "pointer" }}
          onClick={() => setShowSignIn("signin")}
        >
          Already have an account? <span>Sign In</span>
        </div>
        <div
          className=""
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form style={{ width: "100%" }} className="Sign-form">
            <div
              className="input-box"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="First Name"
                style={{
                  width: "100%",
                }}
                className="MU-input"
                type={"text"}
                label="First Name"
                size="small"
                value={addData?.first_name}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    first_name: e.target.value
                      .trimStart()
                      .replace(/[^a-zA-Z0-9 ]/g, "")
                      .replace(/^[0-9]/, "")
                      .replace(/\s{2,}/g, " "),
                    firstNameError: "",
                  });
                }}
                onBlur={() => {
                  if (
                    !addData?.first_name ||
                    (addData?.first_name && addData?.first_name.length === 0)
                  ) {
                    setAddData({
                      ...addData,
                      firstNameError: "First Name is required.",
                    });
                  }
                }}
                error={Boolean(addData?.firstNameError)}
                helperText={addData?.firstNameError}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ backgroundColor: isAutofilled ? "#fff" : "#fff" }}
                className="MU-input"
                type={"text"}
                size="small"
                name="Last Name"
                label="Last Name"
                value={addData?.last_name}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    last_name: e.target.value
                      .trimStart()
                      .replace(/[^a-zA-Z0-9 ]/g, "")
                      .replace(/^[0-9]/, "")
                      .replace(/\s{2,}/g, " "),
                    lastNameError: "",
                  });
                }}
                error={Boolean(addData?.lastNameError)}
                helperText={addData?.lastNameError}
                onBlur={() => {
                  if (
                    !addData?.last_name ||
                    (addData?.last_name && addData?.last_name.length === 0)
                  ) {
                    setAddData({
                      ...addData,
                      lastNameError: "Last Name is required.",
                    });
                  }
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ backgroundColor: isAutofilled ? "#fff" : "#fff" }}
                className="MU-input"
                type={"text"}
                size="small"
                name="Email"
                label="Email"
                value={addData?.email}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    email: e.target.value.trim(),
                    emailError: "",
                  });
                }}
                error={Boolean(addData?.emailError)}
                helperText={addData?.emailError}
                onBlur={() => {
                  if (
                    !addData?.email ||
                    (addData?.email && addData?.email.length === 0)
                  ) {
                    setAddData({
                      ...addData,
                      emailError: "Email is required.",
                    });
                  } else if (isValidEmail(addData?.email) !== true) {
                    setAddData({
                      ...addData,
                      emailError: "Invalid email address.",
                    });
                  }
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ backgroundColor: isAutofilled ? "#fff" : "#fff" }}
                className="MU-input"
                size="small"
                name="Password"
                label="Password"
                value={addData?.password}
                onChange={(e) => {
                  e.target.value.length <= 16 &&
                    setAddData({
                      ...addData,
                      password: e.target.value.trim(),
                      passwordError: "",
                    });
                }}
                error={Boolean(addData?.passwordError)}
                helperText={addData?.passwordError}
                type={showPass ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPass(!showPass)}
                        edge="end"
                      >
                        {showPass ? (
                          <GoEye
                            onClick={() => setShowPass(!showPass)}
                            className="pass-eye"
                          />
                        ) : (
                          <GoEyeClosed
                            onClick={() => setShowPass(!showPass)}
                            className="pass-eye"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onBlur={() => {
                  if (
                    !addData?.password ||
                    (addData?.password && addData?.password.length < 8)
                  ) {
                    setAddData({
                      ...addData,
                      passwordError:
                        "Password should be at least 8 and max 16 characters.",
                    });
                  }
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ backgroundColor: isAutofilled ? "#fff" : "#fff" }}
                className="MU-input"
                size="small"
                name={"Confirm Password".trim()}
                label={"Confirm Password".trim()}
                value={addData?.confirm_password}
                onChange={(e) => {
                  e.target.value.length <= 16 &&
                    setAddData({
                      ...addData,
                      confirm_password: e.target.value.trim(),
                      confirmPasswordError: "",
                    });
                }}
                error={Boolean(addData?.confirmPasswordError)}
                helperText={addData?.confirmPasswordError}
                type={showConfirmPass ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                        edge="end"
                      >
                        {showConfirmPass ? (
                          <GoEye
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                            className="pass-eye"
                          />
                        ) : (
                          <GoEyeClosed
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                            className="pass-eye"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onBlur={() => {
                  if (
                    !addData?.confirm_password ||
                    (addData?.confirm_password &&
                      addData?.confirm_password.length < 8)
                  ) {
                    setAddData({
                      ...addData,
                      confirmPasswordError:
                        "Confirm Password should be at least 8 and max 16 characters.",
                    });
                  } else if (addData?.password !== addData?.confirm_password) {
                    setAddData({
                      ...addData,
                      confirmPasswordError: "Passwords do not match.",
                    });
                  }
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ backgroundColor: isAutofilled ? "#fff" : "#fff" }}
                className="MU-input"
                type={"text"}
                size="small"
                name="Zip Code"
                label="Zip Code"
                value={addData?.zip_code}
                error={Boolean(addData?.zipCodeError)}
                helperText={addData?.zipCodeError}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  const truncatedValue = numericValue.slice(0, 5);
                  setAddData({
                    ...addData,
                    zip_code: truncatedValue.trim(),
                    zipCodeError: "",
                  });
                }}
                onBlur={() => {
                  if (
                    !addData?.zip_code ||
                    (addData?.zip_code && addData?.zip_code.length !== 5)
                  ) {
                    setAddData({
                      ...addData,
                      zipCodeError: "Zip Code should be 5 characters.",
                    });
                  }
                }}
              />

              <div className="Sign-loc">
                Please give us your zip code so we can determine the correct
                total price (with tax and shipping) to your home.
              </div>
            </div>
            <div className="keepin" style={{ cursor: "pointer" }}>
              {!checkValue && (
                <>
                  <div className="modal2">
                    I agree to the{" "}
                    <span onClick={() => setShowAgreement(!showAgreement)}>
                      Demandey User Agreement.
                    </span>
                  </div>
                  <Tooltip title="Click on Demandey User Agreement and Accept the Agreement to proceed further">
                    <div
                      style={{
                        width: "25px",
                        height: "25px",
                        backgroundColor: "rgb(238, 231, 231)",
                        borderRadius: "50%",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      ?
                    </div>
                  </Tooltip>
                </>
              )}
            </div>

            {showAgreement && (
              <div className={styles.manu_user_agree}>
                <div className={styles.manu_title}>
                  You must read and agree to the Manufacturer’s Agreement to
                  purchase your product.
                </div>
                <div className={styles.agreement}>
                  <div className={styles.agree_1}>
                    USER AGREEMENT BETWEEN DEMANDEY AND CUSTOMER
                  </div>
                  <div className={styles.agree_2}>
                    This User Agreement (the "Agreement") is made and entered
                    into on [date] (the "Effective Date") between [Demandey],
                    with its principal place of business at [Demandey], and
                    [Customer name], with its principal place of business at
                    [Customer address] (the "Customer").
                  </div>
                  <div className={styles.agree_3}>
                    <div>RECITALS</div>

                    <span>
                      A. The Manufacturer is in the business of producing and
                      selling [describe the product(s)].
                    </span>
                    <span>
                      B. The Customer desires to purchase [describe the
                      product(s)] from the Manufacturer.
                    </span>
                    <span>
                      C. The parties wish to set forth the terms and conditions
                      governing the purchase and sale of the products by the
                      Customer from the Manufacturer.
                    </span>
                  </div>
                  <div className={styles.agree_4}>
                    <div>AGREEMENT</div>
                    <span>Purchase of Products</span>
                    <span>
                      The Manufacturer agrees to sell and the Customer agrees to
                      purchase [describe the product(s)] (the "Products") on the
                      terms and conditions set forth in this Agreement.
                    </span>
                    <span>Price and Payment</span>
                    <span>
                      The price for the Products shall be [insert price] (the
                      "Price"). Payment shall be made by the Customer within
                      [insert number] days of receipt of the invoice from the
                      Manufacturer. Late payments shall be subject to interest
                      at the rate of [insert interest rate] per month, or the
                      maximum rate permitted by law, whichever is less.
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ backgroundColor: "#252BBE", color: "#fff" }}
                    onClick={handleAccept}
                    className={styles.btn}
                  >
                    I Agree
                  </div>
                </div>
              </div>
            )}
            <div className="log-btn">
              <Button
                style={{
                  width: "50%",
                }}
                onClick={handleSignUp}
                disable={!validate() ? true : false}
                title={"Let's Shop"}
              />
            </div>
          </form>
        </div>
      </div>
      {loader && <Loader loading={loader} />}
    </div>
  );
};

export const ForgatePass = ({ setShowSignIn }) => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [btnLight, setBtnlight] = useState(true);
  const [addData, setAddData] = useState({});
  const [loader, setLoader] = useState(false);

  const validate = () => {
    if (isValidEmail(addData?.email) != true) {
      return false;
    } else {
      return true;
    }
  };
  const [isAutofilled, setIsAutofilled] = useState(false);

  useEffect(() => {
    const handleAnimationStart = () => {
      setIsAutofilled(true);
    };

    const handleAnimationEnd = () => {
      setIsAutofilled(false);
    };

    const inputFields = document.querySelectorAll("input");

    inputFields.forEach((input) => {
      input.addEventListener("animationstart", handleAnimationStart);
      input.addEventListener("animationend", handleAnimationEnd);
    });

    return () => {
      // Clean up event listeners on component unmount
      inputFields.forEach((input) => {
        input.removeEventListener("animationstart", handleAnimationStart);
        input.removeEventListener("animationend", handleAnimationEnd);
      });
    };
  }, []);
  const forgotapi = async () => {
    try {
      if (!validate()) {
        return;
      }
      setLoader(true);
      const response = await fetch(
        base_url + "update/forgot-password/" + addData?.email,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      setLoader(false);
      if (res?.success) {
        setShowSignIn("signin");
        toast.success(res?.message, {
          toastId: "1",
        });
      } else {
        toast.error(res?.message, {
          toastId: "1",
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  return (
    <div style={{ margin: "2rem 0" }} className="signin">
      <div className="Sign-main">
        <div className="log-logo">
          <img src={ColorLogo} alt="" className="log-logo-img" />
        </div>
        <div className="log-tittle">Forgot Password </div>
        <div
          className=""
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form style={{ width: "100%" }} className="Sign-form">
            <div className="input-box">
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{
                  width: "65%",
                }}
                type={"text"}
                size="small"
                name="Email"
                label="Email"
                value={addData?.email}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    email: e.target.value.trim(),
                    emailError: "",
                  });
                }}
                error={Boolean(addData?.emailError)}
                helperText={addData?.emailError}
                onBlur={() => {
                  if (
                    !addData?.email ||
                    (addData?.email && addData?.email.length === 0)
                  ) {
                    setAddData({
                      ...addData,
                      emailError: "Email is required.",
                    });
                  } else if (isValidEmail(addData?.email) !== true) {
                    setAddData({
                      ...addData,
                      emailError: "Invalid email address.",
                    });
                  }
                }}
              />
            </div>
            <div className="log-btn">
              <Button
                style={{
                  width: "50%",
                }}
                onClick={() => forgotapi()}
                disable={!validate() ? true : false}
                title={"Forgot Password"}
              />
            </div>
          </form>
        </div>
      </div>
      {loader && <Loader loading={loader} />}
    </div>
  );
};

export const BrowseAsGuest = ({
  setShowSignIn,
  setOpenNew,
  expert = false,
}) => {
  const [loader, setLoader] = useState(false);
  const [ZipCode, setZipCode] = useState();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (ZipCode?.length >= 5) {
      try {
        setLoader(true);
        localStorage.setItem("zipcode", ZipCode);
        setOpenNew(false);
        navigate("/category", {
          state: {
            productpicker: expert,
          },
        });
        // const body = {
        //   zip_code: ZipCode,
        // };
        // const response = await login_api(body);
        // setLoader(false);
        // if (response?.success) {
        //   setOpenNew(false);
        //   localStorage.setItem("token", response?.consumer?.token);
        //   localStorage.setItem("consumer_id", response?.consumer?.consumer_id);
        //   toast.success(response?.message);
        // }
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    } else {
      console.log("error occured");
    }
  };
  const [isAutofilled, setIsAutofilled] = useState(false);

  useEffect(() => {
    const handleAnimationStart = () => {
      setIsAutofilled(true);
    };

    const handleAnimationEnd = () => {
      setIsAutofilled(false);
    };

    const inputFields = document.querySelectorAll("input");

    inputFields.forEach((input) => {
      input.addEventListener("animationstart", handleAnimationStart);
      input.addEventListener("animationend", handleAnimationEnd);
    });

    return () => {
      inputFields.forEach((input) => {
        input.removeEventListener("animationstart", handleAnimationStart);
        input.removeEventListener("animationend", handleAnimationEnd);
      });
    };
  }, []);
  return (
    <div className="signin">
      <div className="Sign-main">
        <div className="log-logo">
          <img src={ColorLogo} alt="" className="log-logo-img" />
        </div>
        <div className="log-tittle">Browse as Guest</div>
        <div style={{ marginTop: "1rem", fontSize: "15px" }} className="modal2">
          Please give us your zip code so we can determine the correct total
          price (with tax and shipping) to your home.
        </div>
        <div
          className=""
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="log-form"
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{
                width: "80%",
              }}
              type={"text"}
              size="small"
              name="Zip Code"
              label="Zip Code"
              value={ZipCode}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                const truncatedValue = numericValue.slice(0, 5);
                setZipCode(truncatedValue.trim());
              }}
            />

            <div className="log-btn">
              <Button
                disable={ZipCode?.length >= 5 ? false : true}
                onClick={handleLogin}
                title={"Browse"}
              />
            </div>
          </form>
        </div>
        <div
          style={{
            display: "flex",
            gap: ".85rem",
            flexDirection: "column",
            margin: "1rem 0",
            fontWeight: "500",
            fontSize: "14px",
          }}
        >
          <div className="log-end">Demandey helps you save</div>
          <div
            className="log-end"
            style={{ fontSize: "30px", fontWeight: "700" }}
          >
            30-50%
          </div>
          <div className="log-end">
            on the same name brand products you buy today.
          </div>
          <div className="log-end">
            View the <span style={{ fontWeight: "700" }}>User Agreement</span>
          </div>
        </div>
      </div>
      {loader && <Loader loading={loader} />}
    </div>
  );
};
