import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

export const Slider = ({
  onChange,
  value,
  max = 10,
  min = 0,
  stepsRequre = true,
  steps = 1,
  left = "",
  right = "",
  center,
  imgTrue = false,
  image,
  UpperCenter,
  p1,
  p2,
  p3,
}) => {
  const renderLines = () => {
    const lines = [];
    for (let i = min; i <= max; i++) {
      lines.push(
        <div
          key={i}
          className="step-line"
          style={{
            display: i == max || i == min ? "none" : "",
            left: `calc(${(i / max) * 100}% - 1px)`,
          }}
        />
      );
    }
    return lines;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div
        style={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imgTrue && <img src={image} />}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "300px",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
        }}
        className="percent"
      >
        <div>{UpperCenter}</div>
      </div>
      <div className="slidecontainer">
        <input
          style={{ width: "300px", height: 4 }}
          type="range"
          value={value}
          min={min}
          max={max}
          step={steps}
          onChange={onChange}
          className="slider"
          id="myRange"
        />
        {stepsRequre && <div className="step-lines">{renderLines()}</div>}
      </div>
      <div
        className="percent"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100px",
            justifyContent: "flex-start",
            display: "flex",
          }}
        >
          {p1 || "25%"}
        </div>
        <div
          style={{
            width: "100px",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {p2 || "50%"}
        </div>
        <div
          style={{
            width: "100px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {p3 || "75%"}
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "300px",
            // justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
          }}
          className="percent"
        >
          <div
            style={{
              width: "100px",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            {left}
          </div>
          <div
            style={{
              width: "100px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {center}
          </div>
          <div
            style={{
              width: "100px",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            {right}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SliderForBrightness = ({
  onChange,
  value,
  max = 10,
  min = 0,
  stepsRequre = true,
  steps = 1,
  left = "",
  right = "",
  center,
  imgTrue = false,
  image,
  UpperCenter,
  image2,
}) => {
  const renderLines = () => {
    const lines = [];
    for (let i = min; i <= max; i++) {
      lines.push(
        <div
          key={i}
          className="step-line"
          style={{
            display: i == max || i == min ? "none" : "",
            left: `calc(${(i / max) * 100}% - 1px)`,
          }}
        />
      );
    }
    return lines;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div
        style={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {imgTrue && (
          <img
            style={{
              position: "absolute",
              top: "12px",
              right: "45.5%",
              opacity: `${value / 10}`,
            }}
            src={image2}
          />
        )}
        {imgTrue && <img src={image} />}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "300px",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
        }}
        className="percent"
      >
        <div>{UpperCenter}</div>
      </div>
      <div className="slidecontainer">
        <input
          style={{ width: "300px" }}
          type="range"
          value={value}
          min={min}
          max={max}
          step={steps}
          onChange={onChange}
          className="slider"
          id="myRange"
        />
        {stepsRequre && <div className="step-lines">{renderLines()}</div>}
      </div>
      <div
        className="percent"
        style={{
          padding: "0 12.5%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "-0px",
            fontSize: "12px",
          }}
        >
          Dark
        </div>
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "-0px",
            fontSize: "12px",
          }}
        >
          Bright
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "300px",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
          }}
          className="percent"
        >
          <div>{left}</div>
          <div>{center}</div>
          <div>{right}</div>
        </div>
      </div>
    </div>
  );
};

export const SliderVewingAngel = ({
  onChange,
  value,
  max = 10,
  min = 0,
  stepsRequre = true,
  steps = 1,
  left = "",
  right = "",
  center,
  imgTrue = false,
  image,
  UpperCenter,
  image2,
  angleImg,
}) => {
  const renderLines = () => {
    const lines = [];
    for (let i = min; i <= max; i++) {
      lines.push(
        <div
          key={i}
          className="step-line"
          style={{
            display: i == max || i == min ? "none" : "",
            left: `calc(${(i / max) * 100}% - 1px)`,
          }}
        />
      );
    }
    return lines;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div
        style={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imgTrue && (
          <img
            style={{
              position: "absolute",
              top: "12px",
              right: "45.5%",
              opacity: `${value / 10}`,
            }}
            src={image2}
          />
        )}

        {imgTrue && (
          <img
            src={image}
            style={{
              height: 100,
              width: 120,
              objectFit: "contain",
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "300px",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
        }}
        className="percent"
      >
        <div>{UpperCenter}</div>
      </div>
      <div className="slidecontainer">
        <input
          style={{ width: "300px" }}
          type="range"
          value={value}
          min={min}
          max={max}
          step={steps}
          onChange={onChange}
          className="slider"
          id="myRange"
        />
        {stepsRequre && <div className="step-lines">{renderLines()}</div>}
      </div>
      <div
        className="percent"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{}}>0 Deg</div>
        <div style={{}}>30 Deg</div>
        <div style={{}}>60 Deg</div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "300px",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
          }}
          className="percent"
        >
          <div>{left}</div>
          <div>{center}</div>
          <div>{right}</div>
        </div>
      </div>
    </div>
  );
};

export const MultiRangeSlider = ({ min, max, setminimum, setMaximum }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);
    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  return (
    <>
      <div
        style={{ width: "100%", textAlign: "center" }}
        className="price_filter"
      >
        Price Filter
      </div>
      <div className="multislider_container">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), maxVal - 1);
            setMinVal(value);
            setminimum(value);
            minValRef.current = value;
          }}
          className="thumb thumb--left"
          style={{ zIndex: minVal > max - 100 && "5" }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), minVal + 1);
            setMaxVal(value);
            setMaximum(value);
            maxValRef.current = value;
          }}
          className="thumb thumb--right"
        />

        <div className="multislider">
          <div className="multislider__track" />
          <div ref={range} className="multislider__range" />
          <div className="multislider__left-value">$ {minVal}</div>
          <div className="multislider__right-value">$ {maxVal}</div>
        </div>
      </div>
    </>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};
