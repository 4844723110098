import React, { useEffect, useState } from "react";
import "./Questions.css";
import vectorImg from "../../../assets/Vector.svg";
import { FAQ_list_api } from "../../../utils/APIS/Apis";

export const Questions = () => {
  const [showQus, setShowQus] = useState();
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    fetchList();
  }, []);
  const fetchList = async () => {
    try {
      const response = await FAQ_list_api();
      if (response.success) {
        setQuestionList(response?.data);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="QTS">
      <div className="QTS-heading">Frequently Asked Questions</div>

      <div className="QTS-qts">
        {questionList.length > 0 &&
          questionList.map((v, i) => (
            <div key={i} style={{}}>
              <div
                className="qts-1"
                onClick={() => {
                  if (showQus != i) {
                    setShowQus(i);
                  } else {
                    setShowQus();
                  }
                }}
              >
                <div className="QTS-content">{v?.question}</div>
                <img
                  src={vectorImg}
                  alt=""
                  style={{
                    transform: showQus != i ? "rotate(-90deg)" : "rotate(0deg)",
                    transition: "transform 0.5s ease",
                    cursor: "pointer",
                  }}
                />
              </div>
              {showQus == i && (
                <div
                className="qts-answer"
                >
                  {v?.answer}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
