import React, { useState } from "react";
import editIcon from "../assets/edit.png";
import plusCircleIcon from "../assets/plus-circle.png";
import plusIcon from "../assets/plus.png";
import "./css/accounts.css";
import styles from "../../DataDashboard.module.scss";
import BackIcon from "../../../../assets/backIcon.svg";
import { useNavigate } from "react-router-dom";
import { DataDashBoardHeader } from "../../DataDashboard";

const AccountsData = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("-1")}>
              Back
            </span>
          </div>
        </div>
        <div style={{ width: "100%", background: "#F5F5F5" }}>
          <div className="heading">Account Profile</div>
          <div className="card">
            <div className="subCard">
              <div className="accName">
                Glen Maxwell
                <button className="editBtn">
                  <img className="editIcon" src={editIcon} alt="Edit" />
                  Edit
                </button>
              </div>
              <div className="accHolder">Account Holder</div>
            </div>
          </div>
          <div className="card">
            <div className="subCard">
              <div className="accName">
                Contact Details
                <button className="editBtn">
                  <img className="editIcon" src={editIcon} alt="Edit" />
                  Edit
                </button>
              </div>
              <div className="accHolder">
                Receive important alerts for your profile here.
              </div>
              <div className="inDiv">
                <input
                  className="inBox"
                  type="text"
                  defaultValue="+1000-000-0000"
                  disabled=""
                />
                <input
                  className="inBox"
                  type="text"
                  defaultValue="glennmaxwell05@gmal.com"
                  disabled=""
                />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="subCard">
              <div className="accName">Shopping Profile</div>
              <div className="subName">
                Suggested Interests
                <button className="addBtn">
                  <img className="addIcon" src={plusCircleIcon} alt="Add" />
                  <span className="addSpan">Add an Interest</span>
                </button>
              </div>
              <div className="inDiv">
                <button className="selBtn">
                  <img className="plusIcon" src={plusIcon} alt="Plus" />
                  Electronics
                </button>
                {/* Repeat the above button structure for other interests */}
              </div>
              <p className="abouttxt">About You</p>
              <div className="subSection">
                <p className="subSecTxt">
                  Preferred Department
                  <button className="addBtn">
                    <img className="addIcon" src={plusCircleIcon} alt="Add" />
                    <span className="addSpan">Add a Department</span>
                  </button>
                </p>
              </div>
              <div className="subSection">
                <p className="subSecTxt">Height & Weight</p>
                <div className="hwDiv">
                  <div className="hDiv">
                    <p>Height</p>
                    <input
                      className="hInBox"
                      type="number"
                      defaultValue={6}
                      disabled=""
                    />
                    <span className="unit">ft</span>
                    <input
                      className="hInBox"
                      type="number"
                      defaultValue={2}
                      disabled=""
                    />
                    <span className="unit">in</span>
                    <input
                      className="sInBox"
                      type="text"
                      defaultValue="cm"
                      disabled=""
                    />
                    <span className="sunit">ft</span>
                  </div>
                  <div className="hDiv">
                    <p>Width</p>
                    <input
                      className="hInBox"
                      type="number"
                      defaultValue={185}
                      disabled=""
                    />
                    <span className="unit">lbs</span>
                    <input
                      className="sInBox"
                      type="text"
                      defaultValue="KG"
                      disabled=""
                    />
                    <span className="sunit">lbs</span>
                  </div>
                </div>
              </div>
              <div>
                <p className="subSecTxt">Age Group</p>
                <div className="agDiv">
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="18-20"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="21-24"
                    disabled=""
                  />
                  <input
                    className="aInBox hovered"
                    type="text"
                    defaultValue="25-29"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="30-34"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="35-39"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="40-44"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="45-49"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="50-54"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="55-59"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="60-64"
                    disabled=""
                  />
                  <input
                    className="aInBox"
                    type="text"
                    defaultValue="65+"
                    disabled=""
                  />
                </div>
              </div>
              <div>
                <p className="agText">Department Preferences</p>
                <p className="agTextDes">
                  Share preferences for each department to get tailored choices
                  when you shop.
                </p>
                <div className="prefCard">
                  <div className="headDiv">
                    <span className="prefHeadSel">Women's</span>
                    <span className="prefHead">Men's</span>
                  </div>
                  <div className="listDiv">
                    <p className="subSecTxt">
                      Fit attributes
                      <button className="addBtn1">
                        <img
                          className="addIcon1"
                          src={plusCircleIcon}
                          alt="img"
                        />
                        <span className="addSpan1">Add Attributes</span>
                      </button>
                    </p>
                  </div>
                  <div className="listDiv">
                    <p className="subSecTxt">
                      Shoes
                      <button className="addBtn1">
                        <img className="addIcon1" src={plusCircleIcon} alt="" />
                        <span className="addSpan1">Add Shoe Size</span>
                      </button>
                    </p>
                  </div>
                  <div className="listDiv">
                    <p className="subSecTxt">
                      Style size
                      <button className="addBtn1">
                        <img className="addIcon1" src={plusCircleIcon} alt="" />
                        <span className="addSpan1">Add Style Size</span>
                      </button>
                    </p>
                  </div>
                  <div className="listDiv">
                    <p className="subSecTxt">
                      Body Shape
                      <button className="addBtn1">
                        <img
                          className="addIcon1"
                          src={plusCircleIcon}
                          alt="img"
                        />
                        <span className="addSpan1">Select a Body Shape</span>
                      </button>
                    </p>
                  </div>
                  <div className="listDiv">
                    <p className="subSecTxt">
                      Price
                      <button className="addBtn1">
                        <img
                          className="addIcon1"
                          src={plusCircleIcon}
                          alt="img"
                        />
                        <span className="addSpan1">Select your Spending</span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountsData;
