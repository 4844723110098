import React from "react";
import { ClipLoader, FadeLoader, RingLoader } from "react-spinners";

const Loader = ({ loading }) => {
  const override = {
    display: "block",
    margin: "0 auto",
  };
  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        width: "100vw",
        height: "100vh",
        zIndex: 100000000,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: " translate(-50%, -50%)",
          maxWidth: "85%",
          width: "100%",
          zIndex: 100000000,
        }}
      >
        <FadeLoader
          color={"#3035BB"}
          loading={loading}
          cssOverride={override}
          size={80}
          zIndex={1000000}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Loader;
