import React, { useState } from "react";
import YellowStar from "../../assets/RecentProcurement/YellowStar.svg";
import SingleBlueStar from "../../assets/RecentProcurement/SingleBlueStar.svg";

export const FeedBack = ({
  rating,
  setRating,
  style,
  disabled = false,
  className,
}) => {
  const [hover, setHover] = useState(null);
  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      {[...Array(5)].map((star, index) => {
        const starValue = index + 1;
        return (
          <label key={index}>
            <img
              className={className}
              src={starValue <= (hover || rating) ? YellowStar : SingleBlueStar}
              value={starValue}
              onClick={() => !disabled && setRating(starValue)}
              onMouseEnter={() => !disabled && setHover(starValue)}
              onMouseLeave={() => !disabled && setHover(null)}
            />
          </label>
        );
      })}
    </div>
  );
};
