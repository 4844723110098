import { toast } from "react-toastify";
export const base_url = "https://dmdapi.demandey.com/api/";
export const image_base_url = "https://dmdapi.demandey.com/";
export const token = localStorage.getItem("token");
export const consumer_id = localStorage.getItem("consumer_id");
export const ProfileName = localStorage.getItem("full_name");

export const product_main_category = async () => {
  try {
    const responce = await fetch(base_url + "product-main-category/list", {
      method: "GET",
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const login_api = async (body) => {
  try {
    const response = await fetch(base_url + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const res = await response.json();
    return res;

    // if (res.success) {
    //   return res;
    // } else {
    //   return res;
    // }
  } catch (error) {
    console.error(error);
  }
};

export const sign_up_api = async (body) => {
  try {
    const response = await fetch(base_url + "consumer/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};
export const forgat_pass_api = async (email) => {
  try {
    const response = await fetch(base_url + "update/forgot-password/" + email, {
      method: "GET",
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.error(error);
  }
};

// Filter Data api
export const filter_data_api = async (id) => {
  try {
    const response = await fetch(
      base_url + "filter/options/sub-category-uuid/" + id,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const product_category_list_api = async (body, page, listPerPage) => {
  try {
    const queryString = new URLSearchParams({
      page: page,
      listPerPage: listPerPage,
    }).toString();

    const response = await fetch(
      base_url + `product-feature/list/by-filter/tv?${queryString}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Laptop Filter API
export const laptop_product_category_list_api = async (
  body,
  page,
  listPerPage
) => {
  try {
    const queryString = new URLSearchParams({
      page: page,
      listPerPage: listPerPage,
    }).toString();

    const response = await fetch(
      base_url + `product-feature/list/by-filter/laptop?${queryString}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const product_laptop_category_list_api = async (id, zip_code) => {
  try {
    // const queryString = new URLSearchParams({
    //   subcategoryuuid: id,
    //   zipcode: zip_code,
    // }).toString();

    const response = await fetch(
      base_url + `product/list/subcategoryuuid-zipcode/${id}/${zip_code}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const my_item_list_api = async (consumer_id) => {
  try {
    const response = await fetch(
      `${base_url}queue/list/consumer-id/${consumer_id}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Done
export const postal_address_list_api = async () => {
  try {
    const response = await fetch(
      `${base_url}postal-address/list/${localStorage.getItem("consumer_id")}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Done
export const postal_address_add_api = async (body, shipAdd) => {
  try {
    const response = await fetch(base_url + "postal-address/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    if (res.success) {
      if (shipAdd) {
        await update_shipping_address_api(res?.shipping_address_id);
      }
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Done
export const postal_address_update_api = async (body, id, shipAdd) => {
  try {
    const response = await fetch(
      base_url + "postal-address/update/postal-address-id/" + id,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await response.json();
    if (res.success) {
      if (shipAdd) {
        await update_shipping_address_api(id);
      }
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

//  Done
export const postal_address_delete_api = async (id) => {
  try {
    const response = await fetch(
      base_url + "postal-address/postal-address-id/" + id,
      {
        method: "DELETE",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

//  Done
export const update_shipping_address_api = async (id) => {
  try {
    const response = await fetch(
      base_url + "postal-address/change-status/shipping-address-id/" + id,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Update Billing Address

export const update_billing_address_api = async (id) => {
  try {
    const response = await fetch(
      base_url + "postal-address/change-status/billing-address-id/" + id,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Create Payment
export const create_payment_api = async (body) => {
  try {
    const response = await fetch(base_url + "payment/create", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Confirm Payment
export const confirm_payment_api = async (body) => {
  try {
    const response = await fetch(base_url + "payment/payment", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// Card List API

export const card_list_api = async (id) => {
  try {
    const response = await fetch(base_url + "card/list/consumer-uuid/" + id, {
      method: "GET",
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Card Add API
export const card_add_api = async (body) => {
  try {
    const response = await fetch(`${base_url}card/add`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

//  Price BreckDown by
export const product_by_uuid_api = async (id, zipcode) => {
  try {
    // alert(zipcode);
    const response = await fetch(
      base_url + "product/uuid-zipcode/" + id + "/" + zipcode,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};
// QueueDetail
export const get_queue_detail = async (id, zipcode) => {
  // toast.info(zipcode);
  try {
    const response = await fetch(
      base_url + "queue/queue-uuid-zipcode/" + id + "/" + zipcode,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const product_search_api = async (value) => {
  try {
    const response = await fetch(base_url + "search/input/" + value, {
      // const response = await fetch("http://192.168.2.109:3000/api/search/input/"  + value, {
      method: "GET",
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const product_track_api = async (value) => {
  try {
    const response = await fetch(
      base_url + "purchase/info/order/track/consumer_po_id/" + value,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

// this API is Due
export const queue_add_api = async (body) => {
  try {
    const response = await fetch(`${base_url}queue/add`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const queue_quantity_update_api = async (queue_uuid, quantity) => {
  try {
    const response = await fetch(
      `${base_url}queue/update?queue_uuid=${queue_uuid}&quantity=${quantity}`,

      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const queue_delete_api = async (queue_uuid) => {
  try {
    const response = await fetch(`${base_url}queue/uuid/${queue_uuid}`, {
      method: "DELETE",
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const recent_procurement_list_api = async (id) => {
  try {
    const response = await fetch(
      `${base_url}purchase/info/list-recent-purchase-products/consumer-id/${id}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};
export const cancle_order_API = async (consumer_po_id) => {
  try {
    const response = await fetch(
      `${base_url}purchase/info/order/cancle/consumer_po_id/${consumer_po_id}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const saved_product_list_API = async (id) => {
  try {
    const response = await fetch(
      `${base_url}queue/list-save-later-products/consumer-id/${id}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const saved_product_API = async (body) => {
  try {
    const response = await fetch(`${base_url}queue/add-to-save-later`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};
export const order_review_api = async (body) => {
  try {
    const response = await fetch(`${base_url}review/create`, {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const state_list_api = async (consumer_id) => {
  try {
    const response = await fetch(`${base_url}states/list`, {
      method: "GET",
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const expert_product_picker_api = async (body) => {
  try {
    const response = await fetch(`${base_url}expert-product-picker/tv`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const expert_product_picker_laptop_api = async (body) => {
  try {
    const response = await fetch(`${base_url}expert-product-picker/laptop`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const FAQ_list_api = async () => {
  try {
    const response = await fetch(`${base_url}faq/list`, {
      method: "GET",
    });
    const res = await response.json();
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const subCategoryName = async (id) => {
  try {
    const responce = await fetch(base_url + "product-sub-category/uuid/" + id, {
      method: "GET",
    });
    const res = await responce.json();
    if (res.success) {
      return res?.data?.category_name;
    } else {
      return "";
    }
  } catch (error) {
    console.log(error);
  }
};
