import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import whiteLogo from "../../../assets/icons/name.svg";
import ColorLogo from "../../../assets/icons/nameblack.svg";
import { SearchBar } from "../../SearchBar/SearchBar.jsx";
import heartIcon from "../../../assets/headerIcons/Vector.svg";
import TVIcon from "../../../assets/headerIcons/Group.svg";
import userIcon from "../../../assets/headerIcons/Layer_1.svg";
import queueIcon from "../../../assets/headerIcons/Group605.svg";
import hoverheartIcon from "../../../assets/headerIcons/hover/Vector.svg";
import hoverTVIcon from "../../../assets/headerIcons/hover/Group606.svg";
import hoveruserIcon from "../../../assets/headerIcons/hover/Group608.svg";
import hoverqueueIcon from "../../../assets/headerIcons/hover/Group607.svg";
import { IoMdArrowDropup } from "react-icons/io";
import { IoIosMenu } from "react-icons/io";
import { Modal } from "antd";
import { ForgatePass, Login, SignUp } from "../../auth/LoginSign.jsx";
import { useNavigate } from "react-router-dom";
import {
  my_item_list_api,
  product_main_category,
  saved_product_list_API,
} from "../../../utils/APIS/Apis.js";
import { Base64 } from "js-base64";
import { servicesData } from "../../../utils/Data.js";
import { useSelector } from "react-redux";
import QueueDrawer from "../../drawer/QueueDrawer.js";
import { toast } from "react-toastify";
import store from "../../../redux/store.js";
import { reset } from "../../../redux/productSlice.js";

export const Header = ({
  scrollToElement,
  tokenData,
  setTokenData,
  setTotalQueue,
  totalQueue,
}) => {
  const [onHoverState, setOnHoverState] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [scrollOut, setScrollOut] = useState(false);
  const [scrollMenu, setScrollMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");
  const [showFocus, setShowFocus] = useState(false);

  const position1Ref = useRef(null);
  const openMenuRef = useRef(null);
  const openCatMenuRef = useRef(null);

  const [ShowMenuCat, setShowMenuCat] = useState(false);
  const navigate = useNavigate();
  const [subCat, setSubCat] = useState({});
  const [openCatMenu, setOpenCatMenu] = useState(false);
  const { myItemCount, queueCount } = useSelector((state) => state.product);
  const [productListMain, setProductListMain] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [seeAllServices, setSeeAllServices] = useState(false);

  const fetchProductList = async () => {
    try {
      const data = await product_main_category();
      if (data.success) {
        setProductListMain(data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchProductList();
    fetchQueueCount();
    fetchItemCount();
  }, []);

  const fetchItemCount = async () => {
    try {
      const response = await saved_product_list_API(
        localStorage.getItem("consumer_id")
      );
      if (response.success) {
        if (response.data.length > 0) {
          localStorage.setItem("myItemCount", response.data.length);
        } else {
          localStorage.setItem("myItemCount", 0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchQueueCount = async () => {
    try {
      const response = await my_item_list_api(
        localStorage.getItem("consumer_id")
      );
      if (response.success) {
        if (response.data.length > 0) {
          setTotalQueue(response.data.length);
        } else {
          setTotalQueue(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const listenScrollEvent = (event) => {
    if (window.scrollY > 10) {
      // setOnHoverState(true);
      return;
    } else {
      // setOnHoverState(false);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideShowLog);
    return () => {
      document.removeEventListener("click", handleClickOutsideShowLog);
    };
  }, []);
  const handleClickOutsideShowLog = (event) => {
    if (position1Ref.current && !position1Ref.current.contains(event.target)) {
      setScrollOut(true);
      setTimeout(() => {
        setShowLog(false);
        setScrollOut(false);
      }, 1000);
    } else {
      setScrollOut(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (openMenuRef.current && !openMenuRef.current.contains(event.target)) {
      setScrollMenu(true);
      setTimeout(() => {
        setOpenMenu(false);
        setScrollMenu(false);
      }, 1000);
    } else {
      setScrollMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideMenuCat);
    document.addEventListener("click", handleClickMenuItem);

    return () => {
      document.removeEventListener("click", handleClickOutsideMenuCat);
      document.removeEventListener("click", handleClickMenuItem);
    };
  }, []);
  const handleClickOutsideMenuCat = (event) => {
    if (
      openCatMenuRef.current &&
      !openCatMenuRef.current.contains(event.target)
    ) {
      setScrollMenu(true);
      setTimeout(() => {
        setShowMenuCat(false);
        setScrollMenu(false);
        setSubCat({});
      }, 50);
    } else {
      setScrollMenu(false);
    }
  };

  const handleClickMenuItem = (event, v) => {
    event.stopPropagation();
    if (v?.entity_status === "1") {
      setSubCat(v);
    } else {
    }
  };

  const handleLogOut = () => {
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("consumer_id");
    localStorage.removeItem("zipcode");
    localStorage.removeItem("QueueData");
    localStorage.removeItem("QueueTypeFilter");
    localStorage.removeItem("full_name");
    localStorage.removeItem("myItemCount");
    setTotalQueue(0);
    setShowLog(false);
    navigate("/");
    store.dispatch(reset());
  };

  return (
    <div className="nav">
      <div
        className="nav-main"
        style={{ backgroundColor: onHoverState ? "white" : "#252BBE" }}
      >
        <div
          className="logo"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <img
            onClick={() => {
              navigate("/category");
              localStorage.removeItem("productItem");
            }}
            className="nav-logo"
            src={onHoverState ? ColorLogo : whiteLogo}
            alt="logo"
            style={{ cursor: "pointer" }}
          />
          <div
            className="nav-manu nav-manu1"
            onClick={() => {
              setShowMenuCat(!ShowMenuCat);
              setSubCat({});
            }}
            ref={openCatMenuRef}
          >
            <IoIosMenu style={{ fontSize: "35px" }} />
            {ShowMenuCat && (
              <>
                <div
                  className={`cat-menu-div 
                `}
                >
                  <div className="cat-menu-head">Products</div>
                  <div className="cat-menu-items">
                    {productListMain
                      ?.sort((a, b) => a.priority - b.priority)
                      .map((v, i) => (
                        <div
                          className="cat-menu-item"
                          onClick={(event) => handleClickMenuItem(event, v)}
                          style={{
                            color: i == 0 ? "#000" : "#AAA",
                            // color: v?.entity_status == "1" ? "#263238" : "",
                            fontWeight: v?.entity_status == "1" ? "600" : "400",
                            cursor:
                              v?.entity_status == "1"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          key={i}
                        >
                          {v?.category_name}
                        </div>
                      ))}
                  </div>
                  <div className="cat-menu-head" style={{ color: "#AAA" }}>
                    Services
                  </div>

                  {seeAllServices && (
                    <div className="cat-menu-items">
                      {servicesData.map((item, index) => (
                        <div className="cat-menu-item">{item}</div>
                      ))}
                    </div>
                  )}
                  <div
                    className="cat-menu-item"
                    style={{ fontSize: 18 }}
                    onClick={(event, v) => {
                      event.stopPropagation();
                      setSeeAllServices(!seeAllServices);
                    }}
                  >
                    {seeAllServices ? "Hide Services" : "See All Services"}
                  </div>
                </div>
                {subCat?.Sub_Category_Data?.length > 0 && (
                  <div
                    style={{ left: "177px", height: "80vh", maxHeight: "80vh" }}
                    className={`cat-menu-div 
                `}
                  >
                    <div className="cat-menu-head">{subCat?.category_name}</div>
                    <div className="cat-menu-items">
                      {subCat?.Sub_Category_Data?.sort(
                        (a, b) => a.priority - b.priority
                      ).map((v, i) => (
                        <div
                          className="cat-menu-item"
                          onClick={() => {
                            if (v?.entity_status == "1") {
                              if (v?.category_name != "Laptops") {
                                navigate(
                                  `/categoryitem/${Base64.encode(
                                    v?.category_uuid
                                  )}/${v?.category_name}`
                                );
                                setSubCat({});
                              } else {
                                navigate(
                                  `/laptopitem/${Base64.encode(
                                    v?.category_uuid
                                  )}/${v?.category_name}`
                                );
                                setSubCat({});
                              }
                            } else {
                              toast.error("This Vertical coming soon");
                              setSubCat({});
                            }
                          }}
                          style={{
                            color: v?.entity_status == "1" ? "#263238" : "",
                            fontWeight: v?.entity_status == "1" ? "600" : "400",
                            cursor:
                              v?.entity_status == "1"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          key={i}
                        >
                          {v?.category_name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="search">
          <SearchBar
            scrollToElement={scrollToElement}
            setTokenData={setTokenData}
            onHoverState={onHoverState}
            showFocus={showFocus}
            setShowFocus={setShowFocus}
          />
        </div>
        <div className="menu">
          <div
            className="item-1st-menu"
            onClick={() => {
              if (localStorage.getItem("token")) {
                navigate("/myitems");
              } else {
                toast.error("Please Login First", {
                  toastId: "1",
                });
              }
            }}
            style={{ position: "relative" }}
          >
            {localStorage.getItem("token") && myItemCount > 0 && (
              <div className="Queue-count">
                {myItemCount >= 100 ? "99+" : myItemCount}
              </div>
            )}
            <img
              src={onHoverState ? hoverheartIcon : heartIcon}
              alt=""
              className="head-icon"
            />
            <p
              className="head-p"
              style={{ color: onHoverState ? "#252ABE" : "white" }}
            >
              My Items
            </p>
          </div>
          <div
            className="item-1st-menu"
            onClick={() => {
              if (localStorage.getItem("token")) {
                navigate("/datadashboard");
              } else {
                toast.error("Please Login First", {
                  toastId: "1",
                });
              }
            }}
          >
            <img
              src={onHoverState ? hoverTVIcon : TVIcon}
              alt=""
              className="head-icon"
            />
            <p
              className="head-p"
              style={{ color: onHoverState ? "#252ABE" : "white" }}
            >
              My Data
            </p>
          </div>
          <div className="item-1st-menu position1 " ref={position1Ref}>
            <img
              src={onHoverState ? hoveruserIcon : userIcon}
              alt=""
              className="head-icon"
              onClick={() => {
                setShowLog(!showLog);
              }}
            />
            <p
              className="head-p"
              style={{ color: onHoverState ? "#252ABE" : "white" }}
              onClick={() => {
                setShowLog(!showLog);
              }}
            >
              {tokenData ? (
                <>
                  {localStorage.getItem("full_name")?.length > 8
                    ? `${localStorage.getItem("full_name").slice(0, 6)}...`
                    : localStorage.getItem("full_name")}
                </>
              ) : (
                "Sign In"
              )}
            </p>

            {showLog && (
              <>
                <div className="arrow-up">
                  <IoMdArrowDropup />
                </div>
                <div className={`modal `}>
                  {localStorage.getItem("token") ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <button className="modal-btn" onClick={handleLogOut}>
                          Sign Out
                        </button>
                      </div>
                      <div
                        className="modal2"
                        style={{ fontWeight: "600", lineHeight: "16px" }}
                      >
                        Hello, {localStorage?.getItem("full_name")}! Welcome to
                        Demandey, where your demand controls everything.
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                        className="modal1 dfc"
                      >
                        <>
                          <button
                            className="modal-btn"
                            onClick={() => {
                              setShowLog(false);
                              setOpenNew(true);
                              setShowSignIn("signin");
                            }}
                          >
                            Sign In
                          </button>
                          <div className="modal2">
                            Don’t have an account? Create a {"  "}
                            <span
                              onClick={() => {
                                setShowLog(false);
                                setOpenNew(true);
                                setShowSignIn("signup");
                              }}
                            >
                              Demandey Account
                            </span>
                          </div>
                        </>
                        {/* )} */}
                      </div>
                    </>
                  )}
                  <div className="modal3">
                    <div className="modal3-left">
                      <div className="m3-left-1">
                        <div className="m2-left-head">Orders & products</div>
                        <div className="m2-left-content">
                          <div
                            onClick={() => {
                              if (localStorage.getItem("token")) {
                                navigate("/purchasehistory");
                                setShowLog(false);
                              } else {
                                toast.error("Please Login First", {
                                  toastId: "1",
                                });
                              }
                            }}
                          >
                            Recent orders
                          </div>

                          <div
                            onClick={() => {
                              if (localStorage.getItem("token")) {
                                navigate("/saveditems");
                                setShowLog(false);
                              } else {
                                toast.error("Please Login First", {
                                  toastId: "1",
                                });
                              }
                            }}
                          >
                            Saved Products
                          </div>
                          <div> </div>
                          <div> </div>
                          <div> </div>
                        </div>
                      </div>
                      <div className="m3-left-2">
                        <div className="m2-left-head">Account</div>
                        <div
                          className="m2-left-content"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (localStorage.getItem("token")) {
                              navigate("/logindetails");
                              setShowLog(false);
                            } else {
                              toast.error("Please Login First", {
                                toastId: "1",
                              });
                            }
                          }}
                        >
                          <div>Login & Security</div>
                        </div>
                      </div>
                    </div>
                    <div className="modal3-left" style={{ border: "none" }}>
                      <div className="m3-left-1">
                        <div className="m2-left-head">COMMUNICATIONS</div>
                        <div className="m2-left-content">
                          <div
                            onClick={() => {
                              if (localStorage.getItem("token")) {
                                setShowLog(false);
                              } else {
                                toast.error("Please Login First", {
                                  toastId: "1",
                                });
                              }
                            }}
                          >
                            Your Messages
                          </div>
                          <div
                            onClick={() => {
                              if (localStorage.getItem("token")) {
                                setShowLog(false);
                              } else {
                                toast.error("Please Login First", {
                                  toastId: "1",
                                });
                              }
                            }}
                          >
                            Your Groups
                          </div>
                          <div
                            onClick={() => {
                              if (localStorage.getItem("token")) {
                                setShowLog(false);
                              } else {
                                toast.error("Please Login First", {
                                  toastId: "1",
                                });
                              }
                            }}
                          >
                            Your Reviews
                          </div>
                        </div>
                      </div>
                      <div className="m3-left-2">
                        <div className="m2-left-head">CUSTOMER SERVICES</div>
                        <div className="m2-left-content">
                          <div
                            onClick={() => {
                              if (localStorage.getItem("token")) {
                                setShowLog(false);
                              } else {
                                toast.error("Please Login First", {
                                  toastId: "1",
                                });
                              }
                            }}
                          >
                            Contact Us
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="item-1st-menu"
            onClick={() => {
              if (!localStorage.getItem("token")) {
                toast.error("Please Login First", {
                  toastId: "1",
                });
                return;
              }
              if (queueCount == 0) {
                toast.error("No Item In Queue");
                return;
              }
              if (localStorage.getItem("token")) {
                // navigate("/queue");
                // setOpenQueueSlider(true);

                setDrawer(true);
              } else {
                toast.error("Please Login First", {
                  toastId: "1",
                });
              }
            }}
            style={{ position: "relative" }}
          >
            {localStorage.getItem("token") && queueCount > 0 && (
              <div className="Queue-count">
                {queueCount >= 100 ? "99+" : queueCount}
              </div>
            )}

            <img
              src={onHoverState ? hoverqueueIcon : queueIcon}
              alt=""
              className="head-icon"
            />
            <p
              className="head-p"
              style={{ color: onHoverState ? "#252ABE" : "white" }}
            >
              Queue
            </p>
          </div>
        </div>
        <div
          className="menu-icon"
          onClick={() => setOpenMenu(!openMenu)}
          ref={openMenuRef}
          style={{ color: onHoverState ? "#252ABE" : "white" }}
        >
          <IoIosMenu />
          {openMenu && (
            <div
              className={`menu-item scroll-in-animation ${
                scrollMenu && "scroll-out-animation"
              }`}
            >
              <div className="menu-item1">
                <div
                  onClick={() => {
                    setOpenCatMenu(true);
                    // setSubCat({});
                  }}
                >
                  Main Menu
                </div>
                <div
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                      navigate("/myitems");
                    } else {
                      toast.error("Please Login Fisrt", {
                        toastId: "1",
                      });
                    }
                  }}
                >
                  My Items
                </div>
                <div>My Data</div>
                <div
                  onClick={() => {
                    setShowSignIn("signin");
                    setOpenNew(true);
                  }}
                >
                  {tokenData ? (
                    <>
                      {localStorage.getItem("full_name")?.length > 8
                        ? `${localStorage.getItem("full_name").slice(0, 6)}...`
                        : localStorage.getItem("full_name")}
                    </>
                  ) : (
                    "Sign In"
                  )}
                </div>
                <div
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                      if (queueCount == 0) {
                        toast.error("No Item In Queue");
                        return;
                      }
                      setDrawer(true);
                    } else {
                      toast.error("Please Login First", {
                        toastId: "1",
                      });
                    }
                  }}
                >
                  Queue
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {openNew && (
        <Modal
          centered
          open={openNew}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpenNew(false)}
        >
          {showSignIn == "signin" && (
            // <SignIn
            //   navi={true}
            //   tokenData={tokenData}
            //   setShowSignIn={setShowSignIn}
            //   setOpenNew={setOpenNew}
            //   scrollToElement={scrollToElement}
            //   setTokenData={setTokenData}
            // />
            <Login
              navi={true}
              tokenData={tokenData}
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
              scrollToElement={scrollToElement}
              setTokenData={setTokenData}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp
              navi={true}
              setTokenData={setTokenData}
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
            />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
            />
          )}
        </Modal>
      )}

      {openCatMenu && (
        <Modal
          centered
          open={openCatMenu}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpenCatMenu(false)}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column" }}
              // className={`cat-menu-div
              //   `}
            >
              <div className="cat-menu-head">Products</div>
              <div className="cat-menu-items">
                {productListMain?.map((v, i) => (
                  <div
                    className="cat-menu-item"
                    onClick={(event) => handleClickMenuItem(event, v)}
                    style={{
                      color: i == 0 ? "#000" : "#AAA",
                      // color: v?.entity_status == "1" ? "#263238" : "",
                      fontWeight: v?.entity_status == "1" ? "600" : "400",
                      cursor:
                        v?.entity_status == "1" ? "pointer" : "not-allowed",
                    }}
                    key={i}
                  >
                    {v?.category_name}
                  </div>
                ))}
              </div>
              <div className="cat-menu-head" style={{ color: "#AAA" }}>
                Services
              </div>
              {seeAllServices && (
                <div className="cat-menu-items">
                  {servicesData.map((item, index) => (
                    <div className="cat-menu-item">{item}</div>
                  ))}
                </div>
              )}
              <div
                className="cat-menu-item"
                style={{ fontSize: 18 }}
                onClick={(event, v) => {
                  // event.stopPropagation();
                  setSeeAllServices(!seeAllServices);
                }}
              >
                {seeAllServices ? "Hide Services" : "See All Services"}
              </div>
            </div>
          </div>
        </Modal>
      )}
      <QueueDrawer open={drawer} setOpen={setDrawer} />
    </div>
  );
};
