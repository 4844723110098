import React from "react";
import "./Button.css";
import { Tooltip } from "antd";

export const Button = ({
  className,
  style,
  title,
  onClick,
  disable = false,
}) => {
  const handleClick = () => {
    if (!disable && onClick) {
      onClick();
    }
    onClick();
  };

  return (
    <div
      style={{ ...style, backgroundColor: disable ? "#BEBFE0" : "" }}
      className={`btn btn-success ${className}`}
      role="button"
      onClick={handleClick}
    >
      {title}
    </div>
  );
};

export const PaymentButton = ({
  price,
  savedPrice,
  type,
  onClick,
  symbol,
  width,
}) => {
  return (
    <div
      className="P-btn-1"
      onClick={onClick}
      style={{
        backgroundColor: "white",
        color: "#252BBE",
        width: width,
        padding: ".5rem 0",
      }}
    >
      <div className="P-btn-1-01">
        <div style={{ fontSize: "15px" }}>
          {symbol} {price}
        </div>
        <div className="p-btn-saved" style={{ fontSize: "10px" }}>
          Your Data Saves you {symbol} {savedPrice}
        </div>
      </div>
      <div className="P-btn-1-01" style={{ fontSize: "10px", gap: ".25rem" }}>
        <span
          style={{
            fontWeight: "700",
            height: "32px",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#5258D9",
            padding: "0 .75rem",
            borderRadius: "30px",
            fontSize: "10px",
            lineHeight: "10px",
          }}
        >
          {type == "GET" && "Buy it Now"}
          {type == "WAIT" && "Wait & Save"}
        </span>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "10px",
            fontWeight: "400",
          }}
        >
          {type == "WAIT" && "30 Day Delivery"}
          {type == "GET" && "Next Day Delivery"}
        </span>
      </div>
    </div>
  );
};

export const PaymentButtonItem = ({
  price,
  savedPrice,
  type,
  onClick,
  symbol,
  width,
}) => {
  return (
    <>
      <div
        className="item-P-btn-1"
        onClick={onClick}
        style={{
          backgroundColor: "white",
          color: "#252BBE",
          width: width,
          padding: ".5rem 0",
        }}
      >
        <div className="item-P-btn-1-01-price">
          {symbol} {price}
        </div>
        <div className="item-P-btn-1-01">
          <span className="item-btn-1">
            {type == "GET" && "Buy it Now"}
            {type == "WAIT" && "Wait & Save"}
          </span>
          <span className="item-btn-2">
            {type == "WAIT" && "30 Day Delivery"}
            {type == "GET" && "Next Day Delivery"}
          </span>
        </div>
      </div>
      <div className="item-saved-price-div">
        Your data saves you{" "}
        <pre>
          <span className="span_price">
            {" "}
            {symbol}
            {savedPrice}
          </span>
        </pre>
      </div>
    </>
  );
};

export const PaymentButtonItemDesc = ({
  price,
  savedPrice,
  type,
  onClick,
  symbol,
  width,
  title,
}) => {
  return (
    <>
      <div className="PBID">
        <div className="PBID-1">
          <div className="PBID-1-price">
            {symbol} {price}
          </div>
          <div className="PBID-1-saved">
            Your data saves you{" "}
            <pre>
              <span style={{ color: "#000" }}>
                {symbol}
                {savedPrice}
              </span>
            </pre>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBlock: 10,
            }}
          >
            <Tooltip title={title} color="#5258D9">
              <div className="PBID-1-i">
                <span>?</span>
              </div>
            </Tooltip>
          </div>
          <div className="PBID-2" onClick={onClick}>
            {type == "GET" && "Buy it Now"}
            {type == "WAIT" && "Wait & Save"}
          </div>
        </div>
        <div className="PBID-3" style={{ marginTop: 10 }}>
          {type == "WAIT" && "30 Day Delivery"}
          {type == "GET" && "Next Day Delivery"}
        </div>
      </div>
    </>
  );
};
