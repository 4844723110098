import React from "react";
import sty from "../DataDashboard.module.scss";
import videoIcon from "../../../assets/icons/playIcon.svg";

import { DataDashboardData } from "../../../utils/Data";
import { useNavigate } from "react-router-dom";

export const DataDemandey = () => {
  const navigate = useNavigate();
  return (
    <div className={sty.Data_demandey}>
      <div className={sty.data_de_1}>
        <div className={sty.data_de_1_1}>
          <div>
            Learn how Demandey empowers you to sell your data, rather than
            allowing it to be exploited by others.
          </div>
          <div>
            {/* <img src={videoIcon} alt="" />
            <div>(40 Second Video)</div> */}
          </div>
        </div>
        <div className={sty.data_de_1_2}>
          <div className={sty.data_de_1_2_btn}>Opt Out</div>
          <div>
            You always have the opportunity to opt out of using your data to
            save you 30 to 50% on the product and brand you buy today.
          </div>
        </div>
      </div>
      {DataDashboardData.map((v, i) => (
        <div className={sty.data_de_2}>
          <div className={sty.data_de_2_title}>{v.title}</div>
          <div className={sty.data_de_cards} key={i}>
            {DataDashboardData[i].content.map((p, s) => (
              <div
                className={sty.data_de_card}
                key={s}
                onClick={() => navigate(p?.navigation)}
              >
                <div className={sty.img_div}>
                  <img src={p.img} alt="" />
                </div>
                <div className={sty.data_de_card_right}>
                  <div className={sty.data_de_card_right_title}>{p.title}</div>
                  <div className={sty.data_de_card_right_content}>
                    {p.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
