import React, { useState } from "react";
import styles from "../Queue.module.scss";
import manuImg from "../../../assets/Queue/manuAgreeImg.svg";
import manuImg2 from "../../../assets/Queue/vector2.svg";
import rightArrow from "../../../assets/ExpertSearch/Shape.svg";
import Group1471 from "../../../assets/Group1471.svg";
import Group1470 from "../../../assets/Group1470.svg";
import expertImg from "../../../assets/icons/Expert_icon.svg";
import { CheckBox } from "../../../components/inputs/CheckBox";
import { image_base_url } from "../../../utils/APIS/Apis";
import { Modal } from "antd";
import { toast } from "react-toastify";
// import { Checkbox } from "antd";

export const ManuAgreement = ({
  setShowManufactureAgree,
  handleNavigatetoAddress,
  product,
  checkValue = true,
  setCheckValue,
  brandShow = false,
  addressData,
}) => {
  const [open, setOpen] = useState(false);
  const handleAccept = () => {
    if (checkValue) {
      setShowManufactureAgree(false);
      handleNavigatetoAddress();
      toast.success("You Signed the Manufacturer's Agreement");
    }
  };
  return (
    <div style={{ height: "fit-content" }} className={styles.manu_user_agree}>
      <div className={styles.manu_title}>
        You must read and agree to the Manufacturer’s Agreement to purchase your
        product.
      </div>
      {brandShow ? (
        <div className={styles.img_div_manu}>
          <div
            style={{ position: "relative" }}
            className={styles.manu_brand_div}
          >
            <div className={styles.agree_img_content_2}>
              The Data you WILL share with{" "}
              {product?.Manufacturer_Details[0]?.short_name}
            </div>
            <img src={manuImg2} alt="" />
          </div>
          <div>
            <img
              height={"70px"}
              width={"100px"}
              style={{ objectFit: "contain" }}
              // width={"79px"}
              src={`${image_base_url}${product?.Manufacturer_Details[0]?.image_url}`}
              alt=""
            />
          </div>
          <div className={styles.agree_1_btn} onClick={() => setOpen(true)}>
            View
          </div>
        </div>
      ) : (
        <div style={{ position: "relative" }} className={styles.manu_img}>
          <img src={manuImg} alt="" />
          <div className={styles.agree_img_content}>
            {product?.Manufacturer_Details[0]?.full_legal_name}
          </div>
        </div>
      )}
      <div className={styles.agreement}>
        <div className={styles.agree_1}>
          USER AGREEMENT BETWEEN MANUFACTURER AND CUSTOMER
        </div>
        <div className={styles.agree_2}>
          This User Agreement (the "Agreement") is made and entered into on
          [date] (the "Effective Date") between [Manufacturer name], with its
          principal place of business at [Manufacturer address] (the
          "Manufacturer"), and [Customer name], with its principal place of
          business at [Customer address] (the "Customer").
        </div>
        <div className={styles.agree_3}>
          <div>RECITALS</div>

          <span>
            A. The Manufacturer is in the business of producing and selling
            [describe the product(s)].
          </span>
          <span>
            B. The Customer desires to purchase [describe the product(s)] from
            the Manufacturer.
          </span>
          <span>
            C. The parties wish to set forth the terms and conditions governing
            the purchase and sale of the products by the Customer from the
            Manufacturer.
          </span>
        </div>
        <div className={styles.agree_4}>
          <div>AGREEMENT</div>
          <span>Purchase of Products</span>
          <span>
            The Manufacturer agrees to sell and the Customer agrees to purchase
            [describe the product(s)] (the "Products") on the terms and
            conditions set forth in this Agreement.
          </span>
          <span>Price and Payment</span>
          <span>
            The price for the Products shall be [insert price] (the "Price").
            Payment shall be made by the Customer within [insert number] days of
            receipt of the invoice from the Manufacturer. Late payments shall be
            subject to interest at the rate of [insert interest rate] per month,
            or the maximum rate permitted by law, whichever is less.
          </span>
        </div>
      </div>
      <div className={styles.terms}>
        <CheckBox checkValue={checkValue} setCheckValue={setCheckValue} />
        <div>I have read & understood the Manufacturer’s Agreement.</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!brandShow && (
          <div
            style={{
              backgroundColor: checkValue ? "" : "#B3B5E8",
              border: checkValue ? "" : "2px solid transparent",
              color: checkValue ? "" : "#fff",
            }}
            onClick={handleAccept}
            className={styles.btn}
          >
            Accept the Agreement
          </div>
        )}
      </div>
      {open && brandShow && (
        <Modal
          open={open}
          setOpen={open}
          centered
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen(false)}
          width={"628px"}
        >
          <ManuView product={product} addressData={addressData} />
        </Modal>
      )}
    </div>
  );
};

const ManuView = ({ product, addressData }) => {
  return (
    <div style={{ marginTop: "2rem" }} className={styles.MV_main}>
      <div style={{ gap: "3rem" }} className={styles.img_div_manu}>
        <div className={styles.manu_brand_div}>
          <div className={styles.agree_img_content_2}>
            The Data you WILL share with{" "}
            {product?.Manufacturer_Details[0]?.short_name}
          </div>
          <img src={manuImg2} alt="" />
        </div>
        <div>
          <img
            height={"80px"}
            width={"160px"}
            src={`${image_base_url}${product?.Manufacturer_Details[0]?.image_url}`}
            alt=""
            style={{ objectFit: "contain" }}
          />
        </div>
      </div>
      <div className={styles.MV_2nd}>
        <div className={styles.MV_2nd_title}>Personal Information</div>
        <div className={styles.MV_2nd_contents}>
          <div className={styles.MV_2nd_content}>
            <div className={styles.MV_2nd_cont_key}>Name: </div>
            <div className={styles.MV_2nd_cont_value}>
              {addressData?.first_name} {addressData?.last_name}
            </div>
          </div>
          <div className={styles.MV_2nd_content}>
            <div className={styles.MV_2nd_cont_key}>Address: </div>
            <div className={styles.MV_2nd_cont_value}>
              {addressData?.address}, {addressData?.city},{" "}
              {addressData?.zipCode}, {addressData?.state_id}
            </div>
          </div>
          <div className={styles.MV_2nd_content}>
            <div className={styles.MV_2nd_cont_key}>Email: </div>
            <div className={styles.MV_2nd_cont_value}>{addressData?.email}</div>
          </div>
          <div className={styles.MV_2nd_content}>
            <div className={styles.MV_2nd_cont_key}>Phone Number: </div>
            <div className={styles.MV_2nd_cont_value}>
              {addressData?.mobile}
            </div>
          </div>
          <div className={styles.MV_2nd_content}>
            <div className={styles.MV_2nd_cont_key}>Product Details: </div>
            <div className={styles.MV_2nd_cont_value}>
              {product?.Product_Details[0]?.product_description}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "1rem",
          alignItems: "flex-start",
        }}
      >
        <div className={styles.MV_3rd}>
          <div className={styles.MV_3rd_img}>
            <img src={expertImg} alt="" />
          </div>
          <div className={styles.MV_3rd_2nd}>
            <div className={styles.MV_3rd_2nd_title}>
              Expert product picker answers
            </div>
            <div className={styles.MV_3rd_2nd_content}>
              responses for selecting the best-suited products of your previous
              choices.
            </div>
          </div>
          <div className={styles.MV_3rd_btn_div}>
            <div className={styles.MV_3rd_btn}>
              <span>View</span>
              <img src={rightArrow} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.MV_3rd}>
          <div className={styles.MV_3rd_img}>
            <img src={Group1470} alt="" />
          </div>
          <div className={styles.MV_3rd_2nd}>
            <div className={styles.MV_3rd_2nd_title}>Path to Purchase</div>
            <div className={styles.MV_3rd_2nd_content}>
              the journey a consumer takes from awareness to buying.
            </div>
          </div>
          <div className={styles.MV_3rd_btn_div}>
            <div className={styles.MV_3rd_btn}>
              <span>View</span>
              <img src={rightArrow} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.MV_3rd}>
          <div className={styles.MV_3rd_img}>
            <img src={Group1471} alt="" />
          </div>
          <div className={styles.MV_3rd_2nd}>
            <div className={styles.MV_3rd_2nd_title}>
              Answers to Survey at Purchase
            </div>
            <div className={styles.MV_3rd_2nd_content}>
              valuable insights on customer preferences and satisfaction levels.
            </div>
          </div>
          <div className={styles.MV_3rd_btn_div}>
            <div className={styles.MV_3rd_btn}>
              <span>View</span>
              <img src={rightArrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
