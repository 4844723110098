import React, { useState, useEffect, useCallback } from "react";
import "./SearchBar.css";
import searchIcon from "../../assets/icons/search.svg";
import mikeIcon from "../../assets/icons/Group594.png";
import searchBlueIcon from "../../assets/searchBar/searchBlue.svg";
import mikeBlueIcon from "../../assets/searchBar/mikeBlue.svg";
import experpickerIcon from "../../assets/searchBar/expertSearch.svg";
import iIcon from "../../assets/searchBar/iIcone.svg";
import { FaArrowRight } from "react-icons/fa";
import { product_search_api } from "../../utils/APIS/Apis";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { BrowseAsGuest } from "../auth/LoginSign";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Base64 } from "js-base64";
import { toast } from "react-toastify";

export const SearchBar = ({ onHoverState, showFocus, setShowFocus }) => {
  const [open1, setOpen1] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [productList, setproductList] = useState({});
  const searchInput = React.useRef(null);

  const navigate = useNavigate();

  const handleChange = async (e) => {
    // if (searchValue.length > 0) {
    //   if (!localStorage.getItem("token") && !localStorage.getItem("zipcode")) {
    //     setOpen1(true);
    //   } else {
    //     try {
    //       const response = await product_search_api(searchValue);
    //       if (response.success) {
    //         response.data.categories.length == 0 &&
    //           response.data.products.length == 0 &&
    //           toast.error("No Product Found");
    //         setproductList(response.data);
    //       } else {
    //         toast.error("No Product Found");
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // } else {
    //   setproductList({});
    // }
    if (searchValue.length > 0) {
      if (!localStorage.getItem("token") && !localStorage.getItem("zipcode")) {
        setOpen1(true);
      }else{
        navigate(`/search/${Base64.encode(searchValue)}`)
        setSearchValue("")
      }
    }
  };

  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;

      if (keyCode === 13) {
        handleChange();
      }
    },
    [searchValue]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    setproductList([]);
  }, [searchValue.length == 0]);

  useEffect(() => {
    setSearchValue(transcript);
  }, [transcript]);

  const handleVoiceSearch = () => {
    if (browserSupportsSpeechRecognition) {
      resetTranscript();
      SpeechRecognition.startListening();
    }
  };

  const handleVoiceSearchStop = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.stopListening();
    }
  };
  if (document.activeElement === searchInput.current) {
    setShowFocus(true);
  }
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        className="search-bar"
        style={{
          border: onHoverState ? "1px solid rgba(0, 0, 0, 0.158)" : "",
          position: "relative",
          zIndex: "12",
          border: showFocus ? "1px solid #4347C9" : "",
        }}
      >
        <img
          src={showFocus ? searchBlueIcon : searchIcon}
          className="search-icon"
          alt="search"
        />
        <input
          name="searchInput"
          id="searchInput"
          type="text"
          placeholder="What are you looking for ?"
          className={`search-input ${showFocus ? "search-input-blue" : ""}`}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          ref={searchInput}
          onBlur={() => {
            setTimeout(() => {
              setShowFocus(false);
            }, [300]);
          }}
          onFocus={() => setShowFocus(true)}
          autoComplete="off"
        />
        {/* <img
          src={showFocus ? mikeBlueIcon : mikeIcon}
          className="mike-icon"
          alt="search"
          onClick={handleVoiceSearch}
          onMouseLeave={handleVoiceSearchStop}
        /> */}
        <FaArrowRight
          fontWeight={"200"}
          color={showFocus ? "#252bbe" : "gray"}
          onClick={
          //   () => 
          // {

          //   if (searchValue.length > 0) {
          //     if (!localStorage.getItem("token") && !localStorage.getItem("zipcode")) {
          //       setOpen1(true);
          //     }else{
          //       navigate(`/search/${Base64.encode(searchValue)}`)
          //     }
          //   }
          // }
          handleChange
          }
          cursor={"pointer"}
        />
      </div>
      {/* {showFocus &&
        (productList?.categories?.length > 0 ||
          productList?.products?.length > 0) && (
          <div className="search-focus">
            <div className="SF-main"></div>
            <div className="SF-main-2">
              {productList?.categories.map((v, i) => (
                <div key={i} className="SF-item">
                  <div
                    className="SF-item-left"
                    onClick={() => {
                      if (
                        v?.category_name == "Laptop" ||
                        v?.category_name == "Television"
                      ) {
                        if (v?.category_name == "Laptop") {
                          navigate(
                            `/laptopitem/${Base64.encode(v?.category_uuid)}/${
                              v?.category_name
                            }`
                          );
                        } else if (v?.category_name == "Television") {
                          navigate(
                            `/categoryitem/${Base64.encode(v?.category_uuid)}/${
                              v?.category_name
                            }`
                          );
                        }
                      }
                      setSearchValue("");
                    }}
                  >
                    <img src={searchBlueIcon} alt="search" />
                    <div style={{ maxWidth: "350px" }}>{v?.category_name}</div>
                  </div>
                  <div className="SF-item-right">
                    <div
                      onClick={() => {
                        if (
                          v?.category_name == "Laptop" ||
                          v?.category_name == "Television"
                        ) {
                          navigate("/expertpicker", {
                            state: {
                              id: v?.category_uuid,
                              name: v?.category_name,
                            },
                          });
                        }
                        setSearchValue("");
                      }}
                    >
                      <img src={experpickerIcon} alt="search" />
                      <p>Expert Picker</p>
                    </div>
                    <img src={iIcon} alt="search" />
                  </div>
                </div>
              ))}
              {productList?.products.map((v, i) => (
                <div key={i} className="SF-item">
                  <div
                    className="SF-item-left"
                    onClick={() =>
                      navigate(
                        `/productdetails/${Base64.encode(v?.product_uuid)}/${
                          v?.category_name
                        }`
                      )
                    }
                  >
                    <img src={searchBlueIcon} alt="search" />
                    <div style={{ maxWidth: "350px" }}>{v?.product_name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )} */}
      {open1 && (
        <Modal
          centered
          closable={true}
          open={open1}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen1(false)}
        >
          <BrowseAsGuest setOpenNew={setOpen1} />
        </Modal>
      )}
    </div>
  );
};
