import React from "react";
import { image_base_url } from "../../../utils/APIS/Apis";

export const Experience = ({ product }) => {
  return (
    <div className="PD-overview">
      <div
        className="PD-over"
        style={{
          textAlign: "justify",
          maxHeight: "300px",
          minHeight: "299px",
          height: "300px",
          overflowY: "scroll",
          color: "#252bbe",
        }}
      >
        {product?.Web_Overviews[0]?.feature}
      </div>
      <div className="PD-overview-img" style={{ zIndex: "1" }}>
        <img
          style={{
            borderRadius: "30px",
            border: "1px solid #252bbe",
            objectFit: "contain",
            backgroundColor: "#fff",
            padding: 10,
          }}
          src={`${image_base_url}${product?.Product_Image_Details[0]?.product_image_url}`}
          alt=""
        />
      </div>
    </div>
  );
};
