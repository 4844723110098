import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import { Home } from "./screen/01Home/home";
import { useEffect, useLayoutEffect, useState } from "react";
import { Header } from "./components/Home/Header/Header";
import { Footer } from "./components/Home/Footer/Footer";
import { SeeAllProduct } from "./screen/03SeeAllProduct/SeeAllProduct";
import { CategoryItem } from "./screen/04CategoryItem/CategoryItem";
import { ProductDetails } from "./screen/05ProductDetails/ProductDetails";
import { MyItems } from "./screen/06MyItems/MyItems";
import { RecentProcurement } from "./screen/07RecentProcurement/RecentProcurement";
import { Queue } from "./screen/08Queue/Queue";
import { DataDashboard } from "./screen/09DataDashboard/DataDashboard";
import { ExpertPicker } from "./screen/10ExpertPicker/ExpertPicker";
import { scroller } from "react-scroll";
import {
  ExpertProductMain,
  ExpertProductMainLaptopData,
} from "./screen/10ExpertPicker/SmallComponent/ExpertMain";
import {
  AccountProfileOne,
  AnswerstoSurveyatpurchase,
  BehavioralDataAnalysis,
  DataExpertAnswers,
  DataPathToPurchase,
  LoginDetails,
  PaymentInformation,
  ProductDataDashboard,
  PurchaseHistory,
  SavedItems,
  ShippingAndFulfillment,
  SocialEngagement,
} from "./screen/09DataDashboard/SmallComponent/ProductDataDashboard";
import { LaptopData } from "./screen/04CategoryItem/LaptopData";
import Preferences from "./screen/09DataDashboard/SmallComponent/Components/Preferences";
import ExpertPickerAnsewersNew from "./screen/09DataDashboard/SmallComponent/Components/ExpertPicker";
import BrowsingData from "./screen/09DataDashboard/SmallComponent/Components/BrowsingData";
import CustomerData from "./screen/09DataDashboard/SmallComponent/Components/Customer";
import DeviceAndLocation from "./screen/09DataDashboard/SmallComponent/Components/DeviceAndLocation";
import AccountsData from "./screen/09DataDashboard/SmallComponent/Components/Accounts";
import CategoryModule from "./screen/categoryModule/CategoryModule";
import { SearchItem } from "./screen/04CategoryItem/SearchItem";

function App() {
  const [tokenData, setTokenData] = useState();
  const [totalQueue, setTotalQueue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setTokenData(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const scrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 1000,
      smooth: true,
      offset: -89,
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      if (location.pathname == "/datadashboard") {
        navigate("/");
      }
      if (location.pathname == "/logindetails") {
        navigate("/");
      }
      if (location.pathname == "/paymentinformation") {
        navigate("/");
      }
      if (location.pathname == "/shippingandfulfillment") {
        navigate("/");
      }
      if (location.pathname == "/account-profile") {
        navigate("/");
      }
      if (location.pathname == "/edit-profile") {
        navigate("/");
      }
      if (location.pathname == "/purchasehistory") {
        navigate("/");
      }
      if (location.pathname == "/saveditems") {
        navigate("/");
      }
      if (location.pathname == "/expertproductpickeransnew") {
        navigate("/");
      }
      if (location.pathname == "/preferences") {
        navigate("/");
      }
      if (location.pathname == "/browsingdata") {
        navigate("/");
      }
      if (location.pathname == "/deviceandlocation") {
        navigate("/");
      }
      if (location.pathname == "/customerdata") {
        navigate("/");
      }
      if (location.pathname == "/socialengagement") {
        navigate("/");
      }
      if (location.pathname == "/behavioraldataanalysis") {
        navigate("/");
      }
      if (location.pathname == "/productdatadashboard") {
        navigate("/");
      }
      if (location.pathname == "/dataexpertanswers") {
        navigate("/");
      }
      if (location.pathname == "/datapathtopurchase") {
        navigate("/");
      }
      if (location.pathname == "/answerstosurveyatpurchase") {
        navigate("/");
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={<Home tokenData={tokenData} setTokenData={setTokenData} />}
          />
          <Route path="/category" element={<CategoryModule />} />
          <Route path="/seeallproduct" element={<SeeAllProduct />} />
          
          <Route
            path="/categoryitem/:sub_category_id/:name"
            element={
              <CategoryItem
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
                setTotalQueue={setTotalQueue}
              />
            }
          />
          <Route
            path="/categoryitem"
            element={
              <CategoryItem
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
              />
            }
          />
          <Route
            path="/laptopitem/:sub_category_id/:name"
            element={
              <LaptopData
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
              />
            }
          />
          <Route
            path="/laptopitem"
            element={
              <LaptopData
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
              />
            }
          />
          <Route
            path="/search/:prompt"
            element={
              <SearchItem
                tokenData={tokenData}
                setTokenData={setTokenData}
              />
            }
          />
          <Route
            path={`/productdetails/:id/:nameData`}
            element={
              <ProductDetails
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
                // totalQueue={totalQueue}
                setTotalQueue={setTotalQueue}
              />
            }
          />
          <Route
            path={`/productdetails`}
            element={
              <ProductDetails
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
                // totalQueue={totalQueue}
                setTotalQueue={setTotalQueue}
              />
            }
          />
          <Route path="/myitems" element={<MyItems />} />
          <Route path="/recentprocurement" element={<RecentProcurement />} />
          <Route
            path="/queue"
            element={
              <Queue setTotalQueue={setTotalQueue} totalQueue={totalQueue} />
            }
          />
          <Route path="/datadashboard" element={<DataDashboard />} />
          <Route
            path="/productdatadashboard"
            element={<ProductDataDashboard />}
          />
          <Route path="/dataexpertanswers" element={<DataExpertAnswers />} />
          <Route path="/datapathtopurchase" element={<DataPathToPurchase />} />
          <Route
            path="/answerstosurveyatpurchase"
            element={<AnswerstoSurveyatpurchase />}
          />
          <Route path="/logindetails" element={<LoginDetails />} />
          <Route path="/paymentinformation" element={<PaymentInformation />} />
          <Route
            path="/shippingandfulfillment"
            element={<ShippingAndFulfillment />}
          />
          <Route path="/account-profile" element={<AccountProfileOne />} />
          <Route path="/saveditems" element={<SavedItems />} />
          <Route path="/purchasehistory" element={<PurchaseHistory />} />
          <Route path="/socialengagement" element={<SocialEngagement />} />

          {/* By Abhishek  */}
          <Route path="/browsingdata" element={<BrowsingData />} />
          <Route
            path="/expertproductpickeransnew"
            element={<ExpertPickerAnsewersNew />}
          />
          <Route path="/preferences" element={<Preferences />} />
          <Route path="/customerdata" element={<CustomerData />} />
          <Route path="/deviceandlocation" element={<DeviceAndLocation />} />

          {/* By Nataraju Sir  */}
          <Route path="/edit-profile" element={<AccountsData />} />

          <Route
            path="/behavioraldataanalysis"
            element={<BehavioralDataAnalysis />}
          />

          <Route path="/expertpicker" element={<ExpertPicker />} />
          <Route
            path="/expertproductmain"
            element={
              <ExpertProductMain
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
                setTotalQueue={setTotalQueue}
              />
            }
          />
          <Route
            path="/productexpertmainlaptop"
            element={
              <ExpertProductMainLaptopData
                scrollToElement={scrollToElement}
                tokenData={tokenData}
                setTokenData={setTokenData}
                setTotalQueue={setTotalQueue}
              />
            }
          />
        </Route>
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
}

export default App;
function Layout() {
  const [tokenData, setTokenData] = useState();
  const [totalQueue, setTotalQueue] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setTokenData(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  const sharedProps = {
    prop1: "value1",
    prop2: "value2",
  };

  const scrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 1000,
      smooth: true,
      offset: -89,
    });
  };

  return (
    <div className="App">
      <Header
        tokenData={tokenData}
        scrollToElement={scrollToElement}
        setTokenData={setTokenData}
        totalQueue={totalQueue}
        setTotalQueue={setTotalQueue}
      />
      <div className="app-main">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h1 style={{ color: "#000" }}>404</h1>
        <div
          style={{
            color: "#000",
            fontSize: 18,
            fontFamily: "Roboto",
            marginBlock: 6,
          }}
        >
          Page Not Found
        </div>
        <div
          style={{
            color: "#000",
            fontSize: 18,
            fontFamily: "Roboto",
            marginBlock: 6,
          }}
        >
          Oops! You seem to be lost.
        </div>
        <div>
          Go to
          <Link
            to="/"
            style={{
              textDecorationLine: "underline",
              padding: 5,
              color: "#252abe",
              fontWeight: "bolder",
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </div>
      </div>
    </div>
  );
}
