import React, { useEffect, useState } from "react";
import styles from "./ExpertPicker.module.scss";
import timeRound3min from "../../assets/ExpertSearch/timeRound3min.svg";
import ExpertTV from "../../assets/ExpertSearch/ExpertTV.svg";
import closeIconImg from "../../assets/Queue/CloseIcon.svg";
import { Modal } from "antd";
import LeftArrow from "../../assets/ExpertSearch/LeftArrow.svg";
import rightFullArrow from "../../assets/ExpertSearch/rightArrowFull.svg";
import RoomBrightNessImg from "../../assets/ExpertSearch/RoomBrightness.svg";
import RoomBrightNessImg2 from "../../assets/ExpertSearch/Brighness2.svg";
import viewingAngle2 from "../../assets/ExpertSearch/angle2.png";
import viewingAngle3 from "../../assets/ExpertSearch/angle3.png";
import viewingAngle4 from "../../assets/ExpertSearch/angle4.png";
import viewingAngle5 from "../../assets/ExpertSearch/angle5.png";
import viewingAngle6 from "../../assets/ExpertSearch/angle6.png";
import viewingAngle7 from "../../assets/ExpertSearch/angle7.png";
import viewingAngle8 from "../../assets/ExpertSearch/angle8.png";
import viewingAngle9 from "../../assets/ExpertSearch/angle9.png";
import viewingAngle10 from "../../assets/ExpertSearch/angle10.png";
import viewingAngle11 from "../../assets/ExpertSearch/angle11.png";
import viewingAngle12 from "../../assets/ExpertSearch/angle12.png";
import gamingImg from "../../assets/ExpertSearch/GamingImg.svg";
import cableImg from "../../assets/ExpertSearch/CableImg.svg";
import streamingImg from "../../assets/ExpertSearch/StreamingImg.svg";
import cableBlue from "../../assets/icons/cable2.svg";
import gamingBlue from "../../assets/icons/game3.svg";
import StreamingBlue from "../../assets/icons/Streaming2.svg";
import rightArrFull from "../../assets/icons/Shape.svg";
import distanceImg1 from "../../assets/ExpertSearch/DistanceImg1.png";
import distanceImg2 from "../../assets/ExpertSearch/DistanceImg2.png";
import {
  Slider,
  SliderForBrightness,
  SliderVewingAngel,
} from "../../components/inputs/Slider";
import { toast } from "react-toastify";
import {
  expert_product_picker_api,
  expert_product_picker_laptop_api,
  image_base_url,
} from "../../utils/APIS/Apis";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

export const ExpertPicker = () => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [ExpertPickerQ, setExpertPickerQ] = useState(false);
  const [showProductPicker, setShowProductPicker] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("productItem")) {
      setShowProductPicker(true);
    }
  }, []);

  const location = useLocation((state) => state.state);
  const name = location?.state?.name;

  const navigate = useNavigate();

  useEffect(() => {
    if (!name) {
      navigate("/");
    }
  }, [name]);
  useEffect(() => {
    setShowProductPicker(false);
  }, []);

  return (
    <div className={styles.ex_picker_main}>
      <div className={styles.ex_picker}>
        <div className={styles.ex_pick_1}>
          <div
            onClick={() => setExpertPickerQ(true)}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            <span>?</span>
          </div>
          {showToolTip && (
            <div className={styles.ex_picker_tool_tip}>
              <div>Why does this matter?</div>
            </div>
          )}
        </div>
        {showProductPicker ? (
          name == "Laptops" ? (
            <ProductExpertMainLaptop
              setShowProductPicker={setShowProductPicker}
              name={name}
            />
          ) : (
            <ProductExpertMain setShowProductPicker={setShowProductPicker} />
          )
        ) : (
          <ExpertMain setShowProductPicker={setShowProductPicker} name={name} />
        )}
      </div>

      {ExpertPickerQ && (
        <Modal
          closeIcon={<img src={closeIconImg} alt="" />}
          title=""
          centered
          open={ExpertPickerQ}
          footer={false}
          width={"auto"}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setExpertPickerQ(false)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "2rem",
              marginTop: "2rem",
            }}
          >
            <div
              className={styles.ex_modal_1}
              style={{ alignSelf: "flex-start" }}
            >
              Why does this matter?
            </div>
            <div className={styles.ex_modal_2}>
              Our experts have analyzed the attributes and functionalities of
              each product so that we can match the ideal products to your
              personalized wants, needs, and desires and we can also determine
              to what degree that product will satisfy your requirements.
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export const ExpertMain = ({ setShowProductPicker, name }) => {
  return (
    <>
      <div className={styles.ex_pick_2}>
        <img src={timeRound3min} alt="" />
      </div>
      <div className={styles.ex_pick_3}>
        {name == "Laptops" ? "LAPTOP" : "TV"} EXPERT PICKER
      </div>
      <div className={styles.ex_pick_4}>
        <img
          className={styles.expert_main_img}
          src={
            name == "Laptops"
              ? `${image_base_url}uploads/products/images/image1-1700460030326laptop1.png`
              : ExpertTV
          }
          alt=""
        />
      </div>
      <div className={styles.ex_pick_5}>
        Let's help you pick the perfect {name == "Laptops" ? "Laptop" : "TV"}{" "}
        for your needs!
      </div>
      <div className={styles.ex_pick_6}>
        We're going to ask you {name == "Laptops" ? "4" : "5"} easy questions,
        and then we'll give you experts’ analysis and recommendations.
      </div>
      <div
        className={styles.ex_pick_7}
        onClick={() => setShowProductPicker(true)}
      >
        Get Started
      </div>
    </>
  );
};

export const ProductExpertMainLaptop = ({ setShowProductPicker, name }) => {
  const [OperatingSystem, setOperatingSystem] = useState("");
  const [screenSize, setScreenSize] = useState("");
  const [portable, setPortable] = useState("");
  const [bgValue, setBgValue] = useState(0);
  const navigate = useNavigate();
  const arr = [1, 2, 3, 4];
  const [showLoader, setShowLoader] = useState(false);
  const [obj, setObj] = useState({});
  useEffect(() => {
    if (localStorage.getItem("productItem")) {
      const storedItem = localStorage.getItem("productItem");
      const parseData = JSON.parse(storedItem);
      // setObj()
      setOperatingSystem(parseData.OperatingSystem);
      setScreenSize(parseData.screenSize);
      setPortable(parseData.portable);
      // setBgValue(arr.length - 1);
      setBgValue(0);
      setShowProductPicker(true);
    }
  }, []);

  const expert_product_picker = async () => {
    try {
      setShowLoader(true);
      const body = {
        operatingSystem: OperatingSystem,
        size: screenSize,
      };
      const response = await expert_product_picker_laptop_api(body);
      setShowLoader(false);
      if (response.success) {
        // if (response?.data) {
        if (name == "Laptops") {
          navigate("/productexpertmainlaptop", {
            state: {
              product: response?.data,
            },
          });
        } else {
          navigate("/expertproductmain", {
            state: {
              product: response?.data,
            },
          });
          // }
        }
        localStorage.removeItem("productItem");
        localStorage.setItem(
          "productItem",
          JSON.stringify({
            OperatingSystem: OperatingSystem,
            screenSize: screenSize,
            portable: portable,
          })
        );
      } else if (response.success) {
        toast(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.ex_pick_3}>
        {" "}
        {name == "Laptops" ? "LAPTOP" : "TV"} EXPERT PICKER
      </div>
      <div className={styles.ex_pick_pgination}>
        <div className={styles.ex_pick_left_arrow}>
          <img
            style={{ cursor: "pointer" }}
            src={LeftArrow}
            onClick={() => {
              if (bgValue > 0) {
                setBgValue(bgValue - 1);
              }
            }}
            alt=""
          />
        </div>
        <div
          className={styles.ex_pick_pgination_main}
          style={{ borderRadius: "25px", overflow: "hidden" }}
        >
          {arr.map((v, i) => (
            <div
              style={{
                backgroundColor: bgValue >= i ? "#252bbe" : "",
                color: bgValue >= i ? "#ffffff" : "",
                borderTopRightRadius: bgValue == i ? "25px" : "",
                borderBottomRightRadius: bgValue == i ? "25px" : "",
              }}
              key={i}
            >
              {v}
            </div>
          ))}
        </div>
        <div
          className={styles.ex_pick_exit}
          onClick={() => setShowProductPicker(false)}
        >
          Exit
        </div>
      </div>

      {bgValue == 0 && (
        <>
          <div className={styles.ex_pick_Question}>
            What operating system are you comfortable with?
          </div>
          <div
            style={{ textAlign: "center" }}
            className={styles.ex_pick_see_all}
          >
            <p>Select one</p>
          </div>

          <div
            style={{ flexWrap: "wrap", maxWidth: "750px" }}
            className={styles.ex_pick_1_img_div}
          >
            <div
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor:
                  OperatingSystem == "Windows 11" ? "#252bbe" : "",
                color: OperatingSystem == "Windows 11" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
              onClick={() => {
                if (OperatingSystem == "Windows 11") {
                  setOperatingSystem("");
                } else {
                  setOperatingSystem("Windows 11");
                }
              }}
            >
              <div style={{ width: "80%" }}> Microsoft Windows</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={
                  OperatingSystem == "Windows 11"
                    ? rightArrFull
                    : rightFullArrow
                }
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (OperatingSystem == "macOS") {
                  setOperatingSystem("");
                } else {
                  setOperatingSystem("macOS");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: OperatingSystem == "macOS" ? "#252bbe" : "",
                color: OperatingSystem == "macOS" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}> Apple macOS</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={OperatingSystem == "macOS" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (OperatingSystem == "Chrome OS") {
                  setOperatingSystem("");
                } else {
                  setOperatingSystem("Chrome OS");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor:
                  OperatingSystem == "Chrome OS" ? "#252bbe" : "",
                color: OperatingSystem == "Chrome OS" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}>Google Chrome OS</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={
                  OperatingSystem == "Chrome OS" ? rightArrFull : rightFullArrow
                }
                alt=""
              />
            </div>
          </div>
        </>
      )}

      {bgValue == 1 && (
        <>
          <div className={styles.ex_pick_Question}>
            Is there a specific screen size you prefer?
          </div>
          <div
            style={{ textAlign: "center" }}
            className={styles.ex_pick_see_all}
          >
            <p>Select one</p>
          </div>

          <div
            style={{ flexWrap: "wrap", maxWidth: "750px" }}
            className={styles.ex_pick_1_img_div}
          >
            <div
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: screenSize == "0" ? "#252bbe" : "",
                color: screenSize == "0" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
              onClick={() => {
                if (screenSize == "0") {
                  setScreenSize("");
                } else {
                  setScreenSize("0");
                }
              }}
            >
              <div style={{ width: "80%" }}> 10-12” Screen</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={screenSize == "0" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (screenSize == "1") {
                  setScreenSize("");
                } else {
                  setScreenSize("1");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: screenSize == "1" ? "#252bbe" : "",
                color: screenSize == "1" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}> 13-14” Screen</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={screenSize == "1" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (screenSize == "2") {
                  setScreenSize("");
                } else {
                  setScreenSize("2");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: screenSize == "2" ? "#252bbe" : "",
                color: screenSize == "2" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}>15-16” Screen</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={screenSize == "2" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (screenSize == "3") {
                  setScreenSize("");
                } else {
                  setScreenSize("3");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: screenSize == "3" ? "#252bbe" : "",
                color: screenSize == "3" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}>17” Screen and Above</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={screenSize == "3" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
          </div>
        </>
      )}

      {bgValue == 2 && (
        <>
          <div className={styles.ex_pick_Question}>
            Tell us how portable your laptop needs to be?
          </div>
          <div
            style={{ textAlign: "center" }}
            className={styles.ex_pick_see_all}
          >
            <p>Select one</p>
          </div>

          <div
            style={{ flexWrap: "wrap", maxWidth: "750px" }}
            className={styles.ex_pick_1_img_div}
          >
            <div
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: portable == "Ultra Portable" ? "#252bbe" : "",
                color: portable == "Ultra Portable" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
              onClick={() => {
                if (portable == "Ultra Portable") {
                  setPortable("");
                } else {
                  setPortable("Ultra Portable");
                }
              }}
            >
              <div style={{ width: "80%" }}> Ultra Portable</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={
                  portable == "Ultra Portable" ? rightArrFull : rightFullArrow
                }
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (portable == "Portable") {
                  setPortable("");
                } else {
                  setPortable("Portable");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: portable == "Portable" ? "#252bbe" : "",
                color: portable == "Portable" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}> Portable</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={portable == "Portable" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (portable == "Desk/Home Use") {
                  setPortable("");
                } else {
                  setPortable("Desk/Home Use");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: portable == "Desk/Home Use" ? "#252bbe" : "",
                color: portable == "Desk/Home Use" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}>Desk/Home Use</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={
                  portable == "Desk/Home Use" ? rightArrFull : rightFullArrow
                }
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (portable == "Desktop Replacement") {
                  setPortable("");
                } else {
                  setPortable("Desktop Replacement");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor:
                  portable == "Desktop Replacement" ? "#252bbe" : "",
                color: portable == "Desktop Replacement" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}>Desktop Replacement</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={
                  portable == "Desktop Replacement"
                    ? rightArrFull
                    : rightFullArrow
                }
                alt=""
              />
            </div>
          </div>
        </>
      )}

      {bgValue == 3 && (
        <>
          <div className={styles.ex_pick_Question}>
            Rate how you will frequently use your laptop?
          </div>
          <div className={styles.ex_pick_see_all}>Select all that apply</div>
          <div
            style={{ flexDirection: "column", gap: "2rem" }}
            className={styles.ex_pick_1_img_div}
          >
            <Slider
              center={"School"}
              value={obj?.school}
              onChange={(e) => {
                setObj({ ...obj, school: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
            <Slider
              center={"Gaming"}
              value={obj?.gaming}
              onChange={(e) => {
                setObj({ ...obj, gaming: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
            <Slider
              center={"Multimedia"}
              value={obj?.Multimedia}
              onChange={(e) => {
                setObj({ ...obj, Multimedia: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
            <Slider
              center={"Workstation"}
              value={obj?.Workstation}
              onChange={(e) => {
                setObj({ ...obj, Workstation: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
            <Slider
              center={"Business"}
              value={obj?.Business}
              onChange={(e) => {
                setObj({ ...obj, Business: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
          </div>
        </>
      )}

      <div
        className={styles.ex_pick_next}
        // style={{
        //   backgroundColor:
        //     bgValue === 0 &&
        //     OperatingSystem == ""
        //       ? "#DEE0FC"
        //       : bgValue === 1 && OperatingSystem == ""
        //       ? "#DEE0FC"
        //       : "",
        // }}
        style={{
          border:
            bgValue === 0 && OperatingSystem === ""
              ? "1px solid #DEE0FC"
              : bgValue === 1 && screenSize === ""
              ? "1px solid #DEE0FC"
              : bgValue === 2 && portable === ""
              ? "1px solid #DEE0FC"
              : "",
          color:
            bgValue === 0 && OperatingSystem === ""
              ? "#fff"
              : bgValue === 1 && screenSize === ""
              ? "#fff"
              : bgValue === 2 && portable === ""
              ? "#fff"
              : "",
          backgroundColor:
            bgValue === 0 && OperatingSystem === ""
              ? "#DEE0FC"
              : bgValue === 1 && screenSize === ""
              ? "#DEE0FC"
              : bgValue === 2 && portable === ""
              ? "#DEE0FC"
              : "",
        }}
        onClick={() => {
          if (bgValue < arr.length - 1) {
            if (bgValue === 0 && !OperatingSystem == "") {
              setBgValue(bgValue + 1);
            }
            if (bgValue === 1 && screenSize !== "") {
              setBgValue(bgValue + 1);
            }
            if (bgValue === 2 && portable !== "") {
              setBgValue(bgValue + 1);
            }
          }
          if (bgValue === arr.length - 1) {
            expert_product_picker();
          }
        }}
      >
        {bgValue === arr.length - 1 ? "Proceed" : "Next"}
      </div>
      {/* {bgValue > 0 && (
        <div className={styles.ex_pick_see_all_ans}>see my answers</div>
      )} */}
      {showLoader && <Loader loading={showLoader} />}
    </>
  );
};

export const ProductExpertMain = ({ setShowProductPicker }) => {
  const [content, setContent] = useState({
    streaming: false,
    cable: false,
    gaming: false,
  });
  const [speaker, setSpeaker] = useState("");
  const [bgValue, setBgValue] = useState(0);
  const [viewing, setViewing] = useState(5);
  const [viewDis, setViewDis] = useState(10);
  const [screenSize, setScreenSize] = useState(50);
  const [onHover, setOnHover] = useState(false);
  const navigate = useNavigate();
  const arr = [1, 2, 3, 4, 5];
  const [obj, setObj] = useState({
    tv_show: 5,
    sports: 5,
    games: 5,
    movies: 5,
    room_brightness: 5,
    viewing_angle: 5,
    viewDis: viewDis,
    screenSize: screenSize,
    speaker: speaker,
    content: content,
  });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("productItem")) {
      const storedItem = localStorage.getItem("productItem");
      setObj(JSON.parse(storedItem));
      // setBgValue(arr.length - 1);
      setBgValue(0);
      setShowProductPicker(true);
    }
  }, []);

  useEffect(() => {
    setViewDis(6 + (viewing - 5) * 1);
    setScreenSize(50 + (viewing - 5) * 5);
  }, [viewing]);

  const expert_product_picker = async () => {
    try {
      setShowLoader(true);
      const body = {
        Brightness: obj?.room_brightness / 10,
        ViewingAngle: obj?.viewing_angle / 10,
        TVShows: obj?.tv_show / 10,
        Sports: obj?.sports / 10,
        VideoGames: obj?.games / 10,
        Movies: obj?.movies / 10,
      };
      const response = await expert_product_picker_api(body);
      setShowLoader(false);
      if (response.success) {
        navigate("/expertproductmain", {
          state: {
            product: response?.data,
          },
        });
        localStorage.removeItem("productItem");
        localStorage.setItem("productItem", JSON.stringify(obj));
      } else if (response.success) {
        toast(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.ex_pick_3}>TV EXPERT PICKER</div>
      <div className={styles.ex_pick_pgination}>
        <div className={styles.ex_pick_left_arrow}>
          <img
            style={{ cursor: "pointer" }}
            src={LeftArrow}
            onClick={() => {
              if (bgValue > 0) {
                setBgValue(bgValue - 1);
              }
            }}
            alt=""
          />
        </div>
        <div
          className={styles.ex_pick_pgination_main}
          style={{ borderRadius: "25px", overflow: "hidden" }}
        >
          {arr.map((v, i) => (
            <div
              style={{
                backgroundColor: bgValue >= i ? "#252bbe" : "",
                color: bgValue >= i ? "#ffffff" : "",
                borderTopRightRadius: bgValue == i ? "25px" : "",
                borderBottomRightRadius: bgValue == i ? "25px" : "",
              }}
              key={i}
            >
              {v}
            </div>
          ))}
        </div>
        <div
          className={styles.ex_pick_exit}
          onClick={() => setShowProductPicker(false)}
        >
          Exit
        </div>
      </div>
      {bgValue == 3 && (
        <>
          <div className={styles.ex_pick_Question}>
            Tell us about the room you will be using this TV.
          </div>
          <div className={styles.ex_pick_see_all}>Select all that apply</div>
          <div
            style={{ flexDirection: "column", gap: "2rem" }}
            className={styles.ex_pick_1_img_div}
          >
            <SliderForBrightness
              center={"Room Brightness"}
              imgTrue={true}
              image={RoomBrightNessImg}
              image2={RoomBrightNessImg2}
              min={0}
              max={10}
              steps={1}
              value={obj?.room_brightness}
              onChange={(e) => {
                setObj({ ...obj, room_brightness: e.target.value });
              }}
            />

            <SliderVewingAngel
              min={0}
              max={10}
              steps={1}
              center={"Viewing Angle"}
              imgTrue={true}
              image={
                obj?.viewing_angle == 10
                  ? viewingAngle2
                  : obj?.viewing_angle == 9
                  ? viewingAngle3
                  : obj?.viewing_angle == 8
                  ? viewingAngle4
                  : obj?.viewing_angle == 7
                  ? viewingAngle5
                  : obj?.viewing_angle == 6
                  ? viewingAngle6
                  : obj?.viewing_angle == 5
                  ? viewingAngle7
                  : obj?.viewing_angle == 4
                  ? viewingAngle8
                  : obj?.viewing_angle == 3
                  ? viewingAngle9
                  : obj?.viewing_angle == 2
                  ? viewingAngle10
                  : obj?.viewing_angle == 1
                  ? viewingAngle11
                  : viewingAngle12
              }
              value={obj?.viewing_angle}
              onChange={(e) => {
                setObj({ ...obj, viewing_angle: e.target.value });
              }}
            />
          </div>
        </>
      )}
      {bgValue == 4 && (
        <>
          <div className={styles.ex_pick_Question}>
            Prioritize what you watch.
          </div>
          <div className={styles.ex_pick_see_all}>Select all that apply</div>
          <div
            style={{ flexDirection: "column", gap: "2rem" }}
            className={styles.ex_pick_1_img_div}
          >
            <Slider
              center={"TV Shows"}
              value={obj?.tv_show}
              onChange={(e) => {
                setObj({ ...obj, tv_show: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
            <Slider
              center={"Sports"}
              value={obj?.sports}
              onChange={(e) => {
                setObj({ ...obj, sports: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
            <Slider
              center={"Video Games"}
              value={obj?.games}
              onChange={(e) => {
                setObj({ ...obj, games: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
            <Slider
              center={"Movies"}
              value={obj?.movies}
              onChange={(e) => {
                setObj({ ...obj, movies: e.target.value });
              }}
              min={0}
              max={10}
              steps={1}
              p1={"0%"}
              p3={"100%"}
            />
          </div>
        </>
      )}
      {bgValue == 0 && (
        <>
          <div className={styles.ex_pick_Question}>
            Where do you get your content?
          </div>
          <div className={styles.ex_pick_see_all}>Select all that apply</div>
          <div className={styles.ex_pick_1_img_div}>
            <div className={styles.ex_pick_1_img_div_img}>
              <div
                onClick={() => {
                  if (content?.cable) {
                    setContent({ ...content, cable: false });
                  } else if (!content?.cable) {
                    setContent({ ...content, cable: true });
                  }
                }}
                style={{
                  backgroundColor: content?.cable == true ? "#252ABE" : "white",
                }}
                className={styles.ex_pick_img_1}
              >
                <img
                  style={{ width: "65px", height: "65px", objectFit: "fill" }}
                  src={content?.cable == true ? cableBlue : cableImg}
                  alt=""
                />
              </div>
              <div style={{ width: "126px" }}>Cable/Satellite</div>
            </div>
            <div className={styles.ex_pick_1_img_div_img}>
              <div
                onClick={() => {
                  if (content?.streaming) {
                    setContent({ ...content, streaming: false });
                  } else if (!content?.streaming) {
                    setContent({ ...content, streaming: true });
                  }
                }}
                style={{
                  backgroundColor:
                    content?.streaming == true ? "#252ABE" : "white",
                }}
                className={styles.ex_pick_img_1}
              >
                <img
                  style={{ width: "65px", height: "65px", objectFit: "fill" }}
                  src={
                    content?.streaming == true ? StreamingBlue : streamingImg
                  }
                  alt=""
                />
              </div>
              <div style={{ width: "126px" }}>Streaming (Netflix, etc)</div>
            </div>
            <div className={styles.ex_pick_1_img_div_img}>
              <div
                onClick={() => {
                  if (content?.gaming) {
                    setContent({ ...content, gaming: false });
                  } else if (!content?.gaming) {
                    setContent({ ...content, gaming: true });
                  }
                }}
                style={{
                  backgroundColor:
                    content?.gaming == true ? "#252ABE" : "white",
                }}
                className={styles.ex_pick_img_1}
              >
                <img
                  style={{ width: "65px", height: "65px", objectFit: "fill" }}
                  src={content?.gaming == true ? gamingBlue : gamingImg}
                  alt=""
                />
              </div>
              <div style={{ width: "126px" }}>Gaming Console</div>
            </div>
          </div>
        </>
      )}
      {bgValue == 1 && (
        <>
          <div className={styles.ex_pick_Question}>
            Do you have an external speaker system?
          </div>
          <div
            style={{ textAlign: "center" }}
            className={styles.ex_pick_see_all}
          >
            <p>Select one</p>
            <p>e.g. home theatre, soundbar, etc</p>
          </div>

          <div
            style={{ flexWrap: "wrap", maxWidth: "750px" }}
            className={styles.ex_pick_1_img_div}
          >
            <div
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: speaker == "wired" ? "#252bbe" : "",
                color: speaker == "wired" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
              onClick={() => {
                if (speaker == "wired") {
                  setSpeaker("");
                } else {
                  setSpeaker("wired");
                }
              }}
            >
              <div style={{ width: "80%" }}>Yes — Wired</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={speaker == "wired" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (speaker == "Wireless") {
                  setSpeaker("");
                } else {
                  setSpeaker("Wireless");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: speaker == "Wireless" ? "#252bbe" : "",
                color: speaker == "Wireless" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}>Yes — Wireless</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={speaker == "Wireless" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                if (speaker == "no") {
                  setSpeaker("");
                } else {
                  setSpeaker("no");
                }
              }}
              style={{
                height: "45px",
                borderRadius: "30px",
                backgroundColor: speaker == "no" ? "#252bbe" : "",
                color: speaker == "no" ? "#fff" : "",
              }}
              className={styles.ex_pick_3_img_div_img}
            >
              <div style={{ width: "80%" }}>No</div>
              <img
                style={{ height: "12px", width: "20%", cursor: "pointer" }}
                src={speaker == "no" ? rightArrFull : rightFullArrow}
                alt=""
              />
            </div>
          </div>
        </>
      )}
      {bgValue == 2 && (
        <>
          <div className={styles.ex_pick_Question}>
            How far away do you sit when watching TV?
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "2rem",
            }}
          >
            <div className={styles.ex_pick_6_content_div_1}>
              <div
                className={styles.ex_pick_content_div_1}
                style={{
                  backgroundImage:
                    screenSize + 10 >= 85
                      ? "linear-gradient(180deg, #252ABE 0%, #813DEC 100%)"
                      : "none",
                }}
              >
                <div
                  className={styles.ex_pick_content_title}
                  style={{ color: screenSize + 10 >= 85 ? "white" : "" }}
                >
                  85"
                </div>
                <div>
                  <div
                    className={styles.ex_pick_content_div_2}
                    style={{
                      backgroundImage:
                        screenSize + 5 >= 80
                          ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                          : "none",
                    }}
                  >
                    <div
                      className={styles.ex_pick_content_title}
                      style={{ color: screenSize + 5 >= 80 ? "white" : "" }}
                    >
                      80"
                    </div>
                    <div>
                      <div
                        className={styles.ex_pick_content_div_3}
                        style={{
                          backgroundImage:
                            screenSize >= 75
                              ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                              : "none",
                        }}
                      >
                        <div
                          className={styles.ex_pick_content_title}
                          style={{ color: screenSize >= 75 ? "white" : "" }}
                        >
                          75"
                        </div>
                        <div>
                          <div
                            className={styles.ex_pick_content_div_4}
                            style={{
                              backgroundImage:
                                screenSize >= 70
                                  ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                  : "",
                            }}
                          >
                            <div
                              className={styles.ex_pick_content_title}
                              style={{ color: screenSize >= 70 ? "white" : "" }}
                            >
                              70"
                            </div>
                            <div>
                              <div
                                className={styles.ex_pick_content_div_5}
                                style={{
                                  backgroundImage:
                                    screenSize >= 65
                                      ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                      : "",
                                }}
                              >
                                <div
                                  className={styles.ex_pick_content_title}
                                  style={{
                                    color: screenSize >= 65 ? "white" : "",
                                  }}
                                >
                                  65"
                                </div>
                                <div>
                                  <div
                                    className={styles.ex_pick_content_div_6}
                                    style={{
                                      backgroundImage:
                                        screenSize >= 60
                                          ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                          : "",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: screenSize >= 60 ? "white" : "",
                                      }}
                                      className={styles.ex_pick_content_title}
                                    >
                                      60"
                                    </div>
                                    <div>
                                      <div>
                                        <div
                                          className={
                                            styles.ex_pick_content_div_7
                                          }
                                          style={{
                                            backgroundImage:
                                              screenSize >= 55
                                                ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                                : "",
                                          }}
                                        >
                                          <div
                                            className={
                                              styles.ex_pick_content_title
                                            }
                                            style={{
                                              color:
                                                screenSize >= 55 ? "white" : "",
                                            }}
                                          >
                                            55"
                                          </div>
                                          <div>
                                            <div>
                                              <div
                                                className={
                                                  styles.ex_pick_content_div_8
                                                }
                                                style={{
                                                  backgroundImage:
                                                    screenSize >= 50
                                                      ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                                      : "",
                                                }}
                                              >
                                                <div
                                                  className={
                                                    styles.ex_pick_content_title
                                                  }
                                                  style={{
                                                    color:
                                                      screenSize >= 50
                                                        ? "white"
                                                        : "",
                                                  }}
                                                >
                                                  50"
                                                </div>
                                                <div>
                                                  <div>
                                                    <div
                                                      className={
                                                        styles.ex_pick_content_div_9
                                                      }
                                                      style={{
                                                        backgroundImage:
                                                          screenSize >= 45
                                                            ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                                            : "",
                                                      }}
                                                    >
                                                      <div
                                                        className={
                                                          styles.ex_pick_content_title
                                                        }
                                                        style={{
                                                          color:
                                                            screenSize >= 45
                                                              ? "white"
                                                              : "",
                                                        }}
                                                      >
                                                        43"
                                                      </div>
                                                      <div>
                                                        <div>
                                                          <div
                                                            className={
                                                              styles.ex_pick_content_div_10
                                                            }
                                                            style={{
                                                              backgroundImage:
                                                                screenSize >= 40
                                                                  ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                                                  : "",
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                styles.ex_pick_content_title
                                                              }
                                                              style={{
                                                                color:
                                                                  screenSize >=
                                                                  40
                                                                    ? "white"
                                                                    : "",
                                                              }}
                                                            >
                                                              40"
                                                            </div>
                                                            <div>
                                                              <div>
                                                                <div
                                                                  className={
                                                                    styles.ex_pick_content_div_12
                                                                  }
                                                                  style={{
                                                                    backgroundImage:
                                                                      screenSize >=
                                                                      30
                                                                        ? "linear-gradient(180deg, #252ABE 0%, #813DEA 100%)"
                                                                        : "",
                                                                  }}
                                                                >
                                                                  <div
                                                                    className={
                                                                      styles.ex_pick_content_title
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        screenSize >=
                                                                        30
                                                                          ? "white"
                                                                          : "",
                                                                    }}
                                                                  >
                                                                    32"
                                                                  </div>
                                                                  <div></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Slider
                  UpperCenter={`Size: ${screenSize} - ${screenSize + 5}"`}
                  left="Small"
                  right="Bigger"
                  center={"Optimal"}
                  value={viewing}
                  onChange={(e) => setViewing(e.target.value)}
                  min={0}
                  max={10}
                  steps={1}
                  p1={'25"'}
                  p2={'50"'}
                  p3={'75"'}
                />
              </div>
            </div>
            <div className={styles.ex_pick_6_content_div_1}>
              <div
                style={{
                  backgroundColor: "white",
                  border: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.ex_pick_content_div_1}
              >
                <img
                  src={distanceImg1}
                  alt=""
                  style={{
                    height: 45,
                    width: 45,
                    objectFit: "contain",
                  }}
                />
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      height: 3,
                      backgroundColor: "#252BBE",
                      width: 20,
                      borderRadius: 10,
                    }}
                  />
                  <div
                    style={{
                      height: Number(10 * viewing),
                      backgroundColor: "#252BBE",
                      width: 3,
                      borderRadius: 10,
                    }}
                  />
                  <div
                    style={{
                      height: 3,
                      backgroundColor: "#252BBE",
                      width: 20,
                      borderRadius: 10,
                    }}
                  />
                </div>
                <img
                  src={distanceImg2}
                  alt=""
                  style={{
                    height: 60,
                    width: 250,
                    objectFit: "contain",
                    marginTop: -20,
                  }}
                />
              </div>
              <div>
                <Slider
                  left="Closer"
                  right="Farther Away"
                  UpperCenter={`Distance: ${viewDis} - ${viewDis + 1} ft`}
                  center={"Optimal"}
                  value={viewing}
                  onChange={(e) => {
                    setViewing(e.target.value);
                  }}
                  min={0}
                  max={10}
                  steps={1}
                  p1={"1 ft"}
                  p2={"6 ft"}
                  p3={"12 ft"}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={styles.ex_pick_next}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        style={{
          backgroundColor:
            bgValue === 0 &&
            !(content?.streaming || content?.cable || content?.gaming)
              ? "#DEE0FC"
              : bgValue === 1 && speaker == ""
              ? "#DEE0FC"
              : "",
          border:
            bgValue === 0 &&
            !(content?.streaming || content?.cable || content?.gaming)
              ? "1px solid #DEE0FC"
              : bgValue === 1 && speaker == ""
              ? "1px solid #DEE0FC"
              : "1px solid #252bbe",
          color:
            bgValue === 0 &&
            !(content?.streaming || content?.cable || content?.gaming)
              ? "#fff"
              : bgValue === 1 && speaker == ""
              ? "#fff"
              : "",
        }}
        onClick={() => {
          if (bgValue < arr.length - 1) {
            if (
              bgValue === 0 &&
              (content?.streaming || content?.cable || content?.gaming)
            ) {
              setBgValue(bgValue + 1);
            }
            if (bgValue === 1 && speaker !== "") {
              setBgValue(bgValue + 1);
            }
            if (bgValue > 1) {
              setBgValue(bgValue + 1);
            }
          }
          if (bgValue === arr.length - 1) {
            expert_product_picker();
          }
        }}
      >
        {/* Your content goes here */}

        {bgValue === arr.length - 1 ? "Proceed" : "Next"}
      </div>
      {/* {bgValue > 0 && (
        <div className={styles.ex_pick_see_all_ans}>see my answers</div>
      )} */}
      {showLoader && <Loader loading={showLoader} />}
    </>
  );
};
