import React, { useState } from "react";
import arrowDown from "../../..//assets/arrowDown.svg";

export const AllSpecs = ({ product }) => {
  const [showAns, setShowAns] = useState(false);

  return (
    <div
      className="PD-allspecs"
      style={{ margin: "1rem 0", alignSelf: "center" }}
    >
      <div className="PD-allspecs-item">
        <div className="PD-allspecs-q" onClick={() => setShowAns(!showAns)}>
          <div style={{ cursor: "pointer" }}>Specs</div>
        </div>
        {product &&
          product?.Features_Details[0]?.features?.slice(16).map((v, i) => (
            <div style={{ display: "flex", gap: "1rem" }} key={i}>
              <div className="Pd-al-com">{Object.keys(v)[0]}</div>:
              <div className="Pd-al-com">{v[Object.keys(v)[0]]}</div>
            </div>
          ))}
      </div>
    </div>
  );
};
