import React, { useEffect, useRef, useState } from "react";
import reviewImg from "../../../assets/Review/Group691.svg";
import arrowDown from "../../../assets/arrowDown.svg";
import { Range } from "../../../components/Range/Range";
import rting from "../../../assets/SingleProductDetails/Rtings.svg";
import tomsHardware from "../../../assets/productDetails/tomsHardware.svg";
import filterIcon from "../../../assets/SingleProductDetails/filterIcon.svg";
import userIcon from "../../../assets/SingleProductDetails/userIcon.svg";
import { image_base_url } from "../../../utils/APIS/Apis";

export const Review = ({ product, bool }) => {
  const [openMostView, setOpenMostView] = useState(false);

  const openMostViewRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (
      openMostViewRef.current &&
      !openMostViewRef.current.contains(event.target)
    ) {
      setTimeout(() => {
        setOpenMostView(false);
      }, 100);
    } else {
    }
  };
  return (
    <div className="PD-rvw">
      <div className="PD-rvw-title">Reviews</div>
      <div className="PD-rvw-main" style={{}}>
        <div className="PD-rvw-1st">
          <div className="rvw-1st-1">
            <div className="rvw-img">
              {bool && (
                <img
                  style={{
                    width: "164px",
                    height: "125px",
                    objectFit: "contain",
                  }}
                  src={`${image_base_url}${product?.Product_Image_Details[0]?.product_image_url}`}
                  alt=""
                />
              )}
            </div>
            <div className="rvw-item-title">
              <div className="RIT-1st">
                {bool && product?.product_description}
              </div>
              <img src={reviewImg} alt="" className="RIT-img" />
              <div className="RIT-3rd">324 UNBIASED Reviews</div>
            </div>
          </div>
          <div className="rvw-1st-2">
            <div>
              <div className="rvw-1st-21">5 Stars</div>
              <Range width1={65} />
              <div className="rvw-1st-21">30%</div>
            </div>
            <div>
              <div className="rvw-1st-21">5 Stars</div>

              <Range width1={85} />

              <div className="rvw-1st-21">40%</div>
            </div>
            <div>
              <div className="rvw-1st-21">5 Stars</div>

              <Range width1={10} />

              <div className="rvw-1st-21">5%</div>
            </div>
            <div>
              <div className="rvw-1st-21">5 Stars</div>

              <Range width1={25} />

              <div className="rvw-1st-21">15%</div>
            </div>
            <div>
              <div className="rvw-1st-21">5 Stars</div>

              <Range width1={55} />
              <div className="rvw-1st-21">10%</div>
            </div>
          </div>
          <div className="rvw-1st-3">
            <div className="rvw-1st-3-1">Review this product</div>
            <div className="rvw-1st-3-2">
              Share your thoughts with other customers.
            </div>
            <div className="rvw-1st-3-3">Write A Review</div>
          </div>
        </div>
        <div className="PD-rvw-2nd">
          <div className="rvw-2nd-1st">What Others have to say...</div>
          <div className="rvw-2nd-2nd">
            <div className="rvw-card">
              <img
                src={rting}
                alt=""
                style={{ width: "150px", height: "25px" }}
              />
              <div className="rvw-card-content">
                The {bool && product?.product_description} is a home cinema
                lover’s dream come true – an exemplary flatscreen that uses all
                the latest specs and standards, from Dolby Vision and Atmos to
                Google Assistant and...
              </div>
              <div className="rvw-card-btn">Read Full</div>
            </div>
            <div className="rvw-card">
              <img
                src={tomsHardware}
                alt=""
                style={{ width: "150px", height: "25px" }}
              />
              <div className="rvw-card-content">
                The {bool && product?.product_description} is a home cinema
                lover’s dream come true – an exemplary flatscreen that uses all
                the latest specs and standards, from Dolby Vision and Atmos to
                Google Assistant and...
              </div>
              <div className="rvw-card-btn">Read Full</div>
            </div>
          </div>
          <div className="rvw-2nd-3rd">
            <div className="rvw-2nd-1st" style={{ textAlign: "left" }}>
              What Demandey Partners have to say....
            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                height: "auto",
                flexWrap: "wrap",
              }}
            >
              <div className="bt-right-sort-left">
                <span>Sort By:</span>
                <div>
                  <div
                    onClick={() => setOpenMostView(!openMostView)}
                    ref={openMostViewRef}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Most Viewed</p>
                    <img src={arrowDown} alt="" />
                  </div>
                  {openMostView && (
                    <div
                      style={{
                        flexDirection: "column",
                        height: "auto",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        borderRadius: "20px",
                        position: "absolute",
                        border: "1.5px solid #252BBE",
                        background: "#F0F2FF",
                        marginTop: ".12rem",
                        gap: "14.72px",
                        color: "#252ABE",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "normal",
                        padding: "1rem 1.5rem",
                      }}
                      className="scroll-in-animation PD-dropdown"
                    >
                      <p>Newest</p>
                      <p>Ratings (High to Low)</p>
                      <p>Ratings (Low to High)</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="PD-filter2">
                <img src={filterIcon} alt="" style={{ width: "30px" }} />
                <div>Filter</div>
              </div>
            </div>
            <div className="rvw-quantity" style={{ textAlign: "left" }}>
              324 Reviews
            </div>
            <div className="rvw-comments">
              <div className="rvw-comment">
                <div className="rvw-comment-icon">
                  <img src={userIcon} alt="" />
                </div>
                <div className="rvw-cmt-words">
                  <div className="rvw-cmt-words-1">(TVWatcher2040)</div>
                  <div className="rvw-cmt-words-2">
                    Verified Purchase | Reviewed in the United States on October
                    13, 2023
                  </div>
                  <div className="rvw-cmt-words-3">
                    <img src={reviewImg} alt="" />
                  </div>
                  <div className="rvw-cmt-words-4">
                    Unbelievable clarity. Color and true black is spot on. Great
                    tweaks for most all picture and sound options.
                  </div>
                </div>
              </div>
              <div className="rvw-comment">
                <div className="rvw-comment-icon">
                  <img src={userIcon} alt="" />
                </div>
                <div className="rvw-cmt-words">
                  <div className="rvw-cmt-words-1">DLF</div>
                  <div className="rvw-cmt-words-2">
                    Verified Purchase | Reviewed in the United States on October
                    13, 2023
                  </div>
                  <div className="rvw-cmt-words-3">
                    <img src={reviewImg} alt="" />
                  </div>
                  <div className="rvw-cmt-words-4">
                    I bought this to replace a larger screen tv that died of old
                    age. It's perfect for my getaway room where I watch TV for
                    maybe 8 hours a week. The picture and sound are good and
                    setup was easy. It's light enough that I easily carried it
                    upstairs. I did have trouble attaching the base -- the
                    screws were so tight that I needed hubs to help and even
                    after soaping the screws it was hard for him to screw them
                    in. Overall I'm happy with my purchase and you can't beat
                    the price.
                  </div>
                </div>
              </div>
              <div className="rvw-comment">
                <div className="rvw-comment-icon">
                  <img src={userIcon} alt="" />
                </div>
                <div className="rvw-cmt-words">
                  <div className="rvw-cmt-words-1">magek8</div>
                  <div className="rvw-cmt-words-2">
                    Verified Purchase | Reviewed in the United States on October
                    13, 2023
                  </div>
                  <div className="rvw-cmt-words-3">
                    <img src={reviewImg} alt="" />
                  </div>
                  <div className="rvw-cmt-words-4">
                    The picture is beautiful, the sound is good, the operation
                    and setup are straightforward, and I don't know what more
                    you can ask for at this price. We used to have a Vizio that
                    we liked a lot, and this one does not disappoint. The 1080p
                    is not 4K, but on a 40" screen, it provides extreme
                    resolution. One reviewer said that the picture is not very
                    bright, but Vizio must have fixed that because this is more
                    than bright enough. The colors, the black, and the contrast
                    are all excellent. We are really happy with this.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Review2 = ({ product, bool, productName }) => {
  const [openMostView, setOpenMostView] = useState(false);
  const openMostViewRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (
      openMostViewRef.current &&
      !openMostViewRef.current.contains(event.target)
    ) {
      setTimeout(() => {
        setOpenMostView(false);
      }, 100);
    } else {
    }
  };
  return (
    <div className="PD-rvw">
      <div className="PD-rvw-title">Reviews</div>
      <div className="PD-rvw-main" style={{ flexDirection: "column" }}>
        <div className="PD-rvw-1st">
          <div className="rvw-1st-1">
            <div className="rvw-img">
              {bool && (
                <img
                  style={{ width: "164px", height: "125px", objectFit: "fill" }}
                  src={`${image_base_url}${product?.Product_Image_Details[0]?.product_image_url}`}
                  alt=""
                />
              )}
            </div>
            <div className="rvw-item-title">
              <div className="RIT-1st">
                {productName}
                {bool && product?.product_description}
              </div>
              <img src={reviewImg} alt="" className="RIT-img" />
              <div className="RIT-3rd">324 UNBIASED Reviews</div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="rvw-1st-2" style={{ padding: "0", margin: "1rem" }}>
              <div>
                <div className="rvw-1st-21">5 Stars</div>
                <Range width1={65} />
                <div className="rvw-1st-21">30%</div>
              </div>
              <div>
                <div className="rvw-1st-21">4 Stars</div>

                <Range width1={85} />

                <div className="rvw-1st-21">40%</div>
              </div>
              <div>
                <div className="rvw-1st-21">3 Stars</div>

                <Range width1={10} />

                <div className="rvw-1st-21">5%</div>
              </div>
              <div>
                <div className="rvw-1st-21">2 Stars</div>

                <Range width1={25} />

                <div className="rvw-1st-21">15%</div>
              </div>
              <div>
                <div className="rvw-1st-21">1 Star</div>

                <Range width1={15} />
                <div className="rvw-1st-21">10%</div>
              </div>
            </div>
            <div
              className="rvw-1st-3"
              style={{ margin: "1rem", border: "none" }}
            >
              <div className="rvw-1st-3-1">Review this product</div>
              <div className="rvw-1st-3-2">
                Share your thoughts with other customers.
              </div>
              <div className="rvw-1st-3-3">Write A Review</div>
            </div>
          </div>
        </div>
        <div className="PD-rvw-2nd" style={{ width: "100%" }}>
          <div className="rvw-2nd-1st">What Others have to say...</div>
          <div className="rvw-2nd-2nd" style={{ width: "100%" }}>
            <div className="rvw-card" style={{ flex: "1" }}>
              <img
                src={tomsHardware}
                alt=""
                style={{ width: "150px", height: "25px" }}
              />
              <div className="rvw-card-content">
                The {bool && product?.product_description} is a home cinema
                lover’s dream come true – an exemplary flatscreen that uses all
                the latest specs and standards, from Dolby Vision and Atmos to
                Google Assistant and...
              </div>
              <div className="rvw-card-btn">Read Full</div>
            </div>
            <div className="rvw-card" style={{ flex: "1" }}>
              <img
                src={tomsHardware}
                alt=""
                style={{ width: "150px", height: "25px" }}
              />
              <div className="rvw-card-content">
                The {bool && product?.product_description} is a home cinema
                lover’s dream come true – an exemplary flatscreen that uses all
                the latest specs and standards, from Dolby Vision and Atmos to
                Google Assistant and...
              </div>
              <div className="rvw-card-btn">Read Full</div>
            </div>
          </div>
          <div className="rvw-2nd-3rd" style={{ width: "100%" }}>
            <div className="rvw-2nd-1st" style={{ textAlign: "left" }}>
              What Demandey Partners have to say....
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                height: "auto",
                justifyContent: "space-between",
              }}
            >
              <div className="bt-right-sort-left">
                <span>Sort By:</span>
                <div>
                  <div
                    onClick={() => setOpenMostView(!openMostView)}
                    ref={openMostViewRef}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Most Viewed</p>
                    <img src={arrowDown} alt="" />
                  </div>
                  {openMostView && (
                    <div
                      style={{
                        flexDirection: "column",
                        height: "auto",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        borderRadius: "20px",
                        position: "absolute",
                        border: "1.5px solid #252BBE",
                        background: "#F0F2FF",
                        marginTop: ".12rem",
                        gap: "14.72px",
                        color: "#252ABE",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "normal",
                        padding: "1rem 1.5rem",
                      }}
                      className="scroll-in-animation PD-dropdown"
                    >
                      <p>Newest</p>

                      <p>Ratings (High to Low)</p>
                      <p>Ratings (Low to High)</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="PD-filter2">
                <img src={filterIcon} alt="" style={{ width: "30px" }} />
                <div>Filter</div>
              </div>
            </div>
            <div className="rvw-quantity" style={{ textAlign: "left" }}>
              324 Reviews
            </div>
            <div className="rvw-comments">
              <div className="rvw-comment">
                <div className="rvw-comment-icon">
                  <img src={userIcon} alt="" />
                </div>
                <div className="rvw-cmt-words">
                  <div className="rvw-cmt-words-1">(TVWatcher2040)</div>
                  <div className="rvw-cmt-words-2">
                    Verified Purchase | Reviewed in the United States on October
                    13, 2023
                  </div>
                  <div className="rvw-cmt-words-3">
                    <img src={reviewImg} alt="" />
                  </div>
                  <div className="rvw-cmt-words-4">
                    Unbelievable clarity. Color and true black is spot on. Great
                    tweaks for most all picture and sound options.
                  </div>
                </div>
              </div>
              <div className="rvw-comment">
                <div className="rvw-comment-icon">
                  <img src={userIcon} alt="" />
                </div>
                <div className="rvw-cmt-words">
                  <div className="rvw-cmt-words-1">DLF</div>
                  <div className="rvw-cmt-words-2">
                    Verified Purchase | Reviewed in the United States on October
                    13, 2023
                  </div>
                  <div className="rvw-cmt-words-3">
                    <img src={reviewImg} alt="" />
                  </div>
                  <div className="rvw-cmt-words-4">
                    I bought this to replace a larger screen tv that died of old
                    age. It's perfect for my getaway room where I watch TV for
                    maybe 8 hours a week. The picture and sound are good and
                    setup was easy. It's light enough that I easily carried it
                    upstairs. I did have trouble attaching the base -- the
                    screws were so tight that I needed hubs to help and even
                    after soaping the screws it was hard for him to screw them
                    in. Overall I'm happy with my purchase and you can't beat
                    the price.
                  </div>
                </div>
              </div>
              <div className="rvw-comment">
                <div className="rvw-comment-icon">
                  <img src={userIcon} alt="" />
                </div>
                <div className="rvw-cmt-words">
                  <div className="rvw-cmt-words-1">magek8</div>
                  <div className="rvw-cmt-words-2">
                    Verified Purchase | Reviewed in the United States on October
                    13, 2023
                  </div>
                  <div className="rvw-cmt-words-3">
                    <img src={reviewImg} alt="" />
                  </div>
                  <div className="rvw-cmt-words-4">
                    The picture is beautiful, the sound is good, the operation
                    and setup are straightforward, and I don't know what more
                    you can ask for at this price. We used to have a Vizio that
                    we liked a lot, and this one does not disappoint. The 1080p
                    is not 4K, but on a 40" screen, it provides extreme
                    resolution. One reviewer said that the picture is not very
                    bright, but Vizio must have fixed that because this is more
                    than bright enough. The colors, the black, and the contrast
                    are all excellent. We are really happy with this.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
