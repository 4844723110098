export function isValidEmail(email) {
    const EmailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if ( EmailValidation.test(email)) {
        return true;
       } else {
         return "Invalid email address";
       }
  }
  
//   export function passwordValidation(password) {
//     const passwordRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  

//     if ( passwordRegex.test(password)) {
//         return true;
//        } else {
//          return "Invalid email address";
//        }
//   }
  

export const MobileValidation = /^\d{10}$/;