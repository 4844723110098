import React, { useEffect } from "react";
import styled from "./drawer.module.css";
import { Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import queueImg from "../../assets/images/queue.svg";
import store from "../../redux/store";
import {
  get_Queue,
  remove_from_Queue,
  update_Queue_qty,
} from "../../redux/productSlice";
import { Base64 } from "js-base64";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { CgTrash } from "react-icons/cg";
import Loader from "../Loader/Loader";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { base_url } from "../../utils/APIS/Apis";
import { numberWithCommas } from "../../utils/utils";

const QueueDrawer = ({ open, setOpen }) => {
  const { queueCount, queueData, remove_queue_loading, queueloading } =
    useSelector((state) => state.product);
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(get_Queue(localStorage.getItem("consumer_id")));
  }, []);
  useEffect(() => {
    setOpen(false);
  }, [queueData.length == 0 && !queueloading]);

  return (
    <Drawer
      title=""
      placement={"right"}
      closable={false}
      onClose={() => setOpen(false)}
      open={open}
      width={window.innerWidth > 700 ? "35%" : "100%"}
      style={{ zIndex: 10000000 }}
      bodyStyle={{ padding: "0px" }}
    >
      {remove_queue_loading && <Loader />}
      {/* <div style={{ width: window.innerWidth > 700 ? "35%" : "100%" }}> */}
      <div className={styled.drawer_header} style={{ width: "100%" }}>
        <div className={styled.drawer_img_div}>
          <img src={queueImg} className={styled.drawer_queue} />
          <div className={styled.drawer_count}>
            {queueCount > 99 ? "99+" : queueCount}
          </div>
        </div>
        <div className={styled.drawer_header_text}>Added to the Queue!</div>

        <RxCross2
          style={{ marginRight: 40, cursor: "pointer" }}
          size={25}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={styled.drawer_queue_div}>
        {queueData.map((item, index) => (
          <div className={styled.drawer_queue_card}>
            <CgTrash
              size={20}
              className={styled.drawer_queue_card_trash}
              onClick={() => store.dispatch(remove_from_Queue(item.queue_uuid))}
            />
            <div className={styled.drawer_queue_card_header}>
              <div className={styled.drawer_queue_card_header_left}>
                <img
                  className={styled.drawer_queue_card_img}
                  src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.Image_Details[0]?.product_image_url}`}
                  alt=""
                  title={item?.Product_Details[0]?.product_description}
                  onClick={() => {
                    navigate(
                      `/productdetails/${Base64.encode(
                        item?.Product_Details[0]?.product_uuid
                      )}/${item?.category[0]?.category_name}`
                    );
                    setOpen(false);
                  }}
                />
              </div>
              <div className={styled.drawer_queue_card_header_right}>
                <div className={styled.drawer_queue_card_title}>
                  {item?.Product_Details[0]?.product_name}
                </div>
                <div className={styled.drawer_queue_card_title1}>
                  {item?.Product_Details[0]?.product_description}
                </div>
                <div className={styled.drawer_queue_card_price_div}>
                  <div className={styled.drawer_queue_card_price}>
                    {item?.Currency_Details[0]?.currency_symbol}{" "}
                    {item.instant_By
                      ? numberWithCommas(
                          item?.Product_Price_Details[0]?.product_price
                        )
                      : numberWithCommas(
                          item?.Product_Price_Details[0]?.wait_price
                        )}
                  </div>
                  <div className={styled.drawer_queue_card_qty_div}>
                    <FaMinus
                      size={12}
                      className={styled.drawer_queue_card_qty_btn}
                      style={{
                        color: item.quantity == 1 && "gray",
                        cursor: item.quantity == 1 && "not-allowed",
                      }}
                      onClick={() =>
                        item.quantity == 1 ||
                        // ? store.dispatch(remove_from_Queue(item.queue_uuid))
                        store.dispatch(
                          update_Queue_qty({
                            queue_uuid: item.queue_uuid,
                            quantity: item.quantity - 1,
                          })
                        )
                      }
                    />
                    <div className={styled.drawer_queue_card_qty_text}>
                      {item.quantity}
                    </div>
                    <FaPlus
                      size={12}
                      className={styled.drawer_queue_card_qty_btn}
                      onClick={() =>
                        store.dispatch(
                          update_Queue_qty({
                            queue_uuid: item.queue_uuid,
                            quantity: item.quantity + 1,
                          })
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styled.drawer_queue_card_footer}>
              <div
                className={styled.drawer_queue_btn}
                onClick={() => {
                  navigate("/queue", {
                    state: {
                      id: item?.product_uuid,
                      item: item,
                      count: queueCount,
                      q_id: item?.queue_uuid,
                    },
                  });
                  setOpen(false);
                  //   if (setCurrentComponent) {
                  //     setCurrentComponent("addressdata");
                  //   }
                }}
              >
                Purchase
              </div>
              <div className={styled.drawer_queue_card_footer_right}>
                <div className={styled.drawer_queue_card_footer_right_text1}>
                  {!item.instant_By ? "Wait & Save" : "BUY IT NOW"}
                </div>
                <div className={styled.drawer_queue_card_footer_right_text2}>
                  {!item.instant_By ? "30 Day Delivery" : "Next Day Delivery"}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* </div> */}
    </Drawer>
  );
};

export default QueueDrawer;
