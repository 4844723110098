import AccountDetails from "../assets/NewDataDashboard/Data new/Account Profile Icon.png";
import BehavioralData from "../assets/NewDataDashboard/Data new/Behavioral.png";
import BrowsingData from "../assets/NewDataDashboard/Data new/Browsing Icons.png";
import CustomerService from "../assets/NewDataDashboard/Data new/Customer Service Icon.png";
import DeviceLocation from "../assets/NewDataDashboard/Data new/Device and location icon.png";
import ExpertProductPicker from "../assets/NewDataDashboard/Data new/ExpertData.png";
import LoginDetails from "../assets/NewDataDashboard/Data new/LoginDetails.svg";
import PaymentDetails from "../assets/NewDataDashboard/Data new/Payment.svg";
import Performance from "../assets/NewDataDashboard/Data new/Preferences Icon.png";
import PurchaseHistory from "../assets/NewDataDashboard/Data new/Purchase History Icon.png";
import SavedItem from "../assets/NewDataDashboard//Data new/SavedItem.png";
import ShippingDetails from "../assets/NewDataDashboard/Data new/Shipping.svg";
import SocialEngagement from "../assets/NewDataDashboard/Data new/SocialEngasement.png";
import TV from "../assets/NewDataDashboard/TV.svg";
import LG from "../assets/NewDataDashboard/LG.svg";
import faimilyImg from "../assets/NewDataDashboard/Social/Group 1688 (1).svg";
import PetOwner from "../assets/NewDataDashboard/Social/Group 1687.svg";
import MoviesandEntertainmentBuff from "../assets/NewDataDashboard/Social/Group 1686.svg";
import GardeningEnthusiasts from "../assets/NewDataDashboard/Social/Group 2108.svg";
import GamingImgNew from "../assets/NewDataDashboard/Social/Group 2109.svg";
import HomeIpro from "../assets/NewDataDashboard/Social/Group 2110.svg";
import PhotoGraphy from "../assets/NewDataDashboard/Social/Group 2111.svg";
import MusicSound from "../assets/NewDataDashboard/Social/Group 2112.svg";
import ChefRoyal from "../assets/NewDataDashboard/Social/Group 2113.svg";
import macbook from "../screen/09DataDashboard/SmallComponent/assets/macbook.png";
import tab from "../screen/09DataDashboard/SmallComponent/assets/tab.png";
import phone from "../screen/09DataDashboard/SmallComponent/assets/phone.png";

export const dataNew = [
  {
    img: phone,
    title: "iPhone 13",
    description: "192.478.62.5.789.23.006",
  },
  {
    img: tab,
    title: "iPad Pro Gen 12",
    description: "192.478.62.5.789.23.236",
  },
  {
    img: macbook,
    title: "Macbook Pro Gen 12",
    description: "192.478.62.5.789.23.777",
  },
];

export const Communities = [
  {
    img: faimilyImg,
    title: "Family",
  },
  {
    img: PetOwner,
    title: "Pet Owner",
  },
  {
    img: MoviesandEntertainmentBuff,
    title: "Movies and Entertainment Buff",
  },
  {
    img: GardeningEnthusiasts,
    title: "Gardening Enthusiasts",
  },
  {
    img: GamingImgNew,
    title: "Gamer Bros",
  },
  {
    img: HomeIpro,
    title: "Home-Improvement",
  },
  {
    img: PhotoGraphy,
    title: "Photography Club",
  },
  {
    img: MusicSound,
    title: "Music Soundwave",
  },
  {
    img: ChefRoyal,
    title: "Chef Royale",
  },
];
export const servicesData = [
  "Accounting and other business services",
  "Clothing Services",
  "Communication",
  "Domestic Travel",
  "Education services",
  "Energy",
  "Financial Services",
  "Fitness and Personal Training",
  "Funeral and burial services",
  "Healthcare",
  "Health and Wellness Services",
  "Household Maintenance",
  "Home Improvement and Construction Services",
  "Installation Services",
  "Hospitality Services",
  "Insurance",
  "Legal services",
  "Personal care",
  "Postal and delivery services",
  "Property Management",
  "Recreational Services",
  "Social services",
  "Technology and IT Services",
  "Travel - Foreign ",
  "Travel - Domestic",
  "Tax preparation and other related services",
  "Transportation",
  "Vehicle Repair Services",
];

export const full_products = [
  "Appliances",
  "Auto Accessories & Parts",
  "Autos - New",
  "Autos - Used",
  "Arts and Crafts",
  "Baby",
  "Beauty",
  "Books and Media",
  "Clothing",
  "Clothing - Accesories",
  "Electronics",
  "Footware",
  "Furniture",
  "Gift Cards and Tickets",
  "Health",
  "Health & Personal Care",
  "Holiday & Seasonal",
  "Home & Kitchen",
  "Home Improvements",
  "Household Essentials",
  "Household supplies",
  "Jewelry",
  "Luggage and Handbags",
  "Mattresses",
  "Medical Products",
  "Musical Instruments",
  "Office Products",
  "Patio, Lawn & Garden",
  "Personal Care",
  "Pets",
  "Pharmaceutical",
  "Recreation",
  "Sports & Fitness",
  "Sports & Recreational Vehicles",
  "Sunglasses",
  "Therapeutic appliances and equipment",
  "Tires",
  "Toys and Games",
  "Watches",
];

export const DataDashboardData = [
  {
    title: "Your Account Information",
    content: [
      {
        img: LoginDetails,
        title: "Login Details",
        content: "username and password, securing access to digital platforms.",
        navigation: "/logindetails",
      },
      {
        img: PaymentDetails,
        title: "Payment Information",
        content:
          "crucial data for transactions, including card details & billing address.",
        navigation: "/paymentinformation",
      },
      {
        img: ShippingDetails,
        title: "Shipping and Delivery",
        content: "processes ensuring timely delivery of products to customers.",
        navigation: "/shippingandfulfillment",
      },
      {
        img: AccountDetails,
        title: "Account Profile",
        content:
          "personalized settings for a user's specific choices and configurations.",
        navigation: "/account-profile",
      },
    ],
  },
  {
    title: "Your PRODUCT Information",
    content: [
      {
        img: PurchaseHistory,
        title: "Purchase History",
        content:
          "a record of past transactions, detailing items bought and dates.",
        navigation: "/purchasehistory",
      },
      {
        img: SavedItem,
        title: "Saved Items",
        content:
          "a collection of selected products for future reference or purchase.",
        navigation: "/saveditems",
      },
    ],
  },
  {
    title: "Activity data",
    content: [
      {
        img: ExpertProductPicker,
        title: "Expert Product Picker Answers",
        content:
          "responses for selecting the best-suited products of your previous choices with Product Picker.",
        navigation: "/expertproductpickeransnew",
      },
      {
        img: Performance,
        title: "Preferences",
        content:
          "personalized settings reflecting individual choices & user-specific configurations for Data Activity customization.",
        navigation: "/preferences",
      },
      {
        img: BrowsingData,
        title: "Browsing Data",
        content:
          "recorded information on internet activities, including history, cookies, and cache.",
        navigation: "/browsingdata",
      },
      {
        img: DeviceLocation,
        title: "Device and Location Data",
        content: "information on the device used and geographic location.",
        navigation: "/deviceandlocation",
      },
      {
        img: CustomerService,
        title: "Customer Service Interactions",
        content:
          "communication between customers and support teams for issue resolution.",
        navigation: "/customerdata",
      },
      {
        img: SocialEngagement,
        title: "Social Engagement",
        content:
          "active participation and interaction on social media platforms for connections.",
        navigation: "/socialengagement",
      },

      {
        img: BehavioralData,
        title: "Behavioral Data and Analysis",
        content:
          "studying user actions to understand patterns and preferences.",
        navigation: "/behavioraldataanalysis",
      },
    ],
  },
];

export const DataDashboardManuData = [
  {
    title: "30 december 2022",
    content: [
      {
        img1: TV,
        img2: LG,
        title:
          'LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® Total Cost: $1001.87',
        ORDER: "114-5662925-2962646",
      },
      {
        img1: TV,
        img2: LG,
        title:
          'LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® Total Cost: $1001.87',
        ORDER: "114-5662925-2962646",
      },
      {
        img1: TV,
        img2: LG,
        title:
          'LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® Total Cost: $1001.87',
        ORDER: "114-5662925-2962646",
      },
    ],
  },
  {
    title: "24 november 2022",
    content: [
      {
        img1: TV,
        img2: LG,
        title:
          'LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® Total Cost: $1001.87',
        ORDER: "114-5662925-2962646",
      },
      {
        img1: TV,
        img2: LG,
        title:
          'LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® Total Cost: $1001.87',
        ORDER: "114-5662925-2962646",
      },
      {
        img1: TV,
        img2: LG,
        title:
          'LG GX 55" Gallery Design 4K Smart OLED TV w/AI ThinQ® Total Cost: $1001.87',
        ORDER: "114-5662925-2962646",
      },
    ],
  },
];

export const filterData = [
  {
    category: "Size",
    key: "TV Size",
    items: [
      { label: "39 inch & Below", count: 1, checked: false, value: "0" },
      { label: "40 inch - 49 inch", count: 8, checked: false, value: "1" },
      { label: "50 inch - 59 inch", count: 6, checked: false, value: "2" },
      { label: "60 inch - 69 inch", count: 6, checked: false, value: "3" },
      { label: "70 inch - 100 inch", count: 6, checked: false, value: "4" },
    ],
  },
  {
    category: "Price",
    key: "TV Price",
    items: [
      { label: "$ 0 to $ 399", count: 1, checked: false, value: "0" },
      { label: "$ 400 to $ 699", count: 8, checked: false, value: "1" },
      { label: "$ 700 to $ 999", count: 6, checked: false, value: "2" },
      { label: "$ 1000 to $ 1999", count: 1, checked: false, value: "3" },
      { label: "> $ 2000", count: 3, checked: false, value: "4" },
    ],
  },
  {
    category: "Resolution",
    key: "Resolution",
    items: [
      { label: "HD", count: 1, checked: false, value: "HD" },
      { label: "Full HD", count: 6, checked: false, value: "FHD" },
      { label: "Ultra HD", count: 6, checked: false, value: "UHD" },
      { label: "4K", count: 1, checked: false, value: "4K" },
      { label: "8K", count: 30, checked: false, value: "8K" },
    ],
  },
  {
    category: "Brand",
    key: "Brands",
    items: [
      { label: "Samsung", count: 1, checked: false, value: "Samsung" },
      { label: "LG", count: 30, checked: false, value: "LG" },
      { label: "Sony", count: 6, checked: false, value: "Sony" },
      { label: "Hisense", count: 1, checked: false, value: "Hisense" },
      { label: "Panasonic", count: 3, checked: false, value: "Panasonic" },
    ],
  },
  {
    category: "Model Year",
    key: "Model Year",
    items: [
      { label: "2023", count: 1, checked: false, value: "2023" },
      { label: "2022", count: 30, checked: false, value: "2022" },
      { label: "2021", count: 6, checked: false, value: "2021" },
      { label: "2020", count: 1, checked: false, value: "2020" },
    ],
  },
  {
    category: "Outdoor",
    key: "Outdoor",
    items: [
      { label: "Yes", count: 1, checked: false, value: "Yes" },
      { label: "No", count: 30, checked: false, value: "No" },
    ],
  },
  {
    category: "Connectivity Type",
    key: "Connectivity Type",
    items: [
      { label: "Cable", count: 1, checked: false, value: "Cable" },
      { label: "Wi-Fi", count: 30, checked: false, value: "Wi-Fi" },
    ],
  },
  {
    category: "Control Method",
    key: "Control Method",
    items: [
      { label: "Remote", count: 1, checked: false, value: "Remote" },
      { label: "Others", count: 30, checked: false, value: "Others" },
    ],
  },
];

export const filterDataLaptop = [
  {
    category: "Size",
    key: "Screen Size",
    items: [
      { label: "11 inch & Below", count: 1, checked: false, value: "0" },
      { label: "12 inch - 12.9 inch", count: 8, checked: false, value: "1" },
      { label: "13 inch - 13.9 inch", count: 6, checked: false, value: "2" },
      { label: "14 inch - 14.9 inch", count: 6, checked: false, value: "3" },
      { label: "15 inch - 15.9 inch", count: 6, checked: false, value: "4" },
      { label: "16 inch - 17.9 inch", count: 6, checked: false, value: "5" },
      { label: "18 inch - 50 inch", count: 6, checked: false, value: "6" },
      { label: "0 inch - 50 inch", count: 6, checked: false, value: "7" },
    ],
  },
  {
    category: "Price",
    key: "Price",
    items: [
      { label: "$ 0 to $ 399", count: 1, checked: false, value: "0" },
      { label: "$ 400 to $ 699", count: 8, checked: false, value: "1" },
      { label: "$ 700 to $ 999", count: 6, checked: false, value: "2" },
      { label: "$ 1000 to $ 1999", count: 1, checked: false, value: "3" },
      { label: "$ 2000 to $ 90000", count: 1, checked: false, value: "4" },
      { label: "$ 0 to $ 90000", count: 1, checked: false, value: "5" },
      // { label: "> $ 2000", count: 3, checked: false, value: "4" },
    ],
  },
  {
    category: "OS",
    key: "Operating System",
    items: [
      { label: "macOS", count: 1, checked: false, value: "macOS" },
      { label: "Chrome OS", count: 6, checked: false, value: "Chrome OS" },
      { label: "Windows 10", count: 6, checked: false, value: "Windows 10" },
      { label: "Windows 11", count: 1, checked: false, value: "Windows 11" },
    ],
  },
  {
    category: "RAM",
    key: "RAM",
    items: [
      { label: "4GB", count: 6, checked: false, value: "4GB" },
      { label: "8GB", count: 30, checked: false, value: "8GB" },
      { label: "12GB", count: 1, checked: false, value: "12GB" },
      { label: "16GB", count: 1, checked: false, value: "16GB" },
      { label: "32GB", count: 3, checked: false, value: "32GB" },
    ],
  },
  {
    category: "Storage",
    key: "Storage",
    items: [
      { label: "128GB", count: 1, checked: false, value: "128GB" },
      { label: "256GB", count: 30, checked: false, value: "256GB" },
      { label: "512GB", count: 6, checked: false, value: "512GB" },
      { label: "1TB", count: 1, checked: false, value: "1TB" },
    ],
  },
];
