import React from "react";
import styles from "./Range.module.css";

export const Range = ({ width1 = 100 }) => {
  const width2 = 100 - width1;
  return (
    <div style={{ width: "60%" }} className={styles.Range}>
      <div style={{ width: `100%` }} className={styles.np2}>
        <div
          style={{ width: `${width1 > 100 ? 100 : width1}%` }}
          className={styles.np}
        ></div>
      </div>
    </div>
  );
};
