import React, { useEffect, useState } from "react";
import "./categoryModule.css";
import { images } from "../../utils/utils";
import SearchByCategory from "./SearchByCategory";
import ExpertProductPicker from "./ExpertProductPicker";
import store from "../../redux/store";
import { get_product_list } from "../../redux/productSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const CategoryModule = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const location = useLocation((state) => state?.state);

  useEffect(() => {
    setCurrentTab(location?.state?.productpicker ? 2 : 1);
    store.dispatch(get_product_list());
  }, []);
  const navigate = useNavigate();

  return (
    <div className="category_module_main">
      <div
        style={{ width: "calc(100% - 10%)", padding: "0 5%" }}
        className="SAP-back"
      >
        <div>
          <span onClick={() => navigate("/")}>Home </span>/
          <span>
            {currentTab == 2 ? " Expert Product Picker" : " Category"}
          </span>
        </div>
      </div>
      <div className="category_module_tab_div">
        <div className="category_module_tab" onClick={() => setCurrentTab(1)}>
          <div
            className="category_module_tab_text"
            style={{
              paddingInline: "1rem",
            }}
          >
            Search By Category
          </div>
          <div
            className="category_module_tab_line"
            style={{
              backgroundColor: currentTab == 1 || "transparent",
            }}
          />
        </div>
        <div className="category_module_tab" onClick={() => setCurrentTab(2)}>
          <div
            style={{
              display: "flex",
              flex: 1,
              paddingInline: "1rem",
            }}
          >
            <div className="category_module_tab_text">
              use Our Expert Product Picker
            </div>
            <img
              src={images.expertpicker}
              className="category_module_tab_img"
            />
          </div>
          <div
            className="category_module_tab_line"
            style={{
              backgroundColor: currentTab == 2 || "transparent",
            }}
          />
        </div>
      </div>
      <div
        className="category_module_tab_content"
        style={{ backgroundColor: currentTab == 1 && "transparent" }}
      >
        {currentTab == 1 && <SearchByCategory />}
        {currentTab == 2 && <ExpertProductPicker />}
      </div>
    </div>
  );
};

export default CategoryModule;
