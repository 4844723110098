import React, { useState } from "react";
import "./css/customer.css";
import styles from "../../DataDashboard.module.scss";
import BackIcon from "../../../../assets/backIcon.svg";
import chevron from "../assets/chevron-right.png";
import { dataNew } from "../../../../utils/Data";
import { DataDashBoardHeader } from "../../DataDashboard";
import { useNavigate } from "react-router-dom";
const CustomerData = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("-1")}>
              Back
            </span>
          </div>
        </div>
        <div className="main_expert_component">
          <h1 className="main_expert_div_heading">
            Customer Service Interactions
          </h1>
          <div className="expert_picker_inner_div">
            <div className="Customer_sub_heading">
              <p className="label_heading">
                Do you need help with an item, Glenn?
              </p>
              <button className="heading_button">
                Help with a different item
              </button>
            </div>
            <div className="customer_sub_title">
              Select a recent item you need help with below or get help with
              something else
            </div>
            <div
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
                justifyContent: "",
                flexWrap: "wrap",
              }}
            >
              {dataNew.map((e) => (
                <div className="data_to_loop">
                  <div
                    className="img_mainn"
                    style={{
                      background: `${
                        e.title === "Macbook Pro Gen 12"
                          ? "background: linear-gradient(180deg, #60A9FF 0%, #003A7F 100%)"
                          : ""
                      }`,
                      alignItems: "center",
                    }}
                  >
                    <img
                      height={"50px"}
                      width={"50px"}
                      className=""
                      src={e.img}
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="img_titlee">{e.title}</p>
                    <p className="img_descc">{e.description}</p>
                  </div>
                </div>
              ))}
              <div>
                <img style={{ color: "#252BBE" }} src={chevron} alt="" />
              </div>
            </div>
            <div className="buttons">
              <button>Support queries</button>
              <button>Chat logs</button>
              <button>Email correspondence</button>
            </div>
            <div className="Customer_sub_heading">
              <p className="label_heading">Search our help topics</p>
            </div>
            <div
              className=""
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div className="thisss" style={{ width: "70%" }}>
                <p className="label_description">
                  Search for a customer service interaction or a customer help
                  topic or get help with an order
                </p>
              </div>
              <div
                className="label_buttton"
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button>Search a Topic</button>
              </div>
            </div>

            <div className="footer_section">
              <div
                className="footer_child"
                style={{ width: "150px", margin: "20px 0" }}
              >
                <div className="">
                  <p className="title_footer">Help Topics</p>
                  <p className="desc_footer">
                    <span>Recommended Topics</span>
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
              </div>
              <div className="footer_child" style={{ margin: "20px 0" }}>
                <div className="foot_child">
                  <p className="title_footerr">Help Topics</p>
                  <p className="desc_footerr">
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
                <div className="foot_child">
                  <p className="title_footerr">Help Topics</p>
                  <p className="desc_footerr">
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
                <div className="foot_child">
                  <p className="title_footerr">Help Topics</p>
                  <p className="desc_footerr">
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
                <div className="foot_child">
                  <p className="title_footerr">Help Topics</p>
                  <p className="desc_footerr">
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
              </div>
              <div className="footer_child" style={{ margin: "20px 0" }}>
                <div className="foot_child">
                  <p className="title_footerr">Help Topics</p>
                  <p className="desc_footerr">
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
                <div className="foot_child">
                  <p className="title_footerr">Help Topics</p>
                  <p className="desc_footerr">
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
                <div className="foot_child">
                  <p className="title_footerr">Help Topics</p>
                  <p className="desc_footerr">
                    Where's my stuff Shipping and Delivery Returns and Refunds
                    Managing Your Account Security & Privacy Payment, Pricing
                    and Promotions Large Items and Heavy-Bulky Services Other
                    topics & Help
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerData;
