import React, { useEffect, useRef } from "react";
import arrowDown from "../../assets/arrowDown.svg";
import styles from "./Dropdown.module.css";

export const Dropdown = ({ title, body, setOpen, open, width, style }) => {
  const dropRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setTimeout(() => {
        setOpen(false);
      }, 150);
    } else {
    }
  };
  return (
    <div style={{ position: "relative", zIndex: 100 }}>
      <div
        style={{
          border: open ? "1.5px solid transparent" : "",
          width: width,
          ...style,
        }}
        onClick={() => setOpen(!open)}
        ref={dropRef}
        className={styles.Dropdown}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "96%",
          }}
        >
          <p>{title}</p>
          <img
            src={arrowDown}
            style={{
              transform: open ? "rotate(-90deg)" : "rotate(0deg)",
              transition: "transform 0.5s ease",
            }}
            alt=""
          />
        </div>
      </div>
      {open && (
        <div
          className={`${styles.PD_dropdown} scroll-in-animation`}
          style={{ width: width }}
        >
          {body}
        </div>
      )}
    </div>
  );
};

export const Dropdown2 = ({
  label,
  value,
  onChange,
  data = [],
  placeholder,
  mess,
  error,
  options,
  mw,
  w,
  onBlur,
  disabled = false,
  body,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div className={styles.inp_main}>
        {label && <div className={styles.inp_label}>{label} </div>}
      </div>
      <select
        disabled={disabled}
        onBlur={onBlur}
        className={styles.drop_select}
        style={{ minWidth: mw || "120px", width: w || "100%", height: "45px" }}
        value={value}
        onChange={onChange}
      >
        {options ||
          data.map((v, i) => (
            <option key={i} value={v.value}>
              {v.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export const DropdownAddress = ({
  zIndex,
  onChange,
  title,
  body,
  setOpen,
  open,
  width,
  style,
}) => {
  const dropRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setTimeout(() => {
        setOpen(false);
      }, 150);
    } else {
    }
  };
  return (
    <div style={{ position: "relative", zIndex: zIndex ? zIndex : "" }}>
      <div
        style={{
          border: "1.5px solid #f0f2ff",
          // border: open ? "1.5px solid #f0f2ff" : "",
          width: width,
          ...style,
        }}
        onClick={() => setOpen(!open)}
        ref={dropRef}
        className={styles.address_Dropdown}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p>{title}</p>
          <img src={arrowDown} alt="" />
        </div>
      </div>
      {open && (
        <div
          className={`${styles.address_PD_dropdown} scroll-in-animation`}
          style={{ width: width }}
        >
          {body}
        </div>
      )}
    </div>
  );
};
