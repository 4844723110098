import React from "react";
import styles from "../Queue.module.scss";
import closeIconImg from "../../../assets/Queue/CloseIcon.svg";

export const WarrentyPop = ({ close }) => {
  return (
    <div style={{ maxWidth: "420px" }}>
      <div className={styles.close} onClick={close}>
        <img src={closeIconImg} alt="" />
      </div>
      <div className={styles.war_content}>
        <div>
          <div className={styles.war_title}>Add a Warranty</div>
          <div className={styles.war_2}>
            Pick from these 3 Warranty providers:
          </div>
        </div>
        <div>
          <div className={styles.war_3}>Asurion</div>
          <div className={styles.war_2}>
            Protect your TV from screen burn in, defects, dead pixels and
            more.... <span className={styles.war_details}>(details)</span>
          </div>
          <div className={styles.war_details}>
            <span>2 Year Warranty $52.99</span>
            <span> 3 Year Warranty $67.99</span>
            <span> 5 Year Warranty $114.99</span>
          </div>
        </div>
        <div>
          <div className={styles.war_3}>All State</div>
          <div className={styles.war_2}>
            Protect your TV from screen burn in, defects, dead pixels and
            more.... <span className={styles.war_details}>(details)</span>
          </div>
          <div className={styles.war_details}>
            <span>2 Year Warranty $52.99</span>
            <span> 3 Year Warranty $72.99</span>
            <span> 5 Year Warranty $124.99</span>
          </div>
        </div>
        <div>
          <div className={styles.war_3}>Assurant</div>
          <div className={styles.war_2}>
            Protect your TV from screen burn in, defects, dead pixels and
            more.... <span className={styles.war_details}>(details)</span>
          </div>
          <div className={styles.war_details}>
            <span>2 Year Warranty $56.99</span>
            <span> 3 Year Warranty $74.99</span>
            <span> 5 Year Warranty $134.99</span>
          </div>
        </div>
      </div>
    </div>
  );
};

//
