import { Base64 } from "js-base64";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import BackIcon from "../../assets/backIcon.svg";
import {
  image_base_url,
  product_search_api,
  saved_product_API,
} from "../../utils/APIS/Apis";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import likeImg from "../../assets/CategoryItem/like1.svg";
import like from "../../assets/CategoryItem/like.svg";
import store from "../../redux/store";
import {
  add_to_Queue,
  delete_MyItem,
  get_MyItem,
} from "../../redux/productSlice";
import starIcon from "../../assets/Review/Group691.svg";
import walmartImg from "../../assets/CategoryItem/walmart.svg";
import BestBuyImg from "../../assets/CategoryItem/Best_Buy_Logo1.svg";
import AmazonImg from "../../assets/CategoryItem/Amazon_logo.svg";
import { PaymentButtonItem } from "../../components/btn/Button";
import { Modal, Pagination } from "antd";
import Loader from "../../components/Loader/Loader";
import QueueDrawer from "../../components/drawer/QueueDrawer";
import { ForgatePass, Login, SignUp } from "../../components/auth/LoginSign";
import { Review2 } from "../05ProductDetails/SmallComponent/Review";

export const SearchItem = ({ setTokenData }) => {
  const [sizes1, setSizes1] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [page, setPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [openQueueSlider, setOpenQueueSlider] = useState(false);
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const { prompt } = useParams();
  const { myItemData, myItemloading } = useSelector((state) => state.product);
  const [SizeChange, setSizeChange] = useState([]);
  const [openNew, setOpenNew] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");
  const [showRating, setShowRating] = useState(false);
  const [ratingProduct, setRatingProduct] = useState({});

  useEffect(() => {
    fetchList();
    store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
  }, [prompt]);
  const fetchList = async () => {
    try {
      setShowLoader(true);
      const response = await product_search_api(Base64.decode(prompt));
      setShowLoader(false);
      if (response.success) {
        setListData(response?.data?.products);
        const list = response?.data?.products;
        var data = [];
        for (var i = 0; i < list?.length; i++) {
          for (var j = 0; j < list[i]?.available_Sizes?.length; j++) {
            if (
              list[i]?.Features_Details[0]?.features[10]["Size"] ==
              list[i]?.available_Sizes[j]?.tv_size[0]
            ) {
              const obj = {
                index: i,
                id: list[i]?.available_Sizes[j]?.product_uuid,
                size: list[i]?.available_Sizes[j]?.tv_size[0],
                //   index2: bul ? j : 0,
              };
              data.push(obj);
            }
          }
        }
        setSizeChange(data);
        for (let index = 0; index < list?.length; index++) {
          sizes.push(0);
        }
        const arr = [];
        for (let index = 0; index < list?.length; index++) {
          const sz = list[index]?.Features_Details[0]?.features[10];
          arr.push(sz.Size);
          setSizes1(arr);
        }
      } else {
        toast.error("No Product Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const add_my_item = async (id) => {
    if (!localStorage.getItem("token")) {
      //   setOpenNew(true);
    } else {
      try {
        const obj = {
          consumer_uuid: localStorage.getItem("consumer_id"),
          product_uuid: id,
          quantity: 1,
        };
        const response = await saved_product_API(obj);
        if (response.success) {
          store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
          toast.success(response.message, { toastId: 1 });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const addtoqueue = async (id, instant_By) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        consumer_uuid: localStorage.getItem("consumer_id"),
        product_uuid: id,
        quantity: "1",
        instant_By: instant_By == 1 ? true : false,
      };
      store
        .dispatch(add_to_Queue(obj))
        .unwrap()
        .then((res) => {
          setShowLoader(false);
          if (res.success) {
            setOpenQueueSlider(true);
          }
        });
    } catch {}
  };
  const onPageChange = (i) => {
    setPage(i);
  };
  return (
    <div className="CI">
      <div
        className="CI-top"
        style={{ borderBottom: "3px solid #a3a5ea", paddingBottom: "1rem" }}
      >
        <div className="CI-left" onClick={() => navigate(-1)}>
          <img src={BackIcon} alt="" />
          <div>Back</div>
          <div className="SAP-back np1">
            <div>
              <span onClick={() => navigate("/")}>Home </span>/ /
              {Base64.decode(prompt)}
            </div>
          </div>
        </div>
        <div
          className="CI-right"
          style={{ border: "none", paddingBottom: "0" }}
        >
          <div className="SAP-back np2">
            <div>
              <span onClick={() => navigate("/")}>Home </span>/{" "}
              {Base64.decode(prompt)}
            </div>
          </div>
          <div
            style={{ borderBottom: "5px solid #252ABE", width: "100px" }}
          ></div>
          <div className="CI-title-div">
            <div className="CI-title">
              Results For "{Base64.decode(prompt)}"
            </div>
            {/* <div
                className="CI-expert"
                onClick={() =>
                  navigate("/expertpicker", {
                    state: {
                      id: "01",
                      name: Base64.decode(prompt),
                    },
                  })
                }
              >
                Expert Product Picker <img src={iIconBLue} alt="" />
              </div> */}
          </div>
        </div>
      </div>
      <div className="CI-bottom">
        <div className="CI-bottom-right">
          <div className="bt-right-cards">
            {listData?.length > 0 ? (
              listData.map((v, i) => (
                <div className="CI-bt-card" key={i}>
                  <div className="card-1">
                    <img
                      className="item-img"
                      onClick={() => {
                        navigate(
                          `/productdetails/${Base64.encode(v?.product_uuid)}/${
                            v?.category[0]?.category_name
                          }`
                        );
                      }}
                      src={`${image_base_url}${v.Product_Image_Details[0]?.product_image_url}`}
                      alt=""
                    />
                    <img
                      style={{
                        cursor: "pointer",
                        height: 20,
                        width: 20,
                        position: "absolute",
                        right: 0,
                      }}
                      src={
                        myItemData.find(
                          (val) =>
                            val.product_uuid ==
                            v.Features_Details[0]?.product_uuid
                        )
                          ? like
                          : likeImg
                      }
                      alt=""
                      onClick={() => {
                        myItemData.find(
                          (val) =>
                            val.product_uuid ==
                            v.Features_Details[0]?.product_uuid
                        )
                          ? store.dispatch(
                              delete_MyItem(
                                myItemData.filter(
                                  (val) =>
                                    val.product_uuid ==
                                    v.Features_Details[0]?.product_uuid
                                )[0].queue_uuid
                              )
                            )
                          : add_my_item(v?.product_uuid);
                      }}
                    />
                  </div>

                  <div>
                    <div
                      className="bt-card-title"
                      onClick={() => {
                        navigate(
                          `/productdetails/${Base64.encode(v?.product_uuid)}/${
                            v?.category[0]?.category_name
                          }`
                        );
                      }}
                    >
                      {/* {
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.product_name
                      } */}
                      {v?.product_name}
                    </div>
                    <div
                      className="bt-card-title"
                      style={{
                        marginTop: ".5rem",
                        color: "#252bbe",
                        fontSize: "15px",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        cursor: "pointer",
                        height: 35,
                      }}
                      title={
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.product_description || v?.product_description
                      }
                      onClick={() => {
                        navigate(
                          `/productdetails/${Base64.encode(v?.product_uuid)}/${
                            v?.category[0]?.category_name
                          }`
                        );
                      }}
                    >
                      {v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.product_description || v?.product_description}
                    </div>
                  </div>

                  {v?.available_Sizes.length > 1 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        width: "100%",
                        overflowX: "scroll",
                        alignSelf: "center",
                        justifyContent:
                          v?.available_Sizes.slice(0)?.length > 5
                            ? "start"
                            : "center",
                      }}
                    >
                      {v?.available_Sizes.slice(0).map((s, k) => (
                        <div
                          onClick={() => {
                            const newArr = sizes1.map((val, ind) =>
                              ind == i ? s?.tv_size[0] : val
                            );
                            setSizes1(newArr);
                          }}
                          className="CI-5th1"
                          style={{
                            border:
                              sizes1[i] == s?.tv_size[0]
                                ? "1px solid #252bbe"
                                : "",
                            color: sizes1[i] == s?.tv_size[0] ? "#252bbe" : "",
                          }}
                          key={k}
                        >
                          {s?.tv_size[0]}"
                        </div>
                      ))}
                    </div>
                  )}

                  <div>
                    <img
                      style={{ width: "110px", objectFit: "cover" }}
                      src={starIcon}
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      color: "#252BBE",
                      fontFamily: "Roboto Condensed",
                      fontSize: "12px",
                      fontWeight: "300",
                      textTransform: "uppercase",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setRatingProduct(v);
                      setShowRating(true);
                    }}
                  >
                    (324) UNBIASED Reviews
                  </div>

                  {v?.Products_Weights[0]?.MixedUsage ? (
                    <div className="property-card">
                      <div className="PPCard-2">
                        <div
                          style={{ width: "100%" }}
                          className="usage-Rating-1"
                        >
                          <div className="usage-rat">
                            {Number(
                              v?.Products_Weights[0]?.MixedUsage * 10
                            ).toFixed(1)}
                          </div>
                          <div className="usage">Mixed Usage</div>
                        </div>
                      </div>
                      <div className="PPcard-head">usage rating</div>
                      <div className="usage-Rating">
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {Number(
                              v?.Products_Weights[0]?.HDRMovies * 10
                            ).toFixed(1)}
                          </div>
                          <div className="usage">Movies</div>
                        </div>
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {Number(
                              v?.Products_Weights[0]?.VarableRefreshRate * 10
                            ).toFixed(1)}
                          </div>
                          <div className="usage">Streaming</div>
                        </div>
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {Number(
                              v?.Products_Weights[0]?.Sports * 10
                            ).toFixed(1)}
                          </div>
                          <div className="usage">Sports</div>
                        </div>
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {Number(
                              v?.Products_Weights[0]?.VideoGames * 10
                            ).toFixed(1)}
                          </div>
                          <div className="usage">Gaming</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="property-card">
                      <div className="PPCard-2">
                        <div
                          style={{ width: "100%" }}
                          className="usage-Rating-1"
                        >
                          <div className="usage-rat">
                            {v?.Products_Weights[0]?.Business > 1
                              ? Number(
                                  v?.Products_Weights[0]?.Business
                                ).toFixed(1)
                              : Number(
                                  v?.Products_Weights[0]?.Business * 10
                                ).toFixed(1)}
                          </div>
                          <div className="usage">Business</div>
                        </div>
                      </div>
                      <div className="PPcard-head">usage rating</div>
                      <div className="usage-Rating">
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {v?.Products_Weights[0]?.Battery > 1
                              ? Number(v?.Products_Weights[0]?.Battery).toFixed(
                                  1
                                )
                              : Number(
                                  v?.Products_Weights[0]?.Battery * 10
                                ).toFixed(1)}
                          </div>
                          <div className="usage">Battery</div>
                        </div>
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {v?.Products_Weights[0]?.Touchpad > 1
                              ? Number(
                                  v?.Products_Weights[0]?.Touchpad
                                ).toFixed(1)
                              : Number(
                                  v?.Products_Weights[0]?.Touchpad * 10
                                ).toFixed(1)}
                          </div>
                          <div className="usage">Touchpad</div>
                        </div>
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {v?.Products_Weights[0]?.Keyboard > 1
                              ? Number(
                                  v?.Products_Weights[0]?.Keyboard
                                ).toFixed(1)
                              : Number(
                                  v?.Products_Weights[0]?.Keyboard * 10
                                ).toFixed(1)}
                          </div>
                          <div className="usage">Keyboard</div>
                        </div>
                        <div className="usage-Rating-1">
                          <div className="usage-rat">
                            {v?.Products_Weights[0]?.Gaming > 1
                              ? Number(v?.Products_Weights[0]?.Gaming).toFixed(
                                  1
                                )
                              : Number(
                                  v?.Products_Weights[0]?.Gaming * 10
                                ).toFixed(1)}
                          </div>
                          <div className="usage">Gaming</div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="or-get-it-form">Other prices</div>
                  <div className="CI-compare">
                    <div>
                      <img src={walmartImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {v?.Currency_Details[0]?.currency_symbol}{" "}
                        {Number(
                          v?.available_Sizes[
                            v?.available_Sizes.findIndex(function (item) {
                              return item.tv_size[0] == sizes1[i];
                            })
                          ]?.Product_Price_Details[0]?.product_mrp ||
                            v?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <img src={BestBuyImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {v?.Currency_Details[0]?.currency_symbol}
                        {Number(
                          v?.available_Sizes[
                            v?.available_Sizes.findIndex(function (item) {
                              return item.tv_size[0] == sizes1[i];
                            })
                          ]?.Product_Price_Details[0]?.product_mrp ||
                            v?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div>
                      <img src={AmazonImg} alt="" className="" />
                      <p style={{ color: "#000" }}>
                        {v?.Currency_Details[0]?.currency_symbol}
                        {Number(
                          v?.available_Sizes[
                            v?.available_Sizes.findIndex(function (item) {
                              return item.tv_size[0] == sizes1[i];
                            })
                          ]?.Product_Price_Details[0]?.product_mrp ||
                            v?.Product_Price_Details[0]?.product_mrp
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>

                  <PaymentButtonItem
                    onClick={() =>
                      addtoqueue(
                        v.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          }) || 0
                        ]?.product_uuid || v?.product_uuid,
                        1
                      )
                    }
                    savedPrice={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.instant_save ||
                        v?.Product_Price_Details[0]?.instant_save
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    price={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.product_price ||
                        v?.Product_Price_Details[0]?.product_price
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    type={"GET"}
                    symbol={v?.Currency_Details[0]?.currency_symbol}
                  />
                  <PaymentButtonItem
                    onClick={() =>
                      addtoqueue(
                        v.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          }) || 0
                        ]?.product_uuid || v?.product_uuid,
                        0
                      )
                    }
                    savedPrice={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.wait_save ||
                        v?.Product_Price_Details[0]?.wait_save
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    price={Number(
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.Product_Price_Details[0]?.wait_price ||
                        v?.Product_Price_Details[0]?.wait_price
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    symbol={v?.Currency_Details[0]?.currency_symbol}
                    type={"WAIT"}
                  />
                </div>
              ))
            ) : (
              <h3
                style={{
                  display: "flex",
                  height: "400px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showLoader ? "" : "No Data Found"}
              </h3>
            )}

            {/* {totalCount > 9 && (
              <Pagination
                totalCount={totalCount}
                currentPage={page}
                onPageChange={onPageChange}
              />
            )} */}
            {showLoader && <Loader loading={showLoader} />}
            <QueueDrawer open={openQueueSlider} setOpen={setOpenQueueSlider} />
          </div>
        </div>
      </div>
      {openNew && (
        <Modal
          centered
          open={openNew}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpenNew(false)}
        >
          {showSignIn == "signin" && (
            <Login
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
              setTokenData={setTokenData}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp setShowSignIn={setShowSignIn} setOpenNew={setOpenNew} />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
            />
          )}
        </Modal>
      )}

      {showRating && (
        <Modal
          centered
          open={showRating}
          width={"60%"}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setShowRating(false)}
        >
          <Review2
            bool={true}
            product={ratingProduct}
            productName={ratingProduct?.product_description}
          />
        </Modal>
      )}
    </div>
  );
};
