import React, { useEffect, useRef, useState } from "react";
import styles from "../ExpertPicker.module.scss";
import LeftArrow from "../../../assets/ExpertSearch/LeftArrow.svg";
import tryImg from "../../../assets/ExpertSearch/try.svg";
import "../../04CategoryItem/CategoryItem.css";
import { useLocation, useNavigate } from "react-router-dom";
import { image_base_url, saved_product_API } from "../../../utils/APIS/Apis";
import { PaymentButtonItem } from "../../../components/btn/Button";
import Group1 from "../../../assets/Group1.svg";
import Group2 from "../../../assets/Group2.svg";
import Group3 from "../../../assets/Group3.svg";
import Group4 from "../../../assets/Group4.svg";
import starIcon from "../../../assets/Review/Group691.svg";
import likeImg from "../../../assets/CategoryItem/like1.svg";
import like from "../../../assets/CategoryItem/like.svg";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { ForgatePass, Login, SignUp } from "../../../components/auth/LoginSign";
import Loader from "../../../components/Loader/Loader";
import { Base64 } from "js-base64";
import walmartImg from "../../../assets/CategoryItem/walmart.svg";
import BestBuyImg from "../../../assets/CategoryItem/Best_Buy_Logo1.svg";
import AmazonImg from "../../../assets/CategoryItem/Amazon_logo.svg";
import { Review2 } from "../../05ProductDetails/SmallComponent/Review";
import { FadeLoader } from "react-spinners";
import { ai_suggestion } from "../../../utils/api";
import store from "../../../redux/store";
import {
  add_to_Queue,
  delete_MyItem,
  get_MyItem,
} from "../../../redux/productSlice";
import QueueDrawer from "../../../components/drawer/QueueDrawer";
import { useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const override = {
  display: "block",
  margin: "0 auto",
};
export const ExpertProductMain = ({ setTokenData, setTotalQueue }) => {
  const { myItemData, myItemloading } = useSelector((state) => state.product);
  const [openQueueSlider, setOpenQueueSlider] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const location = useLocation((state) => state.state);
  const productListData = location?.state?.product;
  const filterProductType = localStorage.getItem("QueueData");
  const data = JSON.parse(filterProductType);
  const [openModel, setOpenModel] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [ratingProduct, setRatingProduct] = useState({});
  const [sizes, setSizes] = useState([]);
  const [sizes1, setSizes1] = useState([]);

  useEffect(() => {
    localStorage.getItem("token") &&
      store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
    for (let index = 0; index < productListData?.length; index++) {
      sizes.push(0);
    }
    const arr = [];
    for (let index = 0; index < productListData?.length; index++) {
      const sz = productListData[index]?.Features_Details[0]?.features[10];
      arr.push(sz.Size);
      setSizes1(arr);
    }
  }, []);

  const [SizeChange, setSizeChange] = useState([]);

  useEffect(() => {
    if (!productListData) {
      navigate("/");
    }
  }, [productListData]);

  const [aiResponse, setAiResponse] = useState([]);
  const controllerRef = useRef();

  const getAIExpert = async () => {
    const data = productList?.map((item, i) => ({
      Product_Price_Details: item.Product_Price_Details,
      Features_Details: item.Features_Details,
      Products_Weights: item.Products_Weights,
      product_name: item.product_name,
      product_description: item.product_description,
    }));
    if (controllerRef.current) {
      setAiResponse("");
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    await ai_suggestion(data, setAiResponse, signal);
  };

  const addtoqueue = async (id, instant_By) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        consumer_uuid: localStorage.getItem("consumer_id"),
        product_uuid: id,
        quantity: "1",
        instant_By: instant_By == 1 ? true : false,
      };
      store
        .dispatch(add_to_Queue(obj))
        .unwrap()
        .then((res) => {
          setShowLoader(false);
          if (res.success) {
            setOpenQueueSlider(true);
          }
        });
    } catch {}
  };

  const add_my_item = async (id) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
    } else {
      try {
        const obj = {
          consumer_uuid: localStorage.getItem("consumer_id"),
          product_uuid: id,
          quantity: 1,
        };
        const response = await saved_product_API(obj);
        if (response.success) {
          store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
          toast.success(response.message, {
            // hideProgressBar: true,
            toastId: "1",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (productListData) {
      setProductList(productListData);
      fetchList();
    }
  }, [productListData]);

  const fetchList = () => {
    if (productListData.length > 0) {
      const list = productListData;
      var data = [];
      for (var i = 0; i < list.length; i++) {
        for (var j = 0; j < list[i]?.available_Sizes.length; j++) {
          if (
            list[i]?.Features_Details[0]?.features[10]["TV Size"] ==
            list[i]?.available_Sizes[j]?.tv_size[0]
          ) {
            const obj = {
              index: i,
              id: list[i]?.available_Sizes[j]?.product_uuid,
              size: list[i]?.available_Sizes[j]?.tv_size[0],
              index2: j,
            };
            data.push(obj);
          }
        }
      }
      setSizeChange(data);
    }
  };

  return (
    // <div>shubh</div>
    <div className={styles.ex_product_main}>
      <div className={styles.ex_product_main_top}>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            src={LeftArrow}
            alt=""
          />
        </div>
        <div className={styles.ex_product_main_center}>
          <div className={styles.ex_pick_3}>TV EXPERT PICKER</div>
          <div
            className={styles.ex_pick_5}
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {productList?.length > 0
              ? "These are your product picker results!"
              : `We are sorry, we do not have a Television that matches your requirements.`}
          </div>
          <div
            style={{
              display: "flex",
              margin: "1rem 0",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <img
              src={productList?.length == 1 ? Group2 : Group3}
              alt=""
              className={styles.img_ai}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenModel(true);
                getAIExpert();
              }}
            />
          </div>
          <div
            onClick={() =>
              navigate("/expertpicker", {
                state: {
                  id: "01",
                  name: "Television",
                },
              })
            }
            // onClick={() => {
            //   navigate("/expertpicker");
            //   localStorage.removeItem("productItem");
            // }}
            className={styles.try}
          >
            <img src={tryImg} alt="" />
            <div>Try Again</div>
          </div>
        </div>
        <div>
          <div className={styles.ex_product_main_right}>
            <div
              className={styles.ex_pick_exit}
              onClick={() => {
                navigate("/category", {
                  state: {
                    productpicker: true,
                  },
                });
                localStorage.removeItem("productItem");
              }}
            >
              Exit
            </div>
          </div>
        </div>
      </div>

      <div className={styles?.ex_product_bottom}>
        <div
          style={{
            rowGap: "1rem",
            columnGap: ".5rem",
            justifyContent: "center",
          }}
          className="bt-right-cards"
        >
          {productList?.length > 0 ? (
            productList.map((v, i) => (
              <div
                style={{ padding: "1rem 0.5rem" }}
                className="CI-bt-card"
                key={i}
              >
                <div className="card-1">
                  <img
                    className="item-img"
                    onClick={() => {
                      navigate(
                        `/productdetails/${Base64.encode(v?.product_uuid)}/${
                          v?.category[0]?.category_name
                        }`
                      );
                      localStorage.removeItem("productItem");
                    }}
                    src={`${image_base_url}${v.Product_Image_Details[0]?.product_image_url}`}
                    alt=""
                  />
                  <img
                    style={{
                      cursor: "pointer",
                      height: 20,
                      width: 20,
                      position: "absolute",
                      right: 0,
                    }}
                    src={
                      myItemData.find(
                        (val) =>
                          val.product_uuid ==
                          v?.Features_Details[0]?.product_uuid
                      )
                        ? like
                        : likeImg
                    }
                    alt=""
                    onClick={() => {
                      myItemData.find(
                        (val) =>
                          val.product_uuid ==
                          v.Features_Details[0]?.product_uuid
                      )
                        ? store.dispatch(
                            delete_MyItem(
                              myItemData.filter(
                                (val) =>
                                  val.product_uuid ==
                                  v.Features_Details[0]?.product_uuid
                              )[0].queue_uuid
                            )
                          )
                        : add_my_item(v?.Features_Details[0]?.product_uuid);
                    }}
                  />
                </div>
                <div>
                  <div
                    // style={{
                    //   minHeight: "auto",
                    //   cursor: "pointer",
                    //   color: "#252BBE",
                    // }}
                    style={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                    className="bt-card-title"
                    onClick={() => {
                      navigate(
                        `/productdetails/${Base64.encode(v?.product_uuid)}/${
                          v?.category[0]?.category_name
                        }`
                      );
                      localStorage.removeItem("productItem");
                    }}
                    title={v?.product_name}
                  >
                    {v?.product_name}
                  </div>
                  <div
                    className="bt-card-title"
                    style={{
                      padding: "0 .5rem",
                      width: "calc(100% - 1rem)",
                      marginTop: ".5rem",
                      color: "#252bbe",
                      fontSize: "15px",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      cursor: "pointer",
                      height: 35,
                    }}
                    onClick={() => {
                      navigate(
                        `/productdetails/${Base64.encode(v?.product_uuid)}/${
                          v?.category[0]?.category_name
                        }`
                      );
                      localStorage.removeItem("productItem");
                    }}
                    title={
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.product_description
                    }
                  >
                    {
                      v?.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.product_description
                    }
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    width: "100%",
                    overflowX: "scroll",
                    alignSelf: "center",
                    justifyContent:
                      v?.available_Sizes.slice(0)?.length > 5
                        ? "start"
                        : "center",
                  }}
                >
                  {v?.available_Sizes.slice(0).map((s, k) => (
                    <div
                      onClick={() => {
                        const newArr = sizes1.map((val, ind) =>
                          ind == i ? s?.tv_size[0] : val
                        );
                        setSizes1(newArr);
                        // setSizes((pre) =>
                        //   pre.map((val, ind) => (ind == i ? s : val))
                        // );
                        // handleChangeSize(s?.product_uuid, s?.tv_size[0], i, k);
                      }}
                      className="CI-5th1"
                      style={{
                        border:
                          sizes1[i] == s?.tv_size[0] ? "1px solid #252bbe" : "",
                        color: sizes1[i] == s?.tv_size[0] ? "#252bbe" : "",
                      }}
                      key={k}
                    >
                      {s?.tv_size[0]}"
                    </div>
                  ))}
                </div>

                <div>
                  <img
                    style={{ width: "110px", objectFit: "cover" }}
                    src={starIcon}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    color: "#252BBE",
                    fontFamily: "Roboto Condensed",
                    fontSize: "12px",
                    fontWeight: "300",
                    textTransform: "uppercase",
                    textDecorationLine: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setRatingProduct(v);
                    setShowRating(true);
                  }}
                >
                  (324) UNBIASED Reviews
                </div>
                <CircularProgress
                  percentage={v?.totalScore}
                  innerCircleColor="#fff"
                  progressColor="#252BBE"
                  bgColor="#fff"
                />

                <div className="property-card">
                  <div className="PPCard-2">
                    <div style={{ width: "100%" }} className="usage-Rating-1">
                      <div className="usage-rat">
                        {Number(
                          v?.Products_Weights[0]?.MixedUsage * 10
                        ).toFixed(1)}
                      </div>
                      <div className="usage">Mixed Usage</div>
                    </div>
                  </div>
                  <div className="PPcard-head">usage rating</div>
                  <div className="usage-Rating">
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {Number(v?.Products_Weights[0]?.HDRMovies * 10).toFixed(
                          1
                        )}
                      </div>
                      <div className="usage">Movies</div>
                    </div>
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {Number(
                          v?.Products_Weights[0]?.VarableRefreshRate * 10
                        ).toFixed(1)}
                      </div>
                      <div className="usage">Streaming</div>
                    </div>
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {Number(v?.Products_Weights[0]?.Sports * 10).toFixed(1)}
                      </div>
                      <div className="usage">Sports</div>
                    </div>
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {Number(
                          v?.Products_Weights[0]?.VideoGames * 10
                        ).toFixed(1)}
                      </div>
                      <div className="usage">Gaming</div>
                    </div>
                  </div>
                </div>

                <div className="or-get-it-form">Other prices</div>
                <div className="CI-compare">
                  <div>
                    <img src={walmartImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {v?.Currency_Details[0]?.currency_symbol}{" "}
                      {Number(
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div>
                    <img src={BestBuyImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {v?.Currency_Details[0]?.currency_symbol}
                      {Number(
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div>
                    <img src={AmazonImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {v?.Currency_Details[0]?.currency_symbol}
                      {Number(
                        v?.available_Sizes[
                          v?.available_Sizes.findIndex(function (item) {
                            return item.tv_size[0] == sizes1[i];
                          })
                        ]?.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
                <PaymentButtonItem
                  onClick={() =>
                    addtoqueue(
                      v.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.product_uuid,
                      1
                    )
                  }
                  savedPrice={Number(
                    v?.available_Sizes[
                      v?.available_Sizes.findIndex(function (item) {
                        return item.tv_size[0] == sizes1[i];
                      })
                    ]?.Product_Price_Details[0]?.instant_save
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  price={Number(
                    v?.available_Sizes[
                      v?.available_Sizes.findIndex(function (item) {
                        return item.tv_size[0] == sizes1[i];
                      })
                    ]?.Product_Price_Details[0]?.product_price
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  type={"GET"}
                  symbol={v?.Currency_Details[0]?.currency_symbol}
                />
                <PaymentButtonItem
                  onClick={() =>
                    addtoqueue(
                      v.available_Sizes[
                        v?.available_Sizes.findIndex(function (item) {
                          return item.tv_size[0] == sizes1[i];
                        })
                      ]?.product_uuid,
                      0
                    )
                  }
                  savedPrice={Number(
                    v?.available_Sizes[
                      v?.available_Sizes.findIndex(function (item) {
                        return item.tv_size[0] == sizes1[i];
                      })
                    ]?.Product_Price_Details[0]?.wait_save
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  price={Number(
                    v?.available_Sizes[
                      v?.available_Sizes.findIndex(function (item) {
                        return item.tv_size[0] == sizes1[i];
                      })
                    ]?.Product_Price_Details[0]?.wait_price
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  symbol={v?.Currency_Details[0]?.currency_symbol}
                  type={"WAIT"}
                />
              </div>
            ))
          ) : (
            <h3
              style={{
                display: "flex",
                height: "400px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                color: "#252bbe",
              }}
            >
              No TV Match Found
            </h3>
          )}
        </div>
      </div>

      {openNew && (
        <Modal
          centered
          open={openNew}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpenNew(false)}
        >
          {showSignIn == "signin" && (
            <Login
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
              // scrollToElement={scrollToElement}
              setTokenData={setTokenData}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp setShowSignIn={setShowSignIn} setOpenNew={setOpenNew} />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
            />
          )}
        </Modal>
      )}
      {/* {openQueueSlider && (
        <QueueSlider
          setOpenQueueSlider={setOpenQueueSlider}
          setTotalQueue={setTotalQueue}
        />
      )} */}
      {/* added by shubh */}
      <QueueDrawer open={openQueueSlider} setOpen={setOpenQueueSlider} />
      {showRating && (
        <Modal
          centered
          open={showRating}
          width={"60%"}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setShowRating(false)}
        >
          <Review2
            bool={true}
            product={ratingProduct}
            // productName={ratingProduct.Product_Details[0]?.product_description}
          />
        </Modal>
      )}
      {openModel && (
        <Modal
          centered
          open={openModel}
          footer={false}
          width={"60%"}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => {
            controllerRef.current.abort();
            setAiResponse("");
            setOpenModel(false);
          }}
        >
          <div className="PD-7th" style={{ color: "#252bbe" }}>
            {aiResponse?.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBlock: "10rem",
                }}
              >
                <FadeLoader
                  color={"#3035BB"}
                  loading={true}
                  cssOverride={override}
                  size={80}
                  zIndex={1000000}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <textarea
                value={aiResponse}
                className="textarea"
                readOnly
                rows={25} // Update the number of rows as needed
                style={{
                  resize: "none",
                  color: "#000",
                  fontSize: 16,
                  textAlign: "justify",
                  height: "calc(100% - 2rem)",
                  width: "calc(100% - 4rem)",
                  padding: "1rem 2rem",
                }}
              />
            )}
          </div>
        </Modal>
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};

export const ExpertProductMainLaptopData = ({
  setTokenData,
  setTotalQueue,
}) => {
  const { myItemData, myItemloading } = useSelector((state) => state.product);
  const [openQueueSlider, setOpenQueueSlider] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const location = useLocation((state) => state.state);
  const productListData = location?.state?.product;
  const filterProductType = localStorage.getItem("QueueData");
  const data = JSON.parse(filterProductType);
  const [openModel, setOpenModel] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [ratingProduct, setRatingProduct] = useState({});
  const scrollViewRef = useRef();
  useEffect(() => {
    localStorage.getItem("token") &&
      store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
  }, []);

  useEffect(() => {
    if (!productListData) {
      navigate("/");
    }
  }, [productListData]);

  const [aiResponse, setAiResponse] = useState([]);
  const controllerRef = useRef();
  const getAIExpert = async () => {
    const data = productList?.map((item, i) => [
      {
        Product_Price_Details: item.Product_Price_Details,
        Features_Details: item.Features_Details,
        Products_Weights: item.Products_Weights,
        product_name: item.Product_Details[0]?.product_name,
        product_description: item.Product_Details[0]?.product_description,
        // Product_Details: item.Product_Details,
      },
    ]);
    // const data = productList?.map((item, i) => [
    //   Object.assign(item.Product_Details[0], {
    //     Product_Price_Details: item.Product_Price_Details,
    //     Features_Details: item.Features_Details,
    //     Products_Weights: item.Products_Weights,
    //     // Product_Details: item.Product_Details,
    //   }),
    // ]);

    if (controllerRef.current) {
      setAiResponse("");
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    await ai_suggestion(data, setAiResponse, signal);
  };

  const addtoqueue = async (id, instant_By) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        consumer_uuid: localStorage.getItem("consumer_id"),
        product_uuid: id,
        quantity: "1",
        instant_By: instant_By == 1 ? true : false,
      };
      store
        .dispatch(add_to_Queue(obj))
        .unwrap()
        .then((res) => {
          setShowLoader(false);
          if (res.success) {
            setOpenQueueSlider(true);
          }
        });
    } catch {}
  };

  const add_my_item = async (id) => {
    if (!localStorage.getItem("token")) {
      setOpenNew(true);
    } else {
      try {
        const obj = {
          consumer_uuid: localStorage.getItem("consumer_id"),
          product_uuid: id,
          quantity: 1,
        };
        const response = await saved_product_API(obj);
        if (response.success) {
          store.dispatch(get_MyItem(localStorage.getItem("consumer_id")));
          toast.success(response.message, {
            toastId: "1",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (productListData) {
      setProductList(productListData);
      fetchList();
    }
  }, [productListData]);

  const fetchList = () => {};

  return (
    <div className={styles.ex_product_main}>
      <div className={styles.ex_product_main_top}>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/expertpicker", {
                state: {
                  id: "01",
                  name: "Laptops",
                },
              });
            }}
            src={LeftArrow}
            alt=""
          />
        </div>
        <div className={styles.ex_product_main_center}>
          <div className={styles.ex_pick_3}>LAPTOP EXPERT PICKER</div>
          <div className={styles.ex_pick_5} style={{ fontSize: "20px" }}>
            {productList?.length > 0
              ? "These are your product picker results!"
              : `We are sorry, we do not have a Laptop that matches your requirements.`}
          </div>
          <div
            style={{
              display: "flex",
              margin: "1rem 0",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <img
              src={productList?.length == 1 ? Group1 : Group4}
              className={styles.img_ai}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenModel(true);
                getAIExpert();
              }}
            />
          </div>
          <div
            onClick={() => {
              navigate("/expertpicker", {
                state: {
                  id: "01",
                  name: "Laptops",
                },
              });
              localStorage.removeItem("productItem");
            }}
            className={styles.try}
          >
            <img src={tryImg} alt="" />
            <div>Try Again</div>
          </div>
        </div>
        <div>
          <div className={styles.ex_product_main_right}>
            <div
              className={styles.ex_pick_exit}
              onClick={() => {
                navigate("/category", {
                  state: {
                    productpicker: true,
                  },
                });
                localStorage.removeItem("productItem");
              }}
            >
              Exit
            </div>
          </div>
        </div>
      </div>

      <div className={styles?.ex_product_bottom}>
        <div
          style={{
            rowGap: "1rem",
            columnGap: ".5rem",
            justifyContent: "center",
          }}
          className="bt-right-cards"
        >
          {productList?.length > 0 ? (
            productList.map((v, i) => (
              <div
                style={{ padding: "1rem .5rem" }}
                className="CI-bt-card"
                key={i}
              >
                <div className="card-1">
                  <img
                    className="item-img"
                    onClick={() => {
                      navigate(
                        `/productdetails/${Base64.encode(
                          v?.Product_Details[0]?.product_uuid
                        )}/${v?.category[0]?.category_name}`
                      );
                      localStorage.removeItem("productItem");
                    }}
                    src={`${image_base_url}${v?.Product_Image_Details[0]?.product_image_url}`}
                    alt=""
                  />
                  <img
                    style={{
                      cursor: "pointer",
                      height: 20,
                      width: 20,
                      position: "absolute",
                      right: 0,
                    }}
                    src={
                      myItemData.find(
                        (val) =>
                          val.product_uuid ==
                          v.Features_Details[0]?.product_uuid
                      )
                        ? like
                        : likeImg
                    }
                    alt=""
                    onClick={() => {
                      myItemData.find(
                        (val) =>
                          val.product_uuid ==
                          v.Features_Details[0]?.product_uuid
                      )
                        ? store.dispatch(
                            delete_MyItem(
                              myItemData.filter(
                                (val) =>
                                  val.product_uuid ==
                                  v.Features_Details[0]?.product_uuid
                              )[0].queue_uuid
                            )
                          )
                        : add_my_item(v?.Product_Details[0]?.product_uuid);
                    }}
                  />
                </div>
                <div>
                  <div
                    style={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                    className="bt-card-title"
                    onClick={() => {
                      navigate(
                        `/productdetails/${Base64.encode(
                          v?.Product_Details[0]?.product_uuid
                        )}/${v?.category[0]?.category_name}`
                      );
                      localStorage.removeItem("productItem");
                    }}
                    title={v?.Product_Details[0]?.product_name}
                  >
                    {v?.Product_Details[0]?.product_name}
                  </div>
                  <div
                    className="bt-card-title"
                    style={{
                      padding: "0 .5rem",
                      width: "calc(100% - 1rem)",
                      marginTop: ".5rem",
                      color: "#252bbe",
                      fontSize: "15px",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      cursor: "pointer",
                      height: 35,
                    }}
                    onClick={() => {
                      navigate(
                        `/productdetails/${Base64.encode(
                          v?.Product_Details[0]?.product_uuid
                        )}/${v?.category[0]?.category_name}`
                      );
                      localStorage.removeItem("productItem");
                    }}
                    title={v?.Product_Details[0]?.product_description}
                  >
                    {v?.Product_Details[0]?.product_description}
                  </div>
                </div>

                <div>
                  <img
                    style={{ width: "110px", objectFit: "cover" }}
                    src={starIcon}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    color: "#252BBE",
                    fontFamily: "Roboto Condensed",
                    fontSize: "12px",
                    fontWeight: "300",
                    textTransform: "uppercase",
                    textDecorationLine: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setRatingProduct(v);
                    setShowRating(true);
                  }}
                >
                  (324) UNBIASED Reviews
                </div>
                <CircularProgress
                  percentage={v?.totalScore}
                  innerCircleColor="#fff"
                  progressColor="#252BBE"
                  bgColor="#fff"
                />

                <div className="property-card">
                  <div className="PPCard-2">
                    <div style={{ width: "100%" }} className="usage-Rating-1">
                      <div className="usage-rat">
                        {v?.Products_Weights[0]?.Business > 1
                          ? Number(v?.Products_Weights[0]?.Business).toFixed(1)
                          : Number(
                              v?.Products_Weights[0]?.Business * 10
                            ).toFixed(1)}
                      </div>
                      <div className="usage">Business</div>
                    </div>
                  </div>
                  <div className="PPcard-head">usage rating</div>
                  <div className="usage-Rating">
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {v?.Products_Weights[0]?.Battery > 1
                          ? Number(v?.Products_Weights[0]?.Battery).toFixed(1)
                          : Number(
                              v?.Products_Weights[0]?.Battery * 10
                            ).toFixed(1)}
                      </div>
                      <div className="usage">Battery</div>
                    </div>
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {v?.Products_Weights[0]?.Touchpad > 1
                          ? Number(v?.Products_Weights[0]?.Touchpad).toFixed(1)
                          : Number(
                              v?.Products_Weights[0]?.Touchpad * 10
                            ).toFixed(1)}
                      </div>
                      <div className="usage">Touchpad</div>
                    </div>
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {v?.Products_Weights[0]?.Keyboard > 1
                          ? Number(v?.Products_Weights[0]?.Keyboard).toFixed(1)
                          : Number(
                              v?.Products_Weights[0]?.Keyboard * 10
                            ).toFixed(1)}
                      </div>
                      <div className="usage">Keyboard</div>
                    </div>
                    <div className="usage-Rating-1">
                      <div className="usage-rat">
                        {v?.Products_Weights[0]?.Gaming > 1
                          ? Number(v?.Products_Weights[0]?.Gaming).toFixed(1)
                          : Number(v?.Products_Weights[0]?.Gaming * 10).toFixed(
                              1
                            )}
                      </div>
                      <div className="usage">Gaming</div>
                    </div>
                  </div>
                </div>

                <div className="or-get-it-form">Other prices</div>
                <div className="CI-compare">
                  <div>
                    <img src={walmartImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {v?.Currency_Details[0]?.currency_symbol}{" "}
                      {Number(
                        v?.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div>
                    <img src={BestBuyImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {v?.Currency_Details[0]?.currency_symbol}
                      {Number(
                        v?.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div>
                    <img src={AmazonImg} alt="" className="" />
                    <p style={{ color: "#000" }}>
                      {v?.Currency_Details[0]?.currency_symbol}
                      {Number(
                        v?.Product_Price_Details[0]?.product_mrp
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
                <PaymentButtonItem
                  onClick={() =>
                    addtoqueue(v?.Product_Details[0]?.product_uuid, 1)
                  }
                  savedPrice={Number(
                    v?.Product_Price_Details[0]?.instant_save
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  price={Number(
                    v?.Product_Price_Details[0]?.product_price
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  type={"GET"}
                  symbol={v?.Currency_Details[0]?.currency_symbol}
                />
                <PaymentButtonItem
                  onClick={() =>
                    addtoqueue(v?.Product_Details[0]?.product_uuid, 0)
                  }
                  savedPrice={Number(
                    v?.Product_Price_Details[0]?.wait_save
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  price={Number(
                    v?.Product_Price_Details[0]?.wait_price
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  symbol={v?.Currency_Details[0]?.currency_symbol}
                  type={"WAIT"}
                />
              </div>
            ))
          ) : (
            <h3
              style={{
                display: "flex",
                height: "400px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                color: "#252bbe",
              }}
            >
              No Laptop Match Found
            </h3>
          )}
        </div>
      </div>

      {openNew && (
        <Modal
          centered
          open={openNew}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpenNew(false)}
        >
          {showSignIn == "signin" && (
            <Login
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
              setTokenData={setTokenData}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp setShowSignIn={setShowSignIn} setOpenNew={setOpenNew} />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
            />
          )}
        </Modal>
      )}

      {showRating && (
        <Modal
          centered
          open={showRating}
          width={"60%"}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setShowRating(false)}
        >
          <Review2
            bool={true}
            product={ratingProduct}
            productName={ratingProduct.Product_Details[0]?.product_description}
          />
        </Modal>
      )}

      <QueueDrawer open={openQueueSlider} setOpen={setOpenQueueSlider} />
      {openModel && (
        <Modal
          centered
          open={openModel}
          footer={false}
          width={"60%"}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => {
            controllerRef.current.abort();
            setOpenModel(false);
          }}
        >
          <div
            className="PD-7th"
            style={{ color: "#252bbe", textAlign: "justify" }}
          >
            <div className="PD-7th" style={{ color: "#252bbe" }}>
              {aiResponse?.length == 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBlock: "10rem",
                  }}
                >
                  <FadeLoader
                    color={"#3035BB"}
                    loading={true}
                    cssOverride={override}
                    size={80}
                    zIndex={1000000}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                <textarea
                  value={aiResponse}
                  className="textarea"
                  readOnly
                  rows={25}
                  style={{
                    resize: "none",
                    textAlign: "justify",
                    height: "calc(100% - 2rem)",
                    width: "calc(100% - 4rem)",
                    padding: "1rem 2rem",
                    color: "#000",
                    fontSize: 16,
                  }}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};

const CircularProgress = ({
  percentage,
  innerCircleColor,
  progressColor,
  bgColor,
}) => {
  return (
    <div
      style={{
        width: 92,
        height: 92,
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <CircularProgressbar
        styles={buildStyles({
          pathColor: "#252BBE",
          textColor: "#f88",
          trailColor: "#fff",
        })}
        value={percentage}
        strokeWidth={2}
      />
      <div className={styles.match} style={{ position: "absolute" }}>
        <p className={styles.percentage}>{percentage}%</p>
        <p className={styles.match}>MATCH</p>
      </div>
    </div>
  );
};
