import React, { useState } from "react";
import styles from "../../DataDashboard.module.scss";
import "./css/browsingdata.css";
import pages from "../assets/pages_visited.png";
import products from "../assets/products.png";
import search from "../assets/search.png";
import time from "../assets/time.png";
import searchSmall from "../assets/search_small.png";
import { DataDashBoardHeader } from "../../DataDashboard";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/backIcon.svg";
import { Input } from "../../../../components/inputs/Input";

const BrowsingData = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />

      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("-1")}>
              Back
            </span>
          </div>
        </div>
        <div className="main_expert_component">
          <div className="main_browsingdata_component_heading">
            <h1 className="main_expert_div_heading">Browsing Data</h1>
            {/* <div className="expert_picker_search_form">
          <input placeholder='Search an Expert Product Picker Category' className='search_form_expert' type="text" />
          <button className='search_form_button'>Search</button>
        </div> */}
            <div
              className={styles.SI_content_1}
              style={{ display: "flex", gap: "1rem" }}
            >
              <Input
                placeholder={"Search an Expert Product Picker Category"}
                style={{ width: "332.594px" }}
              />
              <div
                className={styles.LD_edit}
                style={{
                  width: "150px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontSize: "12px" }}>Search a Category</span>
              </div>
            </div>
          </div>
          <div className="expert_picker_inner_div">
            <div className="browsing_data_inner_div_child">
              <div className="browsing_child_heading">
                <div className="browsingdata_name">
                  <img
                    style={{ width: "40px", height: "40px", flexShrink: "0" }}
                    src={pages}
                    alt=""
                  />
                  <p className="heading_title">Pages Visited</p>
                </div>
                <div className="browsing_data_show_more">
                  <p className="show_all">Show All</p>
                </div>
              </div>
              {[0, 0, 0, 0, 0].map((e) => (
                <div className="browsing_child_content">
                  <p className="browsing_url">
                    demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K-Smart-OLED-TV
                  </p>
                  <p className="browsing_date">13 November 2023</p>
                </div>
              ))}
            </div>
            <div className="browsing_data_inner_div_child">
              <div className="browsing_child_heading">
                <div className="browsingdata_name">
                  <img
                    style={{ width: "40px", height: "40px", flexShrink: "0" }}
                    src={products}
                    alt=""
                  />
                  <p className="heading_title">Products viewed</p>
                </div>
                <div className="browsing_data_show_more">
                  <p className="show_all">Show All</p>
                </div>
              </div>
              {[0, 0, 0, 0, 0].map((e) => (
                <div className="browsing_child_content">
                  <p className="browsing_url">
                    demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K-Smart-OLED-TV
                  </p>
                  <p className="browsing_date">13 November 2023</p>
                </div>
              ))}
            </div>
            <div className="browsing_data_inner_div_child">
              <div className="browsing_child_heading">
                <div className="browsingdata_name">
                  <img
                    style={{ width: "40px", height: "40px", flexShrink: "0" }}
                    src={time}
                    alt=""
                  />
                  <p className="heading_title">Time spent on pages</p>
                </div>
                <div className="browsing_data_show_more">
                  <p className="show_all">Show All</p>
                </div>
              </div>
              {[0, 0, 0, 0, 0].map((e) => (
                <div className="browsing_child_content">
                  <p className="browsing_url">
                    demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K-Smart-OLED-TV
                  </p>
                  <p className="browsing_date">13 November 2023</p>
                </div>
              ))}
            </div>
            <div className="browsing_data_inner_div_child">
              <div className="browsing_child_heading">
                <div className="browsingdata_name">
                  <img
                    style={{ width: "40px", height: "40px", flexShrink: "0" }}
                    src={search}
                    alt=""
                  />
                  <p className="heading_title">Search queries</p>
                </div>
                <div className="browsing_data_show_more">
                  <p className="show_all">Show All</p>
                </div>
              </div>
              {[0, 0, 0, 0, 0].map((e) => (
                <div className="browsing_child_content">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "70%",
                    }}
                  >
                    <img
                      style={{ height: "13px", width: "13px" }}
                      src={searchSmall}
                      alt=""
                    />
                    <p style={{ width: "90%" }} className="browsing_url">
                      demandey.com/categories/televisions/LG-GX-55-GalleryDesign4K-Smart-OLED-TV
                    </p>
                  </div>
                  <p className="browsing_date">13 November 2023</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrowsingData;
