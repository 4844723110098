import React from "react";
import "./Footer.css";
import logo from "../../../assets/icons/name.svg";
import SocialMedia from "../../../assets/Footer.svg";
import Instagram from "../../../assets/FooterInstagram.svg";
import Facebook from "../../../assets/FooterFacebook.svg";
import Linkedin from "../../../assets/FooterLinkedin.svg";
import X from "../../../assets/FooterX.svg";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="FT">
      <div className="FT-logo">
        <img src={logo} alt="logo" className="FT-img" />
      </div>
      <div className="FT-links">
        <div className="FT-links-1st" style={{ maxWidth: "230px" }}>
          <div className="links-1st-01">
            Empowering you to use your data to save on your wants, needs and
            desires.
          </div>
          <div className="links-1st-02">
            Be the first to know about our best deals!
          </div>
          <div className="links-1st-03">
            <input
              className="links-input"
              placeholder="Email Address"
              type="text"
            />
            <button className="links-btn">Submit</button>
          </div>
        </div>
        <div className="FT-links-1st">
          <div className="links-1st-head">Services & Data</div>
          <div className="links-1st-content">
            <div className="content-item">
              <div onClick={() => navigate("/category")}>
                Expert Product Picker
              </div>
              <div onClick={() => navigate("/category")}>Categories</div>

              <div onClick={() => navigate("/datadashboard")}>
                Data Dashboard
              </div>
            </div>
          </div>
        </div>
        <div className="FT-links-1st">
          <div className="links-1st-head">Customer Service</div>
          <div className="links-1st-content">
            <div className="content-item">
              <div onClick={() => navigate("/purchasehistory")}>My Orders</div>
              <div onClick={() => navigate("/logindetails")}>My Account</div>
              <div>Return Policy</div>
              <div>Help Center</div>
              <div>FAQs</div>
            </div>
          </div>
        </div>
        <div className="FT-links-1st">
          <div className="links-1st-head">About Us</div>
          <div className="links-1st-content">
            <div className="content-item">
              <div onClick={() => navigate("/")}>About Demandey</div>
              <div
                onClick={() => {
                  navigate("/");
                }}
              >
                Your Bill of Rights
              </div>
              <div>Careers</div>
            </div>
          </div>
        </div>
        <div className="FT-links-1st">
          <div className="links-1st-head">Contact Us</div>
          <div
            className="links-1st-content"
            style={{ flexDirection: "column", gap: "2rem" }}
          >
            <div className="content-item">
              <div>Call : +1 2345667</div>
              <div>Email : hello@demandey.com</div>
            </div>
            <div className="links-SMedia">
              <a href="https://www.instagram.com/empowerdemandey/">
                <img src={Instagram} alt="" className="Social-media" />
              </a>
              <a href="https://www.facebook.com/demandey">
                <img src={Facebook} alt="" className="Social-media" />
              </a>
              <a href="https://www.linkedin.com/company/demandey/about/">
                <img src={Linkedin} alt="" className="Social-media" />
              </a>
              <a href="https://twitter.com/EmpowerDemandey">
                <img src={X} alt="" className="Social-media" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="FT-bottom">
        <div className="FT-bot-left">© 2023-2024 Demandey</div>
        <div className="FT-bot-right">
          <div>Terms of Use & Privacy Policy</div>
          <div>Cookie Policy</div>
          <div>Cookie Settings</div>
        </div>
      </div>
    </div>
  );
};
