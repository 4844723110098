import React, { useState } from "react";
import "./Details.Card.css";
import Group1138 from "../../../assets/productDetails/Group1138.svg";
import Character1 from "../../../assets/productDetails/Character1.svg";
import Group1133 from "../../../assets/productDetails/Group1133.svg";
import Character from "../../../assets/productDetails/Character.svg";
import { Modal } from "antd";
import {
  BrowseAsGuest,
  ForgatePass,
  Login,
  SignUp,
} from "../../auth/LoginSign";
import { useNavigate } from "react-router-dom";
import { image_base_url } from "../../../utils/APIS/Apis";
import { RxCross2 } from "react-icons/rx";
import videoImg from "../../../assets/videoImg.svg";
import VideoComp from "../../VideoComp";

export const DetailsCard = ({ tokenData, setTokenData, scrollToElement }) => {
  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");

  const navigate = useNavigate();

  return (
    <div className="DC">
      <div className="DC-1st">Your Consumer Bill of Rights</div>
      <div className="DC-2nd">
        Since the invention of internet, retailers, supply chain participants,
        social platform networks (Meta, X, Instagram, YouTube, , etc.) and
        Google (for search) have been collecting your personal data and selling
        it for Trillions of Dollars per year. This should be your money! It’s
        your personal data, it’s your asset! Demandey was created to empower you
        to sell your own data directly to manufacturers and brands. This will
        allow people and businesses to collect trillions of dollars per year
        that belongs to them. Don’t lose tens of thousands of dollars per year
        per household, use Demandey!
      </div>

      <div className="DC-3rd DC-3rd-reverse">
        <div className="DC-3rd-1">
          <div className="DC-3rd-head">
            Product Recommendations Tailored to Your Needs
          </div>
          <div className="DC-3rd-content">
            We promise that we'll only present you with products that best fit
            your needs. We don't promote anything based on ad dollars or
            sponsorships.
          </div>
          <div
            className="DC-btn-div"
            onClick={() => {
              if (localStorage.getItem("zipcode")) {
                navigate("/category");
              } else {
                setOpen1(true);
              }
            }}
          >
            {localStorage.getItem("token") ? (
              <button className="hc-btn">Let's Shop</button>
            ) : (
              <button className="hc-btn">Start Browsing</button>
            )}
          </div>
        </div>
        <div className="DC-3rd-2">
          <img src={Group1138} className="DC-3rd-img" alt="" />
        </div>
      </div>
      <div className="DC-3rd">
        <div className="DC-3rd-2">
          <img src={Character1} className="DC-3rd-img" alt="" />
        </div>
        <div className="DC-3rd-1">
          <div className="DC-3rd-head">
            Take Control of Your Data and Save a lot with Us
          </div>
          <div className="DC-3rd-content">
            We'll keep you anonymous and we'll never sell your data to anybody.
            Your data is only used to give you better recommendations.
          </div>
          <div
            className="DC-btn-div"
            onClick={() => {
              if (localStorage.getItem("zipcode")) {
                navigate("/category");
              } else {
                setOpen1(true);
              }
            }}
          >
            {localStorage.getItem("token") ? (
              <button className="hc-btn">Let's Shop</button>
            ) : (
              <button className="hc-btn">Start Browsing</button>
            )}
          </div>
        </div>
      </div>
      <div className="DC-3rd DC-3rd-reverse">
        <div className="DC-3rd-1">
          <div className="DC-3rd-head">
            Protecting Your Privacy While Enhancing Your Recommendations
          </div>
          <div className="DC-3rd-content">
            We'll keep you anonymous and we'll never sell your data to anybody.
            Your data is only used to give you better recommendations.
          </div>
          <div
            className="DC-btn-div"
            onClick={() => {
              if (!localStorage.getItem("token")) {
                setOpen2(true);
              } else {
                navigate("/datadashboard");
              }
            }}
          >
            <button className="hc-btn">Data Dashboard</button>
          </div>
        </div>
        <div className="DC-3rd-2">
          <img src={Group1133} className="DC-3rd-img" alt="" />
        </div>
      </div>
      <div className="DC-3rd">
        <div className="DC-3rd-2">
          <img src={Character} className="DC-3rd-img" alt="" />
        </div>
        <div className="DC-3rd-1">
          <div className="DC-3rd-head">Enjoy Savings Up to 30-50%</div>
          <div className="DC-3rd-content">
            Once you're completely satisfied with your purchase, we'll collect
            our 7% procurement fee and you'll still have saved 30-50%
          </div>
          <div className="DC-btn-div">
            <button className="hc-btn" onClick={() => setOpen(true)}>
              How do we do it
            </button>
          </div>
        </div>
      </div>
      {open1 && (
        <Modal
          centered
          closable={true}
          open={open1}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen1(false)}
        >
          <BrowseAsGuest setOpenNew={setOpen1} />
        </Modal>
      )}

      {open2 && (
        <Modal
          centered
          closable={true}
          open={open2}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen2(false)}
        >
          {showSignIn == "signin" && (
            <Login
              navi={true}
              tokenData={tokenData}
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpen2}
              scrollToElement={scrollToElement}
              setTokenData={setTokenData}
              dash={true}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp
              navi={true}
              setTokenData={setTokenData}
              setShowSignIn={setShowSignIn}
              setOpenNew={open2}
            />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass setShowSignIn={setShowSignIn} setOpenNew={open2} />
          )}
        </Modal>
      )}
      <VideoComp
        url="uploads/demandey_video/demandey_supply_chain.mp4"
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};
