import React, { useState } from 'react'
import './Input.css'
import rightClickimg from '../../assets/rightSignWhite.svg'

export const CheckBox = ({checkValue,setCheckValue,disabled=false}) => {
    
  return (
    <div className='checkbox'style={{backgroundColor:checkValue?"blue":"",height:"15px",width:"15px"}} onClick={()=> !disabled && setCheckValue(!checkValue)}>
      {checkValue &&  <img src={rightClickimg} alt='' style={{}} className='checkbox-main'></img>}
    </div>
  )
}


export const RadioCheckBox = ({checkValue,setCheckValue,disabled=false}) => {
    
  return (
    <div className='checkbox'style={{backgroundColor:checkValue?"blue":"",height:"15px",width:"15px"}} onClick={setCheckValue}>
      {checkValue &&  <img src={rightClickimg} alt='' style={{}} className='checkbox-main'></img>}
    </div>
  )
}