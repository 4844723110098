import React from "react";
import { RxCross2 } from "react-icons/rx";
import videoImg from "../assets/videoImg.svg";
import { Box, Fade, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80%",
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};
const VideoComp = ({ open, setOpen, url = "" }) => {
  const handleClose = () => setOpen(false);
  return (
    <>
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          style={{ outline: "none" }}
        >
          <Fade in={open} style={{ outline: "none" }}>
            <Box sx={style}>
              <RxCross2
                onClick={() => {
                  setOpen(false);
                }}
                size={25}
                style={{
                  color: "#000",
                  cursor: "pointer",
                  position: "absolute",
                  right: 5,
                  top: 5,
                }}
              />
              <video
                controls
                autoPlay={true}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                poster={videoImg}
              >
                <source
                  src={`${process.env.REACT_APP_IMG_BASE_URL}${url}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default VideoComp;
