import React from "react";
import styles from "./component.module.scss";
import { images } from "../utils/utils";

const ExpertCard = ({ setOpen }) => {
  return (
    <div className={styles.epp_card}>
      <img src={images.expPicker1} className={styles.epp_card_exp_img1} />
      <div className={styles.epp_card_heading_div}>
        <img src={images.expertpicker} className={styles.epp_card_exp_img} />
        <div className={styles.epp_card_heading}>Expert Product Picker</div>
      </div>
      <div className={styles.epp_card_text}>
        Our patented expert product picker is a matchmaker for your wants, needs
        and desires! Meticulously analyzing your requirements to recommend
        products that align perfectly with your unique preferences.
      </div>
      <div className={styles.epp_card_play_div} onClick={() => setOpen(true)}>
        <div className={styles.epp_card_play_heading}>
          Watch our video on
          <br />
          Expert Product Picker (40 Seconds)
        </div>
        <img src={images.playIcon} className={styles.epp_card_play} />
      </div>
    </div>
  );
};

export default ExpertCard;
