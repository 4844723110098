import React, { useState } from "react";
import sty from "../DataDashboard.module.scss";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { DataDashboardManuData } from "../../../utils/Data";
import { useLocation, useNavigate } from "react-router-dom";
// import { Dropdown } from 'antd'

export const DataWithManu = ({ setDataDash }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={sty.DataWithManu}>
      <div className={sty.DWM_1}>
        <div className={sty.DWM_1_1}>
          Only Demandey provides you full Data transparency
        </div>
        <div className={sty.DWM_1_2}>
          By procuring your product directly from a manufacturer you have agreed
          to share some of your data with a manufacturer.
        </div>
      </div>
      <div className={sty.DWM_2}>
        <div className={sty.DWM_2_title}>
          Your Agreements with manufacturers
        </div>
        <div className={sty.DWM_2_sort}>
          <div className={sty.DWM_2_sort_1}>Sort By:</div>
          {/* <Dropdown
            title={"Date of Order"}
            setOpen={setOpen}
            open={open}
            body={
              <>
                <p>Hello</p>
                <p>Hello</p>
                <p>Hello</p>
                <p>Hello</p>
              </>
            }
          /> */}
          <Dropdown
            width={"250px"}
            title={"Date of Order"}
            setOpen={setOpen}
            open={open}
            body={
              <>
                <p>Hello</p>
                <p>Hello</p>
                <p>Hello</p>
                <p>Hello</p>
              </>
            }
          />
        </div>
      </div>
      {DataDashboardManuData.map((v, i) => (
        <div className={sty.data_de_2} key={1}>
          <div className={sty.data_de_2_manu_title}>{v.title}</div>
          <div className={sty.data_de_cards} key={i}>
            {DataDashboardManuData[i].content.map((p, s) => (
              <div className={sty.manu_card} key={s}>
                <div className={sty.manu_img_div}>
                  <img src={p.img1} alt="" />
                  <img
                    style={{ padding: "0  0 .5rem .5rem" }}
                    src={p.img2}
                    alt=""
                  />
                </div>
                <div className={sty.data_manu_card_right}>
                  <div className={sty.data_manu_card_right_title}>
                    {p.title}
                  </div>
                  <div className={sty.data_manu_card_right_content}>
                    {p.ORDER}
                  </div>
                  <div
                    className={sty.data_manu_card_right_btn}
                    onClick={() => navigate("/productdatadashboard")}
                  >
                    Review Agreement
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
