import React, { useState } from "react";
import styles from "../../DataDashboard.module.scss";
import "./css/preferences.css";
import { Link, useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/backIcon.svg";
import { DataDashBoardHeader } from "../../DataDashboard";

const Preferences = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("-1")}>
              Back
            </span>
          </div>
        </div>
        <div className="main_expert_component">
          <h1 className="main_expert_div_heading">Preferences</h1>
          <div className="preferences_inner_div">
            <div className="expert_picker_inner_child">
              <p className="preferences_inner_sub_heading">
                Accessibility Preferences
              </p>
              <div className="inner_child_flexbox">
                <ul className="flexfirst">
                  <li>Color and Visibility</li>
                  <li>Readability</li>
                  <li>Voice & Speech Settings</li>
                  <li>Keyboard Shortcuts</li>
                </ul>
                <ul className="flexsecond">
                  <li>Closed Captioning</li>
                  <li>WCAG Guidelines in Demandey</li>
                  <li>Conformance</li>
                  <li>Understandability</li>
                </ul>
              </div>
            </div>
            <div className="expert_picker_inner_child">
              <p className="preferences_inner_sub_heading">
                Accessibility Preferences
              </p>
              <div className="inner_child_flexbox">
                <ul className="flexfirst">
                  <li>Email subscriptions</li>
                  <li>Communication preferences</li>
                  <li>Shipment updates via text</li>
                </ul>
                <ul className="flexsecond">
                  <li>Demandey shopping notifications</li>
                  <li>Videos you've uploaded</li>
                  <li>Purchase Reminders</li>
                </ul>
              </div>
            </div>
            <div className="expert_picker_inner_child">
              <p className="preferences_inner_sub_heading">
                Accessibility Preferences
              </p>
              <div className="preinner_child_flexbox">
                <ul className="flexfirst">
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                </ul>
                <ul className="flexfirst">
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                </ul>
                <ul className="flexfirst">
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                </ul>
                <ul className="flexfirst">
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                </ul>
                <ul className="flexfirst">
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                  <li>
                    <div className="checkbox_form">
                      <input type="radio" />
                      <p>English</p>
                    </div>
                  </li>
                </ul>
              </div>
              <Link
                style={{ display: "flex", justifyContent: "center" }}
                className="show_more"
                to={"#"}
              >
                Show more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
