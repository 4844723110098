import React from "react";
import "./categoryItem.css";

const CategoryItem = ({ title, img, style, style_div, onClick }) => {
  return (
    <div className="category_item_main" onClick={onClick}>
      <div className="category_item_img_div" style={style_div}>
        <img
          src={`${process.env.REACT_APP_IMG_BASE_URL}${img}`}
          alt=""
          className="category_item_img"
          style={style}
        />
      </div>
      <div className="category_item_title">{title}</div>
    </div>
  );
};

export default CategoryItem;
