import React, { useEffect, useState } from "react";
import "./Home.css";
import arrowDown from "../../assets/arrowDown.svg";
import bgImage from "../../assets/Backgraound/Vector29.svg";
import { HomeCard } from "../../components/Home/HomeCard/HomeCard";
import { ProductCat } from "../../components/Home/ProductCat/ProductCat";
import { DetailsCard } from "../../components/Home/other/DetailsCard";
import { Review } from "../../components/Home/Review/Review";
import { Questions } from "../../components/Home/Questions/Questions";
import { scroller, animateScroll as scroll } from "react-scroll";
import { images } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import VideoComp from "../../components/VideoComp";
import { Modal } from "antd";
import {
  BrowseAsGuest,
  ForgatePass,
  Login,
  SignUp,
} from "../../components/auth/LoginSign";

export const Home = ({ tokenData, ZipData, setTokenData }) => {
  const elementIds = ["homecard"];
  const [visibleElementId, setVisibleElementId] = useState("");
  const [onHover, setOnHover] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");
  const navigate = useNavigate();
  const handleScroll = () => {
    for (const elementId of elementIds) {
      const element = document.getElementById(elementId);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 10 && rect.top <= 600) {
          setVisibleElementId(elementId);
          return;
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 1000,
      smooth: true,
      offset: -100,
    });
  };

  const handleData = () => {
    handleData(scrollToElement);
  };
  const calculateCenterArea = (event) => {
    const image = event.target;
    const imageRect = image.getBoundingClientRect();

    const centerX = imageRect.width / 2;
    const centerY = imageRect.height / 2;

    const isInCenter =
      event.clientX >= imageRect.left + centerX - 115 &&
      event.clientX <= imageRect.left + centerX + 115 &&
      event.clientY >= imageRect.top + centerY - 115 &&
      event.clientY <= imageRect.top + centerY + 115;

    setOnHover(isInCenter);
  };

  return (
    <div className="home">
      <img src={images.bgImg} className="home-img" />
      <div className="home_data_2">
        <div className="home_top_header">
          <div className="home_top_header_left">
            <div className="home_video_div_main">
              <div className="home_video_div">
                <img src={images.homeGirl} className="home_girl" />
              </div>
              <div className="home_video_text_video_div">
                <img
                  src={images.videotext3}
                  className="home_video_text"
                  onClick={() => setOpen(true)}
                />
                {onHover ? (
                  <img
                    src={images.videoImg1}
                    className="home_video"
                    onClick={() => setOpen(true)}
                    onMouseMove={calculateCenterArea}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src={images.videoImg2}
                    className="home_video"
                    onMouseMove={calculateCenterArea}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: -90,
                left: 90,
                display: "flex",
              }}
            >
              <button
                className="home_top_header_btn2"
                onClick={() => scrollToElement("howdowedoit")}
              >
                Read Your Consumer Bill of Rights{" "}
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#252ABE"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="home_top_header_right">
            <div className="home_top_header_right_text">
              Save{" "}
              <span className="home_top_header_right_text_bold"> 30-50%</span>{" "}
              on the same name brand products you buy today.
            </div>
            <div className="home_top_header_right_text1">
              Empowering you to use your data to save on your wants, needs and
              desires. Where your demand controls everything!
            </div>

            {!tokenData && !localStorage.getItem("zipcode") ? (
              <div
                className="home_top_header_btn_div"
                style={{ display: "flex", gap: "2rem" }}
              >
                <button
                  className="home_top_header_btn1"
                  onClick={() => setOpenNew(true)}
                >
                  Sign In or Create Account
                </button>
                <button
                  className="home_top_header_btn2"
                  onClick={() => setOpen1(true)}
                >
                  Browse as Guest
                </button>
              </div>
            ) : (
              <button
                className="home_top_header_btn1"
                onClick={() =>
                  navigate("/category", {
                    state: {
                      productpicker: false,
                    },
                  })
                }
              >
                Let's Shop
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="home_data_2" style={{ width: "100%", display: "flex" }}>
        <div
          className="homecard"
          style={{ width: "100%", display: "flex" }}
          name="a"
          id="homecard"
        >
          <HomeCard
            data={visibleElementId}
            tokenData={tokenData}
            setTokenData={setTokenData}
            handleScroll={handleScroll}
          />
        </div>
      </div>
      <div name="a" id="productcate" className="pro-cat">
        <div className="home_data_2">
          <ProductCat />
        </div>
      </div>
      <div className="home_data_2">
        <div name="a" id="howdowedoit" className="section CC">
          <DetailsCard />
        </div>
      </div>

      <div className="pro-review">
        <div className="home_data_2">
          <Review />
        </div>
      </div>

      <div className="home_data_2">
        <Questions />
      </div>

      {open1 && (
        <Modal
          centered
          closable={true}
          open={open1}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen1(false)}
        >
          <BrowseAsGuest setOpenNew={setOpen1} />
        </Modal>
      )}

      {openNew && (
        <Modal
          centered
          open={openNew}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpenNew(false)}
        >
          {showSignIn == "signin" && (
            <Login
              navi={true}
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
              scrollToElement={scrollToElement}
              setTokenData={setTokenData}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp
              navi={true}
              setShowSignIn={setShowSignIn}
              setTokenData={setTokenData}
              setOpenNew={setOpenNew}
            />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpenNew}
            />
          )}
        </Modal>
      )}

      <VideoComp
        url="uploads/demandey_video/demandey_supply_chain.mp4"
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};
