import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "./store";
import { toast } from "react-toastify";

export const get_product_list = createAsyncThunk("product", async () => {
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "product-main-category/list",
    { method: "GET" }
  );
  const res = await responce.json();
  return res;
});

export const get_MyItem = createAsyncThunk("myItem", async (id) => {
  const responce = await fetch(
    `${process.env.REACT_APP_BASE_URL}queue/list-save-later-products/consumer-id/${id}`,
    { method: "GET" }
  );
  const res = await responce.json();
  return res;
});

export const delete_MyItem = createAsyncThunk("delete_myItem", async (id) => {
  const { product } = store.getState((state) => state.product);
  const responce = await fetch(
    `${process.env.REACT_APP_BASE_URL}queue/uuid/${id}`,
    { method: "DELETE" }
  );
  const res = await responce.json();
  store.dispatch(get_MyItem(product.consumer_id));
  if (res.success) {
    toast.success(res.message, { toastId: 1 });
  }
  return res;
});

export const add_to_MyItem = createAsyncThunk("add_myItem", async (id) => {
  const { product } = store.getState((state) => state.product);
  const responce = await fetch(
    `${process.env.REACT_APP_BASE_URL}queue/uuid/${id}`,
    { method: "DELETE" }
  );
  const res = await responce.json();
  store.dispatch(get_MyItem(product.consumer_id));
  if (res.success) {
    toast.success(res.message);
  }
  return res;
});

export const get_Queue = createAsyncThunk("queue", async (id) => {
  const responce = await fetch(
    `${process.env.REACT_APP_BASE_URL}queue/list/consumer-id/${id}`,
    { method: "GET" }
  );
  const res = await responce.json();
  return res;
});

export const add_to_Queue = createAsyncThunk("add_queue", async (body) => {
  const { product } = store.getState((state) => state.product);
  const responce = await fetch(`${process.env.REACT_APP_BASE_URL}queue/add`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const res = await responce.json();
  store.dispatch(get_Queue(product.consumer_id));
  if (res.success) {
    toast.success(res.message);
  }
  return res;
});
export const remove_from_Queue = createAsyncThunk(
  "remove_queue",
  async (id) => {
    const { product } = store.getState((state) => state.product);
    const responce = await fetch(
      `${process.env.REACT_APP_BASE_URL}queue/uuid/${id}`,
      { method: "DELETE" }
    );
    const res = await responce.json();
    store.dispatch(get_Queue(product.consumer_id));
    if (res.success) {
      toast.success(res.message);
    }
    return res;
  }
);

export const update_Queue_qty = createAsyncThunk(
  "update_queue",
  async (data) => {
    console.log(data);
    const { product } = store.getState((state) => state.product);
    const responce = await fetch(
      `${process.env.REACT_APP_BASE_URL}queue/update?queue_uuid=${data.queue_uuid}&quantity=${data.quantity}`,
      { method: "GET" }
    );
    const res = await responce.json();
    store.dispatch(get_Queue(product.consumer_id));
    if (res.success) {
      toast.success(res.message);
    }
    return res;
  }
);

const initialState = {
  consumer_id: "",
  consumer_data: {},
  myItemCount: 0,
  queueCount: 0,
  myItemloading: false,
  myItemData: [],
  queueloading: false,
  queueData: [],
  product_list: [],
  p_loading: false,
  remove_queue_loading: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset: () => initialState,
    setConsumerID: (state, action) => {
      state.consumer_id = action.payload;
    },
    setConsumerData: (state, action) => {
      state.consumer_data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(get_product_list.pending, (state, action) => {
      state.p_loading = true;
    });
    builder.addCase(get_product_list.rejected, (state, action) => {
      state.p_loading = false;
    });
    builder.addCase(get_product_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.p_loading = false;
      if (data.success) {
        state.product_list = data.data
          ?.sort((a, b) => a.priority - b.priority)
          .map((v) => v);
      } else {
        state.product_list = [];
      }
    });

    builder.addCase(get_MyItem.pending, (state, action) => {
      state.myItemloading = true;
    });
    builder.addCase(get_MyItem.rejected, (state, action) => {
      state.myItemloading = false;
    });
    builder.addCase(get_MyItem.fulfilled, (state, action) => {
      const data = action.payload;
      state.myItemloading = false;
      if (data.success) {
        state.myItemData = data.data;
        state.myItemCount = data.data.length;
      } else {
        state.myItemData = [];
        state.myItemCount = 0;
      }
    });

    builder.addCase(get_Queue.pending, (state, action) => {
      state.queueloading = true;
    });
    builder.addCase(get_Queue.rejected, (state, action) => {
      state.queueloading = false;
    });
    builder.addCase(get_Queue.fulfilled, (state, action) => {
      const data = action.payload;
      state.queueloading = false;
      if (data.success) {
        state.queueData = data.data;
        state.queueCount = data.data.length;
      } else {
        state.queueData = [];
        state.queueCount = 0;
      }
    });

    builder.addCase(remove_from_Queue.pending, (state, action) => {
      state.remove_queue_loading = true;
    });
    builder.addCase(remove_from_Queue.rejected, (state, action) => {
      state.remove_queue_loading = false;
    });
    builder.addCase(remove_from_Queue.fulfilled, (state, action) => {
      state.remove_queue_loading = false;
    });
  },
});

export const { setConsumerID, reset, setConsumerData } = productSlice.actions;
export default productSlice.reducer;
