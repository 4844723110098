import React, { useEffect, useState } from "react";
import "./SeeAllProduct.css";
import { useLocation } from "react-router-dom";
import cat1 from "../../assets/Category/tv.svg";
import cat2 from "../../assets/Category/shopping1.svg";
import cat3 from "../../assets/Category/rightArrow.svg";
import { useNavigate } from "react-router-dom";
import { image_base_url } from "../../utils/APIS/Apis";
import backImage from "../../assets/backIcon.svg";
import { Base64 } from "js-base64";
import { Modal } from "antd";
import { ProductNotAvailable } from "../../utils/utils";

export const SeeAllProduct = () => {
  const navigate = useNavigate();
  const item = useLocation((state) => state);
  const [rollOverPop, setRollOverPop] = useState(false);

  const itemOne = item?.state;
  const ProductSubCat = itemOne?.Sub_Category_Data;
  useEffect(() => {
    if (!ProductSubCat) {
      navigate("/");
    }
  }, [ProductSubCat]);

  return (
    <div className="SAP">
      <div className="SAP-back">
        <img src={backImage} alt="" className="" onClick={() => navigate(-1)} />
        <div>
          <span onClick={() => navigate("/")}>Home </span>/
          <span
            onClick={() =>
              navigate("/category", {
                state: {
                  productpicker: false,
                },
              })
            }
          >
            Category
          </span>{" "}
          /{itemOne?.category_name}
        </div>
      </div>
      <div className="SAPCat-items " style={{ flexWrap: "wrap" }}>
        <div className="BBCat-Ele">
          <div className="BB-Ele-head">
            <div className="BB-Ele-title">{itemOne?.category_name}</div>
          </div>
          <div className="SAP-items" style={{ marginTop: 20 }}>
            {ProductSubCat?.length > 0 &&
              ProductSubCat?.sort((a, b) => a.priority - b.priority).map(
                (v, i) => (
                  <div
                    className="BB-item"
                    key={i}
                    onClick={() => {
                      if (v?.entity_status == 1) {
                        if (v?.category_name != "Laptops") {
                          navigate(
                            `/categoryitem/${Base64.encode(v?.category_uuid)}/${
                              v?.category_name
                            }`
                          );
                        } else {
                          navigate(
                            `/laptopitem/${Base64.encode(v?.category_uuid)}/${
                              v?.category_name
                            }`
                          );
                        }
                      } else {
                        setRollOverPop(true);
                      }
                    }}
                  >
                    <div
                      className="BB-img-div"
                      style={{
                        border:
                          v?.entity_status == 1
                            ? "5px solid #B8F8E9"
                            : "1px solid #252bbe",
                      }}
                    >
                      <img
                        src={`${image_base_url}${
                          v.category_image_url3 || v.category_image_url2
                        }`}
                        style={{ opacity: v?.entity_status == 1 ? "1" : ".4" }}
                        alt=""
                        className="BB-item-img"
                      />
                    </div>
                    <p className="BB-item-desc">{v?.category_name}</p>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <ProductNotAvailable
        openModal={rollOverPop}
        setOpenModal={setRollOverPop}
      />
    </div>
  );
};
