import OpenAI from "openai";

export async function ai_suggestion(data, setAiResponse, signal) {
  const data1 = {
    recommended_products: data,
  };
  var my_json = JSON.stringify(data1);
  my_json = my_json.replaceAll(" ", "");
  const final_prompt = `Dear GPT, assume the role of a comprehensive and engaging product advisor for a partner.
    You will be examining the provided JSON data, which includes a variety of products.
    Your objective is to distinctly highlight each recommended product's features, attributes, and scores.
    Ensure that each product description is uniquely crafted, employing a diverse set of language styles to maintain an engaging and varied narrative.
    Each summary should be a succinct yet thorough sentence that integrates features, attributes, and scores, and ties them back to the user's specified usages and filters.
    Conclude each product's description with its price, making it the final sentence for that product.
    Begin your response with: Dear Demandey partner, here are the recommended products based on your preferences....
    Aim for a response that is both rich in detail and personable, making each product explanation informative and unique, with a special emphasis on its features, scores, and price.
    Conclude recomendation with final sentence that is cheerful and motivating for the purchase.
    Important Note : Actually when You describe prices for product use comma separated values for pricing.
    Here is the JSON: ${my_json}`;

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, // This is the default and can be omitted
    dangerouslyAllowBrowser: true,
  });

  const stream = await openai.chat.completions.create(
    {
      messages: [
        {
          role: "system",
          content:
            "You are product expert advisor helping user to make decition about online purchase.",
        },
        { role: "user", content: final_prompt },
      ],
      model: "gpt-4",
      stream: true,
    },
    {
      // timeout: 1000 * 60 * 2,
      // responseType: "stream",
      signal: signal,
    }
  );

  for await (const chunk of stream.iterator()) {
    // console.log(chunk?.choices[0]?.delta?.content);
    if (chunk?.choices[0]?.delta?.content === undefined) return;
    // Send each chunk to the client
    try {
      setAiResponse(
        (prevValue) => `${prevValue}${chunk?.choices[0]?.delta?.content}`
      );
      //   return chunk?.choices[0]?.delta?.content;
    } catch (err) {}
  }
}

// const my_data = {
//   recommended_products: [
//     [
//       [
//         {
//           _id: "658bd527e8c86b636990a689",
//           product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//           product_upc_code: "1af48efd-536c-40a9-b97d-e3f2901e8397",
//           variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f801",
//           product_name: "ASUS ROG Zephyrus G14 (2022)",
//           website_url: "https://www.asus.com/us/",
//           product_description:
//             "ASUS - ROG Zephyrus G14 14” 165Hz Gaming Laptop QHD-AMD Ryzen 7 7735HS with 16GB DDR5 Memory-NVIDIA RTX 4050 6G-512GB SSD - Moonlight White",
//           product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//           manufacturer_uuid: "feef344d-a750-4998-8a87-c3f977eb1564",
//           entity_status: "1",
//           created_at: "2023-12-27 08:11:27",
//           updated_at: "2023-12-27 08:11:27",
//           Product_Price_Details: [
//             {
//               _id: "658bfaac7f69e0071047adf1",
//               product_price_uuid: "8364580e-47d7-4462-ac32-837b5da426fe",
//               product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//               currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
//               price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
//               product_mrp: "1099.99",
//               product_price: "725.99",
//               effective_date: "27/12/2023",
//               end_date: "31/12/2024",
//               instant_save: "374",
//               wait_price: "602.57",
//               wait_save: "497.42",
//             },
//           ],
//           Features_Details: [
//             {
//               _id: "658c00cd7f69e0071047ae43",
//               feature_uuid: "5704bbf0-ad43-4a82-ad96-c34609b04be7",
//               sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//               product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//               keyFeature: [
//                 "Brightest Smart Ever Powered by Brightness Booster Max",
//                 "Smooth, swift movement thanks to 120Hz refresh rate",
//               ],
//               features: [
//                 {
//                   Size: 14,
//                 },
//                 {
//                   RAM: "16GB",
//                 },
//                 {
//                   Storage: "512GB",
//                 },
//                 {
//                   Brands: "Asus",
//                 },
//                 {
//                   "Laptop Size": "14 inch",
//                 },
//                 {
//                   "Model Year": "2022",
//                 },
//                 {
//                   "Operating System": "Windows 11",
//                 },
//                 {
//                   "Form Factor": "Traditional (Clamshell)",
//                 },
//                 {
//                   "Hinge Range (degrees)": "180",
//                 },
//                 {
//                   "Hinge Stability": "Good",
//                 },
//                 {
//                   "One Finger Lift": "Yes",
//                 },
//                 {
//                   "Thickness (in.)": "0.9",
//                 },
//                 {
//                   "Width (in)": "12.3",
//                 },
//                 {
//                   "Depth (in.)": "8.9",
//                 },
//                 {
//                   "Weight (lb.)": "3.6",
//                 },
//                 {
//                   "Charger Weight (lb.)": "1.6",
//                 },
//                 {
//                   "RAM Slots": "1",
//                 },
//                 {
//                   "Storage Slots": "1",
//                 },
//                 {
//                   "Replaceable Battery": "Yes",
//                 },
//                 {
//                   "Replaceable Wireless Adapter": "Yes",
//                 },
//                 {
//                   "Screen Resolution": "2560 x 1600",
//                 },
//                 {
//                   "Screen Aspect Ratio": "16:10",
//                 },
//                 {
//                   "Pixel Density": "214 PPI",
//                 },
//                 {
//                   "Panel Type": "IPS",
//                 },
//                 {
//                   "Touch Screen": "No",
//                 },
//                 {
//                   "Screen-To-Body Ratio": "0.85",
//                 },
//                 {
//                   "Refresh Rate": "120Hz",
//                 },
//                 {
//                   "Variable Refresh Rate": "FreeSync",
//                 },
//                 {
//                   "Screen Finish": "Matte",
//                 },
//                 {
//                   "Keyboard Numpad": "No",
//                 },
//                 {
//                   "Keyboard Backlighting": "RGB Zones",
//                 },
//                 {
//                   "Total  Key Travel (mm)": "1.63",
//                 },
//                 {
//                   "Touchpad Size (sq. in.)": "15.3",
//                 },
//                 {
//                   "Touchpad Material": "Glass",
//                 },
//                 {
//                   "Dedicated Touchpad Buttons": "No",
//                 },
//                 {
//                   "Webcam Video Resolution": "720p",
//                 },
//                 {
//                   "USB-A Ports": "2",
//                 },
//                 {
//                   "USB-C Ports": "2",
//                 },
//                 {
//                   Thunderbolt: "No",
//                 },
//                 {
//                   "USB-C Charging": "Yes",
//                 },
//                 {
//                   "USB-C Display Out": "Yes",
//                 },
//                 {
//                   HDMI: "2.0",
//                 },
//                 {
//                   "Display Port": "No",
//                 },
//                 {
//                   "3.5mm Jack": "Combo mic / headphone",
//                 },
//                 {
//                   "Card Reader": "MicroSD UHS 2",
//                 },
//                 {
//                   Ethernet: "No",
//                 },
//                 {
//                   "Proprietary Port": "No",
//                 },
//                 {
//                   "Security Lock": "No",
//                 },
//                 {
//                   WiFi: "Wi-Fi 6E  (802.11ax)",
//                 },
//                 {
//                   Bluetooth: "5.2",
//                 },
//                 {
//                   "CPU Brand and Model": "AMD Ryzen 9 6900HS",
//                 },
//                 {
//                   "GPU Brand and Model": "AMD Radeon RC6700S",
//                 },
//                 {
//                   "VRAM Size": "8GB",
//                 },
//                 {
//                   "RAM Options": "16GB, 32GB",
//                 },
//                 {
//                   "Storage Options": "1TB",
//                 },
//               ],
//               entity_status: "1",
//               created_at: "2023-12-27 11:17:41",
//               updated_at: "2023-12-27 11:17:41",
//             },
//           ],
//           Products_Weights: [
//             {
//               _id: "658bf54c8f8e62e9f12bede0",
//               product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//               variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f801",
//               School: 0.79,
//               Gaming: 0.83,
//               MultiMedia: 0.71,
//               WorkStation: 0.87,
//               Business: 0.8,
//               BuildQuality: 0.8,
//               Hinge: 0.94,
//               Portability: 0.73,
//               Serviceability: 0.77,
//               EaseofAccess: 0.8,
//               RefreshRate: 0.87,
//               Contrast: 0.66,
//               Brightness: 0.83,
//               Reflections: 0.73,
//               BlackUniformity: 0.7,
//               HorizontalViewingAngle: 0.65,
//               VerticalViewingAngle: 0.63,
//               OutOfTheBoxColorAccuracy: 0.75,
//               ColorGamut: 0.94,
//               Flicker: 1,
//               Keyboard: 0.83,
//               TypingQuality: 0.8,
//               Touchpad: 0.88,
//               TrackingQuality: 0.9,
//               Speakers: 0.69,
//               WebcamMicrophone: 0.74,
//               WebcamVideoQuality: 0.75,
//               Ports: 0.8,
//               GeekbenchSynthetics: 0.96,
//               CinebenchCPURendering: 0.96,
//               BlenderRendering: 0.84,
//               BasemarkGPUGameScene: 0.95,
//               StorageDrivePerformance: 0.94,
//               Battery: 0.82,
//               Borderlands3: 0.71,
//               CivilizationVI: 0.83,
//               CounterStrikeGlobalOffensive: 0.96,
//               ShadowOfTheTombRaider: 0.85,
//               ThermalsAndNoise: 0.51,
//               PerformanceOverTime: 0.85,
//             },
//           ],
//         },
//       ],
//       [
//         {
//           _id: "658bd527e8c86b636990a689",
//           product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//           product_upc_code: "1af48efd-536c-40a9-b97d-e3f2901e8397",
//           variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f801",
//           product_name: "ASUS ROG Zephyrus G14 (2022)",
//           website_url: "https://www.asus.com/us/",
//           product_description:
//             "ASUS - ROG Zephyrus G14  14” 165Hz Gaming Laptop QHD-AMD Ryzen 7 7735HS with 16GB DDR5 Memory-NVIDIA RTX 4050 6G-512GB SSD - Moonlight White",
//           product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//           manufacturer_uuid: "feef344d-a750-4998-8a87-c3f977eb1564",
//           entity_status: "1",
//           created_at: "2023-12-27 08:11:27",
//           updated_at: "2023-12-27 08:11:27",
//           Product_Price_Details: [
//             {
//               _id: "658bfaac7f69e0071047adf1",
//               product_price_uuid: "8364580e-47d7-4462-ac32-837b5da426fe",
//               product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//               currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
//               price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
//               product_mrp: "1099.99",
//               product_price: "725.99",
//               effective_date: "27/12/2023",
//               end_date: "31/12/2024",
//               instant_save: "374",
//               wait_price: "602.57",
//               wait_save: "497.42",
//             },
//           ],
//           Features_Details: [
//             {
//               _id: "658c00cd7f69e0071047ae43",
//               feature_uuid: "5704bbf0-ad43-4a82-ad96-c34609b04be7",
//               sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//               product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//               keyFeature: [
//                 "Brightest Smart Ever Powered by  Brightness Booster Max",
//                 "Smooth, swift movement thanks to 120Hz refresh rate",
//               ],
//               features: [
//                 {
//                   Size: 14,
//                 },
//                 {
//                   RAM: "16GB",
//                 },
//                 {
//                   Storage: "512GB",
//                 },
//                 {
//                   Brands: "Asus",
//                 },
//                 {
//                   "Laptop Size": "14 inch",
//                 },
//                 {
//                   "Model Year": "2022",
//                 },
//                 {
//                   "Operating System": "Windows 11",
//                 },
//                 {
//                   "Form Factor": "Traditional (Clamshell)",
//                 },
//                 {
//                   "Hinge Range (degrees)": "180",
//                 },
//                 {
//                   "Hinge Stability": "Good",
//                 },
//                 {
//                   "One Finger Lift": "Yes",
//                 },
//                 {
//                   "Thickness (in.)": "0.9",
//                 },
//                 {
//                   "Width (in)": "12.3",
//                 },
//                 {
//                   "Depth (in.)": "8.9",
//                 },
//                 {
//                   "Weight (lb.)": "3.6",
//                 },
//                 {
//                   "Charger  Weight (lb.)": "1.6",
//                 },
//                 {
//                   "RAM Slots": "1",
//                 },
//                 {
//                   "Storage Slots": "1",
//                 },
//                 {
//                   "Replaceable Battery": "Yes",
//                 },
//                 {
//                   "Replaceable Wireless Adapter": "Yes",
//                 },
//                 {
//                   "Screen Resolution": "2560 x 1600",
//                 },
//                 {
//                   "Screen Aspect Ratio": "16:10",
//                 },
//                 {
//                   "Pixel Density": "214 PPI",
//                 },
//                 {
//                   "Panel  Type": "IPS",
//                 },
//                 {
//                   "Touch Screen": "No",
//                 },
//                 {
//                   "Screen-To-Body Ratio": "0.85",
//                 },
//                 {
//                   "Refresh Rate": "120Hz",
//                 },
//                 {
//                   "Variable Refresh Rate": "FreeSync",
//                 },
//                 {
//                   "Screen Finish": "Matte",
//                 },
//                 {
//                   "Keyboard Numpad": "No",
//                 },
//                 {
//                   "Keyboard Backlighting": "RGB Zones",
//                 },
//                 {
//                   "Total Key Travel (mm)": "1.63",
//                 },
//                 {
//                   "Touchpad Size (sq. in.)": "15.3",
//                 },
//                 {
//                   "Touchpad Material": "Glass",
//                 },
//                 {
//                   "Dedicated Touchpad Buttons": "No",
//                 },
//                 {
//                   "Webcam Video Resolution": "720p",
//                 },
//                 {
//                   "USB-A Ports": "2",
//                 },
//                 {
//                   "USB-C Ports": "2",
//                 },
//                 {
//                   Thunderbolt: "No",
//                 },
//                 {
//                   "USB-C Charging": "Yes",
//                 },
//                 {
//                   "USB-C Display Out": "Yes",
//                 },
//                 {
//                   HDMI: "2.0",
//                 },
//                 {
//                   "Display Port": "No",
//                 },
//                 {
//                   "3.5mm Jack": "Combo mic / headphone",
//                 },
//                 {
//                   "Card Reader": "MicroSD UHS 2",
//                 },
//                 {
//                   Ethernet: "No",
//                 },
//                 {
//                   "Proprietary Port": "No",
//                 },
//                 {
//                   "Security Lock": "No",
//                 },
//                 {
//                   WiFi: "Wi-Fi 6E (802.11ax)",
//                 },
//                 {
//                   Bluetooth: "5.2",
//                 },
//                 {
//                   "CPU Brand and Model": "AMD Ryzen 9 6900HS",
//                 },
//                 {
//                   "GPU Brand and Model": "AMD Radeon RC6700S",
//                 },
//                 {
//                   "VRAM Size": "8GB",
//                 },
//                 {
//                   "RAM Options": "16GB, 32GB",
//                 },
//                 {
//                   "Storage Options": "1TB",
//                 },
//               ],
//               entity_status: "1",
//               created_at: "2023-12-27 11:17:41",
//               updated_at: "2023-12-27 11:17:41",
//             },
//           ],
//           Products_Weights: [
//             {
//               _id: "658bf54c8f8e62e9f12bede0",
//               product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
//               variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f801",
//               School: 0.79,
//               Gaming: 0.83,
//               MultiMedia: 0.71,
//               WorkStation: 0.87,
//               Business: 0.8,
//               BuildQuality: 0.8,
//               Hinge: 0.94,
//               Portability: 0.73,
//               Serviceability: 0.77,
//               EaseofAccess: 0.8,
//               RefreshRate: 0.87,
//               Contrast: 0.66,
//               Brightness: 0.83,
//               Reflections: 0.73,
//               BlackUniformity: 0.7,
//               HorizontalViewingAngle: 0.65,
//               VerticalViewingAngle: 0.63,
//               OutOfTheBoxColorAccuracy: 0.75,
//               ColorGamut: 0.94,
//               Flicker: 1,
//               Keyboard: 0.83,
//               TypingQuality: 0.8,
//               Touchpad: 0.88,
//               TrackingQuality: 0.9,
//               Speakers: 0.69,
//               WebcamMicrophone: 0.74,
//               WebcamVideoQuality: 0.75,
//               Ports: 0.8,
//               GeekbenchSynthetics: 0.96,
//               CinebenchCPURendering: 0.96,
//               BlenderRendering: 0.84,
//               BasemarkGPUGameScene: 0.95,
//               StorageDrivePerformance: 0.94,
//               Battery: 0.82,
//               Borderlands3: 0.71,
//               CivilizationVI: 0.83,
//               CounterStrikeGlobalOffensive: 0.96,
//               ShadowOfTheTombRaider: 0.85,
//               ThermalsAndNoise: 0.51,
//               PerformanceOverTime: 0.85,
//             },
//           ],
//         },
//       ],
//       [
//         {
//           _id: "658bd529e8c86b636990a693",
//           product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
//           product_upc_code: "540c4ab3-66cb-4a80-9634-5c6ba84437e5",
//           variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f806",
//           product_name: "MSI Modern 14 (2023)",
//           website_url: "https://us.msi.com/",
//           product_description:
//             'MSI Modern 14 Laptop: Intel Core i5-1335u, Intel Iris Xe, 14" FHD, 16GB DDR4, 512GB NVMe SSD, 180-Degree Lay-Flat, Type C, Win 11 Home: Beige Rose C13M-606US',
//           product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//           manufacturer_uuid: "2c3143b6-76c1-4b45-81ec-8b0b816c4cb5",
//           entity_status: "1",
//           created_at: "2023-12-27 08:11:29",
//           updated_at: "2023-12-27 08:11:29",
//           Product_Price_Details: [
//             {
//               _id: "658bfaae7f69e0071047adfb",
//               product_price_uuid: "5762d2d8-9aaa-4750-8038-6f9837312505",
//               product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
//               currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
//               price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
//               product_mrp: "699",
//               product_price: "461.34",
//               effective_date: "27/12/2023",
//               end_date: "31/12/2024",
//               instant_save: "237.66",
//               wait_price: "382.91",
//               wait_save: "316.09",
//             },
//           ],
//           Features_Details: [
//             {
//               _id: "658c00ce7f69e0071047ae4d",
//               feature_uuid: "df35b649-a467-45dc-bcae-2f198615acf7",
//               sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//               product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
//               keyFeature: [
//                 "Brightest Smart Ever Powered by Brightness Booster Max",
//                 "Smooth, swift movement thanks to 120Hz refresh rate",
//               ],
//               features: [
//                 {
//                   Size: 14,
//                 },
//                 {
//                   RAM: "16GB",
//                 },
//                 {
//                   Storage: "512GB",
//                 },
//                 {
//                   Brands: "MSI",
//                 },
//                 {
//                   "Laptop Size": "14 inch",
//                 },
//                 {
//                   "Model Year": "2023",
//                 },
//                 {
//                   "Operating System": "Windows 11",
//                 },
//                 {
//                   "Form Factor": "Traditional (Clamshell)",
//                 },
//                 {
//                   "Hinge Range (degrees)": "180",
//                 },
//                 {
//                   "Hinge Stability": "Decent",
//                 },
//                 {
//                   "One Finger Lift": "Yes",
//                 },
//                 {
//                   "Thickness (in.)": "0.8",
//                 },
//                 {
//                   "Width (in)": "12.6",
//                 },
//                 {
//                   "Depth (in.)": "8.9",
//                 },
//                 {
//                   "Weight (lb.)": "3.1",
//                 },
//                 {
//                   "Charger Weight (lb.)": "1.5",
//                 },
//                 {
//                   "RAM Slots": "0",
//                 },
//                 {
//                   "Storage Slots": "1",
//                 },
//                 {
//                   "Replaceable Battery": "Yes",
//                 },
//                 {
//                   "Replaceable Wireless Adapter": "Yes",
//                 },
//                 {
//                   "Screen Resolution": "1920 x 1080",
//                 },
//                 {
//                   "Screen Aspect Ratio": "16:9",
//                 },
//                 {
//                   "Pixel Density": "157 PPI",
//                 },
//                 {
//                   "Panel Type": "IPS",
//                 },
//                 {
//                   "Touch Screen": "No",
//                 },
//                 {
//                   "Screen-To-Body Ratio": "0.81",
//                 },
//                 {
//                   "Refresh Rate": "60Hz",
//                 },
//                 {
//                   "Variable Refresh Rate": "No",
//                 },
//                 {
//                   "Screen Finish": "Matte",
//                 },
//                 {
//                   "Keyboard Numpad": "No",
//                 },
//                 {
//                   "Keyboard Backlighting": "Adjustable",
//                 },
//                 {
//                   "Total Key Travel (mm)": "1.35",
//                 },
//                 {
//                   "Touchpad Size (sq. in.)": "14.0",
//                 },
//                 {
//                   "Touchpad Material": "Plastic",
//                 },
//                 {
//                   "Dedicated Touchpad Buttons": "No",
//                 },
//                 {
//                   "Webcam Video Resolution": "720p",
//                 },
//                 {
//                   "USB-A Ports": "3",
//                 },
//                 {
//                   "USB-C Ports": "1",
//                 },
//                 {
//                   Thunderbolt: "No",
//                 },
//                 {
//                   "USB-C Charging": "Yes",
//                 },
//                 {
//                   "USB-C Display Out": "Yes",
//                 },
//                 {
//                   HDMI: "1.4",
//                 },
//                 {
//                   "Display Port": "No",
//                 },
//                 {
//                   "3.5mm Jack": "Combo mic / headphone",
//                 },
//                 {
//                   "Card Reader": "MicroSD UHS 1",
//                 },
//                 {
//                   Ethernet: "No",
//                 },
//                 {
//                   "Proprietary Port": "No",
//                 },
//                 {
//                   "Security Lock": "No",
//                 },
//                 {
//                   WiFi: "Wi-Fi 6 (802.11ax)",
//                 },
//                 {
//                   Bluetooth: "5.2",
//                 },
//                 {
//                   "CPU Brand and Model": "Intel Core i5-1335U",
//                 },
//                 {
//                   "GPU Brand and Model": "Intel Iris Xe Graphics (Integrated)",
//                 },
//                 {
//                   "VRAM Size": "N/A",
//                 },
//                 {
//                   "RAM Options": "16GB",
//                 },
//                 {
//                   "Storage Options": "512GB, 1TB",
//                 },
//               ],
//               entity_status: "1",
//               created_at: "2023-12-27 11:17:42",
//               updated_at: "2023-12-27 11:17:42",
//             },
//           ],
//           Products_Weights: [
//             {
//               _id: "658bf54e8f8e62e9f12bedea",
//               product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
//               variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f806",
//               School: 0.68,
//               Gaming: 0.6,
//               MultiMedia: 0.62,
//               WorkStation: 0.79,
//               Business: 0.73,
//               BuildQuality: 0.7,
//               Hinge: 0.83,
//               Portability: 0.81,
//               Serviceability: 0.65,
//               EaseofAccess: 0.8,
//               RefreshRate: 0.59,
//               Contrast: 0.76,
//               Brightness: 0.59,
//               Reflections: 0.72,
//               BlackUniformity: 0.88,
//               HorizontalViewingAngle: 0.7,
//               VerticalViewingAngle: 0.69,
//               OutOfTheBoxColorAccuracy: 0.55,
//               ColorGamut: 0.43,
//               Flicker: 1,
//               Keyboard: 0.73,
//               TypingQuality: 0.7,
//               Touchpad: 0.75,
//               TrackingQuality: 0.75,
//               Speakers: 0.53,
//               WebcamMicrophone: 0.61,
//               WebcamVideoQuality: 0.6,
//               Ports: 0.85,
//               GeekbenchSynthetics: 0.84,
//               CinebenchCPURendering: 0.96,
//               BlenderRendering: 0.74,
//               BasemarkGPUGameScene: 0.46,
//               StorageDrivePerformance: 0.94,
//               Battery: 0.45,
//               Borderlands3: 0.16,
//               CivilizationVI: 0.37,
//               CounterStrikeGlobalOffensive: 0.54,
//               ShadowOfTheTombRaider: 0.23,
//               ThermalsAndNoise: 0.76,
//               PerformanceOverTime: 1,
//             },
//           ],
//         },
//       ],
//       [
//         {
//           _id: "658bd529e8c86b636990a695",
//           product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
//           product_upc_code: "da4849e6-cdbf-4f42-b0e3-514b13a4a22d",
//           variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f807",
//           product_name: "Acer Aspire 3 Spin 14 (2023)",
//           website_url: "https://www.acer.com/us-en",
//           product_description:
//             'Acer Aspire 3 Spin 14 Convertible Laptop | 14" 1920 x 1200 IPS Touch Display | Intel Core i3-N305 | Intel UHD Graphics | 8GB LPDDR5 | 128GB SSD | Wi-Fi 6',
//           product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//           manufacturer_uuid: "c238cdfa-510b-48ea-a620-8a92baf9b803",
//           entity_status: "1",
//           created_at: "2023-12-27 08:11:29",
//           updated_at: "2023-12-27 08:11:29",
//           Product_Price_Details: [
//             {
//               _id: "658bfaaf7f69e0071047adfd",
//               product_price_uuid: "b0a105e4-f33d-492e-b470-fe66220d1aaa",
//               product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
//               currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
//               price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
//               product_mrp: "439",
//               product_price: "289.74",
//               effective_date: "27/12/2023",
//               end_date: "31/12/2024",
//               instant_save: "149.26",
//               wait_price: "240.48",
//               wait_save: "198.52",
//             },
//           ],
//           Features_Details: [
//             {
//               _id: "658c00ce7f69e0071047ae4f",
//               feature_uuid: "c3e13ba7-11fd-4c36-aa9c-274e55724c9b",
//               sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//               product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
//               keyFeature: [
//                 "Brightest Smart Ever Powered by Brightness Booster Max",
//                 "Smooth, swift movement thanks to 120Hz refresh rate",
//               ],
//               features: [
//                 {
//                   Size: 14,
//                 },
//                 {
//                   RAM: "8GB",
//                 },
//                 {
//                   Storage: "128GB",
//                 },
//                 {
//                   Brands: "Acer",
//                 },
//                 {
//                   "Laptop Size": "14 inch",
//                 },
//                 {
//                   "Model Year": "2023",
//                 },
//                 {
//                   "Operating System": "Windows 11",
//                 },
//                 {
//                   "Form Factor": "Traditional (Clamshell)",
//                 },
//                 {
//                   "Hinge Range (degrees)": "360",
//                 },
//                 {
//                   "Hinge Stability": "Decent",
//                 },
//                 {
//                   "One Finger Lift": "No",
//                 },
//                 {
//                   "Thickness (in.)": "0.9",
//                 },
//                 {
//                   "Width (in)": "12.6",
//                 },
//                 {
//                   "Depth (in.)": "8.9",
//                 },
//                 {
//                   "Weight (lb.)": "3.2",
//                 },
//                 {
//                   "Charger Weight (lb.)": "0.6",
//                 },
//                 {
//                   "RAM Slots": "0",
//                 },
//                 {
//                   "Storage Slots": "1",
//                 },
//                 {
//                   "Replaceable Battery": "Yes",
//                 },
//                 {
//                   "Replaceable Wireless Adapter": "Yes",
//                 },
//                 {
//                   "Screen Resolution": "1920 x 1200",
//                 },
//                 {
//                   "Screen Aspect Ratio": "16:10",
//                 },
//                 {
//                   "Pixel Density": "162 PPI",
//                 },
//                 {
//                   "Panel Type": "IPS",
//                 },
//                 {
//                   "Touch Screen": "Yes",
//                 },
//                 {
//                   "Screen-To-Body Ratio": "0.83",
//                 },
//                 {
//                   "Refresh Rate": "60Hz",
//                 },
//                 {
//                   "Variable Refresh Rate": "No",
//                 },
//                 {
//                   "Screen Finish": "Glossy",
//                 },
//                 {
//                   "Keyboard Numpad": "No",
//                 },
//                 {
//                   "Keyboard Backlighting": "No",
//                 },
//                 {
//                   "Total Key Travel (mm)": "1.39",
//                 },
//                 {
//                   "Touchpad Size (sq. in.)": "10.6",
//                 },
//                 {
//                   "Touchpad Material": "Plastic",
//                 },
//                 {
//                   "Dedicated Touchpad Buttons": "No",
//                 },
//                 {
//                   "Webcam Video Resolution": "720p",
//                 },
//                 {
//                   "USB-A Ports": "2",
//                 },
//                 {
//                   "USB-C Ports": "1",
//                 },
//                 {
//                   Thunderbolt: "No",
//                 },
//                 {
//                   "USB-C Charging": "Yes",
//                 },
//                 {
//                   "USB-C Display Out": "Yes",
//                 },
//                 {
//                   HDMI: "2.0",
//                 },
//                 {
//                   "Display Port": "No",
//                 },
//                 {
//                   "3.5mm Jack": "Combo mic / headphone",
//                 },
//                 {
//                   "Card Reader": "No",
//                 },
//                 {
//                   Ethernet: "No",
//                 },
//                 {
//                   "Proprietary Port": "No",
//                 },
//                 {
//                   "Security Lock": "Yes",
//                 },
//                 {
//                   WiFi: "Wi-Fi 6 (802.11ax)",
//                 },
//                 {
//                   Bluetooth: "5.2",
//                 },
//                 {
//                   "CPU Brand and Model": "Intel Core i3-N305",
//                 },
//                 {
//                   "GPU Brand and Model": "Intel UHD Graphics (Integrated)",
//                 },
//                 {
//                   "VRAM Size": "N/A",
//                 },
//                 {
//                   "RAM Options": "4GB, 8GB",
//                 },
//                 {
//                   "Storage Options": "128GB, 256GB, 512GB",
//                 },
//               ],
//               entity_status: "1",
//               created_at: "2023-12-27 11:17:42",
//               updated_at: "2023-12-27 11:17:42",
//             },
//           ],
//           Products_Weights: [
//             {
//               _id: "658bf54e8f8e62e9f12bedec",
//               product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
//               variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f807",
//               School: 0.67,
//               Gaming: 0.53,
//               MultiMedia: 0.65,
//               WorkStation: 0.67,
//               Business: 0.67,
//               BuildQuality: 0.65,
//               Hinge: 0.64,
//               Portability: 0.83,
//               Serviceability: 0.6,
//               EaseofAccess: 0.7,
//               RefreshRate: 0.59,
//               Contrast: 0.72,
//               Brightness: 0.61,
//               Reflections: 0.6,
//               BlackUniformity: 0.55,
//               HorizontalViewingAngle: 0.66,
//               VerticalViewingAngle: 0.65,
//               OutOfTheBoxColorAccuracy: 0.41,
//               ColorGamut: 0.38,
//               Flicker: 1,
//               Keyboard: 0.51,
//               TypingQuality: 0.6,
//               Touchpad: 0.64,
//               TrackingQuality: 0.65,
//               Speakers: 0.59,
//               WebcamMicrophone: 0.61,
//               WebcamVideoQuality: 0.6,
//               Ports: 0.8,
//               GeekbenchSynthetics: 0.7,
//               CinebenchCPURendering: 0.72,
//               BlenderRendering: 0.5,
//               BasemarkGPUGameScene: 0.34,
//               StorageDrivePerformance: 0.91,
//               Battery: 0.62,
//               Borderlands3: 0.9,
//               CivilizationVI: 0.27,
//               CounterStrikeGlobalOffensive: 0.18,
//               ShadowOfTheTombRaider: 0.17,
//               ThermalsAndNoise: 0.82,
//               PerformanceOverTime: 1,
//             },
//           ],
//         },
//       ],
//       [
//         {
//           _id: "658bd529e8c86b636990a697",
//           product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
//           product_upc_code: "0dae5963-05de-44b4-af9b-194fd99227fd",
//           variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f808",
//           product_name: "Dell Inspiron 14 (2023)",
//           website_url: "https://www.dell.com/en-us",
//           product_description:
//             'Dell Inspiron i5430 2023 Business Laptop 14" WQXGA IPS 12-Core 13th Intel i7-1360P 16GB LPDDR5 512GB SSD Intel Iris Xe Graphics Thunderbolt 4 Wi-Fi 6E Backlit Keyboard',
//           product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//           manufacturer_uuid: "73ab282f-e84f-41c7-a2d4-23c3e3d2231e",
//           entity_status: "1",
//           created_at: "2023-12-27 08:11:29",
//           updated_at: "2023-12-27 08:11:29",
//           Product_Price_Details: [
//             {
//               _id: "658bfaaf7f69e0071047adff",
//               product_price_uuid: "de630df8-cc13-43ba-a573-5eb6ed324f7d",
//               product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
//               currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
//               price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
//               product_mrp: "1089",
//               product_price: "718.74",
//               effective_date: "27/12/2023",
//               end_date: "31/12/2024",
//               instant_save: "370.26",
//               wait_price: "596.55",
//               wait_save: "492.45",
//             },
//           ],
//           Features_Details: [
//             {
//               _id: "658c00ce7f69e0071047ae51",
//               feature_uuid: "1f42255c-7d4f-46dc-84d8-4efce8bba910",
//               sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
//               product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
//               keyFeature: [
//                 "Brightest Smart Ever Powered by Brightness Booster Max",
//                 "Smooth, swift movement thanks to 120Hz refresh rate",
//               ],
//               features: [
//                 {
//                   Size: 14,
//                 },
//                 {
//                   RAM: "16GB",
//                 },
//                 {
//                   Storage: "512GB",
//                 },
//                 {
//                   Brands: "Dell",
//                 },
//                 {
//                   "Laptop Size": "14 inch",
//                 },
//                 {
//                   "Model Year": "2023",
//                 },
//                 {
//                   "Operating System": "Windows 11",
//                 },
//                 {
//                   "Form Factor": "Traditional (Clamshell)",
//                 },
//                 {
//                   "Hinge Range (degrees)": "130",
//                 },
//                 {
//                   "Hinge Stability": "Decent",
//                 },
//                 {
//                   "One Finger Lift": "No",
//                 },
//                 {
//                   "Thickness (in.)": "0.9",
//                 },
//                 {
//                   "Width (in)": "12.4",
//                 },
//                 {
//                   "Depth (in.)": "8.9",
//                 },
//                 {
//                   "Weight (lb.)": "3.3",
//                 },
//                 {
//                   "Charger Weight (lb.)": "0.7",
//                 },
//                 {
//                   "RAM Slots": "0",
//                 },
//                 {
//                   "Storage Slots": "1",
//                 },
//                 {
//                   "Replaceable Battery": "Yes",
//                 },
//                 {
//                   "Replaceable Wireless Adapter": "Yes",
//                 },
//                 {
//                   "Screen Resolution": "1920 x 1200",
//                 },
//                 {
//                   "Screen Aspect Ratio": "16:10",
//                 },
//                 {
//                   "Pixel Density": "161 PPI",
//                 },
//                 {
//                   "Panel Type": "IPS",
//                 },
//                 {
//                   "Touch Screen": "No",
//                 },
//                 {
//                   "Screen-To-Body Ratio": "0.87",
//                 },
//                 {
//                   "Refresh Rate": "60Hz",
//                 },
//                 {
//                   "Variable Refresh Rate": "No",
//                 },
//                 {
//                   "Screen Finish": "Matte",
//                 },
//                 {
//                   "Keyboard Numpad": "No",
//                 },
//                 {
//                   "Keyboard Backlighting": "Adjustable",
//                 },
//                 {
//                   "Total Key Travel (mm)": "1.33",
//                 },
//                 {
//                   "Touchpad Size (sq. in.)": "14.3",
//                 },
//                 {
//                   "Touchpad Material": "Plastic",
//                 },
//                 {
//                   "Dedicated Touchpad Buttons": "No",
//                 },
//                 {
//                   "Webcam Video Resolution": "1080p",
//                 },
//                 {
//                   "USB-A Ports": "2",
//                 },
//                 {
//                   "USB-C Ports": "1",
//                 },
//                 {
//                   Thunderbolt: "Thunderbold 4",
//                 },
//                 {
//                   "USB-C Charging": "Yes",
//                 },
//                 {
//                   "USB-C Display Out": "Yes",
//                 },
//                 {
//                   HDMI: "1.4",
//                 },
//                 {
//                   "Display Port": "No",
//                 },
//                 {
//                   "3.5mm Jack": "Combo mic / headphone",
//                 },
//                 {
//                   "Card Reader": "SD UHS 1",
//                 },
//                 {
//                   Ethernet: "No",
//                 },
//                 {
//                   "Proprietary Port": "No",
//                 },
//                 {
//                   "Security Lock": "No",
//                 },
//                 {
//                   WiFi: "Wi-Fi 6E (802.11ax)",
//                 },
//                 {
//                   Bluetooth: "5.3",
//                 },
//                 {
//                   "CPU Brand and Model": "Intel Core i5-1335U",
//                 },
//                 {
//                   "GPU  Brand and Model": "Intel Iris Xe Graphics (Integrated)",
//                 },
//                 {
//                   "VRAM Size": "N/A",
//                 },
//                 {
//                   "RAM Options": "8GB, 16GB",
//                 },
//                 {
//                   "Storage Options": "512GB, 1TB",
//                 },
//               ],
//               entity_status: "1",
//               created_at: "2023-12-27 11:17:42",
//               updated_at: "2023-12-27 11:17:42",
//             },
//           ],
//           Products_Weights: [
//             {
//               _id: "658bf54e8f8e62e9f12bedee",
//               product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
//               variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f808",
//               School: 0.76,
//               Gaming: 0.6,
//               MultiMedia: 0.66,
//               WorkStation: 0.76,
//               Business: 0.76,
//               BuildQuality: 0.75,
//               Hinge: 0.54,
//               Portability: 0.82,
//               Serviceability: 0.65,
//               EaseofAccess: 0.8,
//               RefreshRate: 0.59,
//               Contrast: 0.74,
//               Brightness: 0.64,
//               Reflections: 0.71,
//               BlackUniformity: 0.5,
//               HorizontalViewingAngle: 0.63,
//               VerticalViewingAngle: 0.64,
//               OutOfTheBoxColorAccuracy: 0.65,
//               ColorGamut: 0.42,
//               Flicker: 1,
//               Keyboard: 0.77,
//               TypingQuality: 0.75,
//               Touchpad: 0.75,
//               TrackingQuality: 0.75,
//               Speakers: 0.82,
//               WebcamMicrophone: 0.7,
//               WebcamVideoQuality: 0.65,
//               Ports: 0.85,
//               GeekbenchSynthetics: 0.84,
//               CinebenchCPURendering: 0.92,
//               BlenderRendering: 0.69,
//               BasemarkGPUGameScene: 0.51,
//               StorageDrivePerformance: 0.91,
//               Battery: 0.77,
//               Borderlands3: 0.17,
//               CivilizationVI: 0.43,
//               CounterStrikeGlobalOffensive: 0.57,
//               ShadowOfTheTombRaider: 0.36,
//               ThermalsAndNoise: 0.81,
//               PerformanceOverTime: 0.73,
//             },
//           ],
//         },
//       ],
//     ],
//   ],
// };

const my_data = [
  {
    Features_Details: [
      {
        _id: "658c00cd7f69e0071047ae43",
        feature_uuid: "5704bbf0-ad43-4a82-ad96-c34609b04be7",
        sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
        keyFeature: [
          "Brightest Smart Ever Powered by Brightness Booster Max",
          "Smooth, swift movement thanks to 120Hz refresh rate",
        ],
        features: [
          {
            Size: 14,
          },
          {
            RAM: "16GB",
          },
          {
            Storage: "512GB",
          },
          {
            Brands: "Asus",
          },
          {
            "Laptop Size": "14 inch",
          },
          {
            "Model Year": "2022",
          },
          {
            "Operating System": "Windows 11",
          },
          {
            "Form Factor": "Traditional (Clamshell)",
          },
          {
            "Hinge Range (degrees)": "180",
          },
          {
            "Hinge Stability": "Good",
          },
          {
            "One Finger Lift": "Yes",
          },
          {
            "Thickness (in.)": "0.9",
          },
          {
            "Width (in)": "12.3",
          },
          {
            "Depth (in.)": "8.9",
          },
          {
            "Weight (lb.)": "3.6",
          },
          {
            "Charger Weight (lb.)": "1.6",
          },
          {
            "RAM Slots": "1",
          },
          {
            "Storage Slots": "1",
          },
          {
            "Replaceable Battery": "Yes",
          },
          {
            "Replaceable Wireless Adapter": "Yes",
          },
          {
            "Screen Resolution": "2560 x 1600",
          },
          {
            "Screen Aspect Ratio": "16:10",
          },
          {
            "Pixel Density": "214 PPI",
          },
          {
            "Panel Type": "IPS",
          },
          {
            "Touch Screen": "No",
          },
          {
            "Screen-To-Body Ratio": "0.85",
          },
          {
            "Refresh Rate": "120Hz",
          },
          {
            "Variable Refresh Rate": "FreeSync",
          },
          {
            "Screen Finish": "Matte",
          },
          {
            "Keyboard Numpad": "No",
          },
          {
            "Keyboard Backlighting": "RGB Zones",
          },
          {
            "Total Key Travel (mm)": "1.63",
          },
          {
            "Touchpad Size (sq. in.)": "15.3",
          },
          {
            "Touchpad Material": "Glass",
          },
          {
            "Dedicated Touchpad Buttons": "No",
          },
          {
            "Webcam Video Resolution": "720p",
          },
          {
            "USB-A Ports": "2",
          },
          {
            "USB-C Ports": "2",
          },
          {
            Thunderbolt: "No",
          },
          {
            "USB-C Charging": "Yes",
          },
          {
            "USB-C Display Out": "Yes",
          },
          {
            HDMI: "2.0",
          },
          {
            "Display Port": "No",
          },
          {
            "3.5mm Jack": "Combo mic / headphone",
          },
          {
            "Card Reader": "MicroSD UHS 2",
          },
          {
            Ethernet: "No",
          },
          {
            "Proprietary Port": "No",
          },
          {
            "Security Lock": "No",
          },
          {
            WiFi: "Wi-Fi 6E (802.11ax)",
          },
          {
            Bluetooth: "5.2",
          },
          {
            "CPU Brand and Model": "AMD Ryzen 9 6900HS",
          },
          {
            "GPU Brand and Model": "AMD Radeon RC6700S",
          },
          {
            "VRAM Size": "8GB",
          },
          {
            "RAM Options": "16GB, 32GB",
          },
          {
            "Storage Options": "1TB",
          },
        ],
        entity_status: "1",
        created_at: "2023-12-27 11:17:41",
        updated_at: "2023-12-27 11:17:41",
      },
    ],
    Product_Details: [
      {
        _id: "658bd527e8c86b636990a689",
        product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
        product_upc_code: "1af48efd-536c-40a9-b97d-e3f2901e8397",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f801",
        product_name: "ASUS ROG Zephyrus G14 (2022)",
        website_url: "https://www.asus.com/us/",
        product_description:
          "ASUS - ROG Zephyrus G14 14” 165Hz Gaming Laptop QHD-AMD Ryzen 7 7735HS with 16GB DDR5 Memory-NVIDIA RTX 4050 6G-512GB SSD - Moonlight White",
        product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        manufacturer_uuid: "feef344d-a750-4998-8a87-c3f977eb1564",
        entity_status: "1",
        created_at: "2023-12-27 08:11:27",
        updated_at: "2023-12-27 08:11:27",
      },
    ],
    category: [
      {
        _id: "654363dbb7c5676929ce219d",
        category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        category_name: "Laptops",
        category_description: "This Category Related to Laptop Products",
        parent_uuid: "452d1fa5-e147-4088-a029-ff0466aadfaa",
        entity_status: "1",
        priority: 2,
        created_at: "2023-11-02 14:24:51",
        category_image_alt_text1: "image1",
        category_image_alt_text2: "image2",
        category_image_url1:
          "uploads/products/images/image1-1700460030326laptop1.png",
        category_image_url2:
          "uploads/products/images/image2-1703832245465Laptops.png",
        updated_at: "2023-12-29 01:45:54",
        category_image_alt_text3: "image3",
        category_image_url3:
          "uploads/products/images/image3-1703832353975Laptops.png",
      },
    ],
    Products_Weights: [
      {
        _id: "658bf54c8f8e62e9f12bede0",
        product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f801",
        School: 0.79,
        Gaming: 0.83,
        MultiMedia: 0.71,
        WorkStation: 0.87,
        Business: 0.8,
        BuildQuality: 0.8,
        Hinge: 0.94,
        Portability: 0.73,
        Serviceability: 0.77,
        EaseofAccess: 0.8,
        RefreshRate: 0.87,
        Contrast: 0.66,
        Brightness: 0.83,
        Reflections: 0.73,
        BlackUniformity: 0.7,
        HorizontalViewingAngle: 0.65,
        VerticalViewingAngle: 0.63,
        OutOfTheBoxColorAccuracy: 0.75,
        ColorGamut: 0.94,
        Flicker: 1,
        Keyboard: 0.83,
        TypingQuality: 0.8,
        Touchpad: 0.88,
        TrackingQuality: 0.9,
        Speakers: 0.69,
        WebcamMicrophone: 0.74,
        WebcamVideoQuality: 0.75,
        Ports: 0.8,
        GeekbenchSynthetics: 0.96,
        CinebenchCPURendering: 0.96,
        BlenderRendering: 0.84,
        BasemarkGPUGameScene: 0.95,
        StorageDrivePerformance: 0.94,
        Battery: 0.82,
        Borderlands3: 0.71,
        CivilizationVI: 0.83,
        CounterStrikeGlobalOffensive: 0.96,
        ShadowOfTheTombRaider: 0.85,
        ThermalsAndNoise: 0.51,
        PerformanceOverTime: 0.85,
      },
    ],
    Product_Price_Details: [
      {
        _id: "658bfaac7f69e0071047adf1",
        product_price_uuid: "8364580e-47d7-4462-ac32-837b5da426fe",
        product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
        product_mrp: "1099.99",
        product_price: "725.99",
        effective_date: "27/12/2023",
        end_date: "31/12/2024",
        instant_save: "374",
        wait_price: "602.57",
        wait_save: "497.42",
        priceBreakDownForInstantBy: {
          shippingCost: "50.00",
          demandeyCharges: "50.82",
          stateTax: "45.37",
          processingFee: "5.54",
          actualTotal: "877.73",
        },
        priceBreakDownForWaitAndSaveBy: {
          shippingCost: "50.00",
          demandeyCharges: "42.18",
          stateTax: "37.66",
          processingFee: "4.62",
          actualTotal: "737.03",
        },
      },
    ],
    Product_Image_Details: [
      {
        _id: "658d11ef625de206e6c8642d",
        product_image_uuid: "a0bd0ca3-dac8-417e-a3fc-27ecbf815f24",
        product_image_url:
          "uploads/products/images/images-17037439830965535497_sd.jpg",
        imagePaths: [
          "uploads/products/images/images-17037439830965535497_sd.jpg",
          "uploads/products/images/images-17037439831036505497_rd.jpg",
          "uploads/products/images/images-17037439831236525497cv14d.jpg",
          "uploads/products/images/images-17037439831266535497cv1d.jpg",
          "uploads/products/images/images-17037439831276535497cv3d.jpg",
          "uploads/products/images/images-17037439831286535497cv7d.jpg",
          "uploads/products/images/images-17037439831316535497cv11d.jpg",
          "uploads/products/images/images-17037439831326535497cv12d.jpg",
        ],
        product_image_alt_text: "ASUS ROG Zephyrus G14 (2022)",
        product_uuid: "2687ac58-9a81-4bef-9958-acc2d807145f",
      },
    ],
    Manufacturer_Details: [
      {
        _id: "658bc8e4b7e939055a8ee3d9",
        manufacturer_uuid: "feef344d-a750-4998-8a87-c3f977eb1564",
        short_name: "Asus",
        website_url: "https://www.asus.com/us/",
        full_legal_name: "Asus",
        source_type: "XYZ",
        entity_status: "1",
        image_url:
          "uploads/products/images/image-1703659748635Asus-Logo-700x394.png",
        created_at: "2023-12-27 01:49:08",
        updated_at: "2023-12-27 01:49:08",
      },
    ],
    Currency_Details: [
      {
        _id: "6544c8042284c3d380e57681",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        currency_name: "USD",
        currency_symbol: "$",
        description: "US Dollars",
        __v: 0,
      },
    ],
    totalScore: 97,
  },
  {
    Features_Details: [
      {
        _id: "658c00ce7f69e0071047ae4d",
        feature_uuid: "df35b649-a467-45dc-bcae-2f198615acf7",
        sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
        keyFeature: [
          "Brightest Smart Ever Powered by Brightness Booster Max",
          "Smooth, swift movement thanks to 120Hz refresh rate",
        ],
        features: [
          {
            Size: 14,
          },
          {
            RAM: "16GB",
          },
          {
            Storage: "512GB",
          },
          {
            Brands: "MSI",
          },
          {
            "Laptop Size": "14 inch",
          },
          {
            "Model Year": "2023",
          },
          {
            "Operating System": "Windows 11",
          },
          {
            "Form Factor": "Traditional (Clamshell)",
          },
          {
            "Hinge Range (degrees)": "180",
          },
          {
            "Hinge Stability": "Decent",
          },
          {
            "One Finger Lift": "Yes",
          },
          {
            "Thickness (in.)": "0.8",
          },
          {
            "Width (in)": "12.6",
          },
          {
            "Depth (in.)": "8.9",
          },
          {
            "Weight (lb.)": "3.1",
          },
          {
            "Charger Weight (lb.)": "1.5",
          },
          {
            "RAM Slots": "0",
          },
          {
            "Storage Slots": "1",
          },
          {
            "Replaceable Battery": "Yes",
          },
          {
            "Replaceable Wireless Adapter": "Yes",
          },
          {
            "Screen Resolution": "1920 x 1080",
          },
          {
            "Screen Aspect Ratio": "16:9",
          },
          {
            "Pixel Density": "157 PPI",
          },
          {
            "Panel Type": "IPS",
          },
          {
            "Touch Screen": "No",
          },
          {
            "Screen-To-Body Ratio": "0.81",
          },
          {
            "Refresh Rate": "60Hz",
          },
          {
            "Variable Refresh Rate": "No",
          },
          {
            "Screen Finish": "Matte",
          },
          {
            "Keyboard Numpad": "No",
          },
          {
            "Keyboard Backlighting": "Adjustable",
          },
          {
            "Total Key Travel (mm)": "1.35",
          },
          {
            "Touchpad Size (sq. in.)": "14.0",
          },
          {
            "Touchpad Material": "Plastic",
          },
          {
            "Dedicated Touchpad Buttons": "No",
          },
          {
            "Webcam Video Resolution": "720p",
          },
          {
            "USB-A Ports": "3",
          },
          {
            "USB-C Ports": "1",
          },
          {
            Thunderbolt: "No",
          },
          {
            "USB-C Charging": "Yes",
          },
          {
            "USB-C Display Out": "Yes",
          },
          {
            HDMI: "1.4",
          },
          {
            "Display Port": "No",
          },
          {
            "3.5mm Jack": "Combo mic / headphone",
          },
          {
            "Card Reader": "MicroSD UHS 1",
          },
          {
            Ethernet: "No",
          },
          {
            "Proprietary Port": "No",
          },
          {
            "Security Lock": "No",
          },
          {
            WiFi: "Wi-Fi 6 (802.11ax)",
          },
          {
            Bluetooth: "5.2",
          },
          {
            "CPU Brand and Model": "Intel Core i5-1335U",
          },
          {
            "GPU Brand and Model": "Intel Iris Xe Graphics (Integrated)",
          },
          {
            "VRAM Size": "N/A",
          },
          {
            "RAM Options": "16GB",
          },
          {
            "Storage Options": "512GB, 1TB",
          },
        ],
        entity_status: "1",
        created_at: "2023-12-27 11:17:42",
        updated_at: "2023-12-27 11:17:42",
      },
    ],
    Product_Details: [
      {
        _id: "658bd529e8c86b636990a693",
        product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
        product_upc_code: "540c4ab3-66cb-4a80-9634-5c6ba84437e5",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f806",
        product_name: "MSI Modern 14 (2023)",
        website_url: "https://us.msi.com/",
        product_description:
          'MSI Modern 14 Laptop: Intel Core i5-1335u, Intel Iris Xe, 14" FHD, 16GB DDR4, 512GB NVMe SSD, 180-Degree Lay-Flat, Type C, Win 11 Home: Beige Rose C13M-606US',
        product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        manufacturer_uuid: "2c3143b6-76c1-4b45-81ec-8b0b816c4cb5",
        entity_status: "1",
        created_at: "2023-12-27 08:11:29",
        updated_at: "2023-12-27 08:11:29",
      },
    ],
    category: [
      {
        _id: "654363dbb7c5676929ce219d",
        category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        category_name: "Laptops",
        category_description: "This Category Related to Laptop Products",
        parent_uuid: "452d1fa5-e147-4088-a029-ff0466aadfaa",
        entity_status: "1",
        priority: 2,
        created_at: "2023-11-02 14:24:51",
        category_image_alt_text1: "image1",
        category_image_alt_text2: "image2",
        category_image_url1:
          "uploads/products/images/image1-1700460030326laptop1.png",
        category_image_url2:
          "uploads/products/images/image2-1703832245465Laptops.png",
        updated_at: "2023-12-29 01:45:54",
        category_image_alt_text3: "image3",
        category_image_url3:
          "uploads/products/images/image3-1703832353975Laptops.png",
      },
    ],
    Products_Weights: [
      {
        _id: "658bf54e8f8e62e9f12bedea",
        product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f806",
        School: 0.68,
        Gaming: 0.6,
        MultiMedia: 0.62,
        WorkStation: 0.79,
        Business: 0.73,
        BuildQuality: 0.7,
        Hinge: 0.83,
        Portability: 0.81,
        Serviceability: 0.65,
        EaseofAccess: 0.8,
        RefreshRate: 0.59,
        Contrast: 0.76,
        Brightness: 0.59,
        Reflections: 0.72,
        BlackUniformity: 0.88,
        HorizontalViewingAngle: 0.7,
        VerticalViewingAngle: 0.69,
        OutOfTheBoxColorAccuracy: 0.55,
        ColorGamut: 0.43,
        Flicker: 1,
        Keyboard: 0.73,
        TypingQuality: 0.7,
        Touchpad: 0.75,
        TrackingQuality: 0.75,
        Speakers: 0.53,
        WebcamMicrophone: 0.61,
        WebcamVideoQuality: 0.6,
        Ports: 0.85,
        GeekbenchSynthetics: 0.84,
        CinebenchCPURendering: 0.96,
        BlenderRendering: 0.74,
        BasemarkGPUGameScene: 0.46,
        StorageDrivePerformance: 0.94,
        Battery: 0.45,
        Borderlands3: 0.16,
        CivilizationVI: 0.37,
        CounterStrikeGlobalOffensive: 0.54,
        ShadowOfTheTombRaider: 0.23,
        ThermalsAndNoise: 0.76,
        PerformanceOverTime: 1,
      },
    ],
    Product_Price_Details: [
      {
        _id: "658bfaae7f69e0071047adfb",
        product_price_uuid: "5762d2d8-9aaa-4750-8038-6f9837312505",
        product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
        product_mrp: "699",
        product_price: "461.34",
        effective_date: "27/12/2023",
        end_date: "31/12/2024",
        instant_save: "237.66",
        wait_price: "382.91",
        wait_save: "316.09",
        priceBreakDownForInstantBy: {
          shippingCost: "50.00",
          demandeyCharges: "32.29",
          stateTax: "28.83",
          processingFee: "3.56",
          actualTotal: "576.03",
        },
        priceBreakDownForWaitAndSaveBy: {
          shippingCost: "50.00",
          demandeyCharges: "26.80",
          stateTax: "23.93",
          processingFee: "2.97",
          actualTotal: "486.62",
        },
      },
    ],
    Product_Image_Details: [
      {
        _id: "658d1699625de206e6c864d6",
        product_image_uuid: "a414504c-af65-4d0e-baaa-42c60a563081",
        product_image_url: "uploads/products/images/images-17037451771581.jpg",
        imagePaths: [
          "uploads/products/images/images-17037451771581.jpg",
          "uploads/products/images/images-17037451771632.jpg",
          "uploads/products/images/images-17037451771633.jpg",
          "uploads/products/images/images-17037451772934.jpg",
          "uploads/products/images/images-170374517729951KI9deshuL._AC_SL1500_.jpg",
          "uploads/products/images/images-170374517730051n6OEb55bL._AC_SL1500_.jpg",
        ],
        product_image_alt_text: "MSI Modern 14 (2023)",
        product_uuid: "926f9022-0900-42d7-9ff7-a8a4c0fb209a",
      },
    ],
    Manufacturer_Details: [
      {
        _id: "658bc88bb7e939055a8ee3d7",
        manufacturer_uuid: "2c3143b6-76c1-4b45-81ec-8b0b816c4cb5",
        short_name: "MSI",
        website_url: "https://us.msi.com/",
        full_legal_name: "MSI",
        source_type: "XYZ",
        entity_status: "1",
        image_url:
          "uploads/products/images/image-1703659659298MSI-Logo-500x281.png",
        created_at: "2023-12-27 01:47:39",
        updated_at: "2023-12-27 01:47:39",
      },
    ],
    Currency_Details: [
      {
        _id: "6544c8042284c3d380e57681",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        currency_name: "USD",
        currency_symbol: "$",
        description: "US Dollars",
        __v: 0,
      },
    ],
    totalScore: 95,
  },
  {
    Features_Details: [
      {
        _id: "658c00ce7f69e0071047ae4f",
        feature_uuid: "c3e13ba7-11fd-4c36-aa9c-274e55724c9b",
        sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
        keyFeature: [
          "Brightest Smart Ever Powered by Brightness Booster Max",
          "Smooth, swift movement thanks to 120Hz refresh rate",
        ],
        features: [
          {
            Size: 14,
          },
          {
            RAM: "8GB",
          },
          {
            Storage: "128GB",
          },
          {
            Brands: "Acer",
          },
          {
            "Laptop Size": "14 inch",
          },
          {
            "Model Year": "2023",
          },
          {
            "Operating System": "Windows 11",
          },
          {
            "Form Factor": "Traditional (Clamshell)",
          },
          {
            "Hinge Range (degrees)": "360",
          },
          {
            "Hinge Stability": "Decent",
          },
          {
            "One Finger Lift": "No",
          },
          {
            "Thickness (in.)": "0.9",
          },
          {
            "Width (in)": "12.6",
          },
          {
            "Depth (in.)": "8.9",
          },
          {
            "Weight (lb.)": "3.2",
          },
          {
            "Charger Weight (lb.)": "0.6",
          },
          {
            "RAM Slots": "0",
          },
          {
            "Storage Slots": "1",
          },
          {
            "Replaceable Battery": "Yes",
          },
          {
            "Replaceable Wireless Adapter": "Yes",
          },
          {
            "Screen Resolution": "1920 x 1200",
          },
          {
            "Screen Aspect Ratio": "16:10",
          },
          {
            "Pixel Density": "162 PPI",
          },
          {
            "Panel Type": "IPS",
          },
          {
            "Touch Screen": "Yes",
          },
          {
            "Screen-To-Body Ratio": "0.83",
          },
          {
            "Refresh Rate": "60Hz",
          },
          {
            "Variable Refresh Rate": "No",
          },
          {
            "Screen Finish": "Glossy",
          },
          {
            "Keyboard Numpad": "No",
          },
          {
            "Keyboard Backlighting": "No",
          },
          {
            "Total Key Travel (mm)": "1.39",
          },
          {
            "Touchpad Size (sq. in.)": "10.6",
          },
          {
            "Touchpad Material": "Plastic",
          },
          {
            "Dedicated Touchpad Buttons": "No",
          },
          {
            "Webcam Video Resolution": "720p",
          },
          {
            "USB-A Ports": "2",
          },
          {
            "USB-C Ports": "1",
          },
          {
            Thunderbolt: "No",
          },
          {
            "USB-C Charging": "Yes",
          },
          {
            "USB-C Display Out": "Yes",
          },
          {
            HDMI: "2.0",
          },
          {
            "Display Port": "No",
          },
          {
            "3.5mm Jack": "Combo mic / headphone",
          },
          {
            "Card Reader": "No",
          },
          {
            Ethernet: "No",
          },
          {
            "Proprietary Port": "No",
          },
          {
            "Security Lock": "Yes",
          },
          {
            WiFi: "Wi-Fi 6 (802.11ax)",
          },
          {
            Bluetooth: "5.2",
          },
          {
            "CPU Brand and Model": "Intel Core i3-N305",
          },
          {
            "GPU Brand and Model": "Intel UHD Graphics (Integrated)",
          },
          {
            "VRAM Size": "N/A",
          },
          {
            "RAM Options": "4GB, 8GB",
          },
          {
            "Storage Options": "128GB, 256GB, 512GB",
          },
        ],
        entity_status: "1",
        created_at: "2023-12-27 11:17:42",
        updated_at: "2023-12-27 11:17:42",
      },
    ],
    Product_Details: [
      {
        _id: "658bd529e8c86b636990a695",
        product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
        product_upc_code: "da4849e6-cdbf-4f42-b0e3-514b13a4a22d",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f807",
        product_name: "Acer Aspire 3 Spin 14 (2023)",
        website_url: "https://www.acer.com/us-en",
        product_description:
          'Acer Aspire 3 Spin 14 Convertible Laptop | 14" 1920 x 1200 IPS Touch Display | Intel Core i3-N305 | Intel UHD Graphics | 8GB LPDDR5 | 128GB SSD | Wi-Fi 6',
        product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        manufacturer_uuid: "c238cdfa-510b-48ea-a620-8a92baf9b803",
        entity_status: "1",
        created_at: "2023-12-27 08:11:29",
        updated_at: "2023-12-27 08:11:29",
      },
    ],
    category: [
      {
        _id: "654363dbb7c5676929ce219d",
        category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        category_name: "Laptops",
        category_description: "This Category Related to Laptop Products",
        parent_uuid: "452d1fa5-e147-4088-a029-ff0466aadfaa",
        entity_status: "1",
        priority: 2,
        created_at: "2023-11-02 14:24:51",
        category_image_alt_text1: "image1",
        category_image_alt_text2: "image2",
        category_image_url1:
          "uploads/products/images/image1-1700460030326laptop1.png",
        category_image_url2:
          "uploads/products/images/image2-1703832245465Laptops.png",
        updated_at: "2023-12-29 01:45:54",
        category_image_alt_text3: "image3",
        category_image_url3:
          "uploads/products/images/image3-1703832353975Laptops.png",
      },
    ],
    Products_Weights: [
      {
        _id: "658bf54e8f8e62e9f12bedec",
        product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f807",
        School: 0.67,
        Gaming: 0.53,
        MultiMedia: 0.65,
        WorkStation: 0.67,
        Business: 0.67,
        BuildQuality: 0.65,
        Hinge: 0.64,
        Portability: 0.83,
        Serviceability: 0.6,
        EaseofAccess: 0.7,
        RefreshRate: 0.59,
        Contrast: 0.72,
        Brightness: 0.61,
        Reflections: 0.6,
        BlackUniformity: 0.55,
        HorizontalViewingAngle: 0.66,
        VerticalViewingAngle: 0.65,
        OutOfTheBoxColorAccuracy: 0.41,
        ColorGamut: 0.38,
        Flicker: 1,
        Keyboard: 0.51,
        TypingQuality: 0.6,
        Touchpad: 0.64,
        TrackingQuality: 0.65,
        Speakers: 0.59,
        WebcamMicrophone: 0.61,
        WebcamVideoQuality: 0.6,
        Ports: 0.8,
        GeekbenchSynthetics: 0.7,
        CinebenchCPURendering: 0.72,
        BlenderRendering: 0.5,
        BasemarkGPUGameScene: 0.34,
        StorageDrivePerformance: 0.91,
        Battery: 0.62,
        Borderlands3: 0.9,
        CivilizationVI: 0.27,
        CounterStrikeGlobalOffensive: 0.18,
        ShadowOfTheTombRaider: 0.17,
        ThermalsAndNoise: 0.82,
        PerformanceOverTime: 1,
      },
    ],
    Product_Price_Details: [
      {
        _id: "658bfaaf7f69e0071047adfd",
        product_price_uuid: "b0a105e4-f33d-492e-b470-fe66220d1aaa",
        product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
        product_mrp: "439",
        product_price: "289.74",
        effective_date: "27/12/2023",
        end_date: "31/12/2024",
        instant_save: "149.26",
        wait_price: "240.48",
        wait_save: "198.52",
        priceBreakDownForInstantBy: {
          shippingCost: "50.00",
          demandeyCharges: "20.28",
          stateTax: "18.11",
          processingFee: "2.27",
          actualTotal: "380.40",
        },
        priceBreakDownForWaitAndSaveBy: {
          shippingCost: "50.00",
          demandeyCharges: "16.83",
          stateTax: "15.03",
          processingFee: "1.90",
          actualTotal: "324.25",
        },
      },
    ],
    Product_Image_Details: [
      {
        _id: "658d16f0625de206e6c864f0",
        product_image_uuid: "650209c7-9112-4463-8b28-260928e38c03",
        product_image_url: "uploads/products/images/images-17037452645471.jpg",
        imagePaths: [
          "uploads/products/images/images-17037452645471.jpg",
          "uploads/products/images/images-170374526455171+DBJUDpML._AC_SL1500_.jpg",
          "uploads/products/images/images-170374526455371ziw6RxOoL._AC_SL1500_.jpg",
          "uploads/products/images/images-170374526455981XtkiVBYxL._AC_SL1500_.jpg",
        ],
        product_image_alt_text: "Acer Aspire 3 Spin 14 (2023)",
        product_uuid: "8ee4e911-1cb5-44fc-a855-a69bebf90a5a",
      },
    ],
    Manufacturer_Details: [
      {
        _id: "658bcae7b7e939055a8ee3e1",
        manufacturer_uuid: "c238cdfa-510b-48ea-a620-8a92baf9b803",
        short_name: "Acer",
        website_url: "https://www.acer.com/us-en",
        full_legal_name: "Acer",
        source_type: "XYZ",
        entity_status: "1",
        image_url:
          "uploads/products/images/image-1703660263304Acer-Logo-500x313.png",
        created_at: "2023-12-27 01:57:43",
        updated_at: "2023-12-27 01:57:43",
      },
    ],
    Currency_Details: [
      {
        _id: "6544c8042284c3d380e57681",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        currency_name: "USD",
        currency_symbol: "$",
        description: "US Dollars",
        __v: 0,
      },
    ],
    totalScore: 94,
  },
  {
    Features_Details: [
      {
        _id: "658c00ce7f69e0071047ae51",
        feature_uuid: "1f42255c-7d4f-46dc-84d8-4efce8bba910",
        sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
        keyFeature: [
          "Brightest Smart Ever Powered by Brightness Booster Max",
          "Smooth, swift movement thanks to 120Hz refresh rate",
        ],
        features: [
          {
            Size: 14,
          },
          {
            RAM: "16GB",
          },
          {
            Storage: "512GB",
          },
          {
            Brands: "Dell",
          },
          {
            "Laptop Size": "14 inch",
          },
          {
            "Model Year": "2023",
          },
          {
            "Operating System": "Windows 11",
          },
          {
            "Form Factor": "Traditional (Clamshell)",
          },
          {
            "Hinge Range (degrees)": "130",
          },
          {
            "Hinge Stability": "Decent",
          },
          {
            "One Finger Lift": "No",
          },
          {
            "Thickness (in.)": "0.9",
          },
          {
            "Width (in)": "12.4",
          },
          {
            "Depth (in.)": "8.9",
          },
          {
            "Weight (lb.)": "3.3",
          },
          {
            "Charger Weight (lb.)": "0.7",
          },
          {
            "RAM Slots": "0",
          },
          {
            "Storage Slots": "1",
          },
          {
            "Replaceable Battery": "Yes",
          },
          {
            "Replaceable Wireless Adapter": "Yes",
          },
          {
            "Screen Resolution": "1920 x 1200",
          },
          {
            "Screen Aspect Ratio": "16:10",
          },
          {
            "Pixel Density": "161 PPI",
          },
          {
            "Panel Type": "IPS",
          },
          {
            "Touch Screen": "No",
          },
          {
            "Screen-To-Body Ratio": "0.87",
          },
          {
            "Refresh Rate": "60Hz",
          },
          {
            "Variable Refresh Rate": "No",
          },
          {
            "Screen Finish": "Matte",
          },
          {
            "Keyboard Numpad": "No",
          },
          {
            "Keyboard Backlighting": "Adjustable",
          },
          {
            "Total Key Travel (mm)": "1.33",
          },
          {
            "Touchpad Size (sq. in.)": "14.3",
          },
          {
            "Touchpad Material": "Plastic",
          },
          {
            "Dedicated Touchpad Buttons": "No",
          },
          {
            "Webcam Video Resolution": "1080p",
          },
          {
            "USB-A Ports": "2",
          },
          {
            "USB-C Ports": "1",
          },
          {
            Thunderbolt: "Thunderbold 4",
          },
          {
            "USB-C Charging": "Yes",
          },
          {
            "USB-C Display Out": "Yes",
          },
          {
            HDMI: "1.4",
          },
          {
            "Display Port": "No",
          },
          {
            "3.5mm Jack": "Combo mic / headphone",
          },
          {
            "Card Reader": "SD UHS 1",
          },
          {
            Ethernet: "No",
          },
          {
            "Proprietary Port": "No",
          },
          {
            "Security Lock": "No",
          },
          {
            WiFi: "Wi-Fi 6E (802.11ax)",
          },
          {
            Bluetooth: "5.3",
          },
          {
            "CPU Brand and Model": "Intel Core i5-1335U",
          },
          {
            "GPU Brand and Model": "Intel Iris Xe Graphics (Integrated)",
          },
          {
            "VRAM Size": "N/A",
          },
          {
            "RAM Options": "8GB, 16GB",
          },
          {
            "Storage Options": "512GB, 1TB",
          },
        ],
        entity_status: "1",
        created_at: "2023-12-27 11:17:42",
        updated_at: "2023-12-27 11:17:42",
      },
    ],
    Product_Details: [
      {
        _id: "658bd529e8c86b636990a697",
        product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
        product_upc_code: "0dae5963-05de-44b4-af9b-194fd99227fd",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f808",
        product_name: "Dell Inspiron 14 (2023)",
        website_url: "https://www.dell.com/en-us",
        product_description:
          'Dell Inspiron i5430 2023 Business Laptop 14" WQXGA IPS 12-Core 13th Intel i7-1360P 16GB LPDDR5 512GB SSD Intel Iris Xe Graphics Thunderbolt 4 Wi-Fi 6E Backlit Keyboard',
        product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        manufacturer_uuid: "73ab282f-e84f-41c7-a2d4-23c3e3d2231e",
        entity_status: "1",
        created_at: "2023-12-27 08:11:29",
        updated_at: "2023-12-27 08:11:29",
      },
    ],
    category: [
      {
        _id: "654363dbb7c5676929ce219d",
        category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        category_name: "Laptops",
        category_description: "This Category Related to Laptop Products",
        parent_uuid: "452d1fa5-e147-4088-a029-ff0466aadfaa",
        entity_status: "1",
        priority: 2,
        created_at: "2023-11-02 14:24:51",
        category_image_alt_text1: "image1",
        category_image_alt_text2: "image2",
        category_image_url1:
          "uploads/products/images/image1-1700460030326laptop1.png",
        category_image_url2:
          "uploads/products/images/image2-1703832245465Laptops.png",
        updated_at: "2023-12-29 01:45:54",
        category_image_alt_text3: "image3",
        category_image_url3:
          "uploads/products/images/image3-1703832353975Laptops.png",
      },
    ],
    Products_Weights: [
      {
        _id: "658bf54e8f8e62e9f12bedee",
        product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f808",
        School: 0.76,
        Gaming: 0.6,
        MultiMedia: 0.66,
        WorkStation: 0.76,
        Business: 0.76,
        BuildQuality: 0.75,
        Hinge: 0.54,
        Portability: 0.82,
        Serviceability: 0.65,
        EaseofAccess: 0.8,
        RefreshRate: 0.59,
        Contrast: 0.74,
        Brightness: 0.64,
        Reflections: 0.71,
        BlackUniformity: 0.5,
        HorizontalViewingAngle: 0.63,
        VerticalViewingAngle: 0.64,
        OutOfTheBoxColorAccuracy: 0.65,
        ColorGamut: 0.42,
        Flicker: 1,
        Keyboard: 0.77,
        TypingQuality: 0.75,
        Touchpad: 0.75,
        TrackingQuality: 0.75,
        Speakers: 0.82,
        WebcamMicrophone: 0.7,
        WebcamVideoQuality: 0.65,
        Ports: 0.85,
        GeekbenchSynthetics: 0.84,
        CinebenchCPURendering: 0.92,
        BlenderRendering: 0.69,
        BasemarkGPUGameScene: 0.51,
        StorageDrivePerformance: 0.91,
        Battery: 0.77,
        Borderlands3: 0.17,
        CivilizationVI: 0.43,
        CounterStrikeGlobalOffensive: 0.57,
        ShadowOfTheTombRaider: 0.36,
        ThermalsAndNoise: 0.81,
        PerformanceOverTime: 0.73,
      },
    ],
    Product_Price_Details: [
      {
        _id: "658bfaaf7f69e0071047adff",
        product_price_uuid: "de630df8-cc13-43ba-a573-5eb6ed324f7d",
        product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
        product_mrp: "1089",
        product_price: "718.74",
        effective_date: "27/12/2023",
        end_date: "31/12/2024",
        instant_save: "370.26",
        wait_price: "596.55",
        wait_save: "492.45",
        priceBreakDownForInstantBy: {
          shippingCost: "50.00",
          demandeyCharges: "50.31",
          stateTax: "44.92",
          processingFee: "5.49",
          actualTotal: "869.46",
        },
        priceBreakDownForWaitAndSaveBy: {
          shippingCost: "50.00",
          demandeyCharges: "41.76",
          stateTax: "37.28",
          processingFee: "4.57",
          actualTotal: "730.17",
        },
      },
    ],
    Product_Image_Details: [
      {
        _id: "658d1711625de206e6c86502",
        product_image_uuid: "2d066e02-3d18-4d6a-ae40-a228507c24a5",
        product_image_url: "uploads/products/images/images-17037452975841.jpg",
        imagePaths: [
          "uploads/products/images/images-17037452975841.jpg",
          "uploads/products/images/images-17037452975912.jpg",
          "uploads/products/images/images-17037452975933.jpg",
          "uploads/products/images/images-17037452975934.jpg",
          "uploads/products/images/images-170374529759431dcisagOdL._AC_.jpg",
          "uploads/products/images/images-170374529759441PEfB4KwtL._AC_.jpg",
          "uploads/products/images/images-170374529759441TjogIqUjL._AC_.jpg",
          "uploads/products/images/images-1703745297595310UDSfWYHL._AC_.jpg",
        ],
        product_image_alt_text: "Dell Inspiron 14 (2023)",
        product_uuid: "b9a81dd5-494a-4c0c-a289-8e52ae74eeee",
      },
    ],
    Manufacturer_Details: [
      {
        _id: "658bc790b7e939055a8ee3d3",
        manufacturer_uuid: "73ab282f-e84f-41c7-a2d4-23c3e3d2231e",
        short_name: "Dell",
        website_url: "https://www.dell.com/en-us",
        full_legal_name: "Dell",
        source_type: "XYZ",
        entity_status: "1",
        image_url:
          "uploads/products/images/image-1703659408663Dell_Logo.svg.png",
        created_at: "2023-12-27 01:43:28",
        updated_at: "2023-12-27 01:43:28",
      },
    ],
    Currency_Details: [
      {
        _id: "6544c8042284c3d380e57681",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        currency_name: "USD",
        currency_symbol: "$",
        description: "US Dollars",
        __v: 0,
      },
    ],
    totalScore: 93,
  },
  {
    Features_Details: [
      {
        _id: "658c00cf7f69e0071047ae53",
        feature_uuid: "8d5be580-7dc8-4920-b906-0b044612d355",
        sub_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        product_uuid: "1b24f56e-f598-4d0e-8344-936895698aa5",
        keyFeature: [
          "Brightest Smart Ever Powered by Brightness Booster Max",
          "Smooth, swift movement thanks to 120Hz refresh rate",
        ],
        features: [
          {
            Size: 14,
          },
          {
            RAM: "32GB",
          },
          {
            Storage: "1TB",
          },
          {
            Brands: "MSI",
          },
          {
            "Laptop Size": "14 inch",
          },
          {
            "Model Year": "2023",
          },
          {
            "Operating System": "Windows 11",
          },
          {
            "Form Factor": "Traditional (Clamshell)",
          },
          {
            "Hinge Range (degrees)": "170",
          },
          {
            "Hinge Stability": "Good",
          },
          {
            "One Finger Lift": "Yes",
          },
          {
            "Thickness (in.)": "0.9",
          },
          {
            "Width (in)": "12.4",
          },
          {
            "Depth (in.)": "9.1",
          },
          {
            "Weight (lb.)": "3.3",
          },
          {
            "Charger Weight (lb.)": "2.0",
          },
          {
            "RAM Slots": "0",
          },
          {
            "Storage Slots": "1",
          },
          {
            "Replaceable Battery": "Yes",
          },
          {
            "Replaceable Wireless Adapter": "No",
          },
          {
            "Screen Resolution": "1920 x 1200",
          },
          {
            "Screen Aspect Ratio": "16:10",
          },
          {
            "Pixel Density": "161 PPI",
          },
          {
            "Panel Type": "IPS",
          },
          {
            "Touch Screen": "No",
          },
          {
            "Screen-To-Body Ratio": "0.88",
          },
          {
            "Refresh Rate": "60Hz",
          },
          {
            "Variable Refresh Rate": "No",
          },
          {
            "Screen Finish": "Glossy",
          },
          {
            "Keyboard Numpad": "No",
          },
          {
            "Keyboard Backlighting": "Adjustable",
          },
          {
            "Total Key Travel (mm)": "1.32",
          },
          {
            "Touchpad Size (sq. in.)": "14.0",
          },
          {
            "Touchpad Material": "Plastic",
          },
          {
            "Dedicated Touchpad Buttons": "No",
          },
          {
            "Webcam Video Resolution": "1080p",
          },
          {
            "USB-A Ports": "1",
          },
          {
            "USB-C Ports": "2",
          },
          {
            Thunderbolt: "Thunderbolt 4",
          },
          {
            "USB-C Charging": "Yes",
          },
          {
            "USB-C Display Out": "Yes",
          },
          {
            HDMI: "2.0",
          },
          {
            "Display Port": "No",
          },
          {
            "3.5mm Jack": "Combo mic / headphone",
          },
          {
            "Card Reader": "MicroSD UHS 2",
          },
          {
            Ethernet: "No",
          },
          {
            "Proprietary Port": "No",
          },
          {
            "Security Lock": "No",
          },
          {
            WiFi: "Wi-Fi 6E (802.11ax)",
          },
          {
            Bluetooth: "5.2",
          },
          {
            "CPU Brand and Model": "Intel Core i7-13700H",
          },
          {
            "GPU Brand and Model": "Intel Iris Xe Graphics (Integrated)",
          },
          {
            "VRAM Size": "N/a",
          },
          {
            "RAM Options": "8GB, 16GB, 32GB",
          },
          {
            "Storage Options": "512GB, 1TB",
          },
        ],
        entity_status: "1",
        created_at: "2023-12-27 11:17:43",
        updated_at: "2023-12-27 11:17:43",
      },
    ],
    Product_Details: [
      {
        _id: "658bd529e8c86b636990a699",
        product_uuid: "1b24f56e-f598-4d0e-8344-936895698aa5",
        product_upc_code: "051a91b8-e137-4ae8-9ab5-777a254c68e8",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f809",
        product_name: "MSI Prestige 14 Evo (2023)",
        website_url: "https://us.msi.com/",
        product_description:
          'MSI - Prestige 14 EVO 14" Laptop - Intel Core i7-13700H with 32GB Memory - 1TB SSD - Urban Silver',
        product_category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        manufacturer_uuid: "2c3143b6-76c1-4b45-81ec-8b0b816c4cb5",
        entity_status: "1",
        created_at: "2023-12-27 08:11:29",
        updated_at: "2023-12-27 08:11:29",
      },
    ],
    category: [
      {
        _id: "654363dbb7c5676929ce219d",
        category_uuid: "40b6c5a5-1d9d-454a-b01c-f31f3efdb569",
        category_name: "Laptops",
        category_description: "This Category Related to Laptop Products",
        parent_uuid: "452d1fa5-e147-4088-a029-ff0466aadfaa",
        entity_status: "1",
        priority: 2,
        created_at: "2023-11-02 14:24:51",
        category_image_alt_text1: "image1",
        category_image_alt_text2: "image2",
        category_image_url1:
          "uploads/products/images/image1-1700460030326laptop1.png",
        category_image_url2:
          "uploads/products/images/image2-1703832245465Laptops.png",
        updated_at: "2023-12-29 01:45:54",
        category_image_alt_text3: "image3",
        category_image_url3:
          "uploads/products/images/image3-1703832353975Laptops.png",
      },
    ],
    Products_Weights: [
      {
        _id: "658bf54f8f8e62e9f12bedf0",
        product_uuid: "1b24f56e-f598-4d0e-8344-936895698aa5",
        variant_id: "ffa50141-54d1-4f8f-88ba-416a5f87f809",
        School: 0.73,
        Gaming: 0.55,
        MultiMedia: 0.72,
        WorkStation: 0.72,
        Business: 0.74,
        BuildQuality: 0.8,
        Hinge: 0.94,
        Portability: 0.75,
        Serviceability: 0.53,
        EaseofAccess: 0.65,
        RefreshRate: 0.59,
        Contrast: 0.8,
        Brightness: 0.81,
        Reflections: 0.66,
        BlackUniformity: 0.7,
        HorizontalViewingAngle: 0.82,
        VerticalViewingAngle: 0.73,
        OutOfTheBoxColorAccuracy: 0.92,
        ColorGamut: 0.85,
        Flicker: 1,
        Keyboard: 0.77,
        TypingQuality: 0.75,
        Touchpad: 0.75,
        TrackingQuality: 0.75,
        Speakers: 0.67,
        WebcamMicrophone: 0.65,
        WebcamVideoQuality: 0.65,
        Ports: 0.75,
        GeekbenchSynthetics: 0.83,
        CinebenchCPURendering: 0.85,
        BlenderRendering: 0.55,
        BasemarkGPUGameScene: 0.57,
        StorageDrivePerformance: 0.95,
        Battery: 0.65,
        Borderlands3: 0.13,
        CivilizationVI: 0.35,
        CounterStrikeGlobalOffensive: 0.33,
        ShadowOfTheTombRaider: 0.22,
        ThermalsAndNoise: 0.71,
        PerformanceOverTime: 0.7,
      },
    ],
    Product_Price_Details: [
      {
        _id: "658bfaaf7f69e0071047ae01",
        product_price_uuid: "fee36d1f-0f95-4e96-baa2-3d775624ddc9",
        product_uuid: "1b24f56e-f598-4d0e-8344-936895698aa5",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        price_source_uuid: "0ed9a922-4bbf-4a41-a9c2-92fcf09f5bd2",
        product_mrp: "1395",
        product_price: "920.7",
        effective_date: "27/12/2023",
        end_date: "31/12/2024",
        instant_save: "474.3",
        wait_price: "764.18",
        wait_save: "630.82",
        priceBreakDownForInstantBy: {
          shippingCost: "50.00",
          demandeyCharges: "64.45",
          stateTax: "57.54",
          processingFee: "7.01",
          actualTotal: "1099.70",
        },
        priceBreakDownForWaitAndSaveBy: {
          shippingCost: "50.00",
          demandeyCharges: "53.49",
          stateTax: "47.76",
          processingFee: "5.83",
          actualTotal: "921.27",
        },
      },
    ],
    Product_Image_Details: [
      {
        _id: "658d1735625de206e6c8650c",
        product_image_uuid: "8bed03f2-d02b-4256-b927-06e7f8b9f4be",
        product_image_url:
          "uploads/products/images/images-17037453336526539610cv1d.jpg",
        imagePaths: [
          "uploads/products/images/images-17037453336526539610cv1d.jpg",
          "uploads/products/images/images-17037453336526539610cv3d.jpg",
          "uploads/products/images/images-17037453336536539610cv4d.jpg",
          "uploads/products/images/images-17037453336636539610cv7d.jpg",
          "uploads/products/images/images-17037453336646539610cv10d.jpg",
          "uploads/products/images/images-17037453336646539610cv11d.jpg",
          "uploads/products/images/images-17037453336646539610cv12d.jpg",
          "uploads/products/images/images-17037453336646539610ld.jpg",
          "uploads/products/images/images-17037453336656539610_rd.jpg",
          "uploads/products/images/images-17037453337946539610_sd.jpg",
        ],
        product_image_alt_text: "MSI Prestige 14 Evo (2023)",
        product_uuid: "1b24f56e-f598-4d0e-8344-936895698aa5",
      },
    ],
    Manufacturer_Details: [
      {
        _id: "658bc88bb7e939055a8ee3d7",
        manufacturer_uuid: "2c3143b6-76c1-4b45-81ec-8b0b816c4cb5",
        short_name: "MSI",
        website_url: "https://us.msi.com/",
        full_legal_name: "MSI",
        source_type: "XYZ",
        entity_status: "1",
        image_url:
          "uploads/products/images/image-1703659659298MSI-Logo-500x281.png",
        created_at: "2023-12-27 01:47:39",
        updated_at: "2023-12-27 01:47:39",
      },
    ],
    Currency_Details: [
      {
        _id: "6544c8042284c3d380e57681",
        currency_uuid: "051a9616-5c68-4490-a7ab-60c21126246c",
        currency_name: "USD",
        currency_symbol: "$",
        description: "US Dollars",
        __v: 0,
      },
    ],
    totalScore: 94,
  },
];
