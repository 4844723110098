import React from "react";
import "./Input.css";
import { GoEye, GoEyeClosed } from "react-icons/go";

export const Input = ({
  style,
  className,
  type = "text",
  placeholder,
  value,
  disabled = false,
  onChange,
  onFocus,
  onBlur,
  title,
  showPass,
  setShowPass,
}) => {
  return (
    <div className={`input-div ${className}`} style={style}>
      {title && <div className="input-title">{title}</div>}
      <div className="input-wrap">
        <input
          className="input"
          type={showPass ? "text" : type}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
        />
        {type == "password" &&
          (showPass ? (
            <GoEye
              onClick={() => setShowPass(!showPass)}
              className="pass-eye"
            />
          ) : (
            <GoEyeClosed
              onClick={() => setShowPass(!showPass)}
              className="pass-eye"
            />
          ))}
      </div>
    </div>
  );
};

export const InputBank = ({
  style = { width: "80%" },
  placeholder,
  onChange,
  value,
  type = "text",
  disabled = false,
}) => {
  return (
    <div
      style={{
        ...style,
        border: "1px solid #B3B5E8",
        borderRadius: "5px",
        padding: ".75rem 5%",
      }}
    >
      <input
        disabled={disabled}
        type={type}
        style={{ border: "none", outline: "none" }}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
