import React, { useEffect, useRef, useState } from "react";
import "./css/expertpicker.css";
import styles from "../../DataDashboard.module.scss";
import { DataDashBoardHeader } from "../../DataDashboard";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/backIcon.svg";
import arrowDown from "../../../../assets/arrowDown.svg";
import { Input } from "../../../../components/inputs/Input";

const ExpertPickerAnsewersNew = () => {
  const [dataDash, setDataDash] = useState(1);
  const navigate = useNavigate();

  const [openMostView, setOpenMostView] = useState(false);

  const openMostViewRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (
      openMostViewRef.current &&
      !openMostViewRef.current.contains(event.target)
    ) {
      setTimeout(() => {
        setOpenMostView(false);
      }, 100);
    } else {
    }
  };
  return (
    <>
      <DataDashBoardHeader setDataDash={setDataDash} dataDash={dataDash} />
      <div
        className={styles.DEA_main}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "calc(100% - 10%)",
          padding: "1rem 5%",
          backgroundColor: "",
        }}
      >
        <div className="PD-back">
          <img
            src={BackIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ width: "20px", height: "20px", objectFit: "fill" }}
          />
          <div style={{ fontSize: "16px" }}>
            <span style={{ fontSize: "16px" }} onClick={() => navigate("-1")}>
              Back
            </span>
          </div>
        </div>
        <div className="main_expert_component">
          <h1 className="main_expert_div_heading">
            Expert-Product Picker Answers
          </h1>
          <div className="expert_picker_inner_div">
            <div className="expert_picker_inner_first">
              <h2 className="expert_picker_first_heading">Categories</h2>
              <p className="expert_picker_first_desc">
                These are the categories you got recommendations using the
                expert product picker.
              </p>
              <div className="form_picker_expert">
                {/* <div className="expert_picker_search_form">
                  <input
                    placeholder="Search an Expert Product Picker Category"
                    className="search_form_expert"
                    type="text"
                  />
                  <button className="search_form_button">Search</button>
                </div> */}
                <div
                  className={styles.SI_content_1}
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <Input
                    placeholder={"Search an Expert Product Picker Category"}
                    style={{ width: "332.594px" }}
                  />
                  <div
                    className={styles.LD_edit}
                    style={{
                      width: "150px",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: "12px" }}>Search a Category</span>
                  </div>
                </div>
                {/* <div className="expert_picker_filter">
                  <p className="filter_description">Sort By:</p>
                  <select className="filter_select" name="" id="">
                    <option className="filter_options" value=""></option>
                  </select>
                </div> */}
                <div style={{ width: "auto" }}>
                  <div className="bt-right-sort-left">
                    <span style={{ width: "73px" }}>Sort By:</span>
                    <div>
                      <div
                        onClick={() => setOpenMostView(!openMostView)}
                        ref={openMostViewRef}
                        style={{
                          cursor: "pointer",
                          height: "29px",
                          color: "#252bbe",
                        }}
                      >
                        <p>Most Viewed</p>
                        <img src={arrowDown} alt="" />
                      </div>
                      {openMostView && (
                        <div
                          style={{
                            flexDirection: "column",
                            height: "auto",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            borderRadius: "20px",
                            position: "absolute",
                            border: "1.5px solid #252BBE",
                            background: "#F0F2FF",
                            marginTop: ".12rem",
                            gap: "14.72px",
                            color: "#252ABE",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "normal",
                            padding: "1rem 1.5rem",
                          }}
                          className="scroll-in-animation PD-dropdown"
                        >
                          <p>Newest</p>
                          <p>Ratings (High to Low)</p>
                          <p>Price (High to Low)</p>
                          <p>Price (Low to High)</p>
                          <p>Rated Best for Mixed Usage</p>
                          <p>Rated Best for Movies</p>
                          <p>Rated Best for Sports</p>
                          <p>Rated Best for Streaming</p>
                          <p>Rated Best for Gaming</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="expert_picker_inner_second">
              <div className="data_table_expert_picker">
                <table>
                  <tr>
                    <th style={{ padding: "0" }} colSpan="5">
                      <hr style={{ width: "80vw" }} className="table-line" />
                    </th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Category</th>
                    <th>Number of Result Options</th>
                    <th>purchased</th>
                  </tr>
                  <tr>
                    <th style={{ padding: "0" }} colSpan="5">
                      <hr style={{ width: "80vw" }} className="table-line" />
                    </th>
                  </tr>
                  <tr>
                    <td>Jill</td>
                    <td>Smith</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Eve</td>
                    <td>Jackson</td>
                    <td>94</td>
                    <td>94</td>
                    <td>94</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>Adam</td>
                    <td>Johnson</td>
                    <td>67</td>
                    <td>67</td>
                    <td>67</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpertPickerAnsewersNew;
