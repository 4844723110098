import React, { useState } from "react";
import "./HomeCard.css";
import tvicon from "../../../assets/icons/Group1105.svg";
import Group1106 from "../../../assets/icons/Group1106.svg";
import Group1116 from "../../../assets/icons/Group1116.svg";
import Group1115 from "../../../assets/icons/Group1115.svg";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import {
  BrowseAsGuest,
  ForgatePass,
  Login,
  SignUp,
} from "../../auth/LoginSign";
import { images } from "../../../utils/utils";
import VideoComp from "../../VideoComp";

export const HomeCard = ({ tokenData, setTokenData, scrollToElement }) => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open, setOpen] = useState(false);
  const [showSignIn, setShowSignIn] = useState("signin");
  const navigate = useNavigate();

  return (
    <div className="HC">
      <div className="hc_card_1_div">
        <div className={`hc-card-1 `}>
          <div className="hc-card-div1">
            <div className="tvicon">
              <img src={tvicon} alt="" className="hc-tvicon" />
            </div>

            <div className="hc-item">
              <div className="hc-heading">Your Data Dashboard</div>
              <div className="hc-content">
                Take control of your data and manage how you would like to use
                it to save on your wants, needs and desires. Our data dashboard
                provides pathways to the best products to fit your lifestyle.
              </div>
              <button
                className="hc-btn"
                onClick={() => {
                  if (!localStorage.getItem("token")) {
                    setOpen2(true);
                  } else {
                    navigate("/datadashboard");
                  }
                }}
              >
                Manage my Data
              </button>
            </div>

            <div className="hc-images">
              <img src={Group1106} alt="" className="fly-img" />
            </div>
          </div>
          <div
            className="hc-card-div2"
            style={{ display: "flex", justifyContent: "flex-end" }}
          ></div>
        </div>
      </div>
      <div className="hc_card_2_div">
        <div className="card-2nd">
          <div className={`hc-card-2`}>
            <div
              className="hc-card2-div1"
              style={{ backgroundColor: "#5944d1" }}
            >
              <div className="tvicon">
                <img src={Group1116} alt="" className="hc-tvicon2" />
              </div>
              <div className="hc-item">
                <div
                  className="hc-heading hc-heading2"
                  style={{ color: "white" }}
                >
                  Expert Product Picker
                </div>
                <div className="hc-content " style={{ color: "white" }}>
                  Our patented expert product picker is a matchmaker for your
                  wants, needs and desires! Meticulously analyzing your
                  requirements to recommend products that align perfectly with
                  your unique preferences.
                </div>
                <button
                  className="hc-btn2"
                  onClick={() => {
                    if (!localStorage.getItem("zipcode")) {
                      setOpen1(true);
                    } else {
                      navigate("/category", {
                        state: {
                          productpicker: true,
                        },
                      });
                    }
                  }}
                >
                  Select a Product
                </button>
                <div
                  style={{
                    marginTop: -40,
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "flex-end",
                    gap: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <div className="video-img-text">
                    Watch our video on
                    <br />
                    Expert Product Picker (40 Seconds)
                  </div>
                  <img src={images.playIcon} className="video-img2" />
                </div>
              </div>
              <div className="hc-images">
                <img src={Group1115} alt="" className="fly-img2" />
              </div>
            </div>
            <div
              className="hc-card2-div2"
              style={{ display: "flex", justifyContent: "flex-end" }}
            ></div>
          </div>
        </div>
      </div>

      {open2 && (
        <Modal
          centered
          closable={true}
          open={open2}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen2(false)}
        >
          {showSignIn == "signin" && (
            <Login
              navi={true}
              tokenData={tokenData}
              setShowSignIn={setShowSignIn}
              setOpenNew={setOpen2}
              scrollToElement={scrollToElement}
              setTokenData={setTokenData}
              dash={true}
            />
          )}
          {showSignIn == "signup" && (
            <SignUp
              navi={true}
              setTokenData={setTokenData}
              setShowSignIn={setShowSignIn}
              setOpenNew={open2}
            />
          )}
          {showSignIn == "forgatepass" && (
            <ForgatePass setShowSignIn={setShowSignIn} setOpenNew={open2} />
          )}
        </Modal>
      )}
      {open1 && (
        <Modal
          centered
          closable={true}
          open={open1}
          footer={false}
          style={{ padding: "0", margin: "0" }}
          onCancel={() => setOpen1(false)}
        >
          <BrowseAsGuest setOpenNew={setOpen1} expert={true} />
        </Modal>
      )}
      <VideoComp
        url="uploads/demandey_video/demandey_product_picker.mp4"
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};
